<template>
  <div class="mcma-card-button">
    <mcma-button
      :name="name"
      :color="color"
      :uppercase="uppercase"
      :leftImage="getImage"
      :leftIcon="icon"
      :regularIcon="regularIcon"
      :disabled="disabled"
      :isFaIcon="isFaIcon"
      @click="action"
    />
  </div>
</template>

<script>
import McmaButton from "@/components/mcma/common/McmaButton"

export default {
  name: "McmaCardButton",
  components: {
    McmaButton,
  },
  props: {
    color: {
      type: String,
      default: "primary",
    },
    name: {
      type: String,
      default: "button",
    },
    imageIcon: String,
    icon: String,
    uppercase: Boolean,
    regularIcon: Boolean,
    disabled: Boolean,
    isFaIcon: Boolean,
    onClick: Boolean,
  },
  data() {
    return {
      // data: data
    }
  },
  computed: {
    getImage() {
      return this.imageIcon ? require(`@/assets/images/${this.imageIcon}.svg`) : null
    },
  },
  methods: {
    action() {
      this.$emit("click")
    },
  },
}
</script>

<style lang="scss">
@import "./src/assets/scss/variables";
@import "./src/assets/scss/functions";

.mcma-card-button {
  height: 100%;
  width: 100%;
  .mcma-button {
    width: 100%;
    height: 100%;
    .mcma-button-content {
      display: flex;
      flex-flow: column nowrap;
      justify-content: center;
      align-items: center;
      height: calc(100% - 3px - 3px);
      padding: 15.5px 0;
      width: 100%;
      .leftIcon {
        position: relative;
        top: 2px;
        svg {
          width: auto;
          height: 40px;
        }
      }
      .name {
        top: 4px;
      }
    }
  }
}
</style>
