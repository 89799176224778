/* eslint-disable no-undef */
/* eslint-disable spaced-comment */

export function getClientTechInfos() {
  const navigators = {}

  // Opera 8.0+
  navigators.opera = (!!window.opr && !!opr.addons) || !!window.opera || navigator.userAgent.indexOf(" OPR/") >= 0

  // Firefox 1.0+
  navigators.firefox = typeof InstallTrigger !== "undefined"

  // Safari 3.0+ "[object HTMLElementConstructor]"
  navigators.safari =
    /constructor/i.test(window.HTMLElement) ||
    (function (p) {
      return p.toString() === "[object SafariRemoteNotification]"
    })(!window["safari"] || safari.pushNotification)

  // Internet Explorer 6-11
  navigators.ie = /*@cc_on!@*/ false || !!document.documentMode

  // Edge 20+
  navigators.isEdge = !navigators.ie && !!window.StyleMedia

  // Chrome 1+
  navigators.chrome = !!window.chrome && !!window.chrome.webstore

  // Blink engine detection
  navigators.blink = (navigators.chrome || navigators.opera) && !!window.CSS

  return navigators
}
