<template>
  <section v-if="membres && membres.length > 0">
    <label>Progression des {{ total }} membres</label>
    <div class="progressionContainer">
      <div
        v-for="(membreState, index) in membres"
        :key="index + '_' + _uid"
        v-if="membreState.state !== 'total'"
        :style="'width: ' + computedWidths[index] + '%; background-color: ' + computeColor(membreState.state)"
        class="graphPart"
        v-tooltip.auto="$tc('status.' + membreState.state, index) + ': ' + membreState.nbMembre"
      >
        {{ computedWidths[index] }}%
      </div>
    </div>
  </section>
  <section v-else>
    <label>Cette opération ne comporte aucun membre.</label>
  </section>
</template>

<script>
import Constants from "@/util/Constants"

export default {
  name: "progression",
  props: ["membres"],
  computed: {
    total() {
      let res = 0
      if (this.membres) {
        for (const element of this.membres) {
          // console.log(element)
          res = res + element.nbMembre
        }
      }
      console.log("res :  %O", res)
      return res
    },
    computedWidths() {
      let res = []
      let s = 0
      // console.log(this.total)
      for (const element of this.membres) {
        // console.log('element.nbMembre : %O ', element.nbMembre)
        // console.log('element % brute : %O ', element.nbMembre / this.total)
        // console.log('Math arrond : %O ', Math.round(element.nbMembre * 100 / this.total))
        let pourcent = (element.nbMembre * 100) / this.total
        pourcent = Math.round(pourcent * 100) / 100
        // console.log('pourcent : %O', pourcent)
        s = s + pourcent
        // console.log('s : %O', s)
        if (s > 100) {
          pourcent = pourcent - (s - 100)
        }
        // console.log('pourcent : %O', pourcent)
        res.push(pourcent.toFixed(2))
      }
      return res
    },
  },
  methods: {
    computeWidth(value) {
      let pourcent = (value * 100) / this.total
      return pourcent.toFixed(2)
    },
    computeColor(status) {
      // return '#FFFFF';
      switch (status) {
        case "INITIALISED":
          return Constants.STATUS.INITIALISED.color
        case "IN_PROGRESS":
          return Constants.STATUS.IN_PROGRESS.color
        case "EXPORTED":
          return Constants.STATUS.EXPORTED.color
        case "APPROVED":
          return Constants.STATUS.APPROVED.color
        case "NOT_PRESERVED":
          return Constants.STATUS.NOT_PRESERVED.color
      }
    },
    addOrder() {
      this.membres.forEach((element, index, array) => {
        // console.log('element.status : %O', element.state)
        switch (element.state) {
          case "INITIALISED":
            this.membres[index].order = 1
            break
          case "IN_PROGRESS":
            this.membres[index].order = 2
            break
          case "EXPORTED":
            this.membres[index].order = 3
            break
          case "APPROVED":
            this.membres[index].order = 4
            break
          case "NOT_PRESERVED":
            this.membres[index].order = 5
            break
        }
      })
    },
  },
  watch: {
    membres() {
      // console.log('test')
    },
  },
  created() {
    // console.log('progression')
    // console.log('membres : %O', this.membres)
    this.addOrder()
    // console.log('membres : %O', this.membres)
    this.membres.sort(function (a, b) {
      return a.order - b.order
    })
  },
}
</script>

<style lang="scss" scoped>
section {
  display: flex;
  align-content: center;
}

label {
  font-size: 1.1rem;
  line-height: 20px;
  padding-right: 5px;
}

.progressionContainer {
  border-radius: 50px;
  overflow: hidden;
  display: inline-block;
  width: 90%;
  line-height: 3;
}

.graphPart {
  display: inline-block;
  text-align: center;
  color: white;
  font-weight: 600;
}
</style>
