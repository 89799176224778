<template>
  <mcma-card :title="getTitle" icon="edit_site" class="mcma-card-site-detail-header" :inline="true">
    <template #describe>
      <span v-if="site.state === Constants.STATUS.COMPLETED.value">
        Vous avez validé les deux premiers onglets au moins une fois, vous pouvez donc accéder à la certification du
        site.
      </span>
      <span v-else-if="site.state === Constants.STATUS.TO_COMPLETE_MASSE.value">
        Modifier en masse vos sites directement dans la grille de saisie ci-dessous.
      </span>
      <span v-else>
        Veuillez valider les deux premiers onglets au moins une fois pour pouvoir accéder à la certification du site.
      </span>
    </template>
    <template #headerRightSide>
      <mcma-button v-if="site.added && site.id" name="Supprimer" color="error" @click="remove()" />
      <mcma-button
        v-if="site.state === Constants.STATUS.COMPLETED.value"
        name="Certifier le site"
        class="ml12"
        color="success"
        @click="showCertifyModal = true"
      />
    </template>
    <slot></slot>

    <mcma-certify
      :visible="showCertifyModal"
      :canCertify="site.state === Constants.STATUS.COMPLETED.value"
      noCertifyMessage="Ce site ne peut pas être certifié."
      :title="`Validation du site : ${site.nom}`"
      who="ce site"
      @onCancel="closeCertifyModal"
      @onCertify="certify"
    />
  </mcma-card>
</template>

<script>
import McmaButton from "@/components/mcma/common/McmaButton"
import McmaCard from "@/components/mcma/common/McmaCard"
import McmaCertify from "@/components/mcma/common/McmaCertify"
import Axios from "@/util/Axios"
import Constants from "@/util/Constants"

export default {
  name: "SitesDetailCardHeader",
  props: {
    site: Object,
  },
  components: {
    McmaCard,
    McmaButton,
    McmaCertify,
  },
  data() {
    return {
      showCertifyModal: false,
    }
  },
  computed: {
    Constants() {
      return Constants
    },
    getTitle() {
      if (this.site.id) {
        return `Edition du site ${this.site.nom}`
      } else if (this.site.masse) {
        return "Edition en masse des sites"
      }
      return "Création d'un nouveau site"
    },
  },
  methods: {
    goTo(route) {
      if (typeof route === "string") {
        this.$router.push({ name: route })
      } else if (typeof route === "object") {
        this.$router.push(route)
      }
    },
    closeCertifyModal() {
      this.showCertifyModal = false
    },
    certify() {
      this.closeCertifyModal()
      Axios("patch", `api/sites/${this.site.id}`, {
        certified: true,
      })
        .then(() => {
          this.$notification.success({
            message: `Site Certifié !`,
            description: `Le site à bien été certifié !`,
          })
          this.$router.push({
            name: "sites",
          })
        })
        .catch((error) => {
          console.error("error", error)
          this.$notification.error({
            message: `Erreur de validation`,
            description: `Une erreur c'est produite lors de la certification du site, veuillez contacter un administrateur.`,
          })
        })
    },
    remove() {
      const that = this
      that.$confirm({
        title: `Supprimer le site`,
        content: `Voulez-vous vraiment supprimer de façon irréversible le site ${this.site.nom} ?`,
        okText: "Oui",
        okType: "success",
        cancelText: "Non",
        onOk() {
          Axios("delete", "api/sites/" + that.site.id)
            .then((reponse) => {
              that.$notification.success({
                message: `Site supprimé`,
                description: `Le site à bien été supprimé`,
              })
              that.$router.push({
                name: "sites",
              })
            })
            .catch((error) => {
              console.error(error)
              that.$notification.error({
                message: `Erreur de suppression`,
                description: `Erreur lors de la suppression du site ${this.site.nom}`,
              })
            })
        },
        onCancel() {
          console.log(
            `%c Cancel Delete Site`,
            "background:red ; padding: 5px 7px 4px 0px; border-radius: 3px;  color: #FFFFFF"
          )
        },
      })
    },
  },
}
</script>

<style lang="scss"></style>
