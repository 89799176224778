<template>
  <mcma-card title="Nouvelle pièce" icon="add_user" class="mcma-card-header">
    <!--    <template #describe>-->
    <!--      -->
    <!--    </template>-->
    <slot></slot>
  </mcma-card>
</template>

<script>
import McmaCard from "@/components/mcma/common/McmaCard"

export default {
  name: "DocumentsManagerDetailCardHeader",
  components: {
    McmaCard,
  },
}
</script>

<style lang="scss"></style>
