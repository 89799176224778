<template>
  <section>
    <div class="container">
      <a-row type="flex" :gutter="12">
        <a-col :span="24">
          <contact-us-header>
            <contact-us-form :currentUser="currentUser" :membre="membre" :operation="operation" />
          </contact-us-header>
        </a-col>
      </a-row>
    </div>
  </section>
</template>

<script>
import ContactUsForm from "@/components/mcma/help/contactUs/components/ContactUsForm"
import ContactUsHeader from "@/components/mcma/help/contactUs/components/ContactUsHeader"
import { mapState, mapMutations } from "vuex"

export default {
  name: "ContactUs",
  components: {
    ContactUsHeader,
    ContactUsForm,
  },
  computed: {
    ...mapState({
      currentUser: "user",
      membre: "membre",
      operation: "operation",
    }),
  },
  methods: {
    ...mapMutations(["setBreadcrumbAdd"]),
  },
  created() {
    this.setBreadcrumbAdd({
      name: "Contact",
      action: () => {},
    })
  },
}
</script>

<style lang="scss" scoped></style>
