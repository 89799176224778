<template>
  <main>
    <section class="container">
      <form class="card">
        <article class="card-content">
          <span class="card-title"> Documents </span>
          <blockquote>
            Cette interface vous permet de déposer des documents, par exemple :
            <br />
            - des factures existantes qui concernent votre périmètre,
            <br />
            - ou autre document concernent votre périmètre de site.
            <br />
            Taille maximal des fichiers : {{ UPLOAD_SIZE }}Mo.
            <template v-if="membre.status === 'EXPORTED' || membre.status === 'APPROVED'">
              <br /><br />
              Vous avez certifié ce membre, vous ne pouvez donc plus ajouter ni modifier les factures.
            </template>
          </blockquote>
        </article>
        <article class="card-action">
          <div class="row center-align">
            <div class="file-field input-field col s4 offset-s4 center-align">
              <div class="btn waves-effect waves-light blue darken-2" id="uploadBillAdd">
                <span>Deposer des documents</span>
                <input type="file" multiple @change="processFiles" />
              </div>
            </div>
          </div>
        </article>
      </form>
    </section>
    <section class="row">
      <article class="col s12" id="uploadBillGrid">
        <place-holder :notReady="!factures">
          <grid
            v-if="factures"
            name="Liste des documents"
            :rows="factures"
            :columns="['name', 'actions']"
            :columnHeader="{ name: 'Nom', actions: 'Actions' }"
            :columnsNoSearch="['Actions']"
          >
            <template slot="actions" slot-scope="props">
              <i
                class="material-icons pointer deep-orange-text uploadBillDelete"
                v-tooltip.auto="'Supprimer la facture'"
                @click="remove(props.row)"
                v-if="membre.status !== 'EXPORTED' && membre.status !== 'APPROVED'"
                >remove</i
              >

              <i
                class="material-icons pointer blue-grey-text uploadBillDownload"
                v-tooltip.auto="'Télécharger la facture'"
                @click="download(props.row)"
                >file_download</i
              >
            </template>
          </grid>
        </place-holder>
      </article>
    </section>
    <a class="ninja" ref="downloadLink" />
  </main>
</template>

<script>
import Mixin from "../Mixin"
import { mapState, mapMutations } from "vuex"
import Axios from "@/util/Axios"
import Grid from "@/components/common/Grid"
import Materialize from "materialize-css"
import _ from "lodash"
import PlaceHolder from "@/components/common/PlaceHolder"

export default {
  name: "factures",
  mixins: [Mixin],
  components: {
    Grid,
    PlaceHolder,
  },
  data() {
    return {
      flow: "DOCUMENTS",
      factures: null,
    }
  },
  computed: {
    ...mapState(["membre", "operation"]),
    UPLOAD_SIZE() {
      return process.env.VUE_APP_UPLOAD_SIZE
    },
  },
  methods: {
    ...mapMutations(["setBreadcrumbAdd"]),
    initialize() {
      Axios("get", "file/facture?membreId=" + this.membre.id).then((response) => {
        this.factures = _.map(response.data, (facture) => {
          return {
            name: facture,
          }
        })
      })
    },
    remove(facture) {
      if (confirm('Voulez-vous vraiment supprimer le document "' + facture.name + '" ?')) {
        Axios("delete", "file/facture?membreId=" + this.membre.id + "&fileName=" + facture.name)
          .then((response) => {
            this.initialize()
            Materialize.toast("La facture a bien été supprimé", 4000, "lime")
          })
          .catch((error) => {
            console.error(error)
            Materialize.toast("Erreur lors de la suppression", 4000, "amber white-text")
          })
      }
    },
    processFiles(event) {
      const sizeMax = process.env.VUE_APP_UPLOAD_SIZE * 1024 * 1024

      for (const file of event.target.files) {
        if (file.size > sizeMax) {
          Materialize.toast(
            'La facture "' +
              file.name +
              '" dépasse la taille autorisé qui est de ' +
              process.env.VUE_APP_UPLOAD_SIZE +
              "Mo.",
            4000,
            "amber white-text"
          )
        } else {
          const formData = new FormData()
          formData.append("membreId", this.membre.id)
          formData.append("file", file)
          Axios("post", "/file/facture", formData, null, {
            "Content-Type": "multipart/form-data",
          })
            .then((response) => {
              this.initialize()
              Materialize.toast('La facture "' + file.name + '" a bien été ajouté', 4000, "lime")
            })
            .catch((error) => {
              console.error(error)
              Materialize.toast("Erreur lors de l'ajout de la facture \"" + file.name, 4000, "amber white-text")
            })
        }
      }
    },
    download(facture) {
      Axios("get", "file/facture/download?membreId=" + this.membre.id + "&fileName=" + facture.name, null, {
        responseType: "blob",
      })
        .then((response) => {
          const link = this.$refs.downloadLink
          link.href = window.URL.createObjectURL(new Blob([response.data]))
          link.setAttribute("download", facture.name)
          link.click()
        })
        .catch((error) => {
          console.error(error)
          Materialize.toast("Erreur lors du téléchargement", 4000, "amber white-text")
        })
    },
  },
  created() {
    this.setBreadcrumbAdd({
      name: "Factures",
      action: () => {
        this.$router.push({
          name: "factures",
        })
      },
    })
    this.initialize()
  },
}
</script>

<style lang="scss" scoped>
.card-action {
  padding-top: 0px;
}
.card-action > .row {
  margin-bottom: 0px;
}
</style>
