<template>
  <a-modal
    v-model="visible"
    :maskClosable="false"
    class="mcma-multi-membre-modal"
    :class="{ 'is-multi': membres && membres.length > 1 }"
    title="Sélectionnez le membre"
  >
    <div v-if="membres && membres.length > 1" class="container-list">
      <div class="sentence">
        <strong>
          Votre identifiant étant rattaché à plusieurs membres et/ou opérations, merci de sélectionner ci-dessous celui sur lequel
          vous voulez opérer.
        </strong>
        <p>
          (Une fois dans le site, une petite languette apparaitra en haut à droite pour vous permettre de changer
          directement de membre sans avoir à vous reconnecter)
        </p>
      </div>
      <ul class="membres-list">
        <li
          class="membre-item"
          v-for="(membre, index) in $orderBy(membres, 'id')"
          :key="membre.id"
          :style="{ borderColor: multiSiteColor[index] }"
          @click="handleOk(membre)"
        >
          <div class="avatar-block" :style="{ backgroundColor: multiSiteColor[index] }">
            <span class="avatar">
              {{ getRealMembreName(membre.nom)[0] }}
            </span>
          </div>
          <div class="name">
            {{ membre.nom }}
          </div>
          <div class="operation-name">
            {{ membre.operationName }}
          </div>
        </li>
      </ul>
      <div class="inline-buttons">
        <mcma-button name="Me déconnecter" color="primary" class="mt24" @click="disconnect" />
        <mcma-button name="Rafraichir" color="orange" class="mt24" @click="refreshData" />
      </div>
    </div>
    <div v-else>
      <mcma-empty
        :inCard="true"
        image="cant_certify"
        :center="true"
        :imageSize="300"
        message="Votre utilisateur n'est rattaché à aucun membre actif, veuillez contacter le responsable de l'opération."
      >
        <div class="inline-buttons">
          <mcma-button name="Me déconnecter" color="primary" class="mt24" @click="disconnect" />
          <mcma-button name="Rafraichir" color="orange" class="mt24" @click="refreshData" />
        </div>
      </mcma-empty>
    </div>
  </a-modal>
</template>

<script>
// <mcma-multi-membre-modal :visible=""
//                    @membreSelected="" />

import McmaEmpty from "@/components/mcma/common/McmaEmpty"
import McmaButton from "@/components/mcma/common/McmaButton"
import { mapActions, mapState } from "vuex"

export default {
  name: "McmaMultiMembreModal",
  props: {
    visible: Boolean,
  },
  components: {
    McmaButton,
    McmaEmpty,
  },
  data() {
    return {}
  },
  computed: {
    ...mapState(["membres", "multiSiteColor"]),
    getRealMembreName() {
      return (nom) => {
        if (nom.includes("]")) {
          const split = nom.split("]")
          return split[1].trim()
        }
        return nom
      }
    },
  },
  watch: {
    visible(value) {
      if (value) {
      }
    },
  },
  methods: {
    ...mapActions(["disconnect"]),
    handleOk(membre) {
      this.$emit("membreSelected", membre)
    },
    refreshData() {
      this.$emit("refresh")
    },
  },
}
</script>

<style lang="scss">
@import "./src/assets/scss/variables";
@import "./src/assets/scss/functions";

.mcma-multi-membre-modal {
  font-family: $font-family !important;
  width: auto !important;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;
  margin: 0 50px;
  &.is-multi {
    top: 200px;
  }
  .ant-modal-content {
    display: inline-flex;
    .ant-modal-header,
    .ant-modal-footer {
      display: none;
    }
  }
  .container-list {
    display: inline-flex;
    flex-flow: column nowrap;
    align-items: center;
    justify-content: center;
    width: 100%;
    .sentence {
      margin: 20px 0 50px 0;
      padding: 0 20px;
      width: 100%;
      text-align: center;
      strong {
        color: var(--primary-color, $primary-color);
        font-size: 16px;
      }
      p {
      }
    }
    .membres-list {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      justify-content: center;
      margin-bottom: 50px;
      .membre-item {
        margin-right: 20px;
        padding: 10px 12px;
        display: flex;
        flex-flow: column nowrap;
        align-items: center;
        justify-content: flex-start;
        border-width: 4px;
        border-style: solid;
        border-radius: 15px;
        width: 200px;
        height: 180px;
        position: relative;
        cursor: pointer;
        transition: background 0.2s ease;
        &:hover {
          background: hsla(var(--primary-color-h), var(--primary-color-s), var(--primary-color-l), 0.1);
        }
        &:last-child {
          margin-right: 0;
        }
        .avatar-block {
          min-height: 50px;
          min-width: 50px;
          display: flex;
          border-radius: 50px;
          margin: 10px 0 10px 0;
          .avatar {
            margin: auto;
            font-weight: 900;
            font-size: 17px;
            color: $white;
          }
        }
        .name {
          font-weight: 900;
          font-size: 14px;
          line-height: 18px;
          text-align: center;
        }
        .operation-name {
          font-weight: 600;
          font-size: 14px;
          text-align: center;
          color: var(--secondary-color, $orange);
          position: absolute;
          bottom: 10px;
        }
      }
    }
  }
}
</style>
