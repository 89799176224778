/* eslint-disable standard/computed-property-even-spacing */
<template>
  <form class="card" @submit.prevent="submit">
    <section class="card-content">
      <span class="card-title"> Identification et localisation du site </span>
      <blockquote v-if="!site.id">
        Tant que les informations d'identification du site que vous voulez créer ne sont pas complétées, vous ne pouvez
        pas accéder aux autres onglets.
      </blockquote>

      <!-- for debug -->
      <!--
      this.confNom : {{ this.confNom }} <br />
      this.confCodeSite : {{ this.confCodeSite }} <br />
      this.confRae : {{ this.confRae }} <br />
      this.confFluide : {{ this.confFluide }} <br />
      this.confStatus : {{ this.confStatus }} <br />
      this.confAdresse : {{ this.confAdresse }} <br />
      this.confCodePostal : {{ this.confCodePostal }} <br />
      this.confCommune : {{ this.confCommune }} <br />
      this.confCodeImputation : {{ this.confCodeImputation }} <br />
      this.confDateActivation : {{ this.confDateActivation }} <br />
      this.confDateFinContrat : {{ this.confDateFinContrat }} <br />
      -->

      <div class="row">
        <!-- <generic-field v-for="field in fields" :key="field.type" v-model="field.value" :field="field" class="col s6"/> -->

        <!-- <hr/> -->
        <!-- confNom : {{ this.confNom.required }} -->
        <article class="input-field col s6">
          <input id="name" type="text" v-model="nom" required />
          <label :class="{ active: nom }" for="name">
            <font-awesome-icon icon="asterisk" class="red-text" />
            {{ this.confNom.name }}
          </label>
        </article>

        <!-- CodeSite : {{ this.confCodeSite.displayed }} -->
        <article v-if="this.confCodeSite === undefined || this.confCodeSite.displayed" class="input-field col s6">
          <input v-if="this.confCodeSite.displayed" id="code" type="text" v-model="codeSite" />
          <label :class="{ active: codeSite }" for="code" maxlength="20">
            Code Groupement pour l’annexe personnalisée de la facture
          </label>
        </article>

        <article class="col s6" v-if="site.new && (fluidFieldElec || fluidFieldGaz)">
          <label v-if="this.confFluide === undefined || this.confFluide.required">
            <font-awesome-icon icon="asterisk" class="red-text" />
            Fluide <strong>(non modifiable une fois sauvegardé)</strong>
          </label>
          <select class="browser-default" v-model="fluide" required>
            <option value="" disabled selected>Sélectionnez un fluide</option>
            <option value="ELEC" v-if="fluidFieldElec">Electricite</option>
            <option value="GAZ" v-if="fluidFieldGaz">Gaz</option>
          </select>
        </article>
        <!-- <generic-field :key="fluidField.type" v-model="fluidField" class="col s6" v-if="site.new"/> -->

        <rae v-if="this.confRae === undefined || this.confRae.required" class="col s6" v-model="rae" :context="site" />

        <site-status
          v-if="this.confStatus === undefined || this.confStatus.displayed"
          class="col s6"
          v-model="status"
          :context="site"
        />

        <code-imputation
          v-if="this.confCodeImputation === undefined || this.confCodeImputation.displayed"
          class="col s6"
          v-model="codeImputation"
          :context="site"
        />

        <date-activation
          v-if="this.confDateActivation === undefined || this.confDateActivation.displayed"
          class="col s6"
          v-model="dateActivation"
          :context="site"
        />
        <date-fin-contrat
          v-if="this.confDateFinContrat === undefined || this.confDateFinContrat.displayed"
          class="col s6"
          v-model="dateResiliation"
          :context="site"
        />

        <!-- <article class="input-field col s6">
          <input id="ref_tdg" type="text" v-model="refTDG" disabled>
          <label :class="{active: refTDG}" for="ref_tdg">Réf TDG</label>
        </article>

        <article class="input-field col s6">
          <input id="ref_site" type="text" v-model="refSite" disabled>
          <label :class="{active: refSite}" for="ref_site">Réf Site</label>
        </article> -->

        <article v-if="this.confAdresse === undefined || this.confAdresse.displayed" class="input-field col s6">
          <input v-if="confAdresse.displayed" id="adresse" type="text" required v-model="adresse" />
          <label :class="{ active: adresse }" for="adresse">
            <font-awesome-icon icon="asterisk" class="red-text" />
            Adresse
          </label>
        </article>

        <article v-if="this.confCommune === undefined || this.confCommune.displayed" class="input-field col s6">
          <input v-if="confCommune.displayed" id="commune" type="text" required v-model="commune" />
          <label :class="{ active: commune }" for="commune">
            <font-awesome-icon icon="asterisk" class="red-text" />
            Commune
          </label>
        </article>

        <code-postal
          v-if="this.confCodePostal === undefined || this.confCodePostal.displayed"
          class="col s6"
          v-model="codePostal"
        />

        <code-insee class="col s6" v-model="codeInsee" />

        <section class="interlocuteur-wrapper">
          <div>
            <i class="material-icons">edit</i> Interlocuteur technique.<br />
            N.B : Si l'interlocuteur souhaité n'apparaît pas dans la liste, veuillez vérifier que celui-ci possède
            toutes les informations requises (prénom, nom, email, téléphone).
            <router-link
              :to="{ name: 'interlocuteurs' }"
              class="waves-effect waves-light btn blue darken-2 tutorialNoBtn"
              id="validInterlocutorBtn"
            >
              Vérifier mes informations interlocuteurs
            </router-link>
            <br />
            <span v-if="membre.interlocuteurTechnique && customInterlocuteur">
              <i class="material-icons">info</i>
              <i>L'interlocuteur technique pour ce site est différent de l'interlocuteur technique général du membre</i>
            </span>
          </div>
          <ul v-if="interlocuteurTech" class="row">
            <li>
              <label>Sélectionner un interlocuteur :</label>
              <select class="browser-default" v-model="currentInterlocuteur">
                <option v-for="(interlocuteur, index) in interlocuteurs" :key="index" :value="interlocuteur">
                  {{ interlocuteur ? interlocuteur.friendly : " + Ajouter un nouvel interlocuteur" }}
                </option>
              </select>
            </li>
            <li class="input-field col s6">
              <input type="text" required :disabled="currentInterlocuteur" v-model="interlocuteurTech.fonction" />
              <label :class="{ active: interlocuteurTech.fonction }">
                <font-awesome-icon icon="asterisk" class="red-text" />
                {{ $t("membreInfos.fonction") }}
              </label>
            </li>
            <!-- <li class="input-field col s6">
              <input
                type="text"
                required
                :disabled="currentInterlocuteur"
                v-model="interlocuteurTech.civility"
              />
              <label :class="{ active: interlocuteurTech.civility }">
                <font-awesome-icon icon="asterisk" class="red-text"/>
                {{ $t("membreInfos.civility") }}
              </label>
            </li> -->
            <li class="input-field col s6">
              <input type="text" required :disabled="currentInterlocuteur" v-model="interlocuteurTech.prenom" />
              <label :class="{ active: interlocuteurTech.prenom }">
                <font-awesome-icon icon="asterisk" class="red-text" />
                {{ $t("membreInfos.firstName") }}
              </label>
            </li>
            <li class="input-field col s6">
              <input type="text" required :disabled="currentInterlocuteur" v-model="interlocuteurTech.nom" />
              <label :class="{ active: interlocuteurTech.nom }">
                <font-awesome-icon icon="asterisk" class="red-text" />
                {{ $t("membreInfos.lastName") }}
              </label>
            </li>
            <li class="input-field col s6">
              <input type="email" required :disabled="currentInterlocuteur" v-model="interlocuteurTech.mail" />
              <label :class="{ active: interlocuteurTech.mail }">
                <font-awesome-icon icon="asterisk" class="red-text" />
                {{ $t("membreInfos.mail") }}
              </label>
            </li>
            <li class="input-field col s6">
              <input
                type="text"
                :required="!interlocuteurTech.mobile || interlocuteurTech.mobile.length <= 0"
                :disabled="currentInterlocuteur"
                v-model="interlocuteurTech.telephone"
                pattern="0[0-9]([ .]?[0-9]{2}){4}"
              />
              <label :class="{ active: interlocuteurTech.telephone }">
                <font-awesome-icon
                  icon="asterisk"
                  class="red-text"
                  v-if="!interlocuteurTech.mobile || interlocuteurTech.mobile.length <= 0"
                />
                {{ $t("membreInfos.phone") }}
              </label>
            </li>
            <li class="input-field col s6">
              <input
                type="text"
                :required="!interlocuteurTech.telephone || interlocuteurTech.telephone.length <= 0"
                v-model="interlocuteurTech.mobile"
                :disabled="currentInterlocuteur"
                pattern="0[0-9]([ .]?[0-9]{2}){4}"
              />
              <label :class="{ active: interlocuteurTech.mobile }">
                <font-awesome-icon
                  icon="asterisk"
                  class="red-text"
                  v-if="!interlocuteurTech.telephone || interlocuteurTech.telephone.length <= 0"
                />
                {{ $t("membreInfos.mobile") }}
              </label>
            </li>
          </ul>
        </section>

        <!--article
          v-if="
            this.confCommentaire === undefined || this.confCommentaire.displayed
          "
          class="input-field col s12"
        >
          <textarea
            v-if="confCommentaire.displayed"
            id="commentaire"
            class="materialize-textarea"
            v-model="commentaire"
          ></textarea>
          <label for="commentaire" :class="{ active: commentaire }"
            >Commentaires</label
          >
        </article-->
      </div>
    </section>
    <center class="card-action">
      <button type="submit" class="waves-effect waves-light btn light-green" id="validSiteValid">
        <i class="material-icons">save</i>
        Valider
      </button>

      <button type="button" class="waves-effect waves-light btn blue darken-2" @click="initialize">
        <i class="material-icons">remove</i>
        Réinitialiser
      </button>
    </center>
  </form>
</template>

<script>
import Axios from "@/util/Axios"
import { mapState } from "vuex"
import MixinValidator from "@/util/Mixin/Validator"
import $ from "jquery"
import _ from "lodash"
import Materialize from "materialize-css"
import {
  Rae,
  CodeImputation,
  CodePostal,
  CodeInsee,
  DateActivation,
  SiteStatus,
  DateFinContrat,
} from "@/components/common/Fields"
import GenericField from "@/components/common/Fields/GenericField"

export default {
  name: "etape_1",
  mixins: [MixinValidator],
  props: ["site"],
  components: {
    Rae,
    CodeImputation,
    CodePostal,
    CodeInsee,
    DateActivation,
    DateFinContrat,
    SiteStatus,
    GenericField,
  },
  data() {
    return {
      nom: null,
      codeSite: null,
      rae: null,
      fluide: null,
      status: null,
      refTDG: null,
      refSite: null,
      adresse: null,
      codePostal: null,
      commune: null,
      codeImputation: null,
      // commentaire: null,
      added: null,
      dateActivation: null,
      dateResiliation: null,
      codeInsee: null,
      interlocuteurTech: null,
      interlocuteurs: [],
      currentInterlocuteur: null,
    }
  },
  computed: {
    ...mapState(["membre", "operation"]),
    fields() {
      if (this.operation && this.operation.flows) {
        const flow = _.find(this.operation.flows, ["name", "sites"])
        if (flow && flow.panels) {
          const panel = _.find(flow.panels, ["name", "Localisation"])
          if (panel && panel.fields) {
            panel.fields.forEach((element) => {
              switch (element.type) {
                case "siteName":
                  element.value = this.nom
                  break
                case "adress":
                  element.value = this.adresse
                  break
                case "postalCode":
                  element.value = this.codePostal
                  break
                case "siteCode":
                  element.value = this.CodeInsee
                  break
                /* case "comment":
                  element.value = this.commentaire;
                  break; */
                case "town":
                  element.value = this.commune
                  break
                case "codeImputation":
                  element.value = this.codeImputation
                  break
                case "dateFinContrat":
                  element.value = this.Resiliation
                  break
                default:
                  element.value = this[element.type]
              }
              if (!element.value) {
                console.log("element.type non trouvé : %O", element.type)
              } else {
                console.log("%O : %O", element.type, element.value)
              }
            })
            return panel.fields
          }
        }
      }
      return []
    },
    fluidField: {
      get() {
        if (this.operation && this.operation.flows) {
          const flow = _.find(this.operation.flows, ["name", "sites"])
          if (flow && flow.panels) {
            const panel = _.find(flow.panels, ["name", "Localisation"])
            if (panel && panel.fields) {
              let field = _.find(panel.fields, ["type", "fluid"])
              if (!this.fluide) {
                field.value = field.lockedValues[0]
              } else {
                field.value = this.fluide
              }
              return field
            }
          }
        }
        return null
      },
      set(value) {
        this.fluide = value
      },
    },
    fluidFieldElec: {
      get() {
        if (this.fluidField) {
          if (this.fluidField.lockedValues) {
            return this.fluidField.lockedValues.indexOf("Électricité") > -1
          }
        }
        return false
      },
    },
    fluidFieldGaz: {
      get() {
        if (this.fluidField) {
          if (this.fluidField.lockedValues) {
            return this.fluidField.lockedValues.indexOf("Gaz") > -1
          }
        }
        return false
      },
    },
    customInterlocuteur() {
      if (this.membre.interlocuteurTechnique && this.membre.interlocuteurTechnique.id && this.interlocuteurTech) {
        return (
          this.membre.interlocuteurTechnique.fonction !== this.interlocuteurTech.fonction ||
          this.membre.interlocuteurTechnique.prenom !== this.interlocuteurTech.prenom ||
          this.membre.interlocuteurTechnique.nom !== this.interlocuteurTech.nom ||
          this.membre.interlocuteurTechnique.mail !== this.interlocuteurTech.mail ||
          this.membre.interlocuteurTechnique.telephone !== this.interlocuteurTech.telephone ||
          this.membre.interlocuteurTechnique.mobile !== this.interlocuteurTech.mobile
        )
      } else {
        return false
      }
    },
  },
  methods: {
    initialize() {
      this.nom = this.site.nom
      this.codeSite = this.site.codeSite
      this.rae = this.site.rae
      this.fluide = this.site.fluide
      this.status = this.site.status
      this.refTDG = this.site.refTDG
      this.refSite = this.site.refSite
      this.adresse = this.site.adresse
      this.codePostal = this.site.codePostal
      this.commune = this.site.commune
      this.codeImputation = this.site.codeImputation
      console.log("CODE IMPUTATION %O", this.codeImputation)
      console.log("CODE SITE %O", this.site)
      // this.commentaire = this.site.commentaire;
      this.dateActivation = this.site.dateActivation
      this.dateResiliation = this.site.dateResiliation
      this.added = this.site.added
      this.codeInsee = this.site.codeInsee
      console.group("initialize@Site")
      console.log("this.membre :  %O", this.membre)
      console.log("this.operation :  %O", this.operation)
      console.log("this.operation.flows :  %O", this.operation.flows)
      const indexSites = this.operation.flows.map((e) => e.name).indexOf("sites")
      console.log("indexOf Flow sites :  %O ", indexSites)
      console.log("this.operation.flows :  %O", this.operation.flows[indexSites])
      const indexLocalisation = this.operation.flows[indexSites].panels.map((e) => e.name).indexOf("Localisation")
      console.log("indexOf Flow sites Localisation :  %O", indexLocalisation)
      // eslint-disable-next-line standard/computed-property-even-spacing
      var confLocalisation = this.operation.flows[indexSites].panels[indexLocalisation]
      console.log("this.operation.flows['sites'].panels['Localisation'] :  %O", confLocalisation)

      this.confNom =
        // eslint-disable-next-line standard/computed-property-even-spacing
        confLocalisation.fields[confLocalisation.fields.map((e) => e.type).indexOf("siteName")]
      this.confCodeSite =
        // eslint-disable-next-line standard/computed-property-even-spacing
        confLocalisation.fields[confLocalisation.fields.map((e) => e.type).indexOf("siteCode")]
      this.confRae =
        // eslint-disable-next-line standard/computed-property-even-spacing
        confLocalisation.fields[confLocalisation.fields.map((e) => e.type).indexOf("rae")]
      this.confFluide =
        // eslint-disable-next-line standard/computed-property-even-spacing
        confLocalisation.fields[confLocalisation.fields.map((e) => e.type).indexOf("fluid")]
      this.confStatus =
        // eslint-disable-next-line standard/computed-property-even-spacing
        confLocalisation.fields[confLocalisation.fields.map((e) => e.type).indexOf("status")]
      this.confAdresse =
        // eslint-disable-next-line standard/computed-property-even-spacing
        confLocalisation.fields[confLocalisation.fields.map((e) => e.type).indexOf("adress")]
      this.confCodePostal =
        // eslint-disable-next-line standard/computed-property-even-spacing
        confLocalisation.fields[confLocalisation.fields.map((e) => e.type).indexOf("postalCode")]
      this.confCommune =
        // eslint-disable-next-line standard/computed-property-even-spacing
        confLocalisation.fields[confLocalisation.fields.map((e) => e.type).indexOf("town")]
      this.confCodeImputation =
        // eslint-disable-next-line standard/computed-property-even-spacing
        confLocalisation.fields[confLocalisation.fields.map((e) => e.type).indexOf("codeImputation")]
      /* this.confCommentaire =
        // eslint-disable-next-line standard/computed-property-even-spacing
        confLocalisation.fields[
          confLocalisation.fields.map(e => e.type).indexOf("comment")
        ]; */
      this.confDateActivation =
        // eslint-disable-next-line standard/computed-property-even-spacing
        confLocalisation.fields[confLocalisation.fields.map((e) => e.type).indexOf("dateActivation")]
      this.confDateFinContrat =
        // eslint-disable-next-line standard/computed-property-even-spacing
        confLocalisation.fields[confLocalisation.fields.map((e) => e.type).indexOf("dateFinContrat")]
      this.interlocuteurs = [null]
      this.currentInterlocuteur = null
      if (
        this.membre.interlocuteurTechnique &&
        this.membre.interlocuteurTechnique.nom &&
        this.membre.interlocuteurTechnique.prenom &&
        (this.membre.interlocuteurTechnique.telephone || this.membre.interlocuteurTechnique.mobile) &&
        this.membre.interlocuteurTechnique.mail
      ) {
        this.interlocuteurs.push({
          fonction: this.membre.interlocuteurTechnique.fonction,
          nom: this.membre.interlocuteurTechnique.nom,
          prenom: this.membre.interlocuteurTechnique.prenom,
          telephone:
            this.membre.interlocuteurTechnique.telephone.length > 0
              ? this.membre.interlocuteurTechnique.telephone
              : this.membre.interlocuteurTechnique.mobile,
          mail: this.membre.interlocuteurTechnique.mail,
          friendly:
            this.membre.interlocuteurTechnique.nom.toUpperCase() +
            " " +
            _.capitalize(this.membre.interlocuteurTechnique.prenom),
        })
        this.currentInterlocuteur = {
          fonction: this.membre.interlocuteurTechnique.fonction,
          nom: this.membre.interlocuteurTechnique.nom,
          prenom: this.membre.interlocuteurTechnique.prenom,
          telephone:
            this.membre.interlocuteurTechnique.telephone.length > 0
              ? this.membre.interlocuteurTechnique.telephone
              : this.membre.interlocuteurTechnique.mobile,
          mail: this.membre.interlocuteurTechnique.mail,
          friendly:
            this.membre.interlocuteurTechnique.nom.toUpperCase() +
            " " +
            _.capitalize(this.membre.interlocuteurTechnique.prenom),
        }
      }
      Axios("get", "api/membres/" + this.membre.id + "/interlocuteurs").then((response) => {
        for (const interlocuteur of response.data._embedded.interlocuteurs) {
          if (
            interlocuteur.nom &&
            interlocuteur.prenom &&
            interlocuteur.telephone &&
            interlocuteur.mail &&
            !_.find(this.interlocuteurs, ["mail", interlocuteur.mail]) &&
            (interlocuteur.role === "TECHNIQUE" || interlocuteur.role === "MEMBRE")
          ) {
            this.interlocuteurs.push({
              fonction: interlocuteur.fonction,
              nom: interlocuteur.nom,
              prenom: interlocuteur.prenom,
              telephone: interlocuteur.telephone,
              mail: interlocuteur.mail,
              friendly: interlocuteur.nom.toUpperCase() + " " + _.capitalize(interlocuteur.prenom),
            })
          }
        }
      })
      if (this.site && this.site.interlocuteurId) {
        Axios("get", "api/interlocuteurs/" + this.site.interlocuteurId).then((response) => {
          const siteInterlocuteur = _.find(this.interlocuteurs, ["mail", response.data.mail])
          if (siteInterlocuteur) {
            this.currentInterlocuteur = siteInterlocuteur
          } else {
            this.interlocuteurs.push({
              fonction: response.data.fonction,
              nom: response.data.nom,
              prenom: response.data.prenom,
              telephone: response.data.telephone,
              mail: response.data.mail,
              friendly: response.data.nom.toUpperCase() + " " + _.capitalize(response.data.prenom),
            })
            this.currentInterlocuteur = {
              fonction: response.data.fonction,
              nom: response.data.nom,
              prenom: response.data.prenom,
              telephone: response.data.telephone,
              mail: response.data.mail,
              friendly: response.data.nom.toUpperCase() + " " + _.capitalize(response.data.prenom),
            }
          }
        })
      }
      console.groupEnd()
    },
    submit() {
      console.log("Site>Etape_1>submit")
      if (!this.currentInterlocuteur) {
        console.log("pas d'interlocuteur")
        Axios("post", "api/interlocuteurs", {
          removed: false,
          membre: process.env.VUE_APP_API_URL + "/api/membres/" + this.membre.id,
          fonction: this.interlocuteurTech.fonction,
          prenom: this.interlocuteurTech.prenom,
          nom: this.interlocuteurTech.nom,
          mail: this.interlocuteurTech.mail,
          telephone: this.interlocuteurTech.telephone,
          mobile: this.interlocuteurTech.mobile,
          operationId: this.operation.id,
          role: "TECHNIQUE",
        })
          .then((response) => {
            Materialize.toast("Interlocuteur ajouté", 4000, "lime")
            this.proceed(response.data.id)
          })
          .catch((error) => {
            console.error(error)
          })
      } else {
        console.log("interlocuteur")
        Axios("get", "interlocuteur/technique?mail=" + this.currentInterlocuteur.mail)
          .then((response) => {
            console.log("Reponse : %O", response)
            if (Array.isArray(response.data)) {
              console.log("Reponse : %O", response.data[0].id)
              this.proceed(response.data[0].id)
            } else {
              console.log("Reponse : %O", response.data.id)
              this.proceed(response.data.id)
            }
          })
          .catch((error) => {
            console.error("error", error)
          })
        // this.proceed(this.membre.interlocuteurTechnique.id)
      }
    },
    proceed(interlocuteurId) {
      console.log(interlocuteurId)
      const site = {
        ongletIdentification: true,
        membre: process.env.VUE_APP_API_URL + "/api/membres/" + this.membre.id,
        fluide: this.fluide,
        nom: this.nom,
        codeSite: this.codeSite,
        rae: this.rae,
        refTDG: this.refTDG,
        refSite: this.refSite,
        adresse: this.adresse,
        codePostal: this.codePostal,
        commune: this.commune,
        codeImputation: this.codeImputation,
        // commentaire: this.commentaire,
        status: this.status,
        dateActivation: this.dateActivation,
        dateResiliation: this.dateResiliation,
        added: this.added,
        codeInsee: this.codeInsee,
        operationId: this.operation.id,
        interlocuteurId: interlocuteurId,
      }
      if (this.site.id) {
        Axios("patch", "api/sites/" + this.site.id, site)
          .then((response) => {
            this.$emit("save", response.data.id)
            Materialize.toast("Les informations d'identification ont bien été sauvegardées", 4000, "lime")
            this.initialize()
          })
          .catch((error) => {
            console.error(error)
            Materialize.toast("Erreur serveur", 4000, "deep-orange")
          })
      } else {
        const bean = this.fluide === "GAZ" ? "siteGazes" : "siteElecs"
        site.multiSite = true
        Axios("post", "api/" + bean, site)
          .then((response) => {
            this.$emit("save", response.data.id)
            Materialize.toast("Les informations d'identification ont bien été sauvegardées", 4000, "lime")
            this.initialize()
          })
          .catch((error) => {
            console.error(error)
            if (error.response.status === 409) {
              Materialize.toast("Ce RAE existe déjà pour un autre site.", 4000, "deep-orange")
            } else {
              Materialize.toast("Erreur serveur", 4000, "deep-orange")
            }
          })
      }
    },
  },
  watch: {
    site() {
      this.initialize()
    },
    currentInterlocuteur: {
      handler() {
        if (
          this.currentInterlocuteur &&
          (!this.interlocuteurTech || this.currentInterlocuteur.mail !== this.interlocuteurTech.mail)
        ) {
          this.interlocuteurTech = this.currentInterlocuteur
        } else {
          this.interlocuteurTech = {
            fonction: "",
            prenom: "",
            nom: "",
            mail: "",
            telephone: "",
            mobile: "",
          }
        }
      },
      deep: true,
    },
  },
  created() {
    this.initialize()
    if (this.site.interlocuteurId) {
      Axios("get", "api/interlocuteurs/" + this.site.interlocuteurId)
        .then((response) => {
          this.interlocuteurTech = response.data
        })
        .catch((error) => {
          console.error(error)
        })
    } else if (this.membre.interlocuteurTechnique && this.membre.interlocuteurTechnique.id) {
      Axios("get", "api/interlocuteurs/" + this.membre.interlocuteurTechnique.id)
        .then((response) => {
          this.interlocuteurTech = response.data
        })
        .catch((error) => {
          console.error(error)
        })
    } else {
      this.interlocuteurTech = {
        fonction: null,
        prenom: null,
        nom: null,
        mail: null,
        telephone: null,
        mobile: null,
      }
    }
  },
  mounted() {
    $(".datepicker").pickadate({
      selectMonths: true,
      selectYears: 15,
      today: "Aujourd'hui",
      clear: "Anuler",
      close: "Valider",
      closeOnSelect: false,
    })
    $(".collapsible").collapsible()
  },
}
</script>

<style lang="scss" scoped>
.col {
  margin-bottom: 10px;
}

.interlocuteur-wrapper {
  display: inline-block;
  width: 100%;
  border: 1px solid #ddd;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12), 0 3px 1px -2px rgba(0, 0, 0, 0.2);
  & ul {
    padding: 20px;
  }
  & div {
    padding: 20px;
    border-bottom: 1px solid #ddd;
  }
}
</style>
