import delaiPaiement from "./DelaiPaiement"
import moyenPaiement from "./MoyenPaiement"
import rae from "./Rae"
import codeImputation from "./CodeImputation"
import sepa from "./Sepa"
import codeIdentificationIndividuelle from "./CodeIdentificationIndividuelle"
import numConventionTripartite from "./NumConventionTripartite"
import iban from "./Iban"
import codePostal from "./CodePostal"
import email from "./Email"
import telephone from "./Telephone"
import siret from "./Siret"
import codeInsee from "./CodeInsee"
import dateActivation from "./DateActivation"
import dateFinContrat from "./DateFinContrat"
import siteStatus from "./SiteStatus"
import budgetName from "./BudgetName"
import rythmeFacturation from "./RythmeFacturation"

export default {
  delaiPaiement,
  moyenPaiement,
  rae,
  codeImputation,
  sepa,
  codeIdentificationIndividuelle,
  numConventionTripartite,
  iban,
  codePostal,
  email,
  telephone,
  siret,
  codeInsee,
  dateActivation,
  dateFinContrat,
  siteStatus,
  budgetName,
  rythmeFacturation,
}

export const DelaiPaiement = delaiPaiement
export const MoyenPaiement = moyenPaiement
export const Rae = rae
export const CodeImputation = codeImputation
export const Sepa = sepa
export const CodeIdentificationIndividuelle = codeIdentificationIndividuelle
export const NumConventionTripartite = numConventionTripartite
export const Iban = iban
export const CodePostal = codePostal
export const Email = email
export const Telephone = telephone
export const Siret = siret
export const CodeInsee = codeInsee
export const DateActivation = dateActivation
export const DateFinContrat = dateFinContrat
export const SiteStatus = siteStatus
export const BudgetName = budgetName
export const RythmeFacturation = rythmeFacturation
