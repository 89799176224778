import Sites from "./Sites"
import Groupements from "./Groupements"
import Services from "./Services"
import Documents from "./Documents"
import Piecedepot from "./Piecedepot"
import Piecetelechargement from "./Piecetelechargement"
import DocumentsDownload from "./DocumentsDownload"
import DocumentsUpload from "./DocumentsUpload"

export const Steps = {
  Sites,
  Groupements,
  Services,
  Documents,
  Piecedepot,
  Piecetelechargement,
  DocumentsDownload,
  DocumentsUpload,
}
