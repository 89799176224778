<template>
  <mcma-card class="mcma-card-flow" :inline="true" :title="title">
    <template #cardAvatar>
      <span v-if="state === 'COMPLETED'">
        <mcma-icon type="check" color="success" :faIcon="true" />
      </span>
      <span v-else>
        {{ index }}
      </span>
    </template>
    <template #describe>
      <strong> {{ mycomment }} </strong>
      <div
        v-if="customDescribe"
        class="preline customDescribe html-render-content"
        v-html="customDescribe"
        :class="{ 'can-edit': $isAdmin() }"
        @click="setEditorModal(flowName, 'cardDescribe')"
      ></div>
      <p>
        {{ describe }}
      </p>
    </template>
    <!--    <template #headerRightSide>-->
    <mcma-button
      v-for="(button, index) in buttons"
      :key="index"
      :name="button.title"
      :class="{ ml12: index > 0 }"
      @click="goTo(button.routeName, button.routeParams)"
    >
    </mcma-button>
    <!--    </template>-->
    <mcma-editor-modal
      v-if="$isAdmin()"
      :visible="showEditor"
      :flowName="flowName"
      flowProp="cardDescribe"
      @onCancel="setEditorModal"
      @onSave="editorHasMakeChange"
    />
  </mcma-card>
</template>

<script>
import McmaCard from "@/components/mcma/common/McmaCard"
import McmaButton from "@/components/mcma/common/McmaButton"
import McmaEditorModal from "@/components/mcma/common/McmaEditorModal"
import McmaIcon from "@/components/mcma/common/McmaIcon"

export default {
  name: "FlowCard",
  components: {
    McmaCard,
    McmaButton,
    McmaIcon,
    McmaEditorModal,
  },
  props: {
    index: Number,
    title: String,
    flowName: String,
    customDescribe: String,
    describe: String,
    buttons: Array,
    mycomment: String,
    state: String,
  },
  data() {
    return {
      showEditor: false,
    }
  },
  methods: {
    setEditorModal(flowName, flowProp) {
      this.showEditor = !!(flowName && flowProp)
    },
    editorHasMakeChange() {
      this.setEditorModal()
      this.$emit("refresh-flows")
    },
    goTo(routeName, params) {
      this.$router.push({
        name: routeName,
        params: params,
      })
    },
  },
}
</script>

<style lang="scss">
@import "./src/assets/scss/variables";
@import "./src/assets/scss/functions";

.mcma-card-flow {
  display: flex !important;
  .ant-card-body {
    width: 100%;
    .card-avatar {
      transform: scale(0.8, 0.8);
      .cardAvatar {
        span {
          font-weight: 600;
          color: var(--primary-color, $primary-color);
          font-size: 30px;
          line-height: 30px;
          .mcma-icon {
            width: 30px;
            height: 30px;
            margin-top: -6px;
          }
        }
      }
    }
    .card-title {
      &.card-row {
        .describe {
          .customDescribe {
            margin-bottom: 10px;
          }
        }
        .headerRightSide {
          display: flex;
          flex-flow: row nowrap;
          justify-content: flex-end;
          align-items: center !important;
          .mcma-button {
            display: flex;
            flex-flow: row nowrap;
            justify-content: flex-end;
            align-items: center;
            margin-right: 10px;
            .name {
              text-transform: uppercase;
            }
          }
        }
      }
    }
    .card-body {
      margin-top: 30px;
      display: flex;
      flex-flow: row nowrap;
      justify-content: center;
      align-items: center;
    }
  }
}
</style>
