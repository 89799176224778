<template>
  <main>
    <h5>Édition des Administrateurs et rattachement aux opérations</h5>
    <section class="container" id="addUserForm">
      <user-form :user="selected" :admin="true" :superAdmin="true" @submit="submit" @new="selected = null" />
    </section>
    <section class="row">
      <place-holder :notReady="!users" class="col s12" id="addUserGrid">
        <grid
          v-if="users"
          name="Liste des utilisateurs"
          :rows="users"
          :columns="columns"
          :columnHeader="columnHeader"
          :search="true"
          :columnsNoSearch="['Actions']"
        >
          <center slot="Actions" slot-scope="props">
            <router-link
              :to="{
                name: 'user-admin-link-operation',
                params: { id: props.row.id },
              }"
              class="tutorialNoBtn editUserLinksLink"
              v-tooltip.auto="'Associer des opérations en masse'"
            >
              <i class="material-icons blue-grey-text">link</i>
            </router-link>
            <i
              class="material-icons pointer blue-text tutorialNoBtn addUserEdit"
              v-tooltip.auto="'Editer l\'utilisateur'"
              @click.prevent="edit(props.row)"
              >edit</i
            >
          </center>
        </grid>
      </place-holder>
    </section>
  </main>
</template>

<script>
import Axios from "@/util/Axios"
import Materialize from "materialize-css"
import { mapMutations, mapState } from "vuex"
import Grid from "@/components/common/Grid"
import PlaceHolder from "@/components/common/PlaceHolder"
import UserForm from "@/components/common/UserForm"

export default {
  name: "add-admin",
  components: {
    Grid,
    PlaceHolder,
    UserForm,
  },
  data() {
    return {
      columns: ["email", "nom", "prenom", "role", "Actions"],
      columnHeader: {
        email: "E-mail",
        nom: "Nom",
        prenom: "Prenom",
        role: "Rôle",
      },
      selected: null,
      users: null,
    }
  },
  computed: {
    ...mapState(["user", "currentTutorial", "operation", "intro"]),
  },
  methods: {
    ...mapMutations(["setBreadcrumbAdd", "removeLastbreadcrumb"]),
    getPage(page) {
      console.group("AddAdmin:getPage@componentUsers")
      console.log(
        "currentoperation : %O",
        // eslint-disable-next-line no-undef
        this.currentoperation
      )
      console.groupEnd()
      /*
      return Axios(
        "get",
        // 'api/users?page=' + page
        "api/users/search/findByOperationGroupementEquals?groupement=" +
          this.currentoperation.groupement +
          "&page=" +
          page +
          "&size=10"
      ); */
      return Axios(
        "get",
        // 'api/users?page=' + page
        "api/users/search/findByRoleNot?role=CLIENT" + "&page=" + page + "&size=10"
      )
    },
    computeResponse(response) {
      this.users.push(...response.data._embedded.users)
    },
    initialize() {
      console.group("initialize@AddAdmin")
      this.users = []
      this.getPage(0).then((responseInit) => {
        this.computeResponse(responseInit)
        for (let page = 1; page < responseInit.data.page.totalPages; page++) {
          this.getPage(page).then((response) => {
            this.computeResponse(response)
          })
        }
      })
      /*
      Axios(
        'get',
        'api/users'
      ).then(response => {
        const size = response.data.page.totalElements
        Axios(
          'get',
          'api/users?size=' + size
        ).then(response => {
          this.users = response.data._embedded.users
        })
      })
      */
      console.groupEnd()
    },
    submit(user) {
      if (this.selected) {
        Axios("patch", "api/users/" + this.selected.id, {
          ...user,
          welcomed: null,
        })
          .then((response) => {
            Materialize.toast("Utilisateur modifié", 4000, "lime white-text")
            this.selected = null
            this.initialize()
          })
          .catch((error) => {
            console.error(error)
            Materialize.toast("Erreur lors de la modification", 4000, "deep-orange white-text")
          })
      } else if (!this.invalidPassword) {
        Axios("post", "api/users", user)
          .then((response) => {
            Materialize.toast("Utilisateur ajouté", 4000, "lime white-text")
            console.log("Add user response : %O", response.data)
            console.log("Add user response.data.id : %O", response.data.id)
            this.initialize()
          })
          .catch((error) => {
            console.error(error)
            Materialize.toast("Erreur lors de l'ajout", 4000, "deep-orange white-text")
          })
      }
    },
    edit(row) {
      this.selected = row
      if (this.currentTutorial) {
        this.intro.nextStep()
      }
    },
  },
  watch: {
    selected() {
      this.initialize()
    },
  },
  created() {
    this.$scrollToTop()
    this.removeLastbreadcrumb()
    this.setBreadcrumbAdd({
      name: "Gestion des Administrateurs",
      action: () => {},
    })
    console.log("AddAdmin@created this : %O", this)
    this.initialize()
  },
}
</script>

<style lang="scss" scoped></style>
