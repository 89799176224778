<template>
  <mcma-card title="Gestion des utilisateurs" icon="add_user" class="mcma-card-header" :inline="true">
    <template #describe>
      <span v-if="users.length === 1"> Edition de votre <strong>unique utilisateur</strong>. </span>
      <span v-else-if="users.length > 1">
        Edition de vos <strong>{{ users.length }} utilisateurs</strong>.
      </span>
      <span v-else> <strong>Aucun utilisateur</strong> à éditer. </span>
    </template>
    <template #headerRightSide>
      <mcma-button name="Ajouter un nouvel utilisateur" color="primary" leftIcon="user-plus" :isFaIcon="true"
        @click="goToCreateUserForm()" />
      <mcma-button name="Ajouter en masse des utilisateurs" color="primary" leftIcon="user-plus" :isFaIcon="true"
        @click="openImportModal()" />
      <import-users-masse-modal :visible="showModal" @close="close" @initialize="initialize" />
    </template>
    <slot></slot>
  </mcma-card>
</template>

<script>
import McmaCard from "@/components/mcma/common/McmaCard"
import McmaButton from "@/components/mcma/common/McmaButton"
import ImportUsersMasseModal from "@/components/mcma/users/components/ImportUsersMasseModal"
export default {
  name: "UsersHeader",
  props: {
    users: Array,
  },
  components: {
    McmaCard,
    McmaButton,
    ImportUsersMasseModal,
  },
  data() {
    return {
      showModal: false
    }
  },
  methods: {
    initialize(){
      this.$emit("initialize")
    },
    close(){
      this.showModal = false
    },
    openImportModal(){
      this.showModal = true
    },
    goToCreateUserForm() {
      this.$router.push({
        name: "users-detail",
        params: {
          action: "new",
        },
      })
    },
  },
}
</script>

<style lang="scss"></style>
