import { mapGetters } from "vuex"
import Materialize from "materialize-css"

export default {
  computed: {
    ...mapGetters(["getFlow"]),
  },
  created() {
    console.group("created@FLOW")
    console.log("FLOW : %O", this.flow)
    console.log("this.getFLOW : %O", this.getFlow)
    if (!this.getFlow.includes(this.flow)) {
      Materialize.toast("Vous n'avez pas accès à cette fonctionnalité.", 4000, "deep-orange")
      this.$router.push({
        name: "home",
      })
    }
    console.groupEnd()
  },
}
