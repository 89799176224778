<template>
  <section :class="deploy ? 'deployed' : ''">
    <ul>
      <li @click="goTo('superadmin-board')" :class="$route.name === 'superadmin-board' ? 'activePage' : ''">
        <i class="material-icons">format_list_bulleted</i>
        <span>Opérations</span>
      </li>
      <li
        v-if="user.role === 'SUPER_ADMIN'"
        @click="goTo('superadmin-operation-editing')"
        :class="$route.name === 'superadmin-operation-editing' ? 'activePage' : ''"
      >
        <i class="material-icons">add</i>
        <span>Ajouter</span>
      </li>
      <li
        v-if="user.role === 'SUPER_ADMIN'"
        @click="goTo('superadmin-add-admin')"
        :class="$route.name === 'superadmin-add-admin' ? 'activePage' : ''"
      >
        <i class="material-icons">person_add</i>
        <span>Ajouter des Administrateur</span>
      </li>
      <li class="deployBtn" :class="deploy ? 'reverse' : ''" @click=";[(deploy = !deploy), $emit('deploy', deploy)]">
        <i class="material-icons">arrow_forward</i>
      </li>
    </ul>
  </section>
</template>

<script>
import { mapState, mapMutations } from "vuex"

export default {
  name: "side-nav",
  props: ["value"],
  data() {
    return {
      deploy: false,
    }
  },
  computed: {
    ...mapState(["operationNotSaved", "user", "operation"]),
  },
  methods: {
    ...mapMutations(["setOperationEditing", "setOperation"]),
    goTo(routeName) {
      const currentRouteName = this.$route.name
      if (routeName === "superadmin-operation-editing") {
        this.setOperation({})
        this.setOperationEditing(true)
      } else {
        this.setOperationEditing(false)
      }
      if (currentRouteName === "superadmin-operation-editing" && this.operationNotSaved) {
        const that = this
        that.$confirm({
          title: `Quitter sans sauvegarder ?`,
          content: `Voulez-vous vraiment quitter cette page sans sauvegarder vos changements ?
                    \nCeux-ci seront alors effacés.`,
          okText: "Oui",
          okType: "error",
          cancelText: "Non",
          onOk() {
            that.$router.push({
              name: routeName,
              params: {
                membreId: null,
                operationId: currentRouteName === "superadmin-board" && this.operation ? this.operation.id : null,
              },
            })
          },
        })
      } else {
        this.$router.push({
          name: routeName,
          params: {
            membreId: null,
            operationId: currentRouteName === "superadmin-board" && this.operation ? this.operation.id : null,
          },
        })
      }
    },
  },
}
</script>

<style lang="scss" scoped>
section {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 50px;
  background: white;
  box-shadow: 5px 0 10px -6px rgba(0, 0, 0, 0.5);
  transition: 0.3s;
  & ul {
    width: 100%;
    height: 100%;
    padding-top: 155px;
    position: relative;
    margin: 0 !important;
    & li {
      text-align: center;
      cursor: pointer;
      margin: 5px;
      transition: 0.3s;
      width: 40px;
      height: 40px;
      border-radius: 20px;
      padding: 8px;
      white-space: nowrap;
      overflow: hidden;
      &.activePage {
        background: rgba(0, 0, 0, 0.1);
      }
      &:hover {
        background: rgba(0, 0, 0, 0.2);
      }
      &.deployBtn {
        position: absolute;
        right: 0;
        bottom: 50px;
        &.reverse {
          transform: rotate(180deg);
        }
      }
      & i {
        pointer-events: none !important;
      }
      & span {
        opacity: 0;
        transition: 0.2s;
      }
    }
  }
  &:not(.deployed) {
    transition-delay: 0.1s;
    & li:not(:hover) {
      transition-delay: 0.1s;
    }
  }
  &.deployed {
    width: 200px;
    & li {
      &:not(.deployBtn) {
        width: 190px;
      }
      transition-delay: 0s !important;
    }
    & span {
      opacity: 1;
      transition-delay: 0.3s;
    }
  }
}
</style>
