<template>
  <div>
    <mcma-card v-if="inCard && getImage" :class="`mcma-card-empty has-image height-${imageSize}`">
      <a-empty class="empty-block" :class="{ center: center }" :image="getImage">
        <span slot="description"> {{ message }} </span>
        <slot></slot>
      </a-empty>
    </mcma-card>
    <mcma-card v-else-if="inCard && !getImage" class="mcma-card-empty">
      <div class="empty-block" :class="{ center: center }">
        <span> {{ message }} </span>
        <slot></slot>
      </div>
    </mcma-card>
    <a-empty
      v-else-if="!inCard && getImage"
      class="empty-block has-image"
      :class="[{ center: center }, `height-${imageSize}`]"
      :image="getImage"
    >
      <span slot="description"> {{ message }} </span>
      <slot></slot>
    </a-empty>
    <div v-else-if="!inCard && !getImage" :class="{ center: center }" class="empty-block">
      <span>{{ message }}</span>
      <slot></slot>
    </div>
  </div>
</template>

<script>
import McmaCard from "@/components/mcma/common/McmaCard"

export default {
  name: "McmaEmpty",
  components: {
    McmaCard,
  },
  props: {
    inCard: Boolean,
    message: {
      type: String,
      default: "Mon message",
    },
    image: [String, Boolean],
    center: Boolean,
    imageSize: {
      type: Number,
      default: 150,
    },
  },
  computed: {
    getImage() {
      if (this.image === true) {
        return "https://gw.alipayobjects.com/mdn/miniapp_social/afts/img/A*pevERLJC9v0AAAAAAAAAAABjAQAAAQ/original"
      } else if (typeof this.image === "string" && this.image.startsWith("http")) {
        return this.image
      } else if (typeof this.image === "string" && !this.image.startsWith("http")) {
        return require(`@/assets/images/illustrations/${this.image}.svg`)
      } else {
        return null
      }
    },
  },
  created() {
    console.log(
      `%c this.image`,
      "background:#1e4d77 ; padding: 5px 7px 4px 0px; border-radius: 3px;  color: #FFFFFF",
      this.image
    )
    console.log(
      `%c this.getImage`,
      "background:#1e4d77 ; padding: 5px 7px 4px 0px; border-radius: 3px;  color: #FFFFFF",
      this.getImage
    )
  },
}
</script>

<style lang="scss">
@import "./src/assets/scss/variables";
@import "./src/assets/scss/functions";

.mcma-card-empty {
  .card-heading {
    display: none;
  }
  .ant-card-body {
    .card-body {
      padding-top: 0 !important;
      display: flex;
      flex-flow: column nowrap;
    }
  }
  &.has-image {
    @for $i from 0 through 500 {
      &.height-#{$i} {
        .ant-empty-image {
          height: 1px * $i;
        }
      }
    }
  }
}
.empty-block {
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 30px 0 20px 0;
  &.has-image {
    padding: 40px 0 30px 0;
    @for $i from 0 through 500 {
      &.height-#{$i} {
        .ant-empty-image {
          height: 1px * $i;
        }
      }
    }
  }
  .ant-empty-image {
    margin-bottom: 30px;
  }
  &.center {
    justify-content: center;
    align-items: center;
    text-align: center;
  }
  span {
    font-size: 17px;
    font-weight: 900;
  }
}
</style>
