import General from "./General"
import Membres from "./Membres"
import Flow from "./Flow/Flow"
import Mails from "./Mails"

export const Tabs = {
  General,
  Membres,
  Flow,
  Mails,
}
