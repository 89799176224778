import { render, staticRenderFns } from "./InterlocuteursDetail.vue?vue&type=template&id=66d82725&scoped=true&"
import script from "./InterlocuteursDetail.vue?vue&type=script&lang=js&"
export * from "./InterlocuteursDetail.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "66d82725",
  null
  
)

export default component.exports