/* eslint-disable standard/computed-property-even-spacing */
<template>
  <section>
    <site-edit-localisation v-if="site" ref="siteEditLocalisation" @update="update" :site="site" :etapesLength="etapesLength"/>
    <mcma-divider class="mb20" />
    <site-interlocuteur-form :site="site" ref="siteInterlocuteurForm" @save="submitAll" @reset="resetAll" validationButtonName="Valider et passer à l'étape suivante"/>
  </section>
</template>

<script>
import McmaDivider from "@/components/mcma/common/McmaDivider"
import SiteEditLocalisation from "@/components/mcma/steps/sites/components/SiteEditLocalisation"
import SiteInterlocuteurForm from "@/components/mcma/steps/sites/components/SiteInterlocuteurForm"

export default {
  name: "etape_1",
  props: ["site","etapesLength"],
  components: {
    McmaDivider,
    SiteEditLocalisation,
    SiteInterlocuteurForm,
  },
  mounted(){
    console.log("etapes length=>>", this.etapesLength)
  },
  methods: {
    submitAll(interlocuteurId) {
      const interId = interlocuteurId || this.$refs.siteInterlocuteurForm.getSelectedInterlocuteurId()
      this.$refs.siteEditLocalisation.submit(interId)
    },
    resetAll() {
      this.$refs.siteEditLocalisation.initialize()
    },
    update(site) {
      this.$emit("update", site)
    },
  },
}
</script>

<style lang="scss">
.interlocuteur-wrapper {
  display: inline-block;
  width: 100%;
  border: 1px solid #ddd;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12), 0 3px 1px -2px rgba(0, 0, 0, 0.2);
  & ul {
    padding: 20px;
  }
  & div {
    padding: 20px;
    border-bottom: 1px solid #ddd;
  }
}
</style>
