<template>
  <div class="general-info">
    <mcma-card-button
      :name="title"
      color="primary"
      :uppercase="true"
      icon="info-circle"
      :class="{ infosNeeded: infosNeeded }"
      @click="openModal"
    />
    <!--:disabled="!infosNeeded"-->
    <a-modal
      :title="title"
      :visible="visible"
      @ok="handleOk"
      :confirmLoading="confirmLoading"
      @cancel="handleCancel"
      :okText="okText"
      :cancelText="cancelText"
      :maskClosable="false"
      :width="1000"
      wrapClassName="general-info-modal"
    >
      <div class="sub-title">Merci de compléter ces informations :</div>
      <div class="content">
        <div
          v-if="getGeneralFlow && generalInfoDescribe"
          class="html-render-content"
          v-html="generalInfoDescribe"
          :class="{ 'can-edit': $isAdmin() }"
          @click="setEditorModal('general', 'generalInfoDescribe')"
        />
        <a-form id="formGeneralInfo" :form="form">
          <a-tabs @change="tabChange" :activeKey="currentTab" type="card" :class="tabsInError">
            <a-tab-pane :tab="tabs[0].name" :key="tabs[0].key" :forceRender="true">
              <a-row type="flex" :gutter="12">
                <mcma-field
                  type="input"
                  :form="form"
                  :wasSubmit="wasSubmit"
                  :defaultValue="membre.businessName"
                  fieldName="businessName"
                  :label="$t('membreInfos.businessName')"
                  :placeholder="$t('membreInfos.businessName')"
                  :required="true"
                  :maxlength="31"
                  :requiredMessage="`Merci de renseigner la raison sociale`"
                  @onError="onFieldError"
                />
                <mcma-field
                  type="input"
                  :form="form"
                  :wasSubmit="wasSubmit"
                  :defaultValue="membre.ape"
                  fieldName="ape"
                  :label="$t('membreInfos.ape')"
                  :placeholder="$t('membreInfos.ape')"
                  :required="true"
                  @onError="onFieldError"
                  rule="isAPE"
                />
                <mcma-field
                  type="input"
                  :form="form"
                  :wasSubmit="wasSubmit"
                  :defaultValue="membre.siret"
                  fieldName="siret"
                  :label="$t('membreInfos.siret')"
                  :placeholder="$t('membreInfos.siret')"
                  :required="true"
                  :numberOnly="true"
                  @onError="onFieldError"
                  rule="isSiret"
                />
                <mcma-field
                  type="input"
                  :form="form"
                  :wasSubmit="wasSubmit"
                  :defaultValue="membre.numeroVoie"
                  fieldName="numeroVoie"
                  :label="$t('membreInfos.numeroVoie')"
                  :placeholder="$t('membreInfos.numeroVoie')"
                />
                <mcma-field
                  type="input"
                  :form="form"
                  :wasSubmit="wasSubmit"
                  :defaultValue="membre.nomVoie"
                  fieldName="nomVoie"
                  :label="$t('membreInfos.nomVoie')"
                  :placeholder="$t('membreInfos.nomVoie')"
                  :required="true"
                  :requiredMessage="`Merci de renseigner le nom de la voie`"
                  @onError="onFieldError"
                />
                <mcma-field
                  type="input"
                  :form="form"
                  :wasSubmit="wasSubmit"
                  :defaultValue="membre.complementAdresse"
                  fieldName="complementAdresse"
                  :label="$t('membreInfos.complementAdresse')"
                  :placeholder="$t('membreInfos.complementAdresse')"
                />
                <mcma-field
                  type="input"
                  :form="form"
                  :wasSubmit="wasSubmit"
                  :defaultValue="membre.postalCode"
                  fieldName="postalCode"
                  :label="$t('membreInfos.postalCode')"
                  :placeholder="$t('membreInfos.postalCode')"
                  :required="true"
                  :numberOnly="true"
                  @onError="onFieldError"
                  rule="isZipCode"
                />
                <mcma-field
                  type="input"
                  :form="form"
                  :wasSubmit="wasSubmit"
                  :defaultValue="membre.city"
                  fieldName="city"
                  :label="$t('membreInfos.city')"
                  :placeholder="$t('membreInfos.city')"
                  :required="true"
                  :requiredMessage="`Merci de renseigner la ville`"
                  @onError="onFieldError"
                />
              </a-row>

              <a-row type="flex" :gutter="12" class="mt0">
                <mcma-field
                  @click="$refs.documentUploaded.click()"
                  :colSpan="24"
                  :form="form"
                  :wasSubmit="wasSubmit"
                  :defaultValue="membre.ibanFile"
                  fieldName="ibanFile"
                  type="file-upload"
                  getFlow="name::sites"
                  getPanel="name::Localisation"
                  getField="type::fileUploadIban"
                  :color="membre.ibanFile === true ? 'green' : 'blue'"
                  :filesNames="ibanName != null ? ibanName : membre.ibanFile ? 'Vous avez déjà déposée un Iban' : null"
                  label="Iban"
                  :required="!membre.ibanFile && !ibanName"
                  :requiredMessage="`Merci de renseigner votre iban au format PDF`"
                />
                <input class="ninja" type="file" ref="documentUploaded" @change="processFileIban" />
                <mcma-field
                  :colSpan="24"
                  :form="form"
                  :wasSubmit="wasSubmit"
                  :defaultValue="membre.iban"
                  fieldname="iban"
                  type="input"
                  getFlow="name::sites"
                  getPanel="name::Localisation"
                  getField="type::iban"
                  rule="isIban"
                  :requiredMessage="`Merci de renseigner votre iban`"
                />
              </a-row>
            </a-tab-pane>
            <a-tab-pane :tab="tabs[1].name" :key="tabs[1].key" :forceRender="true">
              <a-row type="flex" :gutter="12">
                <mcma-button name="copier l'interlocuteur technique" @click="copyInterlocuteurTechnique" />
              </a-row>
              <a-row type="flex" :gutter="12">
                <mcma-field
                  type="select"
                  :form="form"
                  :wasSubmit="wasSubmit"
                  :defaultValue="membre.interlocuteurFacture.fonction || 'RESP_FACTURATION'"
                  fieldName="interlocuteurFactureFonction"
                  :label="$t('membreInfos.fonction')"
                  disableValue="Sélectionnez une fonction"
                  getFlow="name::services"
                  getPanel="name::Interlocuteurs"
                  getField="type::fonction"
                  :required="true"
                  :requiredMessage="`Merci de renseigner sa fonction`"
                  @onError="onFieldError"
                />
                <mcma-field
                  type="input"
                  :form="form"
                  :wasSubmit="wasSubmit"
                  :defaultValue="membre.interlocuteurFacture.prenom"
                  fieldName="interlocuteurFacturePrenom"
                  :label="$t('membreInfos.firstName')"
                  :placeholder="$t('membreInfos.firstName')"
                  :required="true"
                  :requiredMessage="`Merci de renseigner son prénom`"
                  @onError="onFieldError"
                />
                <mcma-field
                  type="input"
                  :form="form"
                  :wasSubmit="wasSubmit"
                  :defaultValue="membre.interlocuteurFacture.nom"
                  fieldName="interlocuteurFactureNom"
                  :label="$t('membreInfos.lastName')"
                  :placeholder="$t('membreInfos.lastName')"
                  :required="true"
                  :requiredMessage="`Merci de renseigner son nom`"
                  @onError="onFieldError"
                />
                <mcma-field
                  type="select"
                  :form="form"
                  :wasSubmit="wasSubmit"
                  getFlow="name::services"
                  getPanel="name::Interlocuteurs"
                  getField="type::civility"
                  fieldName="interlocuteurFactureCivility"
                  :defaultValue="membre.interlocuteurFacture.civility"
                />
                <mcma-field
                  type="input"
                  :form="form"
                  :wasSubmit="wasSubmit"
                  :defaultValue="membre.interlocuteurFacture.mail"
                  fieldName="interlocuteurFactureMail"
                  :label="$t('membreInfos.mail')"
                  :placeholder="$t('membreInfos.mail')"
                  :required="true"
                  :requiredMessage="`Merci de renseigner son email`"
                  @onError="onFieldError"
                  rule="isEmail"
                />
                <mcma-field
                  type="input"
                  :form="form"
                  :wasSubmit="wasSubmit"
                  :defaultValue="membre.interlocuteurFacture.telephone"
                  fieldName="interlocuteurFactureTelephone"
                  :label="$t('membreInfos.phone')"
                  :placeholder="$t('membreInfos.phone')"
                  :extraValidationFields="['interlocuteurFactureMobile']"
                  :required="
                    !form.getFieldValue('interlocuteurFactureMobile') ||
                    form.getFieldValue('interlocuteurFactureMobile').length <= 0
                  "
                  :requiredMessage="`Merci de renseigner son numéro de téléphone fixe`"
                  @onError="onFieldError"
                  :numberOnly="true"
                  rule="isPhone"
                />
                <mcma-field
                  type="input"
                  :form="form"
                  :wasSubmit="wasSubmit"
                  :defaultValue="membre.interlocuteurFacture.mobile"
                  fieldName="interlocuteurFactureMobile"
                  :label="$t('membreInfos.mobile')"
                  :placeholder="$t('membreInfos.mobile')"
                  :extraValidationFields="['interlocuteurFactureTelephone']"
                  :required="
                    !form.getFieldValue('interlocuteurFactureTelephone') ||
                    form.getFieldValue('interlocuteurFactureTelephone').length <= 0
                  "
                  :requiredMessage="`ou son numéro de téléphone mobile`"
                  @onError="onFieldError"
                  :numberOnly="true"
                  rule="isPhone"
                />
              </a-row>
            </a-tab-pane>
            <a-tab-pane :tab="tabs[2].name" :key="tabs[2].key" :forceRender="true">
              <a-row type="flex" :gutter="12">
                <mcma-button name="copier l'interlocuteur facturation" @click="copyInterlocuteurFacture" />
              </a-row>
              <a-row type="flex" :gutter="12">
                <mcma-field
                  type="select"
                  :form="form"
                  :wasSubmit="wasSubmit"
                  :defaultValue="membre.interlocuteurTechnique.fonction || 'RESP_TECHNIQUE'"
                  fieldName="interlocuteurTechniqueFonction"
                  :label="$t('membreInfos.fonction')"
                  disableValue="Sélectionnez une fonction"
                  getFlow="name::services"
                  getPanel="name::Interlocuteurs"
                  getField="type::fonction"
                  :requiredMessage="`Merci de renseigner sa fonction`"
                  @onError="onFieldError"
                />
                <mcma-field
                  type="input"
                  :form="form"
                  :wasSubmit="wasSubmit"
                  :defaultValue="membre.interlocuteurTechnique.prenom"
                  fieldName="interlocuteurTechniquePrenom"
                  :label="$t('membreInfos.firstName')"
                  :placeholder="$t('membreInfos.firstName')"
                  :required="true"
                  :requiredMessage="`Merci de renseigner son prénom`"
                  @onError="onFieldError"
                />
                <mcma-field
                  type="input"
                  :form="form"
                  :wasSubmit="wasSubmit"
                  :defaultValue="membre.interlocuteurTechnique.nom"
                  fieldName="interlocuteurTechniqueNom"
                  :label="$t('membreInfos.lastName')"
                  :placeholder="$t('membreInfos.lastName')"
                  :required="true"
                  :requiredMessage="`Merci de renseigner son nom`"
                  @onError="onFieldError"
                />
                <mcma-field
                  type="select"
                  :form="form"
                  :wasSubmit="wasSubmit"
                  getFlow="name::services"
                  getPanel="name::Interlocuteurs"
                  getField="type::civility"
                  fieldName="interlocuteurTechniqueCivility"
                  :defaultValue="membre.interlocuteurTechnique.civility"
                />
                <mcma-field
                  type="input"
                  :form="form"
                  :wasSubmit="wasSubmit"
                  :defaultValue="membre.interlocuteurTechnique.mail"
                  fieldName="interlocuteurTechniqueMail"
                  :label="$t('membreInfos.mail')"
                  :placeholder="$t('membreInfos.mail')"
                  :required="true"
                  :requiredMessage="`Merci de renseigner son email`"
                  @onError="onFieldError"
                  rule="isEmail"
                />
                <mcma-field
                  type="input"
                  :form="form"
                  :wasSubmit="wasSubmit"
                  :defaultValue="membre.interlocuteurTechnique.telephone"
                  fieldName="interlocuteurTechniqueTelephone"
                  :label="$t('membreInfos.phone')"
                  :placeholder="$t('membreInfos.phone')"
                  :extraValidationFields="['interlocuteurTechniqueMobile']"
                  :required="
                    !form.getFieldValue('interlocuteurTechniqueMobile') ||
                    form.getFieldValue('interlocuteurTechniqueMobile').length <= 0
                  "
                  :requiredMessage="`Merci de renseigner son numéro de téléphone fixe`"
                  @onError="onFieldError"
                  :numberOnly="true"
                  rule="isPhone"
                />
                <mcma-field
                  type="input"
                  :form="form"
                  :wasSubmit="wasSubmit"
                  :defaultValue="membre.interlocuteurTechnique.mobile"
                  fieldName="interlocuteurTechniqueMobile"
                  :label="$t('membreInfos.mobile')"
                  :placeholder="$t('membreInfos.mobile')"
                  :extraValidationFields="['interlocuteurTechniqueTelephone']"
                  :required="
                    !form.getFieldValue('interlocuteurTechniqueTelephone') ||
                    form.getFieldValue('interlocuteurTechniqueTelephone').length <= 0
                  "
                  :requiredMessage="`ou son numéro de téléphone mobile`"
                  @onError="onFieldError"
                  :numberOnly="true"
                  rule="isPhone"
                />
              </a-row>
            </a-tab-pane>
          </a-tabs>
        </a-form>
      </div>
    </a-modal>
    <mcma-editor-modal
      v-if="$isAdmin()"
      :visible="showEditor"
      :flowName="flowName"
      :flowProp="flowProp"
      @onCancel="setEditorModal"
      @onSave="editorHasMakeChange"
    />
  </div>
</template>

<script>
import Axios from "@/util/Axios"
import McmaCardButton from "@/components/mcma/common/McmaCardButton"
import McmaField from "@/components/mcma/common/McmaField"
import McmaEditorModal from "@/components/mcma/common/McmaEditorModal"
import { mapMutations, mapState } from "vuex"
import _ from "lodash"
import McmaButton from "../../common/McmaButton.vue"

export default {
  name: "GeneralInfo",
  components: {
    McmaField,
    McmaCardButton,
    McmaEditorModal,
    McmaButton,
  },
  data() {
    return {
      form: this.$form.createForm(this),
      title: "Informations générales",
      okText: "Valider les informations",
      cancelText: "Annuler",
      visible: false,
      confirmLoading: false,
      tabsInError: "",
      wasSubmit: false,
      currentTab: "infoAdmin",
      generalInfoDescribe: null,
      showEditor: false,
      flowName: null,
      flowProp: null,
      ibanUploadedFile: null,
      ibanName: null,
      idInterlocTechToLink: null,
      idInterlocFactuToLink: null,
      tabs: [
        {
          key: "infoAdmin",
          name: "Informations administratives",
          fields: ["businessName", "ape", "siret", "nomVoie", "postalCode", "city"],
        },
        {
          key: "contactBill",
          name: "Contact de facturation général",
          fields: [
            "interlocuteurFactureFonction",
            "interlocuteurFacturePrenom",
            "interlocuteurFactureNom",
            "interlocuteurFactureMail",
            "interlocuteurTechniqueCivility",
            "interlocuteurFactureTelephone",
            "interlocuteurFactureMobile",
          ],
        },
        {
          key: "contactTech",
          name: "Contact technique général",
          fields: [
            "interlocuteurTechniqueFonction",
            "interlocuteurTechniquePrenom",
            "interlocuteurTechniqueNom",
            "interlocuteurTechniqueMail",
            "interlocuteurTechniqueCivility",
            "interlocuteurTechniqueTelephone",
            "interlocuteurTechniqueMobile",
          ],
        },
      ],
    }
  },
  computed: {
    ...mapState(["membre", "operation", "user"]),
    getGeneralFlow() {
      return this.operation.flows.find((flow) => flow.name === "general")
    },
  },
  created() {
    this.prepareSentences()
  },
  mounted() {
    console.group("mounted@GereneralInfo")
    this.infosNeeded()
    console.groupEnd()
  },
  methods: {
    copyInterlocuteurFacture() {
      this.form.setFieldsValue({
        interlocuteurTechniquePrenom: this.form.getFieldValue("interlocuteurFacturePrenom"),
        interlocuteurTechniqueNom: this.form.getFieldValue("interlocuteurFactureNom"),
        interlocuteurTechniqueCivility: this.form.getFieldValue("interlocuteurFactureCivility"),
        interlocuteurTechniqueMail: this.form.getFieldValue("interlocuteurFactureMail"),
        interlocuteurTechniqueMobile: this.form.getFieldValue("interlocuteurFactureMobile"),
        interlocuteurTechniqueTelephone: this.form.getFieldValue("interlocuteurFactureTelephone"),
        interlocuteurTechniqueFonction: this.form.getFieldValue("interlocuteurFactureFonction"),
      })
    },
    copyInterlocuteurTechnique() {
      this.form.setFieldsValue({
        interlocuteurFacturePrenom: this.form.getFieldValue("interlocuteurTechniquePrenom"),
        interlocuteurFactureNom: this.form.getFieldValue("interlocuteurTechniqueNom"),
        interlocuteurFactureCivility: this.form.getFieldValue("interlocuteurTechniqueCivility"),
        interlocuteurFactureMail: this.form.getFieldValue("interlocuteurTechniqueMail"),
        interlocuteurFactureMobile: this.form.getFieldValue("interlocuteurTechniqueMobile"),
        interlocuteurFactureTelephone: this.form.getFieldValue("interlocuteurTechniqueTelephone"),
        interlocuteurFactureFonction: this.form.getFieldValue("interlocuteurTechniqueFonction"),
      })
    },
    ...mapMutations(["updateCurrentMembre"]),
    infosNeeded() {
      console.group("infosNeeded@GereneralInfo")
      console.log("this.membre : %O", this.membre)

      const needMembre = !(
        this.membre &&
        this.membre.businessName &&
        this.membre.ape &&
        this.membre.siret &&
        this.membre.nomVoie &&
        this.membre.postalCode &&
        this.membre.city &&
        this.membre.iban
      )

      const needInterlocuteurFacture = !(
        this.membre.interlocuteurFacture &&
        this.membre.interlocuteurFacture.fonction &&
        this.membre.interlocuteurFacture.prenom &&
        this.membre.interlocuteurFacture.nom &&
        this.membre.interlocuteurFacture.mail &&
        (this.membre.interlocuteurFacture.telephone || this.membre.interlocuteurFacture.mobile)
      )

      const needInterlocuteurTechnique = !(
        this.membre.interlocuteurTechnique &&
        this.membre.interlocuteurTechnique.fonction &&
        this.membre.interlocuteurTechnique.prenom &&
        this.membre.interlocuteurTechnique.nom &&
        this.membre.interlocuteurTechnique.mail &&
        (this.membre.interlocuteurTechnique.telephone || this.membre.interlocuteurTechnique.mobile)
      )

      const needInterlocuteurTechniqueDetail = !(
        this.membre.interlocuteurTechnique && this.membre.interlocuteurTechnique.fonction
      )

      const needed = !(
        this.membre &&
        this.membre.businessName &&
        this.membre.ape &&
        this.membre.siret &&
        this.membre.nomVoie &&
        this.membre.postalCode &&
        this.membre.city &&
        this.membre.interlocuteurFacture &&
        this.membre.interlocuteurFacture.prenom &&
        this.membre.interlocuteurFacture.nom &&
        this.membre.interlocuteurFacture.mail &&
        (this.membre.interlocuteurFacture.telephone || this.membre.interlocuteurFacture.mobile) &&
        this.membre.interlocuteurTechnique &&
        this.membre.interlocuteurTechnique.prenom &&
        this.membre.interlocuteurTechnique.nom &&
        this.membre.interlocuteurTechnique.mail &&
        (this.membre.interlocuteurTechnique.telephone || this.membre.interlocuteurTechnique.mobile)
      )

      this.$emit("completed", needed)
      console.log("infosNeeded membre : %O", needMembre)
      console.log("infosNeeded InterlocuteurFacture : %O", needInterlocuteurFacture)
      console.log("infosNeeded InterlocuteurTechnique : %O", needInterlocuteurTechnique)
      console.log("infosNeeded InterlocuteurTechniqueDetail : %O", needInterlocuteurTechniqueDetail)
      console.log("infosNeeded : %O", needed)
      console.groupEnd()
      return needed
    },
    openModal() {
      if (this.membre) {
        this.visible = true
        this.infosNeeded()
        this.currentTab = "infoAdmin"
      }
    },
    setEditorModal(flowName, flowProp) {
      this.showEditor = !!(flowName && flowProp)
      if (flowName && flowProp) {
        this.flowName = flowName
        this.flowProp = flowProp
      } else {
        setTimeout(() => {
          this.flowName = flowName
          this.flowProp = flowProp
        }, 300)
      }
    },
    editorHasMakeChange() {
      this.prepareSentences()
      this.setEditorModal()
    },
    prepareSentences() {
      this.generalInfoDescribe = this.getGeneralFlow
        ? this.$evalSentence(this.getGeneralFlow.generalInfoDescribe, this)
        : null
    },
    getAllFieldName() {
      const fieldsName = []
      const fieldsBlock = this.tabs.map((tab) => tab.fields)
      for (let fields of fieldsBlock) {
        fieldsName.push(...fields)
      }
      return fieldsName
    },
    onFieldError(errors) {
      this.tabsInError = this.getTabsInError(errors)
    },
    getTabsInError(errors) {
      const classes = []
      this.tabs.map((tab) => {
        const fields = tab.fields
        const find = !!fields.find((field) => {
          return !!errors[field]
        })
        if (find) {
          classes.push(`error-${tab.key}`)
        }
      })
      return classes.join(" ")
    },
    handleOk(e) {
      this.form.validateFields((errors, fieldsValue) => {
        this.wasSubmit = true
        const emptyList = this.getEmptyProperties(fieldsValue, this.getAllFieldName())
        console.log(emptyList)
        if (errors || !_.isEmpty(emptyList)) {
          errors = { ...errors, ...emptyList }
          this.tabsInError = this.getTabsInError(errors)
          this.$notification.warning({
            message: "Formulaire invalide",
            description: `Veuillez-vous assurez de remplir tous les champs requis.`,
          })
        } else {
          this.submit(fieldsValue)
        }
      })
    },
    getEmptyProperties(obj, allKeys) {
      const emptyProperties = {}
      for (let key of allKeys) {
        if (obj[key] === undefined || obj[key] === null || obj[key] === "") {
          emptyProperties[key] = true
        }
      }
      if (obj.interlocuteurTechniqueCivility == null) {
        // for no failure operation without civility
        delete emptyProperties.interlocuteurTechniqueCivility
      }
      if (obj.interlocuteurTechniqueFonction == null) {
        delete emptyProperties.interlocuteurTechniqueFonction
      }
      if (obj.interlocuteurFactureFonction == null) {
        delete emptyProperties.interlocuteurFactureFonction
      }
      if (obj.interlocuteurFactureTelephone != null || obj.interlocuteurFactureMobile != null) {
        delete emptyProperties.interlocuteurFactureTelephone
        delete emptyProperties.interlocuteurFactureMobile
      }
      if (obj.interlocuteurTechniqueTelephone != null || obj.interlocuteurTechniqueMobile != null) {
        delete emptyProperties.interlocuteurTechniqueTelephone
        delete emptyProperties.interlocuteurTechniqueMobile
      }
      return emptyProperties
    },
    handleCancel() {
      this.visible = false
    },
    tabChange(tabName) {
      this.currentTab = tabName
    },
    submit(fieldsValue) {
      if (this.membre) {
        this.confirmLoading = true
        Axios("get", "api/membres/" + this.membre.id).then(async () => {
          let interFacturationResponse, interTechniqueResponse

          if (!this.membre.interlocuteurFacture.id) {
            try {
              interFacturationResponse = await this.requestInterlocuteur(
                "post",
                null,
                fieldsValue,
                "interlocuteurFacture",
                "FACTURATION"
              )
            } catch (error) {
              this.interlocuteurRequestError(fieldsValue, error, "interlocuteurFacture")
            }
          } else {
            try {
              let response = await this.requestInterlocuteur(
                "patch",
                this.membre.interlocuteurFacture.id,
                fieldsValue,
                "interlocuteurFacture",
                "FACTURATION"
              )
              if (response) this.interlocuteurRequestSuccess(fieldsValue, "facturation")
            } catch (error) {
              this.interlocuteurRequestError(fieldsValue, error, "interlocuteurFacture")
            }
          }

          if (!this.membre.interlocuteurTechnique.id) {
            try {
              interTechniqueResponse = await this.requestInterlocuteur(
                "post",
                null,
                fieldsValue,
                "interlocuteurTechnique",
                "TECHNIQUE"
              )
            } catch (error) {
              this.interlocuteurRequestError(fieldsValue, error, "interlocuteurTechnique")
            }
          } else {
            try {
              const response = await this.requestInterlocuteur(
                "patch",
                this.membre.interlocuteurTechnique.id,
                fieldsValue,
                "interlocuteurTechnique",
                "TECHNIQUE"
              )
              if (response) this.interlocuteurRequestSuccess(fieldsValue, "technique")
            } catch (error) {
              this.interlocuteurRequestError(fieldsValue, error, "interlocuteurTechnique")
            }
          }
          setTimeout(() => {
            try {
              let response = Axios("patch", "api/membres/" + this.membre.id, {
                businessName: fieldsValue.businessName,
                ape: fieldsValue.ape,
                siret: fieldsValue.siret,
                nomVoie: fieldsValue.nomVoie,
                numeroVoie: fieldsValue.numeroVoie,
                complementAdresse: fieldsValue.complementAdresse,
                postalCode: fieldsValue.postalCode,
                ibanFile: fieldsValue.ibanFile || this.membre.ibanFile,
                iban: fieldsValue.iban,
                city: fieldsValue.city,
                interlocuteurFacture: interFacturationResponse
                  ? process.env.VUE_APP_API_URL + "/api/interlocuteurs/" + interFacturationResponse.data.id
                  : this.idInterlocFactuToLink !== null
                  ? process.env.VUE_APP_API_URL + "/api/interlocuteurs/" + this.idInterlocFactuToLink
                  : undefined,
                interlocuteurTechnique: interTechniqueResponse
                  ? process.env.VUE_APP_API_URL + "/api/interlocuteurs/" + interTechniqueResponse.data.id
                  : this.idInterlocTechToLink !== null
                  ? process.env.VUE_APP_API_URL + "/api/interlocuteurs/" + this.idInterlocTechToLink
                  : undefined,
              })
              if (response) {
                this.processUpdateCurrentMembre(fieldsValue)
                this.$notification.success({
                  message: "Informations mises à jour",
                  description: "Vos informations on bien été mis à jour.",
                })
                setTimeout(() => {
                  this.visible = false
                  this.confirmLoading = false
                }, 1000)
                this.$emit("completed", false)
                // this.infosNeeded()
              }
            } catch (error) {
              this.$notification.error({
                message: "Erreur de mise à jour",
                description: JSON.stringify(error),
              })
              this.confirmLoading = false
              console.log(
                `%c error`,
                "background:red ; padding: 5px 7px 4px 0px; border-radius: 3px;  color: #FFFFFF",
                error
              )
            }
          }, 2000)
        })
      }
    },
    processUpdateCurrentMembre(fieldsValue) {
      this.updateCurrentMembre({
        businessName: fieldsValue.businessName || this.membre.businessName,
        ape: fieldsValue.ape || this.membre.ape,
        siret: fieldsValue.siret || this.membre.siret,
        numeroVoie: fieldsValue.numeroVoie || this.membre.numeroVoie,
        nomVoie: fieldsValue.nomVoie || this.membre.nomVoie,
        complementAdresse: fieldsValue.complementAdresse || this.membre.complementAdresse,
        postalCode: fieldsValue.postalCode || this.membre.postalCode,
        city: fieldsValue.city || this.membre.city,
        ibanFile: fieldsValue.ibanFile || this.membre.ibanFile,
        iban: fieldsValue.iban,
        interlocuteurFacture: {
          fonction: fieldsValue.interlocuteurFactureFonction || this.membre.interlocuteurFacture.fonction,
          prenom: fieldsValue.interlocuteurFacturePrenom || this.membre.interlocuteurFacture.prenom,
          nom: fieldsValue.interlocuteurFactureNom || this.membre.interlocuteurFacture.nom,
          mail: fieldsValue.interlocuteurFactureMail || this.membre.interlocuteurFacture.mail,
          civility: fieldsValue.interlocuteurFactureCivility || this.membre.interlocuteurFacture.civility,
          telephone: fieldsValue.interlocuteurFactureTelephone || this.membre.interlocuteurFacture.telephone,
          mobile: fieldsValue.interlocuteurFactureMobile || this.membre.interlocuteurFacture.mobile,
        },
        interlocuteurTechnique: {
          fonction: fieldsValue.interlocuteurTechniqueFonction || this.membre.interlocuteurTechnique.fonction,
          prenom: fieldsValue.interlocuteurTechniquePrenom || this.membre.interlocuteurTechnique.prenom,
          nom: fieldsValue.interlocuteurTechniqueNom || this.membre.interlocuteurTechnique.nom,
          mail: fieldsValue.interlocuteurTechniqueMail || this.membre.interlocuteurTechnique.mail,
          civility: fieldsValue.interlocuteurTechniqueCivility || this.membre.interlocuteurTechnique.civility,
          telephone: fieldsValue.interlocuteurTechniqueTelephone || this.membre.interlocuteurTechnique.telephone,
          mobile: fieldsValue.interlocuteurTechniqueMobile || this.membre.interlocuteurTechnique.mobile,
        },
      })
      this.addIban()
    },
    requestInterlocuteur(method, id, data, interlocuteurType, role) {
      const obj = {
        fonction: data[`${interlocuteurType}Fonction`],
        prenom: data[`${interlocuteurType}Prenom`],
        nom: data[`${interlocuteurType}Nom`],
        mail: data[`${interlocuteurType}Mail`],
        civility: data[`${interlocuteurType}Civility`],
        telephone: data[`${interlocuteurType}Telephone`],
        mobile: data[`${interlocuteurType}Mobile`],
        role: role,
      }
      console.log("ON est ici requestInterloc >>>>", method, id, data, interlocuteurType, role)
      if (method === "post") {
        obj.removed = false
        obj.membre = process.env.VUE_APP_API_URL + "/api/membres/" + this.membre.id
        obj.operationId = this.operation.id
      }
      const url = method === "patch" && id ? `api/interlocuteurs/${id}` : `api/interlocuteurs`
      return Axios(method, url, obj)
    },
    interlocuteurRequestSuccess(data, dataString) {
      this.processUpdateCurrentMembre(data)
      this.$notification.success({
        message: `Informations du contact ${dataString} général`,
        description: `Les Informations du contact ${dataString} général ont été mises à jour`,
      })
      setTimeout(() => {
        this.visible = false
        this.confirmLoading = false
      }, 2000)
    },
    interlocuteurRequestError(data, error, interlocuteurType) {
      this.confirmLoading = false
      const response = error.response || {}
      const data1 = response.data || {}
      const cause1 = data1.cause || {}
      const cause2 = cause1.cause || {}
      const message = cause2.message || "veuillez contacter un administrateur"
      let role = interlocuteurType === "interlocuteurFacture" ? "FACTURATION" : "TECHNIQUE"
      if (response.status === 409) {
        Axios(
          "get",
          "api/interlocuteurs/search/findByMembreIdAndMailAndNomAndRoleEquals?idMembre=" +
            this.membre.id +
            "&mail=" +
            data[`${interlocuteurType}Mail`] +
            "&nom=" +
            data[`${interlocuteurType}Nom`] +
            "&role=" +
            role
        )
          .then((res) => {
            if (role === "TECHNIQUE") {
              this.idInterlocTechToLink = res.data._embedded.interlocuteurs[0].id
            } else {
              this.idInterlocFactuToLink = res.data._embedded.interlocuteurs[0].id
            }
          })
          .catch((err) => {
            console.log("catch req err >>>> ", err)
          })

        this.$notification.warning({
          message: "Email déjà renseigné",
          description: message.includes(data[`${interlocuteurType}Mail`])
            ? `L'email ${data[`${interlocuteurType}Mail`]} est déjà renseigné pour un autre interlocuteur ${role}`
            : "L'email saisie est déjà renseigné pour un autre interlocuteur Technique.",
        })
      } else {
        this.$notification.error({
          message: "Erreur de mise à jour",
          description: message,
        })
      }
      console.log(`%c error`, "background:red ; padding: 5px 7px 4px 0px; border-radius: 3px;  color: #FFFFFF", error)
    },
    processFileIban(event) {
      const sizeMax = process.env.VUE_APP_UPLOAD_SIZE * 1024 * 1024
      const fileIban = event.target.files[0]

      if (fileIban.size > sizeMax) {
        this.$message.warning(`Le fichier dépasse la taille autorisé qui est de ${process.env.VUE_APP_UPLOAD_SIZE}Mo.`)
      } else if (fileIban.type !== "application/pdf") {
        this.$message.error(`Le fichier n'est pas au format PDF`)
      } else {
        this.ibanUploadedFile = fileIban
        this.ibanName = fileIban.name
        this.form.setFieldsValue({ ibanFile: true })
      }
    },
    addIban() {
      if (this.ibanUploadedFile) {
        const formData = new FormData()
        formData.append("file", this.ibanUploadedFile)
        Axios(
          "post",
          "/file/operation/" + this.operation.id + "/membre/" + this.membre.id + "/perimetrefacturation/piece",
          formData,
          null,
          {
            "Content-Type": "multipart/form-data",
          }
        ).catch((error) => {
          console.error(error)
          this.$notification.error({
            message: `Erreur lors du téléversement de l'iban`,
            description: `Une erreur s'est produite lors du téléversement de la pièce, veuillez contacter un administrateur.`,
          })
        })
      }
    },
  },
}
</script>

<style lang="scss">
@import "./src/assets/scss/variables";
@import "./src/assets/scss/functions";

.general-info {
  height: auto;
}

.general-info-modal {
  .sub-title {
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 10px;
  }
  .content {
    .ant-tabs {
      margin-top: 20px;
      .ant-tabs-tab {
        font-weight: 500;
        &:hover {
          color: var(--primary-color, $primary-color) !important;
        }
        &.ant-tabs-tab-active {
          color: var(--primary-color, $primary-color) !important;
        }
      }
      &.error {
        &-infoAdmin {
          .ant-tabs-tab:nth-child(1) {
            color: var(--error-color, $error-color);
          }
        }
        &-contactBill {
          .ant-tabs-tab:nth-child(2) {
            color: var(--error-color, $error-color);
          }
        }
        &-contactTech {
          .ant-tabs-tab:nth-child(3) {
            color: var(--error-color, $error-color);
          }
        }
      }
    }
  }
}
</style>
