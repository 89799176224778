<template>
  <section v-if="site">
    <div class="container pt17" v-if="site.state === Constants.STATUS.COMPLETED.value">
      <mcma-card :title="`Validation du site : ${site.nom}`" icon="certified" class="mcma-card-validate">
        <template #describe>
          Êtes-vous sur de vouloir certifier les informations de ce site ?
          <br />
          Vous ne pourrez plus modifier les caractéristiques de ce site via cette application après validation.
        </template>
        <div class="align-row-end mtb12">
          <mcma-button name="Non, je ne veux pas encore le certifier." color="orange" class="mr12" @click="goBack" />
          <mcma-button name="Oui, je le certifie" color="success" leftImage="certified_white" @click="validate" />
        </div>
      </mcma-card>
    </div>
    <div class="container pt17" v-else>
      <mcma-empty
        :inCard="true"
        image="cant_certify"
        :center="true"
        :imageSize="300"
        message="Ce site ne peut pas être certifié."
      >
        <mcma-button name="Revenir au site" class="mt24" color="primary" @click="goBack" />
      </mcma-empty>
    </div>
  </section>
</template>

<script>
import GuardiansMixin from "@/components/mcma/steps/guardians/GuardiansMixin"
import McmaCard from "@/components/mcma/common/McmaCard"
import McmaButton from "@/components/mcma/common/McmaButton"
import McmaDivider from "@/components/mcma/common/McmaDivider"
import McmaEmpty from "@/components/mcma/common/McmaEmpty"
import { mapState, mapMutations } from "vuex"
import Axios from "@/util/Axios"
import Constants from "@/util/Constants"

export default {
  name: "validate-site",
  mixins: [GuardiansMixin],
  components: {
    McmaCard,
    McmaButton,
    McmaDivider,
    McmaEmpty,
  },
  data() {
    return {
      flow: "PERIMETRE",
      site: null,
    }
  },
  computed: {
    ...mapState(["membre", "user", "operation"]),
    Constants() {
      return Constants
    },
  },
  methods: {
    ...mapMutations(["setBreadcrumbAdd", "breadcrumbGoBack"]),
    goBack() {
      this.breadcrumbGoBack()
    },
    validate() {
      Axios("patch", `api/sites/${this.$route.params.siteId}`, {
        certified: true,
      })
        .then(() => {
          this.$notification.success({
            message: `Site Validé !`,
            description: `Le site à bien été validé !`,
          })
          this.$router.push({
            name: "sites",
          })
        })
        .catch((error) => {
          console.error("error", error)
          this.$notification.error({
            message: `Erreur de validation`,
            description: `Une erreur c'est produite lors de la validation du site, veuillez contacter un administrateur.`,
          })
        })
    },
  },
  created() {
    Axios("get", `api/sites/${this.$route.params.siteId}`).then((response) => {
      this.site = response.data
      this.setBreadcrumbAdd({
        name: `Validation de : ${this.site.nom}`,
        action: () => {},
      })
    })
  },
}
</script>

<style lang="scss" scoped></style>
