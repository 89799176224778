<template>
  <section class="input-field" v-if="exist">
    <input
      id="codeIdentificationIndividuelle"
      type="text"
      v-model="field"
      :required="
        context.moyenPaiement === 'PRELEVEMENT_BANQUE_DE_FRANCE' ||
        context.moyenPaiement === 'VIREMENT_SANS_MANDATEMENT'
      "
    />
    <label :class="{ active: field }" for="codeIdentificationIndividuelle">
      <font-awesome-icon
        icon="asterisk"
        class="red-text"
        v-if="
          context.moyenPaiement === 'PRELEVEMENT_BANQUE_DE_FRANCE' ||
          context.moyenPaiement === 'VIREMENT_SANS_MANDATEMENT'
        "
      />
      Code d'identification individuelle
    </label>
  </section>
</template>

<script>
import Mixin from "./Mixin"

export default {
  name: "code-identification-individuelle",
  mixins: [Mixin],
  computed: {
    exist() {
      return this.confOperation !== "CARREFOUR"
    },
  },
}
</script>

<style lang="scss" scoped></style>
