<template>
  <section>
    <div>
      <span class="space-between">
        <h5>Text libre de présentation de la collecte :</h5>
        <mcma-select-variables :variables="generalFlow.variables" :disabled="!collectEditor.selection"
          @addVariable="addVariableInText($event, 'collectEditor')" />
      </span>
      <vue-editor id="collectEditor" use-custom-image-handler @image-added="handleImageAdded"
        v-model="generalFlow.collectDescribe" @focus="addQuill($event, 'collectEditor')"
        @selection-change="getCursorPosition($event, 'collectEditor')"
        @text-change="getCursorPosition($event, 'collectEditor')" />
    </div>
    <div>
      <span class="space-between mt25">
        <h5>Text libre de présentation de l'objectif des étapes :</h5>
        <mcma-select-variables :variables="generalFlow.variables" :disabled="!objectifEditor.selection"
          @addVariable="addVariableInText($event, 'objectifEditor')" />
      </span>
      <vue-editor id="objectifEditor" v-model="generalFlow.objectifDescribe" @focus="addQuill($event, 'objectifEditor')"
        @selection-change="getCursorPosition($event, 'objectifEditor')"
        @text-change="getCursorPosition($event, 'objectifEditor')" />
    </div>
    <div>
      <span class="space-between mt25">
        <h5>Text libre de présentation de l'information générale :</h5>
        <mcma-select-variables :variables="generalFlow.variables" :disabled="!generalInfoEditor.selection"
          @addVariable="addVariableInText($event, 'generalInfoEditor')" />
      </span>
      <vue-editor id="generalInfoEditor" v-model="generalFlow.generalInfoDescribe"
        @focus="addQuill($event, 'generalInfoEditor')" @selection-change="getCursorPosition($event, 'generalInfoEditor')"
        @text-change="getCursorPosition($event, 'generalInfoEditor')" />
    </div>
  </section>
</template>

<script>
import McmaSelectVariables from "@/components/mcma/common/McmaSelectVariables"
import { VueEditor } from "vue2-editor"
import Axios from "@/util/Axios"
import { mapState } from "vuex"
export default {
  name: "GeneralFlow",
  props: {
    generalFlow: Object,
  },
  components: {
    McmaSelectVariables,
    VueEditor,
  },
  watch: {
    "generalFlow.collectDescribe"(v) {
      console.log("v collect ===> ", v)
    }
  },
  data() {
    return {
      collectEditor: {
        selection: null,
        quill: null,
      },
      objectifEditor: {
        selection: null,
        quill: null,
      },
      generalInfoEditor: {
        selection: null,
        quill: null,
      },
    }
  },
  computed: {
    ...mapState(["operation"]),
  },
  methods: {
    handleImageAdded: function (file, Editor, cursorLocation, resetUploader) {
      var formData = new FormData()
      formData.append("file", file)
      formData.append("operationId", this.operation.id)
      console.log("ecitor", Editor)
      Axios(
        "post",
        "/file/operation/upload/fileUtils", formData, null, {
        "Content-Type": "multipart/form-data",
      }).then((result) => {
        let url = result.data // Get url from response
        console.log("url", url)
        Editor.insertEmbed(cursorLocation, "image", url)
        resetUploader()
        // console.log("this.discribe", this.collectDescribe)
      }).catch((err) => {
        console.log("error ==> ", err)
      })

    },
    getCursorPosition(range, editor) {
      if (range) {
        if (range.ops && this[editor].selection) {
          if (range.ops[1]) {
            this[editor].selection.index = range.ops[0].retain
            this[editor].selection.length = range.ops[1].retain
          } else {
            this[editor].selection.index = range.ops[0].retain + 1
            this[editor].selection.length = 0
          }
        } else if (!range.ops) {
          this[editor].selection = range
        }
      }
    },
    addQuill(quill, editor) {
      if (quill) {
        this[editor].quill = quill
      }
    },
    addVariableInText(variable, editor) {
      const position = this[editor].selection.index
      const lengthSelection = this[editor].selection.length

      this[editor].quill.deleteText(position, lengthSelection)
      this[editor].quill.insertText(position, variable)
    },
  },
}
</script>

<style lang="scss">
@import "./src/assets/scss/variables";
@import "./src/assets/scss/functions";
</style>
