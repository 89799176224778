import i18n from "@/i18n"

import "@/util/ErrorHandler"
import "@/util/Filters"
import "@/util/Directives"
import GlobalMixin from "@/util/Mixin/Global"

import { LicenseManager } from "@ag-grid-enterprise/core"

import "materialize-css"
import "materialize-css/dist/css/materialize.css"
import "vuetify/dist/vuetify.min.css"

import "ant-design-vue/dist/antd.css"
import "font-awesome/scss/font-awesome.scss"
import "intro.js/introjs.css"
import "vue-swatches/dist/vue-swatches.css"

import Vue from "vue"
import VueIntro from "vue-introjs"
import Vuetify from "vuetify"
import Antd from "ant-design-vue"
import App from "./App"
import router from "./router"
import store from "./store"
import Prototype from "@/util/Prototypes"

import { library } from "@fortawesome/fontawesome-svg-core"
import { fas } from "@fortawesome/free-solid-svg-icons"
import { far } from "@fortawesome/free-regular-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome"
import "@/assets/scss/index.scss"

library.add(fas, far)

Vue.component("font-awesome-icon", FontAwesomeIcon)

LicenseManager.setLicenseKey(
  "MCMA-Solutions_MCMA_1_multi_1_Devs_1_Deployment_License_20_November_2020_[v2]_MTYwNTgzMDQwMDAwMA==8b1adec7224091313089f7d39e0b213b"
)

Vue.config.productionTip = false
Vue.use(Prototype)
Vue.mixin(GlobalMixin)
Vue.use(VueIntro, {
  waitTimeout: 400,
})
Vue.use(Vuetify)
Vue.use(Antd)

/* eslint-disable no-new */
new Vue({
  el: "#app",
  router,
  store,
  i18n,
  template: "<App/>",
  components: { App },
})
