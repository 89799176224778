import moment from "moment"

export default {
  methods: {
    validateDate(date) {
      if (/^[0-9]{2}\/[0-9]{2}\/[0-9]{4}$/.test(date)) {
        const realDate = moment(date, "DD/MM/YYYY").format("x")
        if (realDate !== "Invalid date") {
          return realDate
        }
      }
      return false
    },
  },
}
