<template>
  <a-form :form="form">
    <mcma-card title="Localisation du site" class="mcma-card-in-tab">
      <template #describe>
        <span v-if="!site.id">
          Tant que les informations d'identification du site que vous voulez créer ne sont pas complétées, vous ne
          pouvez pas accéder aux autres onglets.
        </span>
        <span v-else>Vous pouvez modifier les informations d'identification du site autant que souhaité, le temps que
          celui-ci
          n'est pas certifié.</span>
      </template>

      <a-row type="flex" :gutter="12">
        <mcma-field type="input" :form="form" :wasSubmit="wasSubmit" fieldName="nom" :defaultValue="site.nom"
          placeholder="Mon site n°5" getFlow="name::sites" getPanel="name::Localisation" getField="type::siteName"
          :required="true && siteStateDeleted" @change="updateSiteEditing(form.getFieldsValue())" />
        <mcma-field type="input" :form="form" :wasSubmit="wasSubmit" fieldName="codeSite" :defaultValue="site.codeSite"
          placeholder="123456789" :maxlength="20" getFlow="name::sites" getPanel="name::Localisation"
          getField="type::siteCode" @change="updateSiteEditing(form.getFieldsValue())" />
        <mcma-field type="input" :form="form" :wasSubmit="wasSubmit" fieldName="adresse" :defaultValue="site.adresse"
          placeholder="12 rue de la tour eiffel" getFlow="name::sites" getPanel="name::Localisation"
          getField="type::adress" @change="updateSiteEditing(form.getFieldsValue())" />
        <mcma-field type="input" :form="form" :wasSubmit="wasSubmit" fieldName="commune" :defaultValue="site.commune"
          placeholder="PARIS" :uppercase="true" getFlow="name::sites" getPanel="name::Localisation" getField="type::town"
          @change="updateSiteEditing(form.getFieldsValue())" />
        <mcma-field type="input" :form="form" :wasSubmit="wasSubmit" fieldName="codePostal"
          :defaultValue="site.codePostal" placeholder="92000" getFlow="name::sites" getPanel="name::Localisation"
          getField="type::postalCode" rule="isZipCode" :required="true && siteStateDeleted"
          @change="updateEld(form.getFieldsValue())" />
        <mcma-field type="input" :form="form" :wasSubmit="wasSubmit" fieldName="codeInsee" getFlow="name::sites"
          getPanel="name::Localisation" getField="type::codeInsee" :defaultValue="site.codeInsee" placeholder="12345"
          rule="isCodeInsee" @change="updateSiteEditing(form.getFieldsValue())" />
      </a-row>
    </mcma-card>

    <mcma-card title="Energie et Statut" class="mcma-card-in-tab">
      <a-row type="flex" :gutter="12">
        <mcma-field type="select" :form="form" :wasSubmit="wasSubmit" :defaultValue="site.fluide"
          :disabled="site.fluide !== null && site.fluide !== undefined && site.id !== null && site.id !== undefined"
          fieldName="fluide" disableValue="Sélectionnez un fluide" getFlow="name::sites" getPanel="name::Localisation"
          getField="type::fluid" :required="true && siteStateDeleted" @change="
            updateEld(form.getFieldsValue())
          handlerFuel(form.getFieldValue('fluide'))
          handlerEau(form.getFieldValue('fluide'))
          handlerAutre(form.getFieldValue('fluide'))
            " />
        <mcma-field v-if="isAutre" type="select" :form="form" :wasSubmit="wasSubmit" :defaultValue="site.energie"
          fieldName="otherFluid" disableValue="Sélectionnez une énergie" getFlow="name::sites"
          getPanel="name::Localisation" getField="type::otherFluid" />
        <mcma-field type="select" :form="form" :wasSubmit="wasSubmit" :defaultValue="site.status ? site.status : 'ACTIF'"
          label="Statut" disableValue="Sélectionnez un statut" getFlow="name::sites" getPanel="name::Localisation"
          getField="type::status" :required="requiredStatut" @change="onChangeStatus($event)" />
        <mcma-field type="date" class="mt10" :form="form" :wasSubmit="wasSubmit" :defaultValue="site.dateActivation"
          fieldName="dateActivation" label="Sélectionnez la date d'activation" placeholder="date d'activation"
          disableDateBefore="today" getFlow="name::sites" getPanel="name::Localisation" getField="type::dateActivation"
          :extraLabel="extraLabelDateAct" :required="true && siteStateDeleted"
          @change="updateSiteEditing(form.getFieldsValue())" />
        <mcma-field type="date" class="mt10" :form="form" :wasSubmit="wasSubmit" :defaultValue="site.dateResiliation"
          fieldName="dateResiliation" getFlow="name::sites" getPanel="name::Localisation" getField="type::dateFinContrat"
          :extraLabel="extraLabelDateRes" @change="updateSiteEditing(form.getFieldsValue())" />
      </a-row>
    </mcma-card>

    <mcma-card title="Référence du site" class="mcma-card-in-tab">
      <a-row type="flex" :gutter="12">
        <mcma-field type="input" :form="form" :wasSubmit="wasSubmit" disableValue="Renseignez votre fournisseur actuel"
          :defaultValue="site.fournisseur" getFlow="name::sites" getPanel="name::Localisation"
          getField="type::fournisseur" />
        <mcma-field v-if="!isFuel && !isEau && !isAutre" type="select" :form="form" :wasSubmit="wasSubmit"
          fieldName="distributeur" disableValue="Sélectionnez un distributeur" label="Distributeur"
          :defaultValue="site.distributeur" extraLabel="Ajouter préalablement le fluide et le code postal"
          :required="true && siteStateDeleted"
          :disabled="getDistribution(form.getFieldsValue()).length === 0 || (site.id && !enabledDistributeur)"
          :defaultList="getDistribution(form.getFieldsValue())" @change="updateSiteEditing(form.getFieldsValue())" />
        <mcma-field type="input" :form="form" :wasSubmit="wasSubmit" :defaultValue="site.rae" placeholder="123456789"
          :label="raeLabel(form)" :disabled="site.rae && site.id && !site.added" getFlow="name::sites"
          getPanel="name::Localisation" getField="type::rae" :extraLabel="getRaeExtraLabel(form)"
          :required="!isEau && !isAutre" :rule="getRaeRule(form)" @change="updateSiteEditing(form.getFieldsValue())" />
      </a-row>
    </mcma-card>

    <mcma-card class="mcma-card-in-tab">
      <a-row type="flex" :gutter="12">
        <mcma-field type="input" :form="form" :wasSubmit="wasSubmit" fieldName="codeGroupement"
          :defaultValue="site.codeGroupement" placeholder="123456789" :maxlength="20" getFlow="name::sites"
          getPanel="name::Localisation" getField="type::codeGroupement"
          :extraLabel="'*Champ destiné à vous faciliter la préparation de vos périmètres de facturation Code imputation budgétaire'"
          @change="updateSiteEditing(form.getFieldsValue())" />
        <mcma-field type="select" :form="form" :wasSubmit="wasSubmit" :defaultValue="site.codeImputation"
          label="Code imputation budgetaire" disableValue="Sélectionnez le code imputation budgetaire"
          getFlow="name::sites" getPanel="name::Localisation" getField="type::codeImputation"
          @change="updateSiteEditing(form.getFieldsValue())" />
      </a-row>
    </mcma-card>
  </a-form>
</template>

<script>
import { mapState } from "vuex"
import moment from "moment"
import Axios from "@/util/Axios"
import { pick } from "@/util/Helpers"
import McmaCard from "@/components/mcma/common/McmaCard"
import McmaField from "@/components/mcma/common/McmaField"
import Distribution from "@/components/mcma/common/Fields/Jsons/Distribution.json"
import { getClientTechInfos } from "@/util/Helpers/Navigator"

export default {
  name: "SiteEditLocalisation",
  data() {
    return {
      form: this.$form.createForm(this),
      wasSubmit: false,
      siteStateDeleted: null,
      enabledDistributeur: false,
      isFuel: false,
      isEau: false,
      isAutre: false,
      requiredStatut: null,
      displayedStatut: null,
    }
  },
  components: {
    McmaCard,
    McmaField,
  },
  props: {
    site: Object,
    etapesLength: Number,
  },
  computed: {
    ...mapState(["membre", "operation", "operations"]),
    extraLabelDateAct() {
      if (this.operation.groupement !== "UCANSS") {
        return (
          "Le marché débutera le " +
          moment(this.operation.dateBascule, "x").format("DD/MM/YYYY") +
          ". Si votre site est actuellement en contrat en offre de marché, veillez à bien indiquer la date d'échéance de votre contrat en cours + 1 JOUR (postérieure au " +
          moment(this.operation.dateBascule, "x").format("DD/MM/YYYY") +
          " sous peine de vous voir  infliger des pénalités par votre fournisseur actuel pour rupture  anticipée."
        )
      } else {
        return ""
      }
    },
    extraLabelDateRes() {
      if (this.operation.groupement !== "UCANSS") {
        return "cette information vous engage, en cas d'erreur vous vous exposeriez à des pénalités de rupture anticipée de contrat de la part de votre fournisseur actuel."
      } else {
        return ""
      }
    },
    getDistribution() {
      return (fieldsValues) => {
        if (
          fieldsValues.fluide &&
          fieldsValues.fluide !== "FUEL" &&
          fieldsValues.fluide !== "EAU" &&
          fieldsValues.fluide !== "AUTRE" &&
          fieldsValues.codePostal &&
          fieldsValues.codePostal.length === 5
        ) {
          const departmentCode = fieldsValues.codePostal.toString().substring(0, 2)
          if (!Distribution[fieldsValues.fluide][departmentCode]) {
            return [...Distribution[fieldsValues.fluide].default]
          }
          return [...Distribution[fieldsValues.fluide].default, ...Distribution[fieldsValues.fluide][departmentCode]]
        } else {
          return []
        }
      }
    },
    getRaeRule() {
      return (form) => {
        if (this.operation.groupement === "UCANSS") {
          return "isUCANSS"
        } else if (this.raeLabel(form) === "RAE") {
          return "isRAE"
        } else if (this.raeLabel(form) === "RAE (Distributeur)") {
          return "isELD_RAE"
        } else if (this.raeLabel(form) === "PCE") {
          return "isPCE"
        } else if (this.raeLabel(form) === "PCE (Distributeur)") {
          return "isELD_PCE"
        }
        return null
      }
    },
    getRaeExtraLabel() {
      return (form) => {
        if (this.raeLabel(form) === "RAE") {
          if (this.getDistribution(form.getFieldsValue()).length === 1) {
            return "Votre RAE composé de 14 chiffres est présent sur vos factures"
          }
          return "Si votre RAE n’est pas à 14 chiffres, merci de préciser votre distributeur avant de rentrer votre RAE qui est présent sur vos factures"
        } else if (this.raeLabel(form) === "RAE (Distributeur)") {
          return "Si votre RAE n’est pas à 14 chiffres, merci de préciser votre distributeur avant de rentrer votre RAE qui est présent sur vos factures"
        }
        return null
      }
    },
    raeLabel() {
      // On récupère le name choisi par l'admin dans la config, pour l'affecter au label (auparavant le label écrasait la valeur entrée par l'admin)
      let name = this.operation.flows
        .filter((flow) => flow.name === "sites")[0]
        .panels.filter((panel) => panel.name === "Localisation")[0]
        .fields.filter((field) => field.type === "rae")[0].name

      let names = name.split("/")
      return (form) => {
        const lastFluide = form.getFieldValue("fluide") || this.site.fluide
        const isEld =
          form.getFieldValue("distributeur") &&
          form.getFieldValue("distributeur") !== "ENEDIS" &&
          form.getFieldValue("distributeur") !== "GRDF"
        const result = lastFluide === "ELEC" ? names[0] : lastFluide === "GAZ" ? names[1] : names[0] + "/" + names[1]
        return isEld ? result + " (Distributeur)" : result
      }
    },
  },
  mounted() {
    console.log("operations ====> ", this.operations)
    this.initialize()
  },
  methods: {
    moment,
    updateEld(fieldsValue) {
      this.enabledDistributeur = true
      if (this.getDistribution(fieldsValue).length >= 1) {
        this.$nextTick(() => {
          if (!this.getDistribution(fieldsValue).includes(fieldsValue.distributeur)) {
            this.form.setFieldsValue({
              distributeur: this.getDistribution(fieldsValue)[0],
            })
          }
        })
      }
      this.updateSiteEditing(fieldsValue)
    },
    updateSiteEditing(fieldsValue) {
      this.$root.$emit("ROOT_updateSiteEditing", fieldsValue)
    },
    handlerFuel(valueFuel) {
      valueFuel === "FUEL" || this.site.fluide === "FUEL" ? (this.isFuel = true) : (this.isFuel = false)
    },
    handlerEau(valueEau) {
      valueEau === "EAU" || this.site.fluide === "EAU" ? (this.isEau = true) : (this.isEau = false)
    },
    handlerAutre(valueAutre) {
      valueAutre === "AUTRE" || this.site.fluide === "AUTRE" ? (this.isAutre = true) : (this.isAutre = false)
    },
    initialize() {
      this.form.getFieldDecorator("nom")
      this.form.getFieldDecorator("codeSite")
      this.form.getFieldDecorator("rae")
      this.form.getFieldDecorator("fluide")
      this.form.getFieldDecorator("otherFluid")
      this.form.getFieldDecorator("status")
      this.form.getFieldDecorator("refTDG")
      this.form.getFieldDecorator("refSite")
      this.form.getFieldDecorator("adresse")
      this.form.getFieldDecorator("codePostal")
      this.form.getFieldDecorator("commune")
      this.form.getFieldDecorator("codeImputation")
      this.form.getFieldDecorator("codeGroupement")
      this.form.getFieldDecorator("dateActivation")
      this.form.getFieldDecorator("dateResiliation")
      this.form.getFieldDecorator("codeInsee")
      this.form.getFieldDecorator("distributeur")
      this.form.getFieldDecorator("fournisseur")
      let editSite = pick(this.site, [
        "nom",
        "codeSite",
        "rae",
        "fluide",
        "status",
        "refTDG",
        "refSite",
        "adresse",
        "codePostal",
        "commune",
        "codeImputation",
        "codeGroupement",
        "codeInsee",
        "distributeur",
        "fournisseur",
      ])
      editSite = this.$mergeObject(this.form.getFieldsValue(), editSite)

      if (
        this.form.getFieldValue("status") === "INEXISTANT" ||
        this.form.getFieldValue("status") === "RESILIATION" ||
        this.form.getFieldValue("status") === "REJETE"
      ) {
        this.siteStateDeleted = false
        this.$emit("deletedStatus", false)
      } else {
        this.siteStateDeleted = true
        this.$emit("deletedStatus", true)
      }
      this.requiredStatut = this.operation.flows
        .filter((flow) => flow.name === "sites")[0]
        .panels.filter((panel) => panel.name === "Localisation")[0]
        .fields.filter((field) => field.type === "status")[0].required
      this.displayedStatut = this.operation.flows
        .filter((flow) => flow.name === "sites")[0]
        .panels.filter((panel) => panel.name === "Localisation")[0]
        .fields.filter((field) => field.type === "status")[0].displayed

      //Permet d'initialiser automatique le field ditributeur dans le cas où le code postal et fluide sont déjà renseigné à la première ouverture du formulaire
      this.updateEld(this.form.getFieldsValue())
      //A refacto
      this.handlerFuel(this.form.getFieldValue("fluide"))
      this.handlerEau(this.form.getFieldValue("fluide"))
      this.handlerAutre(this.form.getFieldValue("fluide"))
    },
    save(site) {
      this.$emit("save", site)
    },
    submit(interlocuteurId) {
      console.log(
        `%c Site > Etape_1 > submit`,
        "background:#1e4d77 ; padding: 5px 7px 4px 0px; border-radius: 3px;  color: #FFFFFF"
      )
      this.form.validateFields((errors, fieldsValue) => {
        this.wasSubmit = true
        let siteActif = !(
          this.form.getFieldValue("status") === "INEXISTANT" ||
          this.form.getFieldValue("status") === "RESILIATION" ||
          this.form.getFieldValue("status") === "REJETE"
        )
        if (errors) {
          this.$notification.warning({
            message: "Formulaire invalide",
            description: `Veuillez-vous assurez de remplir tous les champs requis.`,
          })
        } else if ((interlocuteurId === null || interlocuteurId === undefined) && siteActif) {
          this.$notification.error({
            message: "Aucun interlocuteur n'existe",
            description: `Vous devez préalablement créer un interlocuteur technique pour ce site.`,
          })
        } else {
          console.log("interlocuteur")
          let currentInterlocuteurId = siteActif ? interlocuteurId : this.membre.interlocuteurTechnique.id
          Axios("get", "api/interlocuteurs/" + currentInterlocuteurId)
            .then((response) => {
              this.proceed(response.data.id, fieldsValue)
            })
            .catch((error) => {
              console.error("error", error)
            })
            .catch((error) => {
              console.error("error", error)
            })
        }
      })
    },
    onChangeStatus(statusValue) {
      if (statusValue === "INEXISTANT" || statusValue === "RESILIATION" || statusValue === "REJETE") {
        this.siteStateDeleted = false
        this.$emit("deletedStatus", false)
      } else {
        this.siteStateDeleted = true
        this.$emit("deletedStatus", true)
      }
    },
    proceed(interlocuteurId, fieldsValue) {
      if ((fieldsValue.fluide === "EAU" || fieldsValue.fluide === "AUTRE") && fieldsValue.rae === undefined) {
        console.log("un numéro de pdl va être automatiquement généré")
      }

      //Génération auto et random d'un RAE pour les site Eau dont les users n'ont pas forcément l'info du n°PDL.
      //Le rae passe en non required si fluide === eau, ainsi l'user peut valider sans rentrer de RAE et on en génère un. (la conf de l'opé doit mettre le RAE en non required sinon pas possible.)
      const prefix = fieldsValue.fluide === "EAU" ? "WTR" : fieldsValue.fluide === "AUTRE" ? "OTH" : "ERROR"
      const randomRae = prefix + Math.random().toString().slice(2, 13)
      const valueRae =
        (fieldsValue.fluide === "EAU" || fieldsValue.fluide === "AUTRE") &&
          (fieldsValue.rae === null || fieldsValue.rae === undefined)
          ? randomRae
          : fieldsValue.rae
      const siteDisabled = ["RESILIATION", "REJETE", "INEXISTANT"]
      const site = {
        ongletIdentification: true,
        membre: process.env.VUE_APP_API_URL + "/api/membres/" + this.membre.id,
        fluide: fieldsValue.fluide,
        nom: fieldsValue.nom == null ? "PAS DE NOM" : fieldsValue.nom,
        codeSite: fieldsValue.codeSite,
        rae: valueRae,
        adresse: fieldsValue.adresse,
        codePostal: fieldsValue.codePostal,
        commune: fieldsValue.commune,
        codeImputation: fieldsValue.codeImputation,
        codeGroupement: fieldsValue.codeGroupement,
        distributeur: fieldsValue.distributeur,
        fournisseur: fieldsValue.fournisseur,
        status: fieldsValue.status ? fieldsValue.status : "ACTIF",
        removed: siteDisabled.includes(fieldsValue.status) ? 1 : 0,
        dateActivation: +moment(fieldsValue.dateActivation),
        dateResiliation: +moment(fieldsValue.dateResiliation),
        added: this.site.added,
        codeInsee: fieldsValue.codeInsee,
        operationId: this.operation.id,
        interlocuteur: process.env.VUE_APP_API_URL + "/api/interlocuteurs/" + interlocuteurId,
      }
      //Fluide AUTRE est particulier: ce cas répresente des sous fluide sans données tech et donc pas de 2nd étape. Pour que le schéma
      // reste cohérent on a créé un entité fille site_autre contenant uniquement l'énergie précise pour ces sous-fluide.
      if (this.etapesLength === 1 && this.site.id) {
        Object.assign(site, { ongletParametre: true })
      }
      if (fieldsValue.fluide === "AUTRE") {
        Object.assign(site, { ongletParametre: true })
        Object.assign(site, { energie: fieldsValue.otherFluid })
      }
      if (this.site.id) {
        Axios("patch", "api/sites/" + this.site.id, site)
          .then((response) => {
            this.$notification.success({
              message: "Sauvegarde terminée",
              description: "Les informations d'identification ont bien été sauvegardées.",
            })
            this.$emit("update", response.data)
          })
          .catch((error) => {
            console.error(error)
            this.$notification.error({
              message: "Erreur serveur",
              description: "Une erreur server est survenue, veuillez contacter un administrateur.",
            })
          })
      } else {
        let bean = ""
        if (fieldsValue.fluide === "GAZ") {
          bean = "siteGazes"
        } else if (fieldsValue.fluide === "ELEC") {
          bean = "siteElecs"
        } else if (fieldsValue.fluide === "FUEL") {
          bean = "siteFuels"
        } else if (fieldsValue.fluide === "EAU") {
          bean = "siteEaus"
        } else if (fieldsValue.fluide === "AUTRE") {
          bean = "siteAutres"
        }
        site.multiSite = true
        Axios("post", "api/" + bean, site)
          .then((response) => {
            this.$notification.success({
              message: "Sauvegarde terminée",
              description: "Les informations d'identification ont bien été sauvegardées.",
            })
            this.$emit("update", response.data)
          })
          .catch((error) => {
            console.error(error)
            if (error.response.status === 409) {
              Axios("get", "api/sites/search/findByRaeEquals?rae=" + site.rae)
                .then((response) => {
                  if (response.data) {
                    let s = response.data
                    //si ope désactivée
                    Axios("get", `api/operations/search/findByIdEquals?id=${s.operationId}`).then((response) => {
                      var myOpe = response.data
                      if (myOpe.active === false) {
                        let oldSite = s
                        site.removed = 0
                        site.certified = 0
                        site.groupement = null
                        Axios("patch", "api/sites/" + oldSite.id, site).then((response) => {
                          this.$notification.success({
                            message: "Succès",
                            description: "Le site possédant ce rae s'est vu mettre à jour et transféré vers ce membre",
                          })
                          this.$emit("update", response.data)
                        })
                      } else if (myOpe.active === true && (s.status === "REJETE" || s.status === "RESILIATION")) {
                        let oldSite = s
                        //Récupération du mail de l'admin de l'opé
                        Axios(
                          "get",
                          "api/users/search/findByUserAdminAndOperationEquals?operationId=" + this.operation.id
                        )
                          .then((response) => {
                            //Envoie du mail
                            var users = response.data._embedded.users
                            for (let i = 0; i < users.length; i++) {
                              Axios(
                                "post",
                                "/mail/transfert?idOperation=" +
                                this.operation.id +
                                "&idOldOperation=" +
                                myOpe.id +
                                "&idMembre=" +
                                this.membre.id +
                                "&membreNom=" +
                                oldSite.membreNom +
                                "&raeSite=" +
                                site.rae,
                                {
                                  membreId: this.membre ? this.membre.id : null,
                                  mail: users[i].email,
                                  subject: "Transfert de RAE",
                                  message:
                                    "Transfert de RAE du membre " +
                                    oldSite.membreNom +
                                    " de l'opération " +
                                    myOpe.id +
                                    " vers le membre " +
                                    site.membre +
                                    " de l'opération " +
                                    this.operation.id,
                                  clientTechInfos: getClientTechInfos(),
                                }
                              )
                              //On affecte les nouvelles valeurs à notre site déjà en base, puis on le patch (après avoir envoyé le mail, de sorte
                              // à pouvoir envoyer les anciennes données à l'admin (trçage))
                            }
                            site.removed = 0
                            site.certified = 0
                            site.groupement = null
                            Axios("patch", "api/sites/" + oldSite.id, site).then((response) => {
                              this.$notification.success({
                                message: "Succès",
                                description:
                                  "Le site possédant ce rae s'est vu mettre à jour et transféré vers ce membre, un mail de suivi a été envoyé à votre administrateur. ",
                              })
                              this.$emit("update", response.data)
                            })
                          })
                          .catch((error) => {
                            console.log(error)
                          })
                      } else {
                        this.$notification.error({
                          message: "Erreur serveur",
                          description:
                            "Ce site est déjà affecté à une autre entité - Veuillez vous adresser à vos interlocuteurs pour le transférer dans votre périmètre.",
                        })
                      }
                    })
                  }
                })
                .catch((error) => {
                  console.log(error)
                })
            } else {
              this.$notification.error({
                message: "Erreur serveur",
                description: "Une erreur server est survenue, veuillez contacter un administrateur.",
              })
            }
          })
      }
    },
  },
}
</script>

<style lang="scss"></style>
