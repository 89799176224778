<template>
  <main class="container">
    <section class="row" v-if="membreStats.statInterlocuteur.state !== 'CERTIFIED'">
      <article class="col s12">
        <form class="card" @submit.prevent="submit" id="validInterlocutorForm">
          <span class="card-title"> Ajout d'un interlocuteur </span>
          <div class="card-content">
            <div class="row">
              <div class="input-field col s6">
                <input id="nom" type="text" v-model="nom" required />
                <label class="active" for="nom">
                  <font-awesome-icon icon="asterisk" class="red-text" />
                  Nom
                </label>
              </div>
              <div class="input-field col s6">
                <input id="prenom" type="text" v-model="prenom" required />
                <label class="active" for="prenom">
                  <font-awesome-icon icon="asterisk" class="red-text" />
                  Prénom
                </label>
              </div>
            </div>
            <div class="row">
              <telephone class="col s6" v-model="telephone" />

              <email class="col s6" v-model="mail" />
            </div>
            <div class="row">
              <!-- <div class="col s6">
                <label for="fonction">
                  <font-awesome-icon icon="asterisk" class="red-text"/>
                  Fonction
                </label>
                <select id="fonction" class="browser-default" v-model="fonction" required>
                  <option :value="null" disabled selected>Sélectionnez une fonction</option>
                  <option v-for="fct in fonctions" :key="fct" :value="fct">{{ fct }}</option>
                </select>
              </div> -->
              <generic-field
                v-model="fonction"
                defaultLabel="Fonction"
                defaultDisabledValue="Sélectionnez une fonction"
                getFlow="name::services"
                getPanel="name::Interlocuteurs"
                getField="type::fonction"
                class="col s6"
                id="validFonction"
              />

              <!-- <div class="col s6" id="validInterlocutorRole">
                <label>
                  <font-awesome-icon icon="asterisk" class="red-text"/>
                  Rôle
                </label>
                <select class="browser-default" v-model="role" required>
                  <option value="" disabled selected>Sélectionnez un rôle</option>
                  <option value="FACTURATION">Gestionnaire</option>
                  <option value="MEMBRE">Membre</option>
                </select>
              </div> -->
              <generic-field
                v-model="role"
                defaultLabel="Rôle"
                defaultDisabledValue="Sélectionnez un rôle"
                getFlow="name::services"
                getPanel="name::Interlocuteurs"
                getField="type::role"
                :upperCaseOnSet="true"
                class="col s6"
                id="validInterlocutorRole"
              />
            </div>
            <div class="row">
              <div class="input-field col s6">
                <input id="refInterlocuteur" type="text" v-model="refInterlocuteur" disabled />
                <label class="active" for="refInterlocuteur"> Référence </label>
              </div>
            </div>
          </div>
          <center class="card-action">
            <button
              type="submit"
              class="waves-effect waves-light btn light-green tutoDoneRetourToRouteInterlocuteurs"
              id="validInterlocutorValid"
            >
              <i class="material-icons">save</i>
              Valider
            </button>
            <button type="button" class="waves-effect waves-light btn blue darken-2" @click="initialize">
              <i class="material-icons">remove</i>
              Réinitialiser
            </button>
          </center>
        </form>
      </article>
    </section>
    <section v-else class="card-panel white-text deep-orange">
      Cet interlocuteur ne peux pas être modifié car il a déjà été envoyé en validation.
    </section>
  </main>
</template>

<script>
import Mixin from "../Mixin"
import Axios from "@/util/Axios"
import { mapState, mapMutations } from "vuex"
import Fonctions from "./fonctions.json"
import Materialize from "materialize-css"
import { Email, Telephone } from "@/components/common/Fields"
import GenericField from "@/components/common/Fields/GenericField"
// import _ from "lodash";

export default {
  name: "interlocuteur",
  mixins: [Mixin],
  components: {
    Email,
    Telephone,
    GenericField,
  },
  data() {
    return {
      flow: "SERVICES",
      interlocuteur: null,

      nom: null,
      prenom: null,
      telephone: null,
      fonction: null,
      mail: null,
      role: null,
      refInterlocuteur: null,
    }
  },
  computed: {
    ...mapState(["user", "membreStats", "operation"]),
    fonctions() {
      return Fonctions
    },
  },
  methods: {
    ...mapMutations(["setBreadcrumbAdd"]),
    initialize() {
      if (this.$route.params.id === "new") {
        this.setBreadcrumbAdd({
          name: "Ajout Interlocuteur",
          action: () => {},
        })
        this.nom = null
        this.prenom = null
        this.telephone = null
        this.fonction = null
        this.mail = null
        this.role = null
        this.refInterlocuteur = null
      } else {
        Axios("get", "api/interlocuteurs/" + this.$route.params.id).then((response) => {
          this.interlocuteur = response.data
          this.setBreadcrumbAdd({
            name: "Edition de : " + this.interlocuteur.nom,
            action: () => {},
          })
          this.nom = this.interlocuteur.nom
          this.prenom = this.interlocuteur.prenom
          this.telephone = this.interlocuteur.telephone
          this.fonction = this.interlocuteur.fonction
          this.mail = this.interlocuteur.mail
          this.role = this.interlocuteur.role
          this.refInterlocuteur = this.interlocuteur.refInterlocuteur
        })
      }
    },
    submit() {
      const interlocuteur = {
        membre: process.env.VUE_APP_API_URL + "/api/membres/" + this.membreStats.id,
        nom: this.nom,
        prenom: this.prenom,
        telephone: this.telephone,
        fonction: this.fonction,
        mail: this.mail,
        role: this.role,
        refInterlocuteur: this.refInterlocuteur,
        operationId: this.operation.id,
      }
      console.log(">> MODIF/ADD Interlocuteur : %O", interlocuteur)
      if (this.interlocuteur && this.interlocuteur.id) {
        Axios("patch", "api/interlocuteurs/" + this.interlocuteur.id, interlocuteur)
          .then((response) => {
            this.$router.push({
              name: "interlocuteurs",
            })
            console.log()
            /* Materialize.toast(
              "L'interlocuteur a bien été sauvegardé",
              4000,
              "lime"
            ); */
          })
          .catch((error) => {
            console.error(error)
            Materialize.toast("Erreur serveur", 4000, "deep-orange")
          })
      } else {
        Axios("post", "api/interlocuteurs/", interlocuteur)
          .then((response) => {
            /* Materialize.toast(
              "Le nouvel interlocuteur a bien été ajouté",
              4000,
              "lime"
            ); */
            this.$router.push({
              name: "interlocuteurs",
            })
          })
          .catch((error) => {
            console.error(error)
            Materialize.toast("Erreur serveur", 4000, "deep-orange")
          })
      }
    },
  },
  created() {
    this.initialize()
  },
}
</script>

<style lang="scss" scoped></style>
