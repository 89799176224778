import Vue from "vue"

import { VTooltip } from "v-tooltip"

Vue.directive("tooltip", VTooltip)

Vue.directive("numberOnly", {
  bind(el, binding, vNode) {
    if (!binding.hasOwnProperty("value") || binding.value === true) {
      el.addEventListener("keyup", () => {
        if (el.value && typeof el.value === "string") {
          el.value = el.value.replace(/\D/g, "")
          el.value = el.value.replace(/\s\s+/g, " ")
          return el.value
        }
        return el.value
      })
    } else if (binding.value === "decimal") {
      el.addEventListener("keyup", () => {
        let regex = /^-?(0|[1-9][0-9]*)([,.][0-9]*)?$/
        if (!regex.test(el.value)) {
          let part = ""
          for (let i = 0; i < el.value.length; i++) {
            part += el.value.charAt(i)
            part = regex.test(part) ? part : part.substring(0, part.length - 1)
          }
          el.value = part
        }
      })
    }
  },
  unbind(el, binding) {
    if (!binding.hasOwnProperty("value") || binding.value === true) {
      el.removeEventListener("keyup", () => {
        if (el.value && typeof el.value === "string") {
          el.value = el.value.replace(/\D/g, "")
          el.value = el.value.replace(/\s\s+/g, " ")
          return el.value
        }
        return el.value
      })
    } else if (binding.value === "decimal") {
      el.removeEventListener("keyup", () => {
        let regex = /^-?(0|[1-9][0-9]*)([,.][0-9]*)?$/
        if (!regex.test(el.value)) {
          let part = ""
          for (let i = 0; i < el.value.length; i++) {
            part += el.value.charAt(i)
            part = regex.test(part) ? part : part.substring(0, part.length - 1)
          }
          el.value = part
        }
      })
    }
  },
})
