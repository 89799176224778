<template v-if="dynField">
  <component :is="component" v-if="component && (forceToShow || dynField.displayed)" />
  <section v-else-if="dynField && (forceToShow || dynField.displayed) && dynField.lockData && !dynField.radio">
    <label class="active">
      <font-awesome-icon icon="asterisk" class="red-text" v-if="dynField.required || forceRequired" />
      {{ getLabel }}
    </label>
    <!-- <span>{{getCamel(field.value)}}</span> -->
    <!-- <span v-for="value in field.lockedValues" :key="getCamel(value) + '_A' + _uid" >{{value}}</span> -->
    <select
      class="browser-default"
      v-model="selected"
      @change="updateValue($event.target.value)"
      :disabled="disabled"
      :required="dynField.required || forceRequired"
    >
      <option :value="null" disabled :selected="!selected">
        {{ defaultDisabledValue }}
      </option>
      <!-- <option v-for="value in field.lockedValues" :value="getCamel(value)" :key="getCamel(value) + '_' + _uid"> -->
      <option
        v-for="value in dynField.lockedValues"
        :value="getTabValue(value)"
        :key="getCamel(value) + '_' + _uid"
        :disabled="disabledOptions"
        :selected="selected === getTabValue(value)"
      >
        {{ formatValue(value) }}
      </option>
    </select>
  </section>
  <center v-else-if="dynField && (forceToShow || dynField.displayed) && isToogle" class="switch col s4">
    <label>
      <font-awesome-icon icon="asterisk" class="red-text" v-if="dynField.required || forceRequired" />
      {{ getLabel }} : Non
      <input type="checkbox" v-model="selected" />
      <span class="lever"></span>
      Oui
    </label>
  </center>
  <article v-else-if="dynField && (forceToShow || dynField.displayed) && dynField.lockData && dynField.radio">
    {{ getLabel }} :
    <span v-for="(value, index) in dynField.lockedValues" :key="index">
      <input
        v-model="selected"
        @change="updateValue($event.target.value)"
        :name="'inputGroup_' + _uid"
        type="radio"
        :value="value.replace('%', '')"
        :id="'inputGroup_' + _uid + '_' + index"
        :checked="'inputGroup_' + _uid + '_' + index == selected"
      />
      <label :for="'inputGroup_' + _uid + '_' + index">{{ formatValue(value) }}</label>
    </span>
  </article>
  <article v-else-if="dynField && (forceToShow || dynField.displayed)" class="input-field">
    <i class="material-icons prefix" v-if="prefix">{{ prefix }}</i>
    <input type="text" class="validate" :required="dynField.required || forceRequired" v-model="dynField.value" />
    <label class="active">
      <font-awesome-icon icon="asterisk" class="red-text" v-if="dynField.required || forceRequired" />
      {{ getLabel }}
    </label>
  </article>
</template>

<script>
import { mapState } from "vuex"
import Fields from "./"
import camelCase from "lodash/camelCase"
import GenericFieldTable from "./GenericFieldTable.json"
import { getDynamiqueFields } from "@/util/Helpers/DynamiqueFields"

export default {
  name: "generic-field",
  props: {
    value: [String, Object, Boolean, Number, Array, Date],
    component: Object,
    prefix: Object,
    disabled: Boolean,
    getFlow: String, // example: property::value
    getPanel: String, // example: property::value
    getField: String, // example: property::value
    defaultDisabledValue: {
      type: String,
      default: "Sélectionnez",
    }, // add default disable value like "selected field"
    defaultLabel: String, // add default label to bypass fieldName
    defaultList: Array, // add json array to bypass the list
    capitalize: Boolean, // capitalize options
    disabledOptions: Boolean,
    reverseToogleValue: Boolean, // reverve boolean value for toogle
    upperCaseOnSet: Boolean,
    forceRequired: Boolean,
    forceToShow: Boolean,
  },
  components: {
    ...Fields,
  },
  /*
  data () {
    return {
      myvalue: 'B',
      selected: 'B',
      options: [
        { text: 'Un', value: 'A' },
        { text: 'Deux', value: 'B' },
        { text: 'Trois', value: 'C' }
      ]
    }
  }, */
  data() {
    return {
      dynField: {},
    }
  },
  computed: {
    ...mapState(["operation"]),
    getCamel() {
      return (value) => {
        return camelCase(value)
      }
    },
    getCapitalize() {
      return (value) => {
        return this.$options.filters.capitalize(value)
      }
    },
    getTabValue() {
      return (value) => {
        return GenericFieldTable[this.dynField.type] && GenericFieldTable[this.dynField.type][this.getCamel(value)]
          ? GenericFieldTable[this.dynField.type][this.getCamel(value)]
          : this.screamingSnakeCase(value)
      }
    },
    screamingSnakeCase() {
      return (value) => {
        return this.$options.filters.screamingSnakeCase(value)
      }
    },
    isToogle() {
      return (
        this.dynField &&
        this.dynField.radio &&
        this.dynField.lockedValues[0].toUpperCase() === "OUI" &&
        this.dynField.lockedValues[1].toUpperCase() === "NON"
      )
    },
    getLabel() {
      return this.defaultLabel || this.dynField.name
    },
    formatValue() {
      return (value) => {
        return this.capitalize ? this.getCapitalize(value) : value
      }
    },
    selected: {
      get() {
        console.log(
          `%c GET generic-field value :`,
          "background:#F8FAFD ; padding: 5px 7px 4px 0px; border-radius: 3px;  color: #2CAB87",
          this.value
        )
        return this.reverseToogleValueIfNeeded(this.value)
      },
      set(value) {
        if (typeof value === "boolean" && this.reverseToogleValue) {
          value = !value
        }
        if (typeof value === "string" && this.upperCaseOnSet) {
          value = this.screamingSnakeCase(value)
        }
        console.log(
          `%c SET generic-field value :`,
          "background:#F8FAFD ; padding: 5px 7px 4px 0px; border-radius: 3px;  color: #0077B7",
          value
        )
        this.$emit("input", value)
      },
    },
  },
  mounted() {
    this.dynField = getDynamiqueFields(
      this.operation,
      this.getFlow,
      this.getPanel,
      this.getField,
      this.value,
      this.defaultList
    )
  },
  methods: {
    updateValue(value) {
      this.$emit("change", value)
    },
    reverseToogleValueIfNeeded(value) {
      if (this.isToogle && value && typeof value === "boolean" && this.reverseToogleValue) {
        value = !value
      }
      return value
    },
  },
}
</script>

<style lang="scss" scoped></style>
