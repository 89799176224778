<template>
  <section class="input-field">
    <input id="rae" type="text" v-model="field" :required="required" :disabled="disabled" ref="field" />
    <label :class="{ active: field }" for="rae">
      <font-awesome-icon icon="asterisk" class="red-text" v-if="required" />
      {{ name }}
    </label>
  </section>
</template>

<script>
import Mixin from "./Mixin"

export default {
  name: "rae",
  mixins: [Mixin],
  computed: {
    required() {
      return this.context.status !== "A_ACTIVER"
    },
    disabled() {
      return this.context.id && !this.context.added
    },
    name() {
      switch (this.context.fluide) {
        case "ELEC":
          return "RAE"
        case "GAZ":
          return "PCE"
        default:
          return "RAE / PCE"
      }
    },
  },
  methods: {
    guardian() {
      // if (this.field && this.field.length && !/^[0-9]{14}$/.test(this.field)) {
      // this.$refs.field.setCustomValidity('Mauvais format de RAE (14 chiffres). Veuillez contacter le support si votre RAE ne respecte pas ce format.')
      // } else {
      this.$refs.field.setCustomValidity("")
      // }
    },
  },
  watch: {
    field() {
      this.guardian()
    },
  },
  mounted() {
    this.guardian()
  },
}
</script>

<style lang="scss" scoped></style>
