<template>
  <mcma-card :title="getTitle" icon="edit_site" class="mcma-card-groupement-detail-header" :inline="true">
    <template #describe>
      <span v-if="$route.params.action === 'duplique'">
        Dupliquer votre périmètre de facturation avec, comme base, le périmètre de facturation nommée
        {{ groupement.nom }}
      </span>
      <span v-else-if="groupement.id"> Editer le périmètre de facturation portant le nom {{ groupement.nom }} </span>
      <span v-else> Créer votre nouveau périmètre de facturation </span>
    </template>
    <template #headerRightSide>
      <mcma-button
        v-if="groupement.state === Constants.STATUS.TO_CERTIFY.value && membre.siteNotGrouped === 0"
        name="Certifier le périmètre de facturation"
        color="success"
        @click="showCertifyModal = true"
      />
    </template>
    <slot></slot>

    <mcma-certify
      :visible="showCertifyModal"
      :canCertify="groupement.state === Constants.STATUS.TO_CERTIFY.value"
      noCertifyMessage="Ce périmètre de facturation ne peut pas être certifié."
      :title="`Validation du périmètre de facturation : ${groupement.nom}`"
      who="ce périmètre de facturation"
      @onCancel="closeCertifyModal"
      @onCertify="certify"
    />
  </mcma-card>
</template>

<script>
import McmaCard from "@/components/mcma/common/McmaCard"
import McmaButton from "@/components/mcma/common/McmaButton"
import McmaCertify from "@/components/mcma/common/McmaCertify"
import { mapMutations, mapState } from "vuex"
import Axios from "@/util/Axios"
import Constants from "@/util/Constants"

export default {
  name: "GroupementsDetailCardHeader",
  props: {
    groupement: Object,
  },
  components: {
    McmaCard,
    McmaButton,
    McmaCertify,
  },
  data() {
    return {
      showCertifyModal: false,
    }
  },
  computed: {
    ...mapState(["membre", "operation"]),
    Constants() {
      return Constants
    },
    getTitle() {
      if (this.$route.params.action === "duplique") {
        return `Duplication du périmètre de facturation ${this.groupement.nom}`
      } else if (this.groupement.id) {
        return `Édition du périmètre de facturation ${this.groupement.nom}`
      }
      return "Nouveau périmètre de facturation"
    },
  },
  methods: {
    ...mapMutations(["setBreadcrumbAdd", "setState"]),
    goTo(route) {
      if (typeof route === "string") {
        this.$router.push({ name: route })
      } else if (typeof route === "object") {
        this.$router.push(route)
      }
    },
    closeCertifyModal() {
      this.showCertifyModal = false
    },
    certify() {
      this.closeCertifyModal()
      Axios("patch", `api/groupements/${this.$route.params.groupementId}`, {
        state: "CERTIFIED",
      })
        .then(() => {
          this.$notification.success({
            message: `Périmètre de facturation validé !`,
            description: `Le périmètre de facturation à bien été validé !`,
          })
          this.$router.push({
            name: "groupements",
          })
        })
        .catch((error) => {
          console.error("error", error)
          this.$notification.error({
            message: `Erreur de validation`,
            description: `Une erreur c'est produite lors de la validation du périmètre de facturation, veuillez contacter un administrateur.`,
          })
        })
    },
    setBreadCrumb(name, groupementId) {
      this.setBreadcrumbAdd({
        name: this.groupement.nom ? `${name} de ${this.groupement.nom}` : name,
        action: () => {
          this.$router.push({
            name: "groupements-detail",
            params: {
              operationId: this.operation.id,
              membreId: this.membre.id,
              groupementId: groupementId,
              action:
                groupementId && name === `Edition`
                  ? "edit"
                  : groupementId && name === `Duplication`
                  ? "duplique"
                  : "new",
            },
          })
        },
      })
    },
  },
  created() {
    let name = "Nouveau"
    if (this.$route.params.action === "duplique") {
      name = `Duplication`
    } else if (this.groupement.id) {
      name = `Edition`
    }
    this.setState({
      stateName: "groupement",
      value: this.groupement || {},
    })
    this.setBreadCrumb(name, this.groupement.id)
  },
}
</script>

<style lang="scss"></style>
