<template>
  <div class="mcma-icon" :class="{ disabled: disabled }">
    <div v-if="leftImage" class="content">
      <div :class="'icon-background ' + getBackground + ' ' + iconOnlyClass">
        <img :src="getLeftImage" class="leftImage" :alt="leftImage" />
      </div>
      <span class="name" :class="getTextColor + ' ' + textTransform">{{ name }}</span>
    </div>
    <div v-else-if="faIcon" class="content">
      <div :class="'icon-background ' + getBackground + ' ' + iconOnlyClass">
        <font-awesome-icon :icon="[regular ? 'far' : 'fas', type]" :class="getColor" />
      </div>
      <span class="name" :class="getTextColor + ' ' + textTransform">{{ name }}</span>
    </div>
    <div v-else class="content">
      <div :class="'icon-background ' + getBackground + ' ' + iconOnlyClass">
        <a-icon :type="type" :class="getColor" />
      </div>
      <span class="name" :class="getTextColor + ' ' + textTransform">{{ name }}</span>
    </div>
  </div>
</template>

<script>
export default {
  name: "McmaIcon",
  props: {
    name: String,
    type: {
      type: String,
      default: "user",
    },
    regular: Boolean,
    color: String,
    coloriseText: Boolean,
    full: Boolean,
    faIcon: Boolean,
    disabled: Boolean,
    leftImage: String,
    textTransform: String,
  },
  computed: {
    getLeftImage() {
      return this.leftImage ? require(`@/assets/images/${this.leftImage}.svg`) : null
    },
    getTextColor() {
      return this.coloriseText && this.color ? this.getColor : ""
    },
    iconOnlyClass() {
      return !this.name ? "icon-only" : ""
    },
    getColor() {
      if (this.full) {
        return this.color ? "white-color" : ""
      }
      if (this.disabled) {
        return "disable-icon-color"
      }
      return this.color ? this.color + "-color" : ""
    },
    getBackground() {
      if (this.full && !this.disabled) {
        return this.color ? this.color + "-bg with-background" : ""
      }
      return ""
    },
  },
}
</script>

<style lang="scss">
@import "./src/assets/scss/variables";
@import "./src/assets/scss/functions";

.mcma-icon {
  display: flex;
  &.disabled {
    color: $disabled-color !important;
  }
  > .content {
    margin: auto;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    height: 100%;
    width: 100%;
    .icon-background {
      display: flex;
      height: auto;
      width: 21px;
      border-radius: 4px;
      margin-right: 10px;
      &.icon-only {
        width: 18px;
        height: 18px;
        margin-right: 0;
        svg {
          /*bottom: 1px;*/
          /*position: relative;*/
        }
      }
      i {
        margin: auto;
        font-size: 16px;
      }
      svg {
        margin: auto;
      }
      .leftImage {
        margin: auto;
      }
      &.with-background {
        i {
          position: relative;
          top: 1px;
          font-size: 12px;
        }
        svg {
        }
        .leftImage {
          width: 13px;
        }
      }
    }
  }
}
</style>
