<template>
  <mcma-card title="Contactez-nous" icon="add_user">
    <template #describe>
      Pour plus d'informations, des précisions sur le fonctionnement du site, d'accompagnement dans votre saisie, ou le
      report d'une anomalie, n'hésitez pas à nous contacter.
    </template>
    <slot></slot>
  </mcma-card>
</template>

<script>
import McmaCard from "@/components/mcma/common/McmaCard"

export default {
  name: "ContactUsHeader",
  components: {
    McmaCard,
  },
}
</script>

<style lang="scss"></style>
