<template>
  <div>
    <div v-if="!text">
      {{ getText }}
    </div>
    <div v-if="(typeof text === 'string' || typeof text === 'number') && tag">
      <span v-if="searchText[fieldName]" class="customTag">
        <div class="customTag-items">
          <div
            v-for="(fragment, i) in text
              .toString()
              .split(new RegExp(`(?<=${searchText[fieldName]})|(?=${searchText[fieldName]})`, 'i'))"
            :key="'-' + i"
            :class="{
              'highlight-part': fragment.toLowerCase() === searchText[fieldName].toLowerCase(),
              split:
                text.toString().split(new RegExp(`(?<=${searchText[fieldName]})|(?=${searchText[fieldName]})`, 'i'))
                  .length -
                  1 >
                0,
              alone:
                i === 0 &&
                text.toString().split(new RegExp(`(?<=${searchText[fieldName]})|(?=${searchText[fieldName]})`, 'i'))
                  .length -
                  1 ===
                  i,
              first:
                i === 0 &&
                !(
                  text.toString().split(new RegExp(`(?<=${searchText[fieldName]})|(?=${searchText[fieldName]})`, 'i'))
                    .length -
                    1 ===
                  i
                ),
              middle:
                i > 0 &&
                i <
                  text.toString().split(new RegExp(`(?<=${searchText[fieldName]})|(?=${searchText[fieldName]})`, 'i'))
                    .length -
                    1 &&
                text.toString().split(new RegExp(`(?<=${searchText[fieldName]})|(?=${searchText[fieldName]})`, 'i'))
                  .length -
                  1 !==
                  i,
              last:
                i > 0 &&
                text.toString().split(new RegExp(`(?<=${searchText[fieldName]})|(?=${searchText[fieldName]})`, 'i'))
                  .length -
                  1 ===
                  i,
            }"
            class="part-of-split-tag"
          >
            <a-tag v-if="fragment.toLowerCase() === searchText[fieldName].toLowerCase()" class="highlight">
              {{ fragment }}
            </a-tag>
            <template v-else>
              <a-tag :color="tagColor">
                {{ fragment }}
              </a-tag>
            </template>
          </div>
        </div>
      </span>
      <template v-else>
        <div class="tag-list">
          <a-tag :color="tagColor">
            {{ getText }}
          </a-tag>
        </div>
      </template>
    </div>
    <div v-else-if="typeof text === 'string' || typeof text === 'number'">
      <span v-if="searchText[fieldName]" class="mcma-search-table-filter">
        <template
          v-for="(fragment, i) in text
            .toString()
            .split(new RegExp(`(?<=${searchText[fieldName]})|(?=${searchText[fieldName]})`, 'i'))"
        >
          <div v-if="fragment.toLowerCase() === searchText[fieldName].toLowerCase()" :key="i" class="highlight">
            {{ fragment }}
          </div>
          <template v-else>{{ fragment }}</template>
        </template>
      </span>
      <template v-else>
        {{ getText }}
      </template>
    </div>
    <div v-else-if="typeof text === 'object'">
      <span v-if="searchText[fieldName]" class="customTag">
        <div v-for="(tag, index) in text" :key="index" class="customTag-items">
          <div
            v-for="(fragment, i) in tag
              .toString()
              .split(new RegExp(`(?<=${searchText[fieldName]})|(?=${searchText[fieldName]})`, 'i'))"
            :key="index + '-' + i"
            :class="{
              'highlight-part': fragment.toLowerCase() === searchText[fieldName].toLowerCase(),
              split:
                tag.toString().split(new RegExp(`(?<=${searchText[fieldName]})|(?=${searchText[fieldName]})`, 'i'))
                  .length -
                  1 >
                0,
              alone:
                i === 0 &&
                tag.toString().split(new RegExp(`(?<=${searchText[fieldName]})|(?=${searchText[fieldName]})`, 'i'))
                  .length -
                  1 ===
                  i,
              first:
                i === 0 &&
                !(
                  tag.toString().split(new RegExp(`(?<=${searchText[fieldName]})|(?=${searchText[fieldName]})`, 'i'))
                    .length -
                    1 ===
                  i
                ),
              middle:
                i > 0 &&
                i <
                  tag.toString().split(new RegExp(`(?<=${searchText[fieldName]})|(?=${searchText[fieldName]})`, 'i'))
                    .length -
                    1 &&
                tag.toString().split(new RegExp(`(?<=${searchText[fieldName]})|(?=${searchText[fieldName]})`, 'i'))
                  .length -
                  1 !==
                  i,
              last:
                i > 0 &&
                tag.toString().split(new RegExp(`(?<=${searchText[fieldName]})|(?=${searchText[fieldName]})`, 'i'))
                  .length -
                  1 ===
                  i,
            }"
            class="part-of-split-tag"
          >
            <a-tag v-if="fragment.toLowerCase() === searchText[fieldName].toLowerCase()" class="highlight">
              {{ fragment }}
            </a-tag>
            <template v-else>
              <a-tag :color="tagColor">
                {{ fragment }}
              </a-tag>
            </template>
          </div>
        </div>
      </span>
      <template v-else>
        <div class="tag-list">
          <a-tag v-for="(tag, index) in text" :key="index" :color="tagColor">
            {{ tag }}
          </a-tag>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import moment from "moment"

// const DATE_FORMAT = 'DD/MM/YYYY';
const DATE_FORMAT = "DD-MM-YYYY HH:mm:ss"

export default {
  name: "McmaSearchTableFilter",
  props: {
    searchText: Object,
    fieldName: String,
    text: [String, Array, Number, Object],
    tag: Boolean,
    tagColor: {
      type: String,
      default: "blue",
    },
    isDate: Boolean,
  },
  computed: {
    getText() {
      return this.isDate ? this.toDate(this.text) : this.text
    },
    toDate() {
      return (timestamp) => {
        return timestamp ? moment(timestamp, "x").format(DATE_FORMAT) : "Inconnue"
      }
    },
  },
}
</script>

<style lang="scss">
@import "./src/assets/scss/variables";
@import "./src/assets/scss/functions";

.mcma-table {
  > .header {
    display: flex;
    margin-bottom: 16px;
    .selected-sentence {
      transition: all 0.3s ease;
      background: var(--primary-color, $primary-color);
      color: $white;
      padding: 10px 20px;
      border-radius: 4px;
      transform: translateY(70px);
      opacity: 0;
      font-weight: 900;
      &.show {
        opacity: 1;
        transform: translateY(18px);
      }
    }
  }
  .ant-table-wrapper {
    .ant-table {
      .ant-table-thead {
        th {
          &.ant-table-column-has-sorters {
            padding-right: 10px;
            .ant-table-header-column {
              width: 100%;
              .ant-table-column-sorters {
                width: 100%;
                display: flex;
                flex-flow: row nowrap;
                justify-content: space-between;
                align-items: center;
              }
            }
          }
          &[key="actions"] {
            text-align: center;
          }
        }
      }
      td {
        white-space: nowrap;
      }
      .ant-table-row-selected {
        td {
          background: hsla(var(--success-color-h), var(--success-color-s), var(--success-color-l), 0.05);
        }
      }
      .ant-table-row-hover {
        td {
          background: hsla(var(--success-color-h), var(--success-color-s), var(--success-color-l), 0.1);
        }
      }
    }
    .cell-nbDetail {
      margin: auto;
      display: flex;
      justify-content: center;
      align-items: center;
      span {
        margin: auto;
        border-radius: 5px;
        padding: 5px 20px;
        font-weight: 900;
        // color: $white;
        font-size: 16px;
        &.low {
          color: var(--error-color, $error-color);
          background: hsla(var(--error-color-h), var(--error-color-s), var(--error-color-l), 0.05);
        }
        &.high {
          color: var(--primary-color, $primary-color);
          background: hsla(var(--primary-color-h), var(--primary-color-s), var(--primary-color-l), 0.05);
        }
      }
    }
    .cell-members-status {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      img {
        margin-right: 10px;
      }
      span {
        text-transform: capitalize;
        white-space: nowrap;
      }
    }
    .cell-members-progress {
      display: flex;
      flex-flow: column nowrap;
      justify-content: center;
      align-items: center;
      margin-left: -18px;
      .all-status {
        display: flex;
        flex-flow: row nowrap;
        justify-content: space-between;
        align-items: center;
        .status {
          border-radius: 4px;
          margin-right: 10px;
          padding: 2px 6px 2px 3px;
          &:last-child {
            margin-right: 0;
          }
          .mcma-icon {
            .icon-background {
              margin-right: 2px;
              img {
                width: 15px;
              }
            }
            .name {
              color: $white;
              line-height: normal;
              font-size: 16px;
            }
          }
        }
      }
      &.detail {
        .progression {
          margin-top: 0;
        }
      }
      .progression {
        margin-top: 5px;
        width: 85%;
        margin-left: 10px;
        .ant-progress-text,
        .ant-progress-text i {
          font-size: 17px;
        }
      }
    }
    // GENERAL CELL BUTTON
    .cell-button {
      margin: 0;
      display: flex;
      flex-flow: row nowrap;
      justify-content: flex-start;
      align-items: center;
    }
    // GENERAL CELL ACTION
    .cell-actions {
      margin: 0 10px;
      display: flex;
      flex-flow: row nowrap;
      justify-content: center;
      align-items: center;
      .ant-divider {
        height: 50px;
      }
      img {
        width: 28px;
        cursor: pointer;
      }
      .actions-block {
        display: flex;
        flex-flow: column nowrap;
        /*min-width: 101px;*/
        .line {
          display: flex;
          flex-flow: row nowrap;
          justify-content: center;
          align-items: center;
          .mcma-icon {
            cursor: pointer;
            .icon-background {
              &.with-background {
                .leftImage {
                  width: 16px;
                }
              }
            }
            .content {
              .icon-background {
                margin: 0;
                height: 27px;
                width: 27px;
                font-size: 13px;
              }
            }
          }
        }
      }
    }
  }
  .mcma-search-table-filter {
    .highlight {
      display: inline-block;
      background-color: hsla(var(--warning-color-h), var(--warning-color-s), var(--warning-color-l), 0.2);
      padding: 0;
    }
  }
  .ant-table-column-has-filters {
    .customTag {
      display: flex;
      flex-flow: column nowrap;
      .customTag-items {
        display: flex;
        flex-flow: row nowrap;
        margin-left: 10px;
        .part-of-split-tag {
          &.alone {
            margin-right: 5px;
          }
          .ant-tag {
            margin: 0 0 5px 0;
          }
          &.split {
            &.first {
              .ant-tag {
                padding: 1px 0 0 5px;
                border-top-right-radius: 0;
                border-bottom-right-radius: 0;
                border-right: 0;
              }
            }
            &.middle {
              .ant-tag {
                padding: 1px 0 0 0;
                border-radius: 0;
                border-left: 0;
                border-right: 0;
              }
            }
            &.last {
              margin-right: 5px;
              .ant-tag {
                padding: 1px 5px 0 0;
                border-top-left-radius: 0;
                border-bottom-left-radius: 0;
                border-left: 0;
              }
            }
            &.highlight-part {
            }
          }
        }
      }
    }
  }
  .highlight {
    display: inline-block;
    background-color: hsla(var(--warning-color-h), var(--warning-color-s), var(--warning-color-l), 0.2);
    padding: 0;
  }
  .tag-list-with-search {
    display: flex;
    flex-flow: row wrap;
    align-items: flex-start;
  }
  .tag-list {
    display: flex;
    flex-flow: column wrap;
    align-items: flex-start;
    margin-right: 10px;
    > .ant-tag {
      margin: 0 5px 5px 0;
      &:last-child {
        margin: 0 5px 0 0;
      }
    }
  }
}

.ant-table-filter-dropdown {
  min-width: 150px;
}
</style>
