<template>
  <section v-if="exist">
    <generic-field
      v-model="field"
      defaultLabel="Sélectionnez le Code imputation budgetaire"
      defaultDisabledValue="Sélectionnez le code"
      getFlow="name::sites"
      getPanel="name::Localisation"
      getField="type::codeImputation"
    />
  </section>
</template>

<script>
import Mixin from "../Mixin"
import M14 from "./M14.json"
import GenericField from "@/components/common/Fields/GenericField"

export default {
  name: "code-imputation",
  mixins: [Mixin],
  components: {
    GenericField,
  },
  computed: {
    m14() {
      return M14
    },
    exist() {
      return this.confOperation !== "CARREFOUR"
    },
  },
}
</script>

<style lang="scss" scoped></style>
