<template>
  <a-modal
    v-model="visible"
    :maskClosable="false"
    :destroyOnClose="true"
    class="mcma-interlocuteur-create-modal"
    title="Ajout d'un nouvel interlocuteur"
  >
    <div>
      <interlocuteurs-edit
        ref="interEdit"
        :interlocuteur="{}"
        :from="from"
        @save-interlocuteur="saveAndClose"
        @form-error="onFormError"
      />
    </div>
    <template slot="footer">
      <div class="align-row-end mtb12">
        <mcma-button
          name="Fermer"
          color="orange"
          class="mr12"
          leftIcon="times"
          :isFaIcon="true"
          @click="handleCancel"
        />
        <mcma-button
          class="ml12"
          name="Valider les informations"
          color="success"
          leftIcon="check"
          :isFaIcon="true"
          :loading="loading"
          @click="handleOk"
        />
      </div>
    </template>
  </a-modal>
</template>

<script>
// <interlocuteur-create-modal :visible=""
//                             @onCancel=""
//                             @onOk="" />

import InterlocuteursEdit from "@/components/mcma/steps/interlocuteurs/components/InterlocuteursEdit"
import McmaEmpty from "@/components/mcma/common/McmaEmpty"
import McmaButton from "@/components/mcma/common/McmaButton"
import { mapState } from "vuex"

export default {
  name: "InterlocuteurCreateModal",
  components: {
    InterlocuteursEdit,
    McmaEmpty,
    McmaButton,
  },
  props: {
    visible: Boolean,
    from: String,
  },
  data() {
    return {
      loading: false,
    }
  },
  computed: {
    ...mapState(["interlocuteur", "membre"]),
  },
  methods: {
    handleCancel() {
      this.loading = false
      this.$emit("onCancel")
      this.$refs.interEdit.initialize()
    },
    handleOk() {
      this.loading = true
      this.$refs.interEdit.submit()
    },
    onFormError() {
      this.loading = false
    },
    saveAndClose(interlocuteur) {
      this.loading = false
      this.$emit("onOk", interlocuteur)
    },
  },
  destroyed() {
    this.$store._mutations.resetState[0]("interlocuteur")
  },
}
</script>

<style lang="scss">
@import "./src/assets/scss/variables";
@import "./src/assets/scss/functions";

.mcma-interlocuteur-create-modal {
  font-family: $font-family !important;
  width: 900px !important;
}
</style>
