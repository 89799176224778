<template>
  <a-form :form="form">
    <a-row type="flex" :gutter="12">
      <mcma-field
        :colSpan="24"
        type="input"
        :form="form"
        :wasSubmit="wasSubmit"
        fieldName="nom"
        label="Nom"
        placeholder="Nom du membre"
        :required="true"
      />
      <div class="usersList" v-if="membreStats && membreStats.id">
        <div class="header">
          <span>{{ membreStats.userEmail ? "Utilisateurs liés :" : "Aucun utilisateur n'est lié" }} </span>
        </div>
        <div class="content">
          <div class="user" v-for="(userMail, index) in split(membreStats.userEmail).reverse()" :key="userMail">
            <a-tooltip placement="bottom">
              <template slot="title">
                <span>{{ userMail }}</span>
              </template>
              <div
                class="avatar"
                :style="{
                  backgroundColor: $stringToColor(userMail),
                  zIndex: split(membreStats.userEmail).length - index,
                }"
              >
                <span>{{ userMail[0] }}</span>
              </div>
            </a-tooltip>
          </div>
        </div>
      </div>
    </a-row>
    <a-row type="flex" :gutter="12">
      <a-col :span="24">
        <div class="button-group-inline mt24">
          <mcma-button
            name="Annuler les modifications"
            color="primary"
            leftIcon="undo-alt"
            :isFaIcon="true"
            @click="initialize"
          />
          <mcma-button
            :name="membreStats && membreStats.id ? 'Modifier le membre' : 'Ajouter le membre'"
            class="ml12"
            color="success"
            leftIcon="save"
            :isFaIcon="true"
            @click="submit"
          />
        </div>
      </a-col>
    </a-row>
  </a-form>
</template>

<script>
import Axios from "@/util/Axios"
import McmaField from "@/components/mcma/common/McmaField"
import McmaButton from "@/components/mcma/common/McmaButton"
import { mapActions, mapState } from "vuex"

export default {
  name: "MembersEdit",
  props: {
    membreStats: Object,
  },
  components: {
    McmaField,
    McmaButton,
  },
  data() {
    return {
      form: this.$form.createForm(this),
      wasSubmit: false,
    }
  },
  computed: {
    ...mapState({
      currentUser: "user",
      operation: "operation",
    }),
    isSuperAdmin() {
      return this.currentUser && this.currentUser.role === "SUPER_ADMIN"
    },
    isAdmin() {
      return this.currentUser && this.currentUser.role === "ADMIN"
    },
  },
  methods: {
    ...mapActions(["disconnect"]),
    initialize() {
      this.form.getFieldDecorator("nom")
      this.form.setFieldsValue({
        nom: this.membreStats ? this.membreStats.nom : null,
      })
    },
    submit() {
      this.form.validateFields((errors, fieldsValue) => {
        this.wasSubmit = true
        if (errors) {
          this.$notification.warning({
            message: "Formulaire invalide",
            description: `Veuillez-vous assurez de remplir tous les champs requis.`,
          })
        } else {
          if (this.membreStats) {
            Axios(
              "post",
              "membre/save?membre=" +
                this.membreStats.id +
                "&name=" +
                fieldsValue.nom +
                "&operation=" +
                this.operation.id
            )
              .then((response) => {
                this.$notification.success({
                  message: `Membre modifié`,
                  description: `Le membre ${this.membreStats.nom} à bien été modifié par ${fieldsValue.nom}.`,
                })
                this.goToMembersManager()
              })
              .catch((error) => {
                console.error(error)
                this.$notification.error({
                  message: `Erreur de modification du membre`,
                  description: `Une erreur esr survenu lors de la modification du membre ${this.membreStats.nom}. Si le probléme persiste, veuillez contacter un administrateur.`,
                })
              })
          } else {
            Axios(
              "post",
              "membre/save?membre=" + 0 + "&name=" + fieldsValue.nom + "&operation=" + this.operation.id
              // 'api/membres',
              // membre
            )
              .then((response) => {
                this.$notification.success({
                  message: `Membre ajouté`,
                  description: `Le membre ${fieldsValue.nom} à bien été ajouté`,
                })
                /*
          console.log('> New membre response : %O', response)
          console.log('> this.operation._links.self.href : %O', this.operation._links.self.href)
          // ajout du lien vers l'opération
          Axios(
            'post',
            'operation/addLinkMembre?operation=' + this.operation.id + '&membre=' + response.data.id
          ).then(response => {
            Materialize.toast('Liaison membre / opérations sauvegardée', 4000, 'lime')
          }).catch(error => {
            console.error(error)
            Materialize.toast('Erreur lors de l\'ajout du lien entre le membre et son opération !', 4000, 'deep-orange white-text')
          })
          */
                /* TODO : recherche pourquoi la methode du href fonction pour user <=> membre et pas pour operation <=> membre
          Axios(
            'put',
            'api/membres/' + response.data.id + '/operations',
            this.operation._links.self.href + '\n',
            null, {
              'Content-Type': 'text/uri-list'
            }
          ).then(response => {
            Materialize.toast('Liaison membre / opérations sauvegardée', 4000, 'lime')
          }).catch(error => {
            console.error(error)
            Materialize.toast('Erreur lors de l\'ajout du lien entre le membre et son opération !', 4000, 'deep-orange white-text')
          })
          */
                this.initialize()
              })
              .catch((error) => {
                console.error(error)
                this.$notification.error({
                  message: `Erreur de l'ajout du membre`,
                  description: `Une erreur est survenue lors de l'ajout du membre ${fieldsValue.nom}.`,
                })
              })
          }
        }
      })
    },
    goToMembersManager() {
      this.initialize()
      this.$router.push({
        name: "members-manager",
      })
    },
    split(value) {
      if (value) {
        return value.split(",")
      }
      return []
    },
  },
  created() {
    this.initialize()
  },
}
</script>

<style lang="scss">
@import "./src/assets/scss/variables";
@import "./src/assets/scss/functions";

.usersList {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  padding: 5px;
  .header {
    margin-right: 10px;
    span {
      color: var(--text-color, $text-color);
      font-weight: 900;
      font-size: 16px;
    }
  }
  .content {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    .user {
      .avatar {
        position: relative;
        height: 40px;
        width: 40px;
        border-radius: 40px;
        background: $white;
        display: flex;
        margin-right: -10px;
        box-shadow: $box-shadow;
        cursor: pointer;
        transition: all 0.1s ease;
        &:hover {
          margin-right: 5px;
        }
        span {
          color: $white;
          margin: auto;
          font-size: 20px;
          font-weight: 900;
          text-transform: uppercase;
        }
      }
    }
  }
}
</style>
