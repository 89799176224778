import { mapState } from "vuex"

export default {
  props: ["value", "context"],
  computed: {
    ...mapState(["operation"]),
    field: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit("input", value)
      },
    },
  },
}
