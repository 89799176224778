<template>
  <div class="mcma-divider" :style="`width: ${width}`"></div>
</template>

<script>
export default {
  name: "McmaDivider",
  props: {
    width: {
      type: String,
      default: "100%",
    },
  },
}
</script>

<style lang="scss">
@import "./src/assets/scss/variables";
@import "./src/assets/scss/functions";

.mcma-divider {
  position: relative;
  margin-top: 11px;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  height: 1px;
  &:before {
    content: "";
    position: absolute;
    top: 0;
    width: calc(100% + (var(--padding, $padding) * 2) * 2);
    height: 1px;
    background-image: linear-gradient(to right, darken($border-color-base, 5) 66%, rgba($border-color-base, 0) 0%);
    background-position: top;
    background-size: 15px 1px;
    background-repeat: repeat-x;
  }
}
</style>
