<template>
  <section>
    <div class="container">
      <a-row type="flex" :gutter="12" v-if="documents">
        <a-col :span="24">
          <documents-manager-header>
            <mcma-spin-box :spinning="loading">
              <documents-manager-list-table
                :documents="documents"
                @initialize="initialize"
                @selectedChanged="selectedChanged"
              />
            </mcma-spin-box>
          </documents-manager-header>
        </a-col>
      </a-row>
    </div>
    <a class="ninja" ref="downloadLink" />
  </section>
</template>

<script>
import GuardiansMixin from "@/components/mcma/steps/guardians/GuardiansMixin"
import Axios from "@/util/Axios"
import DocumentsManagerHeader from "@/components/mcma/admin/documentsManager/components/DocumentsManagerHeader"
import DocumentsManagerListTable from "@/components/mcma/admin/documentsManager/components/DocumentsManagerListTable"
import McmaSpinBox from "@/components/mcma/common/McmaSpinBox"
import { mapState, mapMutations } from "vuex"

export default {
  name: "documents-manager",
  mixins: [GuardiansMixin],
  components: {
    DocumentsManagerHeader,
    DocumentsManagerListTable,
    McmaSpinBox,
  },
  data() {
    return {
      flow: "DOWNLOAD",
      documents: null,
      loading: true,
    }
  },
  computed: {
    ...mapState(["membre", "operation"]),
  },
  methods: {
    ...mapMutations(["setBreadcrumbAdd"]),
    initialize() {
      Axios("get", "api/pieces/search/findByOperationIdEquals?size=2000&operationId=" + this.operation.id).then(
        (response) => {
          this.loading = false
          this.documents = response.data._embedded.pieces
        }
      )
    },
    processFile(event) {
      this.file = event.target.files[0]
      console.log(
        `%c this.file`,
        "background:#1e4d77 ; padding: 5px 7px 4px 0px; border-radius: 3px;  color: #FFFFFF",
        this.file
      )
    },
    selectedChanged(list) {
      console.log(
        `%c selectedChanged`,
        "background:#1e4d77 ; padding: 5px 7px 4px 0px; border-radius: 3px;  color: #FFFFFF",
        list
      )
    },
  },
  created() {
    this.setBreadcrumbAdd({
      name: "Gestion des pièces",
      action: () => {
        this.$router.push({
          name: "documents-manager",
        })
      },
    })
    this.initialize()
  },
}
</script>

<style lang="scss" scoped></style>
