<template>
  <main>
    <section class="container">
      <article class="card">
        <div class="card-content">
          <span class="card-title"> Pièces </span>
          <blockquote>
            Vous devez transmettre, pour chacunes des pièces ici présentes, votre version signée.
            <br />
            Pour ce faire :
            <ul>
              <li>
                <i class="material-icons">play_arrow</i>
                1- Télécharger la version originale de chacun des pièces,
              </li>
              <li>
                <i class="material-icons">play_arrow</i>
                2- Signer chacune des pièces,
              </li>
              <li>
                <i class="material-icons">play_arrow</i>
                3- Déposer les versions signées.
              </li>
            </ul>
          </blockquote>
        </div>
      </article>
    </section>
    <section class="row">
      <place-holder :notReady="!pieces" class="col s12" id="signDocumentGrid">
        <grid
          v-if="pieces"
          name="Liste des Pièces"
          :rows="pieces"
          :columns="['name', 'type', 'actions']"
          :search="true"
          :columnHeader="{ name: 'Nom', type: 'Type', actions: 'Actions' }"
          :columnsNoSearch="['actions']"
        >
          <div slot="actions" slot-scope="props" class="row valign-wrapper">
            <article class="col s4 center-align">
              <button class="btn waves-effect waves-light blue-grey signDocumentDownload" @click="download(props.row)">
                <i class="material-icons left">file_download</i>
                Télécharger l'original
              </button>
            </article>

            <article class="center-align col s4">
              <article class="file-field input-field">
                <div class="btn waves-effect waves-light blue darken-2 signDocumentUpload">
                  <span>
                    <i class="material-icons left">file_upload</i>
                    Uploader la pièce signé
                  </span>
                  <input type="file" @change="(event) => processFile(event, props.row)" />
                </div>
              </article>
            </article>

            <article class="col s4 center-align">
              <button
                class="btn waves-effect waves-light lime"
                @click="download(props.row, true)"
                v-if="props.row.uploaded"
              >
                <i class="material-icons left">remove_red_eye</i>
                Voir votre version signé
              </button>
            </article>
          </div>
        </grid>
      </place-holder>
    </section>

    <a class="ninja" ref="downloadLink" />
  </main>
</template>

<script>
import Mixin from "../../Mixin"
import Axios from "@/util/Axios"
import Grid from "@/components/common/Grid"
import PlaceHolder from "@/components/common/PlaceHolder"
import { mapState, mapMutations } from "vuex"
import Materialize from "materialize-css"

export default {
  name: "pieces-client",
  mixins: [Mixin],
  components: {
    Grid,
    PlaceHolder,
  },
  data() {
    return {
      flow: "DOCUMENTS",
      pieces: null,
      uploads: null,
    }
  },
  computed: {
    ...mapState(["membre", "operation"]),
  },
  methods: {
    ...mapMutations(["setBreadcrumbAdd"]),
    initialize() {
      this.pieces = null
      this.uploads = null
      Axios("get", "api/pieces/search/findByOperationIdEquals?size=2000&operationId=" + this.operation.id).then(
        (response) => {
          console.log("PIECES!!!!!!!!!!!")
          console.log(response.data)
          this.pieces = response.data._embedded.pieces
          this.concile()
        }
      )
      Axios("get", "/file/piece?membreId=" + this.membre.id + "&operationId=" + this.operation.id).then((response) => {
        this.uploads = response.data
        this.concile()
      })
    },
    download(piece, isMembre) {
      let membre = ""
      if (isMembre) {
        membre = "&membreId=" + this.membre.id
      }
      Axios("get", "file/piece/download?pieceId=" + piece.id + "&operationId=" + this.operation.id + membre, null, {
        responseType: "blob",
      })
        .then((response) => {
          const link = this.$refs.downloadLink
          link.href = window.URL.createObjectURL(new Blob([response.data]))
          link.setAttribute("download", piece.name + "." + piece.extension)
          link.click()
        })
        .catch((error) => {
          console.error(error)
          Materialize.toast("Erreur lors du téléchargement", 4000, "amber white-text")
        })
    },
    concile() {
      if (this.uploads && this.pieces) {
        for (let piece of this.pieces) {
          if (this.uploads.includes(piece.name + "." + piece.extension)) {
            piece.uploaded = true
          }
        }
      }
    },
    processFile(event, piece) {
      const sizeMax = process.env.VUE_APP_UPLOAD_SIZE * 1024 * 1024
      const file = event.target.files[0]

      if (file.size > sizeMax) {
        Materialize.toast(
          "Le fichier dépasse la taille autorisé qui est de " + process.env.VUE_APP_UPLOAD_SIZE + "Mo.",
          4000,
          "amber white-text"
        )
      } else {
        const formData = new FormData()
        formData.append("piece", JSON.stringify(piece))
        formData.append("file", file)
        formData.append("membreId", this.membre.id)
        Axios("post", "/file/piece/membre", formData, null, {
          "Content-Type": "multipart/form-data",
        })
          .then((reponse) => {
            this.initialize()
            Materialize.toast("Piece signé !", 4000, "lime")
          })
          .catch((error) => {
            console.error(error)
            Materialize.toast("Erreur lors de la signature", 4000, "amber white-text")
          })
      }
    },
  },
  created() {
    this.setBreadcrumbAdd({
      name: "Pieces",
      action: () => {
        this.$router.push({
          name: "pieces-client",
        })
      },
    })
    this.initialize()
  },
}
</script>

<style lang="scss" scoped>
td .row,
td .input-field {
  margin: 0px;
}
</style>
