import Vue from "vue"
import VueI18n from "vue-i18n"

Vue.use(VueI18n)

// vue-i18n USE => {{ $t('test.message') }} => https://kazupon.github.io/vue-i18n/en/formatting.html
const messages = {
  "en-US": require("./messages_en-US.json"),
  "fr-FR": require("./messages_fr-FR.json"),
}

// vue-i18n USE => {{ $d(new Date(), 'numeric') }} => https://kazupon.github.io/vue-i18n/en/datetime.html
// Global CONF => http://www.ecma-international.org/ecma-402/2.0/#sec-intl-datetimeformat-constructor
const dateTimeFormats = {
  "en-US": require("./dateTimeFormats.json"),
  "fr-FR": require("./dateTimeFormats.json"),
}

// vue-i18n USE => {{ $n(1000000.25, 'EUR') }} => https://kazupon.github.io/vue-i18n/en/number.html
// Global CONF => https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/NumberFormat
const numberFormats = {
  "en-US": require("./numberFormats.json"),
  "fr-FR": require("./numberFormats.json"),
}

export default new VueI18n({
  locale: "fr-FR",
  fallbackLocale: "fr-FR",
  messages,
  dateTimeFormats,
  numberFormats,
})
