<template>
  <mcma-card :title="getTitle" icon="add_user" class="mcma-card-header">
    <!--    <template #describe>-->
    <!--      -->
    <!--    </template>-->
    <slot></slot>
  </mcma-card>
</template>

<script>
import McmaCard from "@/components/mcma/common/McmaCard"
import { mapState } from "vuex"

export default {
  name: "UsersDetailCardHeader",
  props: {
    user: Object,
  },
  components: {
    McmaCard,
  },
  data() {
    return {}
  },
  computed: {
    ...mapState({
      currentUser: "user",
    }),
    getTitle() {
      if (this.user) {
        if (this.currentUser && this.currentUser.id === this.user.id) {
          return `Modifier mon profil utilisateur`
        }
        return `Edition de l'utilisateur ${this.user.prenom} ${this.user.nom}`
      }
      return "Nouvel utilisateur"
    },
  },
}
</script>

<style lang="scss"></style>
