<template>
  <section class="card">
    <article class="tableHeader" v-if="name">
      <h5>
        {{ name }}
      </h5>
      <slot name="header"> </slot>
    </article>
    <div class="row">
      <div class="col s12">
        <div class="table-wrapper">
          <table class="striped">
            <thead>
              <tr>
                <th v-if="multiSelect">
                  <div class="header">Sélection</div>
                  <div class="searchField valign-wrapper">
                    <input type="checkbox" :id="'checkAll' + name" v-model="checkAll" />
                    <label :for="'checkAll' + name" />
                  </div>
                </th>
                <th v-for="column in columnsFlat" :key="column">
                  <div @click="sort(column)" class="header">
                    <span v-if="sortColumn === column">
                      <i class="material-icons" v-if="sortOrderAsc"> arrow_drop_down </i>
                      <i class="material-icons" v-else> arrow_drop_up </i>
                    </span>
                    {{ column | header(columnHeader) }}
                  </div>
                  <div v-if="search" class="searchField">
                    <select v-if="iscolumnsListFilter(column)" class="browser-default" v-model="searchValues[column]">
                      <option :value="null" selected>-- Tout --</option>
                      <option :value="key" v-for="(value, key) in columnsListFilter[column]" :key="key">
                        {{ value }}
                      </option>
                    </select>
                    <input
                      v-else-if="!columnsNoSearch.includes(column)"
                      type="text"
                      class="search"
                      v-model="searchValues[column]"
                      placeholder="Rechercher"
                    />
                  </div>
                </th>
              </tr>
            </thead>
            <tbody>
              <template v-for="(row, index) in pageActual">
                <tr @click="rowSelected = row" :key="index">
                  <td v-if="multiSelect">
                    <div>
                      <input type="checkbox" :id="row.id" :checked="row.selected" @click="sendSelected(row)" />
                      <label :for="row.id" />
                    </div>
                  </td>
                  <td v-for="column in columnsFlat" :key="column" class="classicCase">
                    <slot :name="column" :row="row">
                      <span v-if="isColumnDated(column)">
                        <span v-if="row[column]">
                          {{ $d(row[column], "numeric") }}
                        </span>
                      </span>
                      <span v-else-if="isColumnCurrency(column)">
                        {{ $n(row[column], "EUR") }}
                      </span>
                      <span v-else-if="typeof row[column] === 'number'">
                        {{ $n(row[column], "decimal") }}
                      </span>
                      <span v-else>
                        {{ row[column] }}
                      </span>
                    </slot>
                  </td>
                </tr>
                <tr v-if="rowContent && rowSelected === row" :key="index">
                  <td :colspan="columnsFlat.length">
                    <slot name="rowContent" :row="row"> No configured content. </slot>
                  </td>
                </tr>
              </template>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <article class="row">
      <label for="pageSize">Total : {{ rows.length }}</label>
      <div class="col s12 l6">
        <pagination :page="pageActive" :total="pages.length" @paging="(value) => (pageActive = value)" />
      </div>
      <div class="col s12 l3">
        <label for="pageSize">lignes, par page : </label>
        <select id="pageSize" class="browser-default" v-model="pageSize">
          <option :value="10">10</option>
          <option :value="25">25</option>
          <option :value="50">50</option>
        </select>
      </div>
    </article>
  </section>
</template>

<script>
import _ from "lodash"
import Pagination from "./Pagination"

export default {
  name: "grid-classic",
  components: {
    Pagination,
  },
  props: {
    name: null,
    rows: {
      default: () => [],
    },
    columns: {
      default: () => [],
    },
    columnsDated: {
      default: () => ["welcomed"],
    },
    columnsCurrency: {
      default: () => [],
    },
    rowContent: null,
    columnHeader: {
      default: () => {},
    },

    // Props SEARCH
    search: null,
    columnsNoSearch: {
      default: () => [],
    },
    columnsListFilter: {
      default: () => {},
    },
    columnsCustomFilter: {
      default: () => [],
    },
    customFilter: {
      default: () => {
        return () => {
          return true
        }
      },
    },

    multiSelect: null,
    /*
    pageActive:{
       default: () => []
    }
    */
  },
  data() {
    return {
      pageSize: 10,
      pageActive: 0,
      rowSelected: null,

      sortColumn: null,
      sortOrderAsc: true,

      searchValues: {},
      checkAll: false,
    }
  },
  computed: {
    rowsFlat() {
      let rows = this.rows

      if (this.search) {
        rows = _.filter(rows, (row) => {
          for (let column in this.searchValues) {
            if (this.columnsCustomFilter.includes(column)) {
              if (!this.customFilter(column, this.searchValues[column], row[column])) {
                return false
              }
            } else {
              if (
                this.searchValues[column] !== null &&
                !String(row[column]).toLowerCase().includes(this.searchValues[column].toLowerCase())
              ) {
                return false
              }
            }
          }
          return true
        })
      }

      if (this.sortColumn) {
        rows = _.sortBy(rows, [this.sortColumn])
        if (!this.sortOrderAsc) {
          rows = _.reverse(rows)
        }
      }

      return rows
    },
    pages() {
      return _.chunk(this.rowsFlat, this.pageSize)
    },
    pageActual() {
      return this.pages[this.pageActive]
    },
    columnsFlat() {
      return _.flatMap(this.columns, (column) => {
        if (typeof column === "object") {
          return column.cols
        }
        return column
      })
    },
  },
  methods: {
    getColSpan(column) {
      if (typeof column === "object" && column.cols) {
        return column.cols.length
      }
      return 1
    },
    isColumnDated(column) {
      return (
        typeof this.columnsDated.find((value) => {
          return value === column
        }) !== "undefined"
      )
    },
    isColumnCurrency(column) {
      return (
        typeof this.columnsCurrency.find((value) => {
          return value === column
        }) !== "undefined"
      )
    },
    sort(column) {
      if (this.sortColumn === column) {
        this.sortOrderAsc = !this.sortOrderAsc
      } else {
        this.sortColumn = column
        this.sortOrderAsc = true
      }
    },
    iscolumnsListFilter(column) {
      return _.keys(this.columnsListFilter).includes(column)
    },
    sendSelected(row) {
      if (row) {
        row.selected = !row.selected
      }
      this.$emit("select", _.filter(this.rowsFlat, "selected"))
    },
  },
  watch: {
    rowSelected(val) {
      if (!this.multiSelect) {
        this.$emit("select", val)
      }
    },
    checkAll(val) {
      if (val) {
        this.rowsFlat.forEach((row) => {
          row.selected = true
        })
      } else {
        this.rowsFlat.forEach((row) => {
          row.selected = false
        })
      }
      this.sendSelected()
    },
    rowsFlat() {
      this.pageActive = 0
    },
  },
  filters: {
    header(val, headers) {
      return headers[val] ? headers[val] : val
    },
  },
}
</script>

<style lang="scss" scoped>
.table-wrapper {
  max-width: 100%;
  overflow-x: scroll;
}

.classicCase {
  word-wrap: break-word;
}

.tableHeader {
  padding: 5px;
  border-bottom: 1px solid black;
}
table {
  border-bottom: 1px solid black;
}

.card .card-action {
  border: none;
}

.header {
  cursor: pointer;
}

.search {
  margin: 0px !important;
  width: 100px !important;
}

.searchField {
  min-height: 46px;
}
</style>
