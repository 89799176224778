<template>
  <section>
    <div class="container">
      <a-row type="flex" :gutter="12">
        <a-col :span="24">
          <members-detail-card-header :membreStats="membreStats">
            <members-edit :membreStats="membreStats" />
          </members-detail-card-header>
        </a-col>
      </a-row>
    </div>
  </section>
</template>

<script>
import MembersEdit from "@/components/mcma/members/components/MembersEdit"
import MembersDetailCardHeader from "@/components/mcma/members/components/MembersDetailCardHeader"
import { mapMutations, mapState } from "vuex"

export default {
  name: "MembersDetail",
  components: {
    MembersDetailCardHeader,
    MembersEdit,
  },
  computed: {
    ...mapState(["membreStats"]),
  },
  methods: {
    ...mapMutations(["setBreadcrumbAdd"]),
  },
  created() {
    let name = "Action"
    const action = this.$route.params.action
    if (action === "edit") {
      name = "Edition"
    } else if (action === "new") {
      name = "Nouveau"
    }
    this.setBreadcrumbAdd({
      name: name,
      action: () => {},
    })
  },
  destroyed() {
    this.$store._mutations.resetState[0]("membreStats")
  },
}
</script>

<style lang="scss" scoped></style>
