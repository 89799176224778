<template>
  <main class="container">
    <div class="row">
      <div class="col s12">
        <form class="card" @submit.prevent="submit">
          <div class="card-content">
            <span class="card-title"> Contactez-nous </span>
            <hr />
            <blockquote>
              Pour plus d'infomations, des précisions sur le fonctionnement du site, d'accompagnement dans votre saisie,
              ou le report d'une annomalie, n'hésitez pas à nous contacter.
            </blockquote>
            <div>
              <div class="row">
                <div class="input-field col s6">
                  <i class="material-icons prefix">subject</i>
                  <input id="object" type="text" class="validate" required v-model="subject" />
                  <label for="object">Objet</label>
                </div>
                <div class="input-field col s6">
                  <i class="material-icons prefix">email</i>
                  <input id="mail" type="email" class="validate" required v-model="mail" />
                  <label for="mail" class="active">Adresse E-mail de réponse</label>
                </div>
              </div>
              <div class="row">
                <div class="input-field col s12">
                  <i class="material-icons prefix">mode_edit</i>
                  <textarea id="text" class="materialize-textarea validate" required v-model="message"></textarea>
                  <label for="text">Message</label>
                </div>
              </div>
            </div>
          </div>
          <center class="card-action">
            <button type="submit" class="waves-effect waves-light btn blue darken-2">Envoyer</button>
          </center>
        </form>
      </div>
    </div>
  </main>
</template>

<script>
import Axios from "@/util/Axios"
import { mapState, mapMutations } from "vuex"
import Materialize from "materialize-css"
import { getClientTechInfos } from "@/util/Helpers/Navigator"

export default {
  name: "contact",
  data() {
    return {
      mail: null,
      subject: null,
      message: null,
    }
  },
  computed: {
    ...mapState(["user", "membre", "operation"]),
  },
  methods: {
    ...mapMutations(["setBreadcrumbAdd"]),
    submit() {
      Axios("post", "/mail/contact?idOperation=" + this.operation.id, {
        membreId: this.membre ? this.membre.id : null,
        mail: this.mail,
        subject: this.subject,
        message: this.message,
        clientTechInfos: getClientTechInfos(),
      })
        .then((response) => {
          Materialize.toast("E-mail envoyé ! Un exemplaire vous a été également communiqué.", 4000, "lime white-text")
        })
        .catch((error) => {
          console.error(error)
          Materialize.toast("Erreur lors de l'envoie", 4000, "amber white-text")
        })
    },
  },
  created() {
    if (this.user) {
      this.mail = this.user.email
    }
    this.setBreadcrumbAdd({
      name: "Contact",
      action: () => {},
    })
  },
}
</script>

<style lang="scss" scoped></style>
