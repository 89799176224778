<template>
  <main class="container login-page logo-and-bg">
    <div class="logo-background" :style="{ backgroundImage: $getLogoBySubDomain().backgroundCss }">
      <img
        v-if="$getLogoBySubDomain().logo.login"
        class="logo"
        :src="$getLogoBySubDomain().logo.login"
        :alt="`${$getLogoBySubDomain().name} logo on Waika`"
      />
    </div>
    <section class="row login-error" v-if="error">
      <div class="col s12 m8 offset-m2">
        <div class="card-panel deep-orange">
          <div>
            {{ error }}
          </div>
          <div v-if="timeOut">
            La page va se rafraichir dans {{ timeOut / 1000 }} secondes, pour tenter de mettre à jour l'application.
          </div>
        </div>
      </div>
    </section>
    <section class="row login-message" v-if="message">
      <div class="col s12 m8 offset-m2">
        <div class="card-panel cyan">
          {{ message }}
        </div>
      </div>
    </section>

    <section class="row login-container">
      <div class="col s12 m6 offset-m3">
        <div v-if="loading" class="col s6 offset-s5">
          Connexion ...
          <v-progress-circular indeterminate color="info" />
        </div>
        <div v-else>
          <form class="card" @submit.prevent="act">
            <section class="card-content">
              <span class="card-title"> Veuillez vous connecter </span>

              <article class="input-field">
                <i class="material-icons prefix">account_circle</i>
                <input
                  v-model="identifiant"
                  id="identifiant"
                  type="text"
                  class="validate"
                  required
                  :disabled="askNew"
                />
                <label for="identifiant" class="active"> Identifiant </label>
              </article>

              <article class="input-field">
                <i class="material-icons prefix">lock</i>
                <input v-model="password" id="password" type="password" class="validate" required :disabled="askNew" />
                <label for="password" class="active"> Mot de passe </label>
              </article>

              <article class="input-field" v-if="askNew">
                <i class="material-icons prefix">lock_outline</i>
                <input v-model="newPassword" id="newPassword" type="password" class="validate" required />
                <label for="newPassword" class="active"> Nouveau mot de passe </label>
              </article>
            </section>

            <section class="card-action">
              <div class="row valign-wrapper">
                <article v-if="askNew" class="col s6 center-align">
                  <button type="submit" class="waves-effect waves-light btn blue darken-2">
                    Changer le mot de passe
                  </button>
                </article>

                <template v-else>
                  <article class="col s6 center-align">
                    <button type="submit" class="waves-effect waves-light btn blue darken-2">Connexion</button>
                  </article>

                  <article class="col s6 center-align askChangePassword">
                    <a href @click.prevent="forgot">Mot de passe oublié ?</a>
                  </article>
                </template>
              </div>
            </section>
          </form>
        </div>
      </div>
    </section>
  </main>
</template>

<script>
import Sha1 from "sha1"
import moment from "moment"
import Axios from "@/util/Axios"
import AxiosNoCrypt from "@/util/AxiosNoCrypt"
import { mapMutations, mapActions } from "vuex"
import Materialize from "materialize-css"
import { getClientTechInfos } from "@/util/Helpers/Navigator"

export default {
  name: "login",
  data() {
    return {
      error: null,
      message: null,

      identifiant: null,
      lastIdentifiant: null,
      password: null,

      newPassword: null,
      askNew: null,
      loading: false,
      timeOut: null,
    }
  },
  methods: {
    ...mapMutations(["setConnected", "initBreadcrumb", "setConfOperation"]),
    ...mapActions(["disconnect", "tutoReset"]),
    okConnect() {
      console.log("okConnect")
      this.setConnected(true)
      this.$router.push({
        name: "home",
      })
    },
    act() {
      if (this.askNew) {
        this.change()
      } else {
        this.connexion()
      }
    },
    connexion() {
      const passHash = Sha1(this.identifiant + ":" + this.password.trim()).toUpperCase()
      const token = Sha1(this.identifiant + ":" + passHash + ":" + moment().format("YYYY-MM-DD")).toUpperCase()

      window.localStorage.setItem("identifiant", this.identifiant)
      window.localStorage.setItem("token", token)

      Axios("get", "api/login", null, null, {
        clientTechInfos: JSON.stringify(getClientTechInfos()),
      })
        .then((response) => {
          if (!response.data.passwordChanged) {
            this.askNew = response.data.id
            this.message = "Pour votre première connexion, veuillez changer votre mot de passe."
          } else {
            this.okConnect()
          }
        })
        .catch((error) => {
          if (error.response) {
            this.error = "Mauvais identifiant ou mot de passe"
          } else {
            this.error = "Erreur de connexion au serveur"
          }
          this.startReload()
        })
    },
    espaceAdherentCedre() {
      const urlParams = new URLSearchParams(window.location.search)
      const accessToken = urlParams.get("access-token")
      // const accessToken = "ebc68c2a-e9e4-4212-afbb-cfe86ba64fd9" token de test
      const uuidOperation = urlParams.get("operation-uid")

      if (accessToken != null && uuidOperation != null) {
        this.loading = true
        return AxiosNoCrypt("get", `/membre/cedre?operation-uid=${uuidOperation}&access-token=${accessToken}`)
          .then((response) => {
            this.identifiant = response.data.email
            this.password = response.data.password
            const token = Sha1(
              this.identifiant + ":" + this.password + ":" + moment().format("YYYY-MM-DD")
            ).toUpperCase()
            window.localStorage.setItem("identifiant", this.identifiant)
            window.localStorage.setItem("token", token)

            Axios("get", "api/login", null, null, {
              clientTechInfos: JSON.stringify(getClientTechInfos()),
            })
              .then(() => {
                this.loading = false
                this.okConnect()
              })
              .catch((error) => {
                this.loading = false
                if (error.response) {
                  this.error =
                    "Mauvais login ou mot de passe. Contactez votre coordonnateur : " + this.operation.contactMail
                } else {
                  this.error = "Erreur de connexion au serveur"
                }
                this.startReload()
              })
          })
          .catch((error) => {
            console.error(error)
            this.loading = false
            if (error.response) {
              this.error = "Mauvais login ou mot de passe"
            } else {
              this.error = "Erreur de connexion au serveur"
            }
          })
      }
    },
    change() {
      const passHash = Sha1(this.identifiant + ":" + this.newPassword).toUpperCase()
      Axios("patch", "api/users/" + this.askNew, {
        password: passHash,
        passwordChanged: true,
      })
        .then((response) => {
          this.password = this.newPassword.trim()
          this.connexion()
        })
        .catch((error) => {
          console.error(error)
          if (error.response.status === 400) {
            this.error = "Mauvais identifiants"
          } else {
            this.error = "Erreur de connexion au serveur"
          }
          this.startReload()
        })
    },
    forgot() {
      if (this.identifiant) {
        if (this.identifiant !== this.lastIdentifiant) {
          AxiosNoCrypt("post", "/mail/forgot", {
            identifiant: this.identifiant,
          })
            .then((response) => {
              Materialize.toast("Un nouveau mot de passe vous a été envoyé par mail.", 4000, "lime white-text")
              this.lastIdentifiant = this.identifiant
            })
            .catch((error) => {
              console.error(error)
              if (error.response.status === 400) {
                this.error = "Mauvais identifiants"
              } else {
                this.error = "Erreur de connexion au serveur"
              }
              this.startReload()
            })
        } else {
          Materialize.toast("Un email vous a déjà été envoyé à cette adresse.", 2000, "amber white-text")
        }
      } else {
        Materialize.toast("Vous devez d'abord renseigner un identifiant valide.", 4000, "amber white-text")
      }
    },
    startReload() {
      this.timeOut = 3000
      setTimeout(() => {
        window.location.reload(true)
      }, this.timeOut)
    },
    showError(error) {
      const errorCode = error.response?.status == 401 && error.response?.data?.message

      this.$toast.error(this.$te("error." + errorCode) ? this.$t("error." + errorCode) : errorCode)
    },
  },
  created() {
    if (window.location.hostname.includes("cedre")) {
      this.espaceAdherentCedre()
    }
    this.disconnect()
    this.tutoReset()
    this.initBreadcrumb(null)
    this.setConfOperation("SUPER_WAIKA")
  },
}
</script>

<style lang="scss">
@import "./src/assets/scss/variables";
@import "./src/assets/scss/functions";

.login-page {
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  &.logo-and-bg {
    .logo-background {
      img.logo {
        width: 350px;
        margin-top: -450px;
      }
    }
    .login-container {
      margin-top: 150px;
    }
  }
  .login-error,
  .login-message {
    position: relative;
    z-index: 10;
  }
  .logo-background {
    position: absolute;
    z-index: 0;
    width: 100vw;
    height: 100vh;
    left: 0;
    top: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background-size: cover;
    background-repeat: no-repeat;
    img.logo {
      width: 100vh;
    }
  }
  .login-container {
    .card {
      background: rgba($white, 0.9);
      .card-action {
        background: transparent;
        .askChangePassword {
          font-weight: 600;
        }
      }
    }
    .loader {
      border: 16px solid #f3f3f3;
      border-radius: 50%;
      border-top: 16px solid green;
      border-bottom: 16px solid green;
      width: 120px;
      height: 120px;
      -webkit-animation: spin 2s linear infinite;
      animation: spin 2s linear infinite;
    }

    @-webkit-keyframes spin {
      0% {
        -webkit-transform: rotate(0deg);
      }
      100% {
        -webkit-transform: rotate(360deg);
      }
    }

    @keyframes spin {
      0% {
        transform: rotate(0deg);
      }
      100% {
        transform: rotate(360deg);
      }
    }
  }
}
</style>
