<template>
  <main>
    <section class="container">
      <article class="col s6 center-align">
        <button class="waves-effect waves-light btn lime" type="alldownload" id="addDocumentAdd" @click="alldownload()">
          <i class="material-icons left">file_download</i>
          Télécharger toutes les pièces déposées par les membres
        </button>
      </article>
    </section>
    <section class="container">
      <form class="card" @submit.prevent="submit" @reset="initialize">
        <section class="card-content" id="addDocumentForm">
          <span class="card-title"> Nouvelle pièce </span>

          <article class="row">
            <div class="input-field col s12">
              <i class="material-icons prefix">attachment</i>
              <input id="name" type="text" class="validate" required v-model="name" />
              <label for="name" class="active">
                <font-awesome-icon icon="asterisk" class="red-text" />
                Nom
              </label>
            </div>
          </article>

          <article class="row valign-wrapper">
            <div class="col s2">
              <i class="material-icons small prefix">local_offer</i>
              <label>
                <font-awesome-icon icon="asterisk" class="red-text" />
                Type
              </label>
            </div>
            <div class="col s10">
              <select class="browser-default" v-model="type" required>
                <option value="MANDAT">Mandat</option>
                <option value="DELIBERATION">Deliberation</option>
                <option value="BORDEREAU">Bordereau de prix unitaire</option>
                <option value="CONTRAT">Contrat cadre</option>
                <option value="AUTRES">Autres</option>
              </select>
            </div>
          </article>

          <article class="row">
            <div class="file-field input-field col s12">
              <div class="btn">
                <span>
                  <i class="material-icons left">file_upload</i>
                  Fichier source
                </span>
                <input type="file" @change="processFile" />
              </div>
              <div class="file-path-wrapper">
                <input class="file-path validate" type="text" placeholder="Fichier source" required />
              </div>
            </div>
          </article>
        </section>
        <section class="card-action">
          <div class="row">
            <article class="col s6 center-align">
              <button class="waves-effect waves-light btn lime" type="submit" id="addDocumentAdd">
                <i class="material-icons left">add</i>
                Ajouter
              </button>
            </article>

            <article class="col s6 center-align">
              <button class="waves-effect waves-light btn deep-orange" type="reset" id="addDocumentReset">
                <i class="material-icons left">refresh</i>
                Annuler
              </button>
            </article>
          </div>
        </section>
      </form>
    </section>
    <section class="row">
      <place-holder :notReady="!pieces" class="col s12" id="addDocumentGrid">
        <grid
          v-if="pieces"
          name="Liste des Pièces"
          :rows="pieces"
          :columns="['name', 'type', 'actions']"
          :search="true"
          :columnHeader="{ name: 'Nom', type: 'Type', actions: 'Actions' }"
          :columnsNoSearch="['actions']"
        >
          <template slot="actions" slot-scope="props">
            <i
              class="material-icons pointer blue-grey-text addDocumentDownload"
              v-tooltip.auto="'Télécharger'"
              @click="download(props.row)"
              >file_download</i
            >
          </template>
        </grid>
      </place-holder>
    </section>

    <a class="ninja" ref="downloadLink" />
  </main>
</template>

<script>
import Mixin from "../../Mixin"
import Axios from "@/util/Axios"
import Grid from "@/components/common/Grid"
import PlaceHolder from "@/components/common/PlaceHolder"
import Materialize from "materialize-css"
import { mapState, mapMutations } from "vuex"

export default {
  name: "pieces-admin",
  mixins: [Mixin],
  components: {
    Grid,
    PlaceHolder,
  },
  data() {
    return {
      flow: "DOWNLOAD",
      pieces: null,

      name: null,
      type: null,
      file: null,
    }
  },
  computed: {
    ...mapState(["membre", "operation"]),
  },
  methods: {
    ...mapMutations(["setBreadcrumbAdd"]),
    initialize() {
      this.name = null
      this.type = null
      this.file = null
      Axios("get", "api/pieces/search/findByOperationIdEquals?size=2000&operationId=" + this.operation.id).then(
        (response) => {
          this.pieces = response.data._embedded.pieces
        }
      )
    },
    processFile(event) {
      this.file = event.target.files[0]
    },
    submit() {
      const sizeMax = process.env.VUE_APP_UPLOAD_SIZE * 1024 * 1024
      if (this.file.size > sizeMax) {
        Materialize.toast(
          "Le fichier dépasse la taille autorisé qui est de " + process.env.VUE_APP_UPLOAD_SIZE + "Mo.",
          4000,
          "amber white-text"
        )
      } else {
        const formData = new FormData()
        formData.append(
          "piece",
          JSON.stringify({
            name: this.name,
            type: this.type,
          })
        )
        formData.append("file", this.file)
        formData.append("operationId", this.operation.id)
        Axios("post", "/file/piece", formData, null, {
          "Content-Type": "multipart/form-data",
        })
          .then((reponse) => {
            this.initialize()
            Materialize.toast("Piece ajouté", 4000, "lime")
          })
          .catch((error) => {
            console.error(error)
            if (error.response.status === 400) {
              Materialize.toast("Cette pièce existe déjà", 4000, "deep-orange")
            } else {
              Materialize.toast("Erreur lors de l'ajout", 4000, "amber white-text")
            }
          })
      }
    },
    alldownload() {
      console.group("alldownload@FlowPieceAdmin")
      Axios("get", "file/pieceMembre/operation/allDownload?operationId=" + this.operation.id, null, {
        responseType: "blob",
      })
        .then((response) => {
          console.log("Response : %O", response)
          const link = this.$refs.downloadLink
          link.href = window.URL.createObjectURL(new Blob([response.data]))
          link.setAttribute("download", "touteslespieces.zip")
          link.click()
          console.groupEnd()
        })
        .catch((error) => {
          console.error(error)
          Materialize.toast("Erreur lors du téléchargement", 4000, "mcmaGrey white-text")
          console.groupEnd()
        })
    },
    download(piece) {
      Axios("get", "file/piece/download?pieceId=" + piece.id + "&operationId=" + this.operation.id, null, {
        responseType: "blob",
      })
        .then((response) => {
          const link = this.$refs.downloadLink
          link.href = window.URL.createObjectURL(new Blob([response.data]))
          link.setAttribute("download", piece.name + "." + piece.extension)
          link.click()
        })
        .catch((error) => {
          console.error(error)
          Materialize.toast("Erreur lors du téléchargement", 4000, "amber white-text")
        })
    },
  },
  created() {
    this.setBreadcrumbAdd({
      name: "Gestion des pièces",
      action: () => {
        this.$router.push({
          name: "pieces-admin",
        })
      },
    })
    this.initialize()
  },
}
</script>

<style lang="scss" scoped></style>
