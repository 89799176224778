<template>
  <main class="container">
    <section class="row">
      <groupement-form v-if="groupement" :groupement="groupement" @save="submit" />
    </section>
  </main>
</template>

<script>
import Mixin from "../Mixin"
import Axios from "@/util/Axios"
import GroupementForm from "@/components/common/Groupement/Form"
import { mapState, mapMutations } from "vuex"

export default {
  name: "groupement",
  mixins: [Mixin],
  components: {
    GroupementForm,
  },
  data() {
    return {
      flow: "FACTURATION",
      groupement: null,
    }
  },
  computed: {
    ...mapState(["membre", "user", "operation"]),
  },
  methods: {
    ...mapMutations(["setBreadcrumbAdd"]),
    submit() {
      this.$router.push({
        name: "groupements",
      })
    },
  },
  created() {
    if (this.$route.params.id === "new") {
      this.groupement = {}
      this.setBreadcrumbAdd({
        name: "Création",
        action: () => {},
      })
    } else {
      Axios("get", "api/groupements/" + this.$route.params.id).then((response) => {
        this.groupement = response.data
        if (this.$route.query.action) {
          this.setBreadcrumbAdd({
            name: "Duplication de :" + this.groupement.nom,
            action: () => {},
          })
        } else {
          this.setBreadcrumbAdd({
            name: "Edition du périmetre de facturation :" + this.groupement.nom,
            action: () => {},
          })
        }
      })
    }
  },
}
</script>

<style lang="scss" scoped></style>
