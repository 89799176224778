var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('main',{staticClass:"row"},_vm._l((_vm.tutorials),function(tuto,index){return (
      ((!tuto.admin && _vm.user.role === 'CLIENT') ||
        _vm.user.role === 'SUPER_ADMIN' ||
        _vm.user.role === 'ADMIN' ||
        _vm.user.role === 'COORDINATEUR' ||
        _vm.user.role === 'COORDINATEUR_AVANCE') &&
      (tuto.flow ? _vm.getFlow.includes(tuto.flow) : true)
    )?_c('section',{key:index,staticClass:"col s12 m6"},[_c('article',{staticClass:"card hoverable pointer",on:{"click":function($event){return _vm.goToTuto(tuto)}}},[_c('div',{staticClass:"card-content"},[_c('span',{staticClass:"card-title"},[_vm._v(_vm._s(tuto.title))]),_c('blockquote',[_vm._v(_vm._s(tuto.description))])])])]):_vm._e()}),0)}
var staticRenderFns = []

export { render, staticRenderFns }