<template>
  <section>
    <div class="container">
      <a-row type="flex" :gutter="12">
        <a-col :span="24">
          <users-detail-card-header :user="getUser">
            <users-edit :user="getUser" />
          </users-detail-card-header>
        </a-col>
      </a-row>
    </div>
  </section>
</template>

<script>
import UsersEdit from "@/components/mcma/users/components/UsersEdit"
import UsersDetailCardHeader from "@/components/mcma/users/components/UsersDetailCardHeader"
import { mapMutations, mapState } from "vuex"

export default {
  name: "UsersDetail",
  components: {
    UsersDetailCardHeader,
    UsersEdit,
  },
  computed: {
    ...mapState({
      currentUser: "user",
      userEditing: "userEditing",
    }),
    getUser() {
      const action = this.$route.params.action
      if (action === "my-account") {
        return this.currentUser
      } else if (action === "edit") {
        return this.userEditing
      }
      return null
    },
  },
  methods: {
    ...mapMutations(["setBreadcrumbAdd"]),
  },
  created() {
    let name = "Action"
    const action = this.$route.params.action
    if (action === "my-account") {
      name = "Mon compte"
    } else if (action === "edit") {
      name = "Edition"
    } else if (action === "new") {
      name = "Nouveau"
    }
    this.setBreadcrumbAdd({
      name: name,
      action: () => {},
    })
  },
  destroyed() {
    this.$store._mutations.resetState[0]("userEditing")
  },
}
</script>

<style lang="scss" scoped></style>
