<template>
  <section class="input-field" @focusin="focus = true" @focusout="focus = false">
    <input :id="_uid" type="text" class="validate" v-model="dateFinContrat" required ref="field" />
    <label :class="{ active: dateFinContrat }" :for="_uid">
      <font-awesome-icon icon="asterisk" class="red-text" />
      Date de sortie du site du contrat (jj/mm/aaaa)
    </label>

    <blockquote class="card-panel" v-if="focus">
      Attention, cette date vous engage. Elle doit doit impérativement se situer entre les dates prévues au nouveau
      contrat du
      {{ this.dateBasucleFormat }} au {{ this.dateFinContratFormat }} (inclus)
      <br />
      Avant toute saisie, vérifiez bien la date de fin de contrat souhaitée. En cas d’erreur vous vous exposez à
      l’application de pénalité pour reactivation du contrat.
    </blockquote>
  </section>
</template>

<script>
import Mixin from "./Mixin"
import moment from "moment"
import { mapState } from "vuex"

export default {
  name: "date-fin-contrat",
  mixins: [Mixin],
  data() {
    return {
      focus: false,
    }
  },
  computed: {
    ...mapState(["membre", "operation"]),
    activeService: {
      get() {
        return this.getActive("services", "Services", "")
      },
    },
    dateBasucleFormat: {
      get() {
        return moment(this.operation.dateBascule, "x").format("DD/MM/YYYY")
      },
    },
    dateFinContratFormat: {
      get() {
        return moment(this.operation.dateFinContrat, "x").format("DD/MM/YYYY")
      },
    },
    dateFinContrat: {
      get() {
        // console.log('get@dateFinContrat')
        // console.log('this.field : %O', this.field)
        // console.log('this.field moment : %O', moment(this.field, 'x').format('DD/MM/YYYY'))
        // console.log('this.operation.dateFinContrat : %O', this.operation.dateFinContrat)
        // console.log('this.operation.dateFinContrat moment : %O', moment(this.operation.dateFinContrat, 'x').format('DD/MM/YYYY'))
        return this.field
          ? moment(this.field, "x").format("DD/MM/YYYY")
          : this.operation.dateFinContrat
          ? moment(this.operation.dateFinContrat, "x").format("DD/MM/YYYY")
          : "01/01/2022"
      },
      set(value) {
        // console.group('set@dateFinContrat')
        // console.log('value : %O', value)
        let valid = false
        let validInterval = false
        if (/^[0-9]{2}\/[0-9]{2}\/[0-9]{4}$/.test(value)) {
          var realDate = moment(value, "DD/MM/YYYY").format("x")
          realDate = moment(value, "DD/MM/YYYY").format("x")
          // console.log('realDate : %O', realDate)
          if (realDate !== "Invalid date") {
            valid = true
            // console.log('0- realDate moment : %O', moment(realDate, 'x').format('DD/MM/YYYY'))
            // console.log('0- realDate moment : %O', realDate)
            if (realDate >= this.operation.dateBascule && realDate <= this.operation.dateFinContrat) {
              validInterval = true
              this.field = realDate
            }
          }
        }

        if (valid) {
          if (validInterval) {
            this.$refs.field.setCustomValidity("")
          } else {
            this.$refs.field.setCustomValidity(
              "la date saisie doit impérativement se situer entre les dates prévues au nouveau contrat : " +
                moment(this.operation.dateBascule, "x").format("DD/MM/YYYY") +
                " <= votre_date <= " +
                moment(this.operation.dateFinContrat, "x").format("DD/MM/YYYY")
            )
          }
        } else {
          this.$refs.field.setCustomValidity("Mauvais format de date")
        }
        // console.groupEnd()
      },
    },
  },
  mounted() {
    this.dateFinContrat = this.dateFinContrat
  },
}
</script>

<style lang="scss" scoped>
.card-panel {
  position: absolute;
  top: 45px;
  z-index: 5;
}
</style>
