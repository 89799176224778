<template>
  <article class="operationContainer" :class="toggleDetails ? 'grow' : 'compress'">
    <h5>{{ operation.name }}</h5>
    <label> Durée : du {{ $d(operation.dateDebut) }} au {{ $d(operation.dateFin) }} </label>
    <i class="material-icons actionBtn" v-tooltip.auto="'Se connecter à l\'opération'" @click="$emit('goTo', operation)"
      >home</i
    >
    <i
      class="material-icons actionBtn"
      v-tooltip.auto="'Éditer l\'opération'"
      @click="$emit('edit', operation)"
      v-if="user.role === 'SUPER_ADMIN' || user.role === 'ADMIN'"
      >edit</i
    >
    <!-- <i
      class="material-icons actionBtn"
      v-tooltip.auto="'Ajouter des Administrateurs'"
      @click="$emit('addAdmin', operation)"
      >person_add</i
    > -->
    <i
      class="material-icons actionBtn"
      v-tooltip.auto="'Supprimer l\'opération'"
      @click="$emit('delete', operation)"
      v-if="user.role === 'SUPER_ADMIN'"
      >delete</i
    >
    <i class="switch material-icons" 
    v-if="user.role === 'SUPER_ADMIN'">
      <div class="switch">
        <label>
          <input type="checkbox" v-model="operation.active" @click="$emit('disable', operation)"/>
          <span class="lever"></span>
        </label>
      </div>
    </i>
    <span class="badge" id="menuSuperAdminTime">
      <count-down :dateFin="operation.dateFin" />
    </span>
    <place-holder :notReady="loading">
      <progression :membres="operation.progression" />
    </place-holder>
    <div class="detailsPlus" @click="toggleDetails = !toggleDetails" :class="toggleDetails ? 'active' : ''">
      <i class="material-icons">{{ toggleDetails ? "zoom_out" : "zoom_in" }}</i>
    </div>
    <div class="detailsContainer" :class="toggleDetails ? 'slideIn' : 'slideOut'">
      <div
        v-for="(value, status) in membres"
        :key="status + '_' + _uid"
        class="detailsTab"
        @click="activeTab = status"
        :class="{ active: activeTab === status }"
      >
        {{ $tc("status." + status, value.length) }} ({{ value.length }})
      </div>
      <div class="detailsWrapper">
        <place-holder :notReady="loadingMembers">
          <ul>
            <li v-for="membre in membres[activeTab]" :key="membre.id + '_' + _uid">
              {{ membre.nom }}
            </li>
          </ul>
        </place-holder>
      </div>
    </div>
  </article>
</template>

<script>
import Progression from "./Progression"
import Axios from "@/util/Axios"
import PlaceHolder from "@/components/common/PlaceHolder"
import CountDown from "@/components/common/CountDown"

export default {
  name: "operation-card",
  components: {
    Progression,
    PlaceHolder,
    CountDown,
  },
  props: ["operation", "user"],
  data() {
    return {
      toggleDetails: false,
      membres: {
        INITIALISED: [],
        IN_PROGRESS: [],
        EXPORTED: [],
        APPROVED: [],
        NOT_PRESERVED: [],
      },
      activeTab: "INITIALISED",
      loading: false,
      loadingMembers: false,
    }
  },
  methods: {
    initialize() {
      this.loading = true
      // console.group('initialize@IndexOperationCard')
      // console.log(this.operation)
      Axios(
        "get",
        "api/operationStats/search/findByOperationId?operation=" + this.operation.id + "&page=0&size=10"
      ).then((response) => {
        var res = response.data._embedded.operationStats
        console.log("OperationStats : %O ", res)
        this.operation.progression = res
        this.loading = false
      })
      // console.groupEnd()
    },
  },
  watch: {
    toggleDetails() {
      if (this.toggleDetails) {
        this.loadingMembers = true
        this.membres = {
          INITIALISED: [],
          IN_PROGRESS: [],
          EXPORTED: [],
          APPROVED: [],
          NOT_PRESERVED: [],
        }
        Axios("get", "api/membreStats/search/findAllByOperationId?operationId=" + this.operation.id).then(
          (response) => {
            console.log(response)
            for (const membre of response.data._embedded.membreStats) {
              switch (membre.status) {
                case "IN_PROGRESS":
                  this.membres.IN_PROGRESS.push(membre)
                  break
                case "EXPORTED":
                  this.membres.EXPORTED.push(membre)
                  break
                case "APPROVED":
                  this.membres.APPROVED.push(membre)
                  break
                case "NOT_PRESERVED":
                  this.membres.NOT_PRESERVED.push(membre)
                  break
                default:
                  this.membres.INITIALISED.push(membre)
                  break
              }
              // if (membre.approuved) {
              //   this.membres.APPROVED.push(membre)
              // } else if (membre.certified) {
              //   this.membres.EXPORTED.push(membre)
              // } else if (membre.lastLogin) {
              //   this.membres.IN_PROGRESS.push(membre)
              // } else {
              //   this.membres.INITIALISED.push(membre)
              // }
            }
            this.loadingMembers = false
          }
        )
      }
    },
  },
  created() {
    this.initialize()
  },
}
</script>

<style lang="scss" scoped>
.operationContainer {
  background: white;
  margin: 5px;
  padding: 5px;
  border-radius: 5px 40px 40px 5px;
  position: relative;
  overflow: hidden;
  transition: 0.3s;
  padding-right: 80px;

  &.grow {
    transition-delay: 0.3s;
    height: 200px;
  }

  &.compress {
    height: 100px;
  }

  & h5 {
    display: inline-block;
  }

  & i.actionBtn {
    width: 40px;
    height: 40px;
    padding: 8px;
    margin: -5px 5px 5px 5px;
    border-radius: 20px;
    cursor: pointer;
    transition: 0.3s;
    &:hover {
      background: rgba(0, 0, 0, 0.1);
    }
  }

  & .detailsContainer {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #005bbb;
    padding: 5px;
    padding-right: 80px;
    transition: 0.3s;
    &.slideIn {
      transform: translateX(0);
      & * {
        transition-delay: 0.3s;
        opacity: 1;
      }
    }
    &.slideOut {
      transition-delay: 0.3s;
      transform: translateX(100%);
      & * {
        opacity: 0;
      }
    }
    & .detailsTab {
      display: inline-block;
      border-radius: 5px 5px 0 0;
      padding: 4px 10px;
      cursor: pointer;
      transition: 0.3s;
      &:not(.active) {
        color: white;
        &:hover {
          background: #dadada !important;
          color: black !important;
        }
      }
      &.active {
        background: white;
        color: black;
      }
    }
    & .detailsWrapper {
      background: white;
      padding: 5px;
      border-radius: 0 4px 4px 4px;
      & ul {
        margin: 0;
        max-height: 150px;
        overflow: auto;
        border: 1px solid rgba(0, 0, 0, 0.1);
        border-radius: 5px;
        &::-webkit-scrollbar {
          background: rgba(0, 0, 0, 0.1);
          width: 10px;
          border-radius: 5px;
          &:hover {
            transform: scaleX(1.5);
          }
        }
        &::-webkit-scrollbar-thumb {
          background: rgba(0, 0, 0, 0.2);
          border-radius: 5px;
          &:hover {
            background: rgba(0, 0, 0, 0.4);
          }
        }
        & li {
          padding: 5px;
          &:not(:last-of-type) {
            border-bottom: 1px solid rgba(0, 0, 0, 0.1);
          }
        }
      }
    }
  }

  & .detailsPlus {
    position: absolute;
    right: 24px;
    top: 50%;
    transform: translateY(-15px);
    width: 30px;
    height: 30px;
    border-radius: 500px;
    z-index: 1;
    cursor: pointer;
    transition: all 0.3s;

    & i {
      padding: 3px;
      transition: 0.3s;
    }

    &:not(.active) {
      background: #005bbb;
      &:hover {
        background: rgb(0, 66, 136);
      }
      & i {
        color: white;
      }
    }

    &.active {
      background: white;
      &:hover {
        background: #dadada;
      }
      & i {
        color: #005bbb;
        transform: scaleX(-1);
      }
    }
  }
}
</style>
