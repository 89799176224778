<template>
  <section>
    <mcma-spin-box :spinning="!operations" tip="Chargement des opérations..." :size="80" :full="true">
      <search v-model="searchValue" @search="search" />
      <div>
        <operation-card v-for="operation in operations" :key="operation.id" :operation="operation" :user="user"
          @goTo="(operation) => $parent.goTo(operation)" @edit="(operation) => $parent.edit(operation)"
          @addAdmin="(operation) => $parent.addAdmin(operation)"
          @delete="(operation) => $parent.deleteOperation(operation)"
          @disable="(operation) => $parent.disableOperation(operation)" />
      </div>
    </mcma-spin-box>
  </section>
</template>

<script>
import variables from "@/assets/scss/_variables.scss"
import Search from "./Search"
import OperationCard from "./OperationCard"
import Axios from "@/util/Axios"
import { mapActions, mapMutations, mapState } from "vuex"
import McmaSpinBox from "@/components/mcma/common/McmaSpinBox"

export default {
  name: "board",
  components: {
    Search,
    OperationCard,
    McmaSpinBox,
  },
  data() {
    return {
      searchValue: "",
      operations: null,
    }
  },
  computed: {
    ...mapState(["user"]),
    /*
    operations () {
      return [
        operations.operation1,
        operations.operation2
      ]
    }
    */
  },
  methods: {
    ...mapMutations(["setBreadcrumbAdd", "removeLastbreadcrumb"]),
    ...mapActions(["requestOperations"]),
    updateAllColor(colors) {
      const root = document.documentElement
      Object.keys(colors).forEach(async (property) => {
        root.style.setProperty(`${property}`, colors[property])
        root.style.setProperty(`${property}-h`, await this.$hexToHSL(colors[property], "hue"))
        root.style.setProperty(`${property}-s`, await this.$hexToHSL(colors[property], "saturation"))
        root.style.setProperty(`${property}-l`, await this.$hexToHSL(colors[property], "lightness"))
      })
    },
    initialize() {
      console.group("initialize@BoardSuperAdmin")
      console.log("user : %O", this.user)
      if (this.user.role === "SUPER_ADMIN") {
        Axios("get", "api/operations/?page=0&size=150").then((response) => {
          console.log("Operation : %O", response.data)
          this.operations = response.data._embedded.operations
        })
      } else {
        Axios("get", "api/operations/search/findByOperationsEquals?user=" + this.user.id).then((response) => {
          console.log(response.data)
          this.operations = response.data._embedded.operations
        })
      }
      this.operations = this.requestOperations()

      this.updateAllColor({
        "--primary-color": variables.primaryColor,
        "--secondary-color": variables.secondaryColor,
        "--tertiary-color": variables.tertiaryColor,
        "--heading-color": variables.headingColor,
        "--text-color": variables.textColor,
        "--text-color-secondary": variables.textColorSecondary,
      })
      console.groupEnd()
    },
    search() {
      console.log("Search !")
    },
  },
  watch: {
    selected() {
      console.group("selected@BoardIndex")
      console.groupEnd()
    },
  },
  created() {
    console.group("created@BoardIndex")
    this.$scrollToTop()
    this.removeLastbreadcrumb()
    this.setBreadcrumbAdd({
      name: "Administration des Opérations",
      action: () => {
        this.$router.push({
          name: "superadmin-board",
          params: {
            operationId: null,
            membreId: null,
          },
        })
      },
    })
    this.initialize()
    console.groupEnd()
  },
}
</script>

<style lang="scss" scoped></style>
