<template>
  <section>
    <ul class="collapsible" data-collapsible="expandable">
      <li v-for="(step, index) in steps" :key="index">
        <div class="collapsible-header" :class="step.active ? '' : 'disabled'">
          <span @click.stop>
            <input type="checkbox" :id="'step' + index + '_' + _uid" v-model="step.active" />
            <label :for="'step' + index + '_' + _uid"></label>
          </span>
          {{ step.name }}
        </div>
        <div class="collapsible-body">
          <table v-show="step.active">
            <tbody>
              <template v-for="(field, key) in step.fields">
                <tr :key="key" :class="!field.displayed ? 'notDisplayed' : ''">
                  <td>{{ field.type }}</td>
                  <td>
                    <span v-if="field.displayed"> actif &nbsp;</span>
                    <span v-else> inactif &nbsp;</span>
                    <input type="checkbox" :id="field.type + '_' + _uid" v-model="field.displayed" />
                    <label :for="field.type + '_' + _uid"></label>
                  </td>
                  <td :class="field.edit ? 'edited' : ''">
                    <span v-if="field.edit" class="textInput">
                      <input type="text" v-model="field.name" />
                      <i class="material-icons editBtn validation" @click="field.edit = null">check</i>
                      <i
                        class="material-icons editBtn cancel"
                        @click=";[(field.name = field.edit), (field.edit = null)]"
                        >close</i
                      >
                    </span>
                    <span v-else>
                      {{ field.name }}
                      <i class="material-icons editBtn" @click="field.edit = field.name">edit</i>
                    </span>
                  </td>
                  <td class="switch">
                    <label>
                      <input type="checkbox" v-model="field.required" />
                      <span class="lever"></span>
                      Champ requis
                    </label>
                  </td>
                  <td>
                    <i
                      class="material-icons lockBtn"
                      :class="field.lockData ? 'locked' : ''"
                      @click="field.lockData = !field.lockData"
                      v-tooltip="
                        field.lockData ? 'Rendre la saisie des données libre' : 'Restreindre la saisie des valeurs'
                      "
                      >{{ field.lockData ? "lock" : "lock_open" }}</i
                    >
                  </td>
                </tr>
                <tr :key="key + '_expand'" :class="field.lockData ? 'expanded' : 'hidden'">
                  <td style="width: 30%" colspan="4">
                    <div>
                      <div
                        class="chips"
                        :class="key + 'LockedValues'"
                        v-tooltip="'Entrez une valeur puis appuyez sur la touche entrée'"
                      ></div>
                      <div class="switch">
                        <label>
                          <input type="checkbox" v-model="field.radio" />
                          Liste déroulante
                          <span class="lever"></span>
                          Cases à cocher
                        </label>
                      </div>
                    </div>
                  </td>
                </tr>
              </template>
            </tbody>
          </table>
        </div>
      </li>
    </ul>
  </section>
</template>

<script>
import $ from "jquery"
import _ from "lodash"

export default {
  name: "services",
  props: ["value"],
  computed: {
    steps: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit(value)
      },
    },
  },
  mounted() {
    $(".collapsible").collapsible()
    _.each(this.steps, (step) => {
      _.each(step.fields, (field, key) => {
        $("." + key + "LockedValues").material_chip({
          data: _.map(field.lockedValues, (value) => {
            return {
              tag: value,
            }
          }),
          placeholder: "Entrez une valeur",
        })
        $("." + key + "LockedValues").on("chip.add", (e, chip) => {
          field.lockedValues.push(chip.tag)
        })
        $("." + key + "LockedValues").on("chip.delete", (e, chip) => {
          field.lockedValues.splice(field.lockedValues.indexOf(chip.tag), 1)
        })
      })
    })
  },
}
</script>

<style lang="scss" scoped>
.collapsible {
  box-shadow: none !important;
  border: 0 !important;
  margin: 0 !important;
  & li {
    & .collapsible-header {
      &.disabled {
        background: rgba(0, 0, 0, 0.1);
        color: rgba(0, 0, 0, 0.4);
        cursor: auto;
      }
    }
    & .collapsible-body {
      padding: 0 !important;
      & table {
        margin: 2rem !important;
      }
    }
    &:last-child {
      & .collapsible-header {
        border: 0 !important;
      }
      & .collapsible-body {
        border-top: 1px solid #ddd !important;
        border-bottom: 0 !important;
      }
    }
  }
}

tr {
  transition: 0.3s;
  transform-origin: top;
  & .chips {
    width: 50%;
    display: inline-block;
    margin: 0 !important;
  }
  &:hover {
    background: rgba(0, 0, 0, 0.05);
  }
  & td {
    min-width: 30px;
    padding: 5px !important;
    & .editBtn {
      cursor: pointer;
      display: inline-block;
      height: 30px;
      padding: 3px;
      border-radius: 15px;
      transition: 0.3s;
      &:hover {
        background: rgba(0, 0, 0, 0.1);
      }
    }
    &.edited {
      border-right: 60px solid transparent;
      & input {
        display: inline-block !important;
        min-width: 200px !important;
        margin: 0 !important;
      }
    }
    &:not(:last-child) {
      width: 1%;
      white-space: nowrap;
    }
    & .switch {
      display: inline-block;
    }
    & .lockBtn {
      display: inline-block;
      height: 30px;
      width: 30px;
      padding: 3px;
      cursor: pointer;
      border-radius: 15px;
      transition: 0.3s;
      background: #005bbb;
      color: white;
      &:hover {
        background: rgb(0, 102, 211);
      }
      &.locked {
        transform: scaleX(-1);
      }
    }
  }
  &.hidden td {
    padding: 0 !important;
    & > div {
      transition: 0.3s;
      height: 0 !important;
      overflow: hidden;
    }
  }
  &.expanded td > div {
    transition: 0.3s;
    overflow: hidden;
  }
}
</style>
