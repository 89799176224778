<template>
  <div class="app-header" :class="{ isLog: connected }">
    <div class="header-background" @dblclick="$scrollToTop()">
      <mcma-svg name="header_piece" />
    </div>
    <div class="header-container">
      <div class="left-content">
        <router-link :to="{ name: 'home' }" class="brand-logo">
          <img
            :src="$getLogoBySubDomain().logo.header"
            :alt="`${$getLogoBySubDomain().name} logo on Waika`"
            :class="`header-logo-scale-${$getLogoBySubDomain().scaleNumber}`"
          />
          <div class="slogan" v-if="$getLogoBySubDomain().slogan">
            {{ $getLogoBySubDomain().slogan }}
          </div>
        </router-link>
      </div>
      <div class="right-content">
        <a-menu v-model="current" mode="horizontal" v-if="connected">
          <!--          TODO : DESACTIVATED-->
          <!--          <a-menu-item key="tutorials">-->
          <!--            <router-link :to="{ name: 'tutorials' }">-->
          <!--              <mcma-icon type="question-circle" color="white" :faIcon="true"/>-->
          <!--              <span>-->
          <!--                M'accompagner-->
          <!--              </span>-->
          <!--            </router-link>-->
          <!--          </a-menu-item>-->
          <a-menu-item key="membre" v-if="membre">
            <router-link :to="{ name: 'contact-us' }">
              <mcma-icon type="users" color="white" :faIcon="true" />
              <span> Contact </span>
            </router-link>
          </a-menu-item>
          <!--            TODO : DESACTIVATED-->
          <!--          <a-sub-menu popupClassName="header-perimetre-sub-menu-popup"-->
          <!--                      v-if="membre">-->
          <!--            <span slot="title"-->
          <!--                  class="submenu-title-wrapper">-->
          <!--              <mcma-icon type="building" color="white" :faIcon="true" />-->
          <!--              <span>-->
          <!--                Mon Périmètre-->
          <!--              </span>-->
          <!--            </span>-->
          <!--            <a-menu-item key="sites" v-if="getFlow.includes('PERIMETRE')">-->
          <!--              <router-link :to="{ name: 'sites' }">-->
          <!--                Sites-->
          <!--              </router-link>-->
          <!--            </a-menu-item>-->
          <!--            <a-menu-item key="facturation" v-if="getFlow.includes('FACTURATION')">-->
          <!--              <router-link :to="{ name: 'groupements' }">-->
          <!--                Facturation-->
          <!--              </router-link>-->
          <!--            </a-menu-item>-->
          <!--            <a-menu-item key="interlocuteurs" v-if="getFlow.includes('SERVICES')">-->
          <!--              <router-link :to="{ name: 'interlocuteurs' }">-->
          <!--                Interlocuteurs-->
          <!--              </router-link>-->
          <!--            </a-menu-item>-->
          <!--            <a-menu-item key="services" v-if="getFlow.includes('SERVICES')">-->
          <!--              <router-link :to="{ name: 'services' }">-->
          <!--                Services-->
          <!--              </router-link>-->
          <!--            </a-menu-item>-->
          <!--            <a-menu-item key="documents" v-if="getFlow.includes('DOCUMENTS')">-->
          <!--              <router-link :to="{ name: 'factures' }">-->
          <!--                Documents-->
          <!--              </router-link>-->
          <!--            </a-menu-item>-->
          <!--            <a-menu-item key="pieces" v-if="getFlow.includes('DOCUMENTS')">-->
          <!--              <router-link :to="{ name: 'pieces-client' }">-->
          <!--                Pièces-->
          <!--              </router-link>-->
          <!--            </a-menu-item>-->
          <!--          </a-sub-menu>-->
          <a-sub-menu popupClassName="header-contact-sub-menu-popup">
            <span slot="title" class="submenu-title-wrapper">
              <mcma-icon type="user" color="white" :faIcon="true" />
              <span> Compte </span>
            </span>
            <a-menu-item key="update-account" :disabled="$route.name === 'users-detail'">
              <router-link
                :to="{
                  name: 'users-detail',
                  params: {
                    userId: user ? user.id : null,
                    action: 'my-account',
                  },
                }"
              >
                Modification du compte
              </router-link>
            </a-menu-item>
            <a-menu-item key="control-panel" v-if="membre && (user.role === 'ADMIN' || user.role === 'SUPER_ADMIN')">
              <a @click.prevent="goAdmin()"> Retour au panneau de contrôle </a>
            </a-menu-item>
            <a-menu-item key="disconnect">
              <a @click.prevent="disconnect()" class="red-color"> Deconnexion </a>
            </a-menu-item>
          </a-sub-menu>
        </a-menu>
      </div>
    </div>
    <div class="mcma-breadcrumb-block">
      <a-breadcrumb class="mcma-breadcrumb">
        <a-breadcrumb-item v-for="(item, index) in breadcrumb" :key="index + 1">
          <span
            @click="action(item)"
            :class="{ clickable: breadcrumb.length - 1 !== index }"
            :id="item.name === 'Périmètres de facturation' ? 'validGroupGetBack' : ''"
          >
            {{ item.name }}
          </span>
        </a-breadcrumb-item>
      </a-breadcrumb>
      <mcma-button
        name="Retour"
        :class="{ show: breadcrumb.length > 1 }"
        color="orange"
        leftIcon="arrow-left"
        :isFaIcon="true"
        @click="goBack"
      />
    </div>
    <mcma-multi-membre-fixed v-if="user && user.role === 'CLIENT'" />
    <mcma-konami-debug />
  </div>
</template>

<script>
import McmaIcon from "@/components/mcma/common/McmaIcon"
import McmaButton from "@/components/mcma/common/McmaButton"
import McmaMultiMembreFixed from "@/components/mcma/common/McmaMultiMembreFixed"
import McmaSvg from "@/components/mcma/common/McmaSvg"
import McmaKonamiDebug from "@/components/mcma/common/McmaKonamiDebug"
import { mapState, mapMutations, mapActions, mapGetters } from "vuex"

export default {
  name: "app-header",
  components: {
    McmaIcon,
    McmaButton,
    McmaMultiMembreFixed,
    McmaSvg,
    McmaKonamiDebug,
  },
  data() {
    return {
      current: ["perimetre"],
    }
  },
  computed: {
    ...mapState(["connected", "membre", "user", "breadcrumb"]),
    ...mapGetters(["getFlow"]),
  },
  methods: {
    ...mapMutations(["setState", "breadcrumbGoBack"]),
    ...mapActions(["disconnect"]),
    goAdmin() {
      this.setState({
        stateName: "membre",
        value: null,
      })
      this.$router.push({
        name: "home",
      })
    },
    action(item) {
      item.action(this)
    },
    goBack() {
      console.log("goBack@Header : %O", this.breadcrumb.length)
      if (this.breadcrumb.length > 1) {
        this.breadcrumbGoBack()
      }
    },
  },
}
</script>

<style lang="scss">
@import "./src/assets/scss/variables";
@import "./src/assets/scss/functions";

.app-header {
  position: sticky;
  top: 0;
  z-index: 200 !important;
  height: 130px;
  width: 100%;
  /*overflow: hidden;*/
  &.isLog {
    box-shadow: $box-shadow;
    background: $white;
  }
  .header-background {
    position: absolute;
    top: 0;
    left: 0;
    height: 100px;
    width: 100%;
    background-repeat: no-repeat;
    background-size: auto 100%;
    overflow: visible;
    &:before {
      content: "";
      position: absolute;
      z-index: -1;
      left: 0;
      top: 0;
      width: 100%;
      height: 80px;
      background-color: var(--secondary-color, $orange);
    }
    > .mcma-svg {
      height: 100px;
      width: 164px;
      > svg {
        height: 100%;
        width: 100%;
      }
    }
  }
  .header-container {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    .left-content {
      height: 80px;
      .brand-logo {
        position: relative;
        z-index: 50;
        display: flex;
        align-items: center;
        height: 100%;
        img {
          margin: auto auto 0 10px;
          height: 70px;
          overflow: hidden;
          filter: brightness(0) invert(1);
        }
        .slogan {
          color: $white;
          font-size: 20px;
          font-style: italic;
          margin: auto auto 20px 20px;
        }
      }
    }
    .right-content {
      height: 80px;
      .ant-menu {
        height: 100%;
        display: flex;
        flex-flow: row nowrap;
        align-items: stretch;
        .ant-menu-item {
          min-width: 100px;
          display: flex;
          background: var(--secondary-color, $orange);
          color: $white;
          border-bottom: 0 solid $white;
          .mcma-icon {
            display: flex;
            .content {
              margin: auto;
              height: 35px;
              width: 100%;
              .icon-background {
                margin: 0;
                height: 100%;
                width: 100%;
                font-size: 35px;
              }
            }
          }
          &:hover {
            background: darkenVar(var(--secondary-color), 3);
          }
          &.ant-menu-item-selected {
            box-shadow: $inset-box-shadow;
            background: darkenVar(var(--secondary-color), 3);
          }
          a {
            margin: auto;
            display: flex;
            flex-flow: column nowrap;
            justify-content: center;
            color: $white;
            i {
              font-size: 35px;
              margin-right: 0;
            }
            span {
              height: 28px;
              margin-top: -7px;
            }
          }
        }
        .ant-menu-submenu {
          min-width: 100px;
          padding: 0 20px;
          background: var(--secondary-color, $orange);
          border-bottom: 0 solid $white !important;
          color: $white !important;
          text-align: center;
          &:hover {
            background: darkenVar(var(--secondary-color), 3);
          }
          &.ant-menu-submenu-selected {
            box-shadow: $inset-box-shadow;
            background: darkenVar(var(--secondary-color), 3);
          }
          .ant-menu-submenu-title {
            height: 100%;
            padding: 0;
            color: $white;
            .submenu-title-wrapper {
              height: 100%;
              display: flex;
              flex-flow: column nowrap;
              justify-content: center;
              i {
                font-size: 35px;
                margin-right: 0;
              }
              span {
                height: 28px;
                margin-top: -7px;
              }
              .mcma-icon {
                display: flex;
                .content {
                  margin: auto;
                  height: 35px;
                  width: 100%;
                  .icon-background {
                    margin: 0;
                    height: 100%;
                    width: 100%;
                    font-size: 35px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  .mcma-breadcrumb-block {
    height: 50px;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center;
    .mcma-breadcrumb {
      display: flex;
      flex-grow: 2;
      margin: auto 20px auto 150px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      > div {
        > span:last-child {
          .ant-breadcrumb-link {
            color: var(--text-color, $text-color);
          }
        }
      }
      .ant-breadcrumb-link {
        font-size: 14px !important;
        .clickable {
          cursor: pointer;
          &:hover {
            color: var(--secondary-color, $orange);
          }
        }
      }
      .ant-breadcrumb-separator {
        font-size: 14px !important;
      }
    }
    .mcma-button {
      margin: auto 10px auto auto;
      transition: transform 0.5s ease;
      transform: translateX(150px);
      &.show {
        transform: translateX(0);
      }
      button {
        padding: 3px 12px 0 12px;
        height: 25px;
        .name {
          top: -1px !important;
        }
      }
    }
  }
}
.ant-menu-submenu-content {
  .ant-menu-item {
    background: $white !important;
    color: var(--heading-color, $heading-color);
    > a {
      &:hover,
      &:active {
        color: var(--secondary-color, $orange);
      }
    }
  }
  .ant-menu-item-selected {
    a {
      color: var(--heading-color, $heading-color);
    }
  }
}
.header-contact-sub-menu-popup {
  left: inherit !important;
  right: 7px;
}
.header-perimetre-sub-menu-popup {
  left: inherit !important;
  right: 87px;
}
</style>
