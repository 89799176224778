<template>
  <main class="container">
    <section v-if="membre.statService === 'COMPLETED'" class="card">
      <article class="card-content">
        <div class="card-title">Validation des services.</div>
        <blockquote>
          Êtes-vous sur de vouloir certifier les informations de vos services ?
          <br />
          Vous ne pourrez plus modifier ceux-ci, ni en ajouter, via cette application après validation.
        </blockquote>
      </article>
      <center class="card-action">
        <button type="button" class="waves-effect waves-light btn light-green" @click="validate">
          Oui, je les certifie.
        </button>
        <router-link type="button" class="waves-effect waves-light btn blue darken-2" :to="{ name: 'services' }">
          Non, je ne veux pas encore les certifier.
        </router-link>
      </center>
    </section>
    <section v-else class="card-panel white-text deep-orange">Les services ne peuvent pas être certifiés.</section>
  </main>
</template>

<script>
import Mixin from "../Mixin"
import { mapState, mapMutations } from "vuex"
import Axios from "@/util/Axios"
import Materialize from "materialize-css"

export default {
  name: "validation-services",
  mixins: [Mixin],
  data() {
    return {
      flow: "SERVICES",
    }
  },
  computed: {
    ...mapState(["membre", "user", "operation"]),
  },
  methods: {
    validate() {
      Axios("patch", "api/membres/" + this.membre.id, {
        serviceCertified: true,
      }).then((response) => {
        Materialize.toast("Services Validé !", 4000, "white-text lime")
        this.$router.push({
          name: "services",
        })
      })
    },
    ...mapMutations(["setBreadcrumbAdd"]),
  },
  created() {
    this.setBreadcrumbAdd({
      name: "Validation",
      action: () => {},
    })
  },
}
</script>

<style lang="scss" scoped></style>
