<template>
  <a-col :span="colSpan" :class="colClass" v-if="(!debug && hasLoaded && canDisplayField) || debug">
    <a-form-item
      :label="!hasSlot('information') ? getLabel : undefined"
      :extra="extraLabel"
      class="mcma-field"
      :class="[
        type + ' ' + randomClassName,
        {
          'label-information': hasSlot('information'),
        },
      ]"
    >
      <mcma-code>
        getFieldName: {{ getFieldName }} flowIsAvailable: {{ flowIsAvailable }} hasLoaded:
        {{ hasLoaded }} canDisplayField: {{ canDisplayField }} dynField: {{ dynField }} operation:
        {{ operation.name }} getFlow: {{ getFlow }} getPanel: {{ getPanel }} getField: {{ getField }} value:
        {{ value }} defaultList: {{ defaultList }}
      </mcma-code>

      <div slot="label" v-if="hasSlot('information')">
        <span class="infoLabel">
          {{ getLabel }}
        </span>
        <div class="mcma-informations">
          <slot name="information"></slot>
        </div>
      </div>

      <a-input
        v-if="type === 'input'"
        :placeholder="placeholder"
        @change="validFields([getFieldName], 'input', numberRequired)"
        :maxLength="getMaxLength"
        :minlength="getMinLength"
        :disabled="disabled"
        :class="{ uppercase: uppercase, capitalize: capitalize }"
        v-decorator="[
          getFieldName,
          {
            normalize: onTransform,
            rules: [
              {
                required: isRequired,
                message: getRequiredMessage,
              },
              ...moreRule,
            ],
          },
        ]"
      />
      <a-input-password
        v-if="type === 'password'"
        :placeholder="placeholder"
        @change="validFields([getFieldName], 'input', numberRequired)"
        :maxLength="getMaxLength"
        :minlength="getMinLength"
        :disabled="disabled"
        :class="{ uppercase: uppercase, capitalize: capitalize }"
        v-decorator="[
          getFieldName,
          {
            normalize: onTransform,
            rules: [
              {
                required: isRequired,
                message: getRequiredMessage,
              },
              ...moreRule,
            ],
          },
        ]"
      />
      <a-textarea
        v-if="type === 'textarea'"
        autoSize
        :placeholder="placeholder"
        @change="validFields([getFieldName], 'input', numberRequired)"
        :maxLength="getMaxLength"
        :minlength="getMinLength"
        :style="`height:${height}px;max-height:${height}px`"
        :disabled="disabled"
        :class="{ uppercase: uppercase, capitalize: capitalize }"
        v-decorator="[
          getFieldName,
          {
            rules: [
              {
                required: isRequired,
                message: getRequiredMessage,
              },
              ...moreRule,
            ],
          },
        ]"
        v-number-only="numberRequired"
      />
      <a-input-number
        v-if="type === 'number'"
        :placeholder="placeholder"
        @change="validFields([getFieldName], 'number', numberRequired)"
        :min="getMinLength"
        :max="getMaxLength"
        :disabled="disabled"
        :step="step"
        v-decorator="[
          getFieldName,
          {
            rules: [
              {
                required: isRequired,
                message: getRequiredMessage,
              },
              ...moreRule,
            ],
          },
        ]"
      />
      <a-select
        v-if="type === 'select'"
        showSearch
        :size="fieldSize"
        :filterOption="selectFilterOption"
        @change="validFields([getFieldName], 'select')"
        :class="{ uniqValue: getSelectFieldPossibleKeys.length === 1, shine: shine }"
        :disabled="disabled || getSelectFieldPossibleKeys.length === 1"
        :placeholder="getDisableValue"
        v-decorator="[
          getFieldName,
          {
            rules: [
              {
                required: isRequired,
                message: getRequiredMessage,
              },
            ],
          },
        ]"
      >
        <template v-if="hasSlot()">
          <slot></slot>
        </template>
        <template v-else-if="flowIsAvailable || defaultList">
          <a-select-option
            v-for="(key, index) in getSelectFieldPossibleKeys"
            :key="generateRandomUniqKey(index)"
            :value="key"
            :disabled="disabledOptions"
          >
            {{ flowIsAvailable ? $getValueByKey(key) : key }}
          </a-select-option>
        </template>
        <template v-else>
          <a-select-option :value="null" disabled>
            <span>Aucune liste de donnée n'est renseigné</span>
          </a-select-option>
        </template>
      </a-select>

      <div v-if="type === 'switch'" class="switch-block">
        <a-switch
          v-if="getSwitchList && getSwitchList.length === 2"
          :style="`width:${33 + getLengthOfBiggerString(getSwitchList) * 9}px`"
          :checkedChildren="getSwitchList[0]"
          :checked="form.getFieldValue(getFieldName)"
          :unCheckedChildren="getSwitchList[1]"
          :defaultChecked="getSwitchDefaultValue"
          @change="switchChange"
          :disabled="disabled"
          v-decorator="[getFieldName]"
        />
        <div v-else>
          <p>les Champs de type <strong>switch</strong> nécessite d'avoir 2 réponses uniquement</p>
        </div>
      </div>

      <div v-if="type === 'radio'" class="radio-block">
        <a-radio-group
          v-if="getRadioList && getRadioList.length > 0"
          :disabled="disabled"
          v-decorator="[getFieldName]"
          @change="validFields([getFieldName], 'radio')"
        >
          <a-radio-button v-for="(value, index) in getRadioList" :key="generateRandomUniqKey(index)" :value="value">
            {{ $getValueByKey(value) }}
          </a-radio-button>
        </a-radio-group>
        <div v-else>
          <p>les Champs de type <strong>radio</strong> nécessite d'avoir au moins une valeur</p>
        </div>
      </div>

      <div v-if="type === 'file-upload'">
        <div class="sentence">
          <p class="principal-message inline">
            {{ description }}
          </p>
        </div>
        <div class="upload-box" :class="{ 'has-file': filesNames }">
          <a-button
            v-decorator="[
              getFieldName,
              {
                rules: [
                  {
                    required: isRequired,
                    message: getRequiredMessage,
                  },
                ],
              },
            ]"
            @click.prevent="action()"
            :color="color"
          >
            Téléversement</a-button
          >
          <div class="file-name">
            {{ filesNames ? filesNames : "" }}
          </div>
        </div>
      </div>

      <div v-if="type === 'checkbox'" class="checkbox-block">
        <a-checkbox-group
          v-if="getRadioList && getRadioList.length > 0"
          :disabled="disabled"
          v-decorator="[
            getFieldName,
            {
              rules: [
                {
                  required: isRequired,
                  message: getRequiredMessage,
                },
              ],
            },
          ]"
          @change="validFields([getFieldName], 'checkbox')"
        >
          <a-row>
            <a-col :span="colSubSpan" v-for="(value, index) in getRadioList" :key="generateRandomUniqKey(index)">
              <a-checkbox :value="value">
                {{ $getValueByKey(value) }}
              </a-checkbox>
            </a-col>
          </a-row>
        </a-checkbox-group>
      </div>

      <a-date-picker
        v-if="type === 'date'"
        format="DD/MM/YYYY"
        :placeholder="placeholder"
        :disabledDate="disabledDate"
        @change="dateChange"
        :defaultPickerValue="getDefaultPickerValue"
        :disabled="disabled"
        v-decorator="[
          getFieldName,
          {
            rules: [
              {
                required: isRequired,
                message: getRequiredMessage,
              },
            ],
          },
        ]"
      />
    </a-form-item>
  </a-col>
</template>

<script>
import { getNumberFromString, getDecimalFromString } from "@/util/Helpers"
import moment from "moment"
import McmaCode from "@/components/mcma/common/McmaCode"
import { mapState } from "vuex"
import GenericFieldTable from "@/components/mcma/common/Fields/Jsons/GenericFieldTable.json"
import { getDynamiqueFields } from "@/util/Helpers/DynamiqueFields"
import _ from "lodash"

export default {
  name: "McmaField",
  components: {
    McmaCode,
  },
  props: {
    form: Object,
    wasSubmit: Boolean,
    fieldName: String, // bypass fieldName
    label: String, // add default label to bypass label
    type: {
      type: String,
      default: "input",
    },
    defaultValue: {
      type: [String, Object, Boolean, Number, Array, Date],
      default: undefined,
    },
    value: {
      type: [String, Object, Boolean, Number, Array, Date],
      default: undefined,
    },
    numberOnly: [Boolean, String], // indique le type de valeur saisie acceptée
    prefix: Object, // ajoute un prefix à la saisie
    disabled: Boolean, // indique si le champ est actif ou pas
    getFlow: String, // indique le flow du DynamiqueField example: property::value
    getPanel: String, // indique le panel du DynamiqueField example: property::value
    getField: String, // indique le Field du DynamiqueField example: property::value
    disableValue: String, // add default disable value like "selected field"
    defaultList: Array, // add json array to bypass the list
    capitalize: Boolean, // capitalize options
    disabledOptions: Boolean, // methode permetant de desactiver certaine value dans le cas des lockvalue
    uppercase: Boolean, // retourne la valeur saisie en UperCase
    required: Boolean, // indique si le champ est requis ou pas /!\ la conf de l'opération est prioritaire
    maxlength: Number, // longeur max du champ
    minlength: Number, // longeur min du champ
    rule: String, // indique la regle de validation du champ
    extraLabel: String, // label présenté sous le champ
    disableDateBefore: [String, Object, Number],
    defaultPickerValue: [String, Object, Number],
    placeholder: String,
    extraValidationFields: Array,
    switchColors: Array,
    step: {
      type: Number,
      default: 1,
    },
    height: Number,
    requiredMessage: String,
    colSpan: {
      type: Number,
      default: 12,
    },
    colSubSpan: {
      type: Number,
      default: 12,
    },
    colOffset: Number,
    colClass: String,
    append: String,
    prepend: String,
    reverse: Boolean,
    fieldSize: {
      type: String,
      default: "default",
    },
    filesNames: String,
    color: String,
    description: String,
    shine: Boolean,
  },
  data() {
    return {
      dynField: {},
      timer: null,
      needNumber: false,
      max: false,
      min: false,
      moreRule: {},
      randomClassName: null,
    }
  },
  computed: {
    ...mapState(["operation", "debug"]),
    getRule() {
      return this.rule
    },
    hasSlot() {
      return (name = "default") => {
        return !!this.$slots[name] || !!this.$scopedSlots[name]
      }
    },
    generateRandomUniqKey() {
      return (index) => {
        return `_mcma_key_${index}_${Math.random().toString(36).substr(2, 9)}`
      }
    },
    getCamel() {
      return (value) => {
        return _.camelCase(value)
      }
    },
    getCapitalize() {
      return (value) => {
        return this.$options.filters.capitalize(value)
      }
    },
    getInitialSelectValue() {
      //BUG ICI --> ou est set dynfield.value ? dans le cas d'un import de site en masse, quand on retourne directement dans le formulaire de site, dynfield.value n'est pas null et donc on vient écraser la vraie donnée (sur type = fluid)
      let value
      if (this.flowIsAvailable && this.dynField && this.dynField.value != null && this.dynField.type != "fluid") {
        value = this.dynField.value
      } else if (this.getFieldName && this.form.getFieldValue(this.getFieldName) != null) {
        value = this.form.getFieldValue(this.getFieldName)
      } else if (this.defaultValue != null) {
        value = this.defaultValue
      } else if (this.getSelectFieldPossibleKeys.length === 1) {
        value = this.getSelectFieldPossibleKeys[0]
      }
      return typeof value === "number" ? value.toString() : value
    },
    getRequiredMessage() {
      if (this.requiredMessage) {
        return this.requiredMessage
      }
      let name = this.getLabel || this.getFieldName
      switch (this.type) {
        case "input":
        case "textarea":
        case "password":
          return name ? `Merci de renseigner le ${name.toLowerCase()}` : `Merci de renseigner le champs`
        case "select":
        case "date":
          return name ? `Merci de sélectionner le ${name.toLowerCase()}` : `Merci de sélectionner le champs`
      }
    },
    getDisableValue() {
      return this.disableValue || this.getLabel
    },
    numberRequired() {
      if (this.numberOnly && typeof this.numberOnly === "string") {
        return this.numberOnly
      }
      return this.numberOnly || this.needNumber
    },
    isRequired() {
      return this.required || (this.flowIsAvailable ? this.dynField.required : false)
    },
    getMaxLength() {
      return this.type === "number" ? parseFloat(this.maxlength || this.max) : this.maxlength || this.max || undefined
    },
    getMinLength() {
      return this.type === "number" ? parseFloat(this.minlength || this.min) : this.minlength || this.min || undefined
    },
    getSwitchList() {
      let arrayValue = this.flowIsAvailable ? this.dynField.lockedValues : this.defaultList
      arrayValue = arrayValue.map((item) => {
        item = this.append ? `${this.append}${item}` : item
        item = this.prepend ? `${item}${this.prepend}` : item
        return item
      })
      return this.reverse ? arrayValue.reverse() : arrayValue
    },
    getRadioList() {
      let arrayValue = this.flowIsAvailable ? this.dynField.lockedValues : this.defaultList
      return this.reverse ? arrayValue.reverse() : arrayValue
    },
    getSwitchDefaultValue() {
      if (this.flowIsAvailable && this.dynField.lockedValues && this.dynField.lockedValues.length === 2) {
        if (this.getFieldName && this.form.getFieldValue(this.getFieldName) != null) {
          return !!this.form.getFieldValue(this.getFieldName)
        } else if (this.dynField.value != null) {
          return !!this.dynField.value
        } else if (this.defaultValue != null) {
          return !!this.defaultValue
        }
      } else if (typeof this.defaultValue === "boolean") {
        return this.defaultValue
      }
      return this.reverse
    },
    getRadioDefaultValue() {
      if (this.flowIsAvailable && this.dynField.lockedValues && this.dynField.lockedValues.length > 0) {
        if (this.getFieldName && this.form.getFieldValue(this.getFieldName) != null) {
          return this.form.getFieldValue(this.getFieldName)
        } else if (this.dynField.value != null) {
          return this.dynField.value
        } else if (this.defaultValue != null) {
          return this.defaultValue
        }
      }
      return this.getRadioList[0]
    },
    canDisplayField() {
      return this.flowIsAvailable ? this.dynField.displayed : true
    },
    getLengthOfBiggerString() {
      return (strings) => {
        let length = 0
        strings.forEach((string) => {
          length = string && string.length > length ? string.length : length
        })
        return length
      }
    },
    getSelectFieldPossibleKeys() {
      if (this.defaultList) {
        return this.$sortGenericFieldASC(this.defaultList)
      }
      const genericObject = GenericFieldTable[this.dynField[this.getFieldFlowProperty]]
      const allKeys = genericObject ? Object.keys(genericObject) : []
      const lockedValues = this.dynField.lockedValues || []
      if (lockedValues.length > 0) {
        if (allKeys.length > 0) {
          const filter =
            lockedValues.filter(function (val) {
              return allKeys.indexOf(val) !== -1
            }) || []
            
          return filter.length > 0 ? this.$sortGenericFieldASC(filter) : this.$sortGenericFieldASC(lockedValues)
        }
        return this.$sortGenericFieldASC(lockedValues)
      }
      return this.$sortGenericFieldASC(allKeys)
    },
    screamingSnakeCase() {
      return (value) => {
        return this.$options.filters.screamingSnakeCase(value)
      }
    },
    getLabel() {
      return this.label || this.dynField.name
    },
    getFieldFlowProperty() {
      if (this.flowIsAvailable) {
        const property = this.getField.split("::") || []
        if (property.length === 2) {
          return property[0]
        }
      }
      return null
    },
    getFieldName() {
      if (this.fieldName) {
        return this.fieldName
      } else if (this.flowIsAvailable) {
        return this.dynField[this.getFieldFlowProperty]
      }
    },
    flowIsAvailable() {
      return this.getFlow && this.getPanel && this.getField && !_.isEmpty(this.dynField)
    },
    hasLoaded() {
      if (this.getFlow && this.getPanel && this.getField) {
        return !_.isEmpty(this.dynField)
      }
      return true
    },
    getDefaultPickerValue() {
      return this.defaultPickerValue ? moment(this.defaultPickerValue) : moment()
    },
  },
  mounted() {
    this.dynField =
      getDynamiqueFields(this.operation, this.getFlow, this.getPanel, this.getField, this.value, this.defaultList) || {}
    this.checkErrorOnMouted()
    this.prepareField()
    this.getMoreRules()
    this.generateRandomIdName()
    if (this.type === "switch") {
      this.applySwitchColor(this.getSwitchDefaultValue)
    }
  },
  methods: {
    action() {
      this.$emit("click")
    },
    parseValue(value) {
      if (this.getFieldName) {
        value = value || _.get(this.form.getFieldsValue(), this.getFieldName)
        if (this.numberRequired) {
          if (this.numberRequired === "decimal") {
            value = getDecimalFromString(value) || value
          } else {
            value = getNumberFromString(value)
          }
        }
        if (value != null) {
          return value
        }
      }
      return this.defaultValue
    },
    fieldValueObjectOnSet(fieldName, value) {
      let result = {}
      if (fieldName) {
        if (fieldName.includes(".")) {
          _.set(result, fieldName, value)
        } else {
          result[fieldName] = value
        }
      }

      return value || value != null || value === false ? result : {}
    },
    prepareField() {
      this.form.getFieldDecorator(this.getFieldName)
      let valueToSet = this.parseValue()
      if (this.type === "date" && typeof valueToSet === "number") {
        valueToSet = moment(valueToSet)
      } else if (this.type === "switch") {
        valueToSet = this.getSwitchDefaultValue
      } else if (this.type === "radio") {
        valueToSet = this.getRadioDefaultValue
      } else if (this.type === "select") {
        valueToSet = this.getInitialSelectValue
      }
      const fieldObject = this.fieldValueObjectOnSet(this.getFieldName, valueToSet)
      this.form.setFieldsValue(fieldObject)
    },
    dateChange(date) {
      this.$nextTick(() => {
        this.form.setFieldsValue({
          [this.getFieldName]: date,
        })
      })
    },
    checkErrorOnMouted() {
      if (!this.getLabel && !this.placeholder) {
        console.log(
          `%c [MCMA-FIELD] : Aucun label, ni placeholder n'est definie`,
          "background:red ; padding: 5px 7px 4px 0px; border-radius: 3px;  color: #FFFFFF"
        )
      }
      if (!this.getFieldName) {
        console.log(
          `%c [MCMA-FIELD] : Aucun fieldName n'est definie pour ${this.getLabel}`,
          "background:red ; padding: 5px 7px 4px 0px; border-radius: 3px;  color: #FFFFFF"
        )
      }
      if (this.getFlow && this.getPanel && this.getField && _.isEmpty(this.dynField)) {
        console.log(
          `%c [MCMA-FIELD] : Aucun dynField est vide malgré qu'il soit renseigné`,
          "background:red ; padding: 5px 7px 4px 0px; border-radius: 3px;  color: #FFFFFF",
          this.getFlow,
          this.getPanel,
          this.getField
        )
      }
    },
    updateValue(value) {
      this.$nextTick(() => {
        this.$emit("change", value)
      })
    },
    switchChange(check) {
      console.log(check)
      this.applySwitchColor(check)
      this.$emit("change", check)
    },
    onTransform(value) {
      return value ? this.parseValue(value) : value
    },
    validFields(keys, type, isNumber) {
      this.$nextTick(() => {
        keys.map((key) => {
          // this.form.setFieldsValue(this.fieldValueObjectOnSet(key, this.parseValue()));
          this.$emit("change", this.parseValue())
        })
        if (this.extraValidationFields && this.extraValidationFields.length > 0) {
          this.extraValidationFields.forEach((item) => {
            keys.push(item)
          })
        }
        if (!this.wasSubmit) {
          this.form.validateFields(keys, (errors, fieldsValue) => {
            if (!errors || (errors && !errors[this.getFieldName])) {
              this.$emit("isOk", fieldsValue[this.getFieldName])
            }
          })
        } else {
          this.form.validateFields((errors, fieldsValue) => {
            if (!errors || (errors && !errors[this.getFieldName])) {
              this.$emit("isOk", fieldsValue[this.getFieldName])
            } else {
              this.$emit("onError", errors)
            }
          })
        }
      })
    },
    getMoreRules() {
      switch (this.rule) {
        case "isZipCode":
          this.moreRule = {
            pattern: /(?:0[1-9]|[13-8][0-9]|2[ab1-9]|9[0-5])(?:[0-9]{3})?|9[78][1-9](?:[0-9]{2})?/,
            message: "Votre code postal n'est pas valide (ex: 33000)",
          }
          this.max = 6
          this.needNumber = true
          break
        case "isEmail":
          this.moreRule = {
            pattern:
              /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
            message: "Votre email n'est pas valide (ex: john@gmail.com)",
          }
          break
        case "isPhone":
          this.moreRule = {
            pattern: /0[0-9]([ .]?[0-9]{2}){4}/,
            message: "Votre numéro de téléphone n'est pas valide (ex: 01 02 03 04 05)",
          }
          this.max = 20
          this.needNumber = true
          break
        case "isSiret":
          this.moreRule = {
            pattern: /^[0-9]{14}$/,
            message: "Votre numéro de siret n'est pas complet (ex: 12345678912345)",
          }
          this.max = 14
          this.needNumber = true
          break
        case "isSiren":
          this.moreRule = {
            pattern: /^[0-9]{9}$/,
            message: "Votre numéro de siren n'est pas complet (ex: 123456789)",
          }
          this.max = 9
          this.needNumber = true
          break
        case "isAPE":
          this.moreRule = {
            pattern: /^[0-9]{4}[A-Z]$/,
            message: "Votre code APE n'est pas valide (ex: 6391Z)",
          }
          this.max = 5
          break
        case "isNAF":
          this.moreRule = {
            pattern: /^[0-9]{4}[A-Z]$/,
            message: "Votre code NAF n'est pas valide (ex: 6391Z)",
          }
          this.max = 5
          break
        case "isRAE":
          this.moreRule = {
            pattern: /^[0-9]{14}$/,
            message: "Votre RAE n'est pas valide (ex: 12345678912345)",
          }
          this.max = 14
          break
        case "isCodeGroupment":
          this.moreRule = {
            pattern: /^[ a-zA-Z0-9]{1,39}$/,
            message:
              "Ce champ n'est pas valide il doit contenir 39 caratètres alphanumérique maximum (ex: CE CHAMP 1 EST BON)",
          }
          this.max = 14
          break
        case "isELD_RAE":
          this.moreRule = {
            pattern: /^.{1,45}$/,
            message: "Votre RAE (Distributeur) n'est pas valide",
          }
          this.max = 45
          break
        case "isIban":
          this.moreRule = {
            pattern: /^(FR[0-9]{2}[0-9]{5}[0-9]{5}[A-Z0-9]{11}[0-9]{2})$|^(((?!FR)[A-Z]{2}[ -]?[0-9]{2})(?=(?:[-]?[A-Z0-9]){9,30}$)((?:[-]?[A-Z0-9]{3,5}){2,7})([-]?[A-Z0-9]{1,3})?)$/,
            //pattern:
             // /^([A-Z]{2}[ -]?[0-9]{2})(?=(?:[-]?[A-Z0-9]){9,30}$)((?:[-]?[A-Z0-9]{3,5}){2,7})([-]?[A-Z0-9]{1,3})?$/,
            message: "Votre code IBAN n'est pas valide (ex: FR8937040044053201307366444)",
          }
          //set max 27 pour IBAN FR --> revoir peut-être le pattern pour l'adapter au mieux et éviter le max.
          //this.max = 27
          break
        case "isCodeInsee":
          this.moreRule = {
            pattern: /^[0-9]{5}$/,
            message: "Votre code isee n'est pas valide (ex: 12345)",
          }
          this.max = 5
          this.needNumber = true
          break
        case "isPCE":
          this.moreRule = {
            pattern: /^[0-9]{14}|GI[0-9]{6}$/,
            message: "Votre PCE n'est pas valide (ex: 12345678901234 ou GI123456)",
          }
          this.max = 14
          break
        case "isUCANSS":
          this.moreRule = {
            pattern: /.*/
          }
          this.max = 45
          break
        case "isELD_PCE":
          this.moreRule = {
            pattern: /^.{1,45}$/,
            message: "Votre PCE (Distributeur) n'est pas valide",
          }
          this.max = 45
          break
        case "isConfirmPassword":
          this.moreRule = {
            validator: (rule, value) => {
              const confirmPassword = this.form.getFieldValue(this.extraValidationFields[0])
              if (value && confirmPassword) {
                return value.trim() === confirmPassword.trim()
              }
              return true
            },
            message: "Les mots de passes ne sont pas identique",
          }
          break
        default:
          this.moreRule = {}
      }
    },
    disabledDate(current) {
      if (this.disableDateBefore === "today") {
        // Can not select days before today
        return current < moment().endOf("day").subtract(1, "day")
      } else if (this.disableDateBefore === "todayInclude") {
        // Can not select days before today and today
        return current && current < moment().endOf("day")
      } else if (typeof this.disableDateBefore === "number" || typeof this.disableDateBefore === "object") {
        return current && current < moment(this.disableDateBefore).add(1, "day")
      }
      return false
    },
    generateRandomIdName() {
      this.randomClassName = `_mcma_${Math.random().toString(36).substr(2, 9)}`
    },
    applySwitchColor(check) {
      if (this.switchColors && this.switchColors.length > 0) {
        let switchColors = this.reverse ? this.switchColors.reverse() : this.switchColors
        setTimeout(() => {
          const antSwitch = document.querySelector(`.switch.${this.randomClassName} .switch-block .ant-switch`)
          const colorToAdd = check ? switchColors[0] : switchColors[1]
          if (colorToAdd) {
            antSwitch.classList.add(`${colorToAdd}-bg`)
          }
        })
      }
    },
    selectFilterOption(input, option) {
      return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
    },
  },
  watch: {
    getRule(newValue) {
      if (newValue) {
        this.getMoreRules()
      } else {
        this.moreRule = {}
        this.max = undefined
        this.needNumber = undefined
      }
    },
  },
}
</script>

<style lang="scss">
@import "./src/assets/scss/variables";
@import "./src/assets/scss/functions";
.shine {
  .ant-select-selection {
    background-color: rgba(231, 229, 217, 0.644);
    border: solid 2px;
  }
}
.ant-select-dropdown-menu-item {
  &:first-letter {
    text-transform: capitalize;
  }
}
.ant-select-disabled {
  &.uniqValue {
    background: #fafafa;
    color: rgba(0, 0, 0, 0.65);
    .ant-select-selection {
      background: #fafafa;
    }
  }
}

.mcma-field {
  margin: 0;
  &.label-information {
    .ant-form-item-label {
      width: 100%;
      text-align: left;
      > label {
        &.ant-form-item-required {
          display: flex;
          &:before {
            position: absolute;
            left: 0;
            top: 12px;
          }
          .infoLabel {
            margin-left: 9px;
          }
        }
        > div {
          > .infoLabel {
            &:after {
              content: ":";
              position: relative;
              top: -0.5px;
              margin: 0 8px 0 -2px;
            }
          }
          > .mcma-informations {
            color: rgba(0, 0, 0, 0.65);
            font-size: 14px;
            line-height: 21px;
            white-space: normal;
            margin-bottom: 12px;
          }
        }
        &:after {
          display: none;
        }
      }
    }
  }
  &.input {
  }
  &.select {
    .ant-select-selection-selected-value {
      &:first-letter {
        text-transform: capitalize;
      }
    }
  }
  &.checkbox {
    .checkbox-block {
      .ant-row {
      }
    }
  }
  &.date {
  }
  &.radio {
    display: flex;
    flex-flow: row nowrap;
    .radio-block {
      overflow: visible;
    }
  }
  &.switch {
    display: flex;
    flex-flow: row nowrap;
    .switch-block {
      .ant-form-item-control {
        position: initial;
        .ant-form-extra {
          position: absolute;
          left: 0;
        }
      }
      .ant-switch {
        background-color: var(--error-color, $error-color);
        &.ant-switch-checked {
          background-color: var(--success-color, $success-color);
        }
        .ant-switch-inner {
          position: relative;
          top: 1px;
          font-weight: 900;
        }
      }
      .ant-radio-group {
        box-shadow: inherit;
        line-height: unset;
      }
    }
  }
  .ant-form-item-label {
    &:first-letter {
      text-transform: capitalize;
    }
  }
}
</style>
