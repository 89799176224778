<template>
  <article class="input-field">
    <input id="codePostal" type="text" required v-model="field" ref="field" class="validate" />
    <label :class="{ active: field }" for="codePostal">
      <font-awesome-icon icon="asterisk" class="red-text" />
      Code postal
    </label>
  </article>
</template>

<script>
import Mixin from "./Mixin"

export default {
  name: "code-postal",
  mixins: [Mixin],
  methods: {
    guardian() {
      if (this.field && this.field.length && !/^[0-9]{5}$/.test(this.field)) {
        this.$refs.field.setCustomValidity("Mauvais format de code postal")
      } else {
        this.$refs.field.setCustomValidity("")
      }
    },
  },
  watch: {
    field() {
      this.guardian()
    },
  },
  mounted() {
    this.guardian()
  },
}
</script>

<style lang="scss" scoped></style>
