<template>
  <section>
    <div class="container">
      <a-row type="flex" :gutter="12">
        <a-col :span="24">
          <documents-manager-detail-card-header>
            <documents-manager-edit />
          </documents-manager-detail-card-header>
        </a-col>
      </a-row>
    </div>
  </section>
</template>

<script>
import DocumentsManagerDetailCardHeader from "@/components/mcma/admin/documentsManager/components/DocumentsManagerDetailCardHeader"
import DocumentsManagerEdit from "@/components/mcma/admin/documentsManager/components/DocumentsManagerEdit"
import { mapMutations } from "vuex"

export default {
  name: "DocumentsManagerDetail",
  components: {
    DocumentsManagerDetailCardHeader,
    DocumentsManagerEdit,
  },
  methods: {
    ...mapMutations(["setBreadcrumbAdd"]),
  },
  created() {
    this.setBreadcrumbAdd({
      name: "Nouveau",
      action: () => {},
    })
  },
}
</script>

<style lang="scss" scoped></style>
