import Vue from "vue"

Vue.filter("capitalize", function (value) {
  if (!value) return ""
  value = value.toString()
  return value.charAt(0).toUpperCase() + value.slice(1)
})

Vue.filter("pretty", function (value) {
  return JSON.stringify(value, null, 2)
})

Vue.filter("screamingSnakeCase", function (string) {
  return (
    string &&
    string
      .match(/[A-Z]{2,}(?=[A-Z][a-z]+[0-9]*|\b)|[A-Z]?[a-z]+[0-9]*|[A-Z]|[0-9]+/g)
      .map((x) => x.toUpperCase())
      .join("_")
  )
})
