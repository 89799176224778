<template>
  <mcma-card
    title="Interlocuteur du périmètre de facturation"
    icon="administration"
    class="mcma-card-in-tab"
    :inline="true"
  >
    <template #describe>
      Sélectionner un interlocuteur en charge du traitement/suivi de la facture.
      <br />
      N.B : Si l'interlocuteur souhaité n'apparaît pas dans la liste, veuillez vérifier que celui-ci possède toutes les
      informations requises (prénom, nom, email, téléphone).
    </template>
    <template #headerRightSide>
      <mcma-button
        name="Vérifier mes informations interlocuteurs"
        color="primary"
        leftIcon="check-circle"
        :isFaIcon="true"
        @click="goTo('interlocuteurs')"
      />
    </template>
    <a-form :form="form" v-if="form.getFieldsValue()">
      <a-row type="flex" :gutter="12">
        <mcma-field
          :colSpan="16"
          type="select"
          :form="form"
          :wasSubmit="wasSubmit"
          :defaultValue="groupement.interlocuteurPayeurId"
          fieldName="selectedInterlocuteurId"
          disableValue="Sélectionner un interlocuteur"
          @change="selectedInterlocuteurIdChange"
          :required="true"
          label="Interlocuteurs"
        >
          <a-select-option v-for="(interlocuteur, index) in interlocuteurs" :key="index" :value="interlocuteur.id">
            {{ interlocuteur.friendly }}
          </a-select-option>
        </mcma-field>
        <a-col :span="8" class="align-column-end">
          <mcma-button
            class="grow-2"
            name="Ajouter un nouvel interlocuteur"
            color="primary"
            leftIcon="user-plus"
            :isFaIcon="true"
            @click="setInterlocuteurCreateModal(true)"
          />
        </a-col>
      </a-row>
      <a-row type="flex" :gutter="12">
        <mcma-field
          type="input"
          :form="form"
          :wasSubmit="wasSubmit"
          :defaultValue="groupement.interlocuteurPayeurNom"
          fieldName="interlocuteurPayeurNom"
          label="Interlocuteur payeur (nom)"
          :required="true"
          :requiredMessage="`Merci de renseigner l'interlocuteur payeur (nom)`"
          :disabled="true"
        />
        <mcma-field
          type="input"
          :form="form"
          :wasSubmit="wasSubmit"
          :defaultValue="groupement.interlocuteurPayeurPrenom"
          fieldName="interlocuteurPayeurPrenom"
          label="Interlocuteur payeur (prénom)"
          :required="true"
          :requiredMessage="`Merci de renseigner l'interlocuteur payeur (prénom)`"
          :disabled="true"
        />
        <mcma-field
          type="input"
          :form="form"
          :wasSubmit="wasSubmit"
          :defaultValue="groupement.emailPayeur"
          fieldName="emailPayeur"
          label="Email"
          :required="true"
          :requiredMessage="`Merci de renseigner l'email`"
          :disabled="true"
          rule="isEmail"
        />
        <mcma-field
          :colSpan="6"
          type="input"
          :form="form"
          :wasSubmit="wasSubmit"
          :defaultValue="groupement.telephonePayeur"
          fieldName="telephonePayeur"
          label="Téléphone fixe"
          :extraValidationFields="['mobilePayeur']"
          :required="!form.getFieldValue('mobilePayeur') || form.getFieldValue('mobilePayeur').length <= 0"
          :disabled="true"
          rule="isPhone"
        />
        <mcma-field
          :colSpan="6"
          type="input"
          :form="form"
          :wasSubmit="wasSubmit"
          :defaultValue="groupement.mobilePayeur"
          fieldName="mobilePayeur"
          label="Téléphone mobile"
          :extraValidationFields="['telephonePayeur']"
          :requiredMessage="`ou le téléphone mobile`"
          :required="!form.getFieldValue('telephonePayeur') || form.getFieldValue('telephonePayeur').length <= 0"
          :disabled="true"
          rule="isPhone"
        />
        <mcma-field
          type="input"
          :form="form"
          :wasSubmit="wasSubmit"
          :defaultValue="groupement.siret"
          fieldName="siret"
          label="Siret"
          :required="true"
          rule="isSiret"
        />
        <mcma-field
          type="input"
          :form="form"
          :wasSubmit="wasSubmit"
          :defaultValue="groupement.iban"
          fieldName="iban"
          label="Code IBAN"
          rule="isIban"
          :required="ibanIsRequired(form.getFieldValue('moyenPaiement'))"
        />
        <mcma-field
          type="select"
          :form="form"
          :wasSubmit="wasSubmit"
          :defaultValue="groupement.moyenPaiement"
          fieldName="moyenPaiement"
          disableValue="Sélectionnez un mode de paiement"
          label="Moyen de paiement"
          getFlow="name::groupements"
          getPanel="name::groupements"
          getField="type::moyenPaiement"
          @change="moyenPayementChange"
          :required="true"
        />
        <mcma-field
          :colSpan="6"
          type="select"
          :form="form"
          :wasSubmit="wasSubmit"
          :defaultValue="groupement.rythmeFacturation"
          fieldName="rythmeFacturation"
          disableValue="Sélectionnez un rythme de facturation"
          label="Rythme de facturation"
          getFlow="name::groupements"
          getPanel="name::groupements"
          getField="type::rythmeFacturation"
          :required="true"
        />
        <mcma-field
          :colSpan="6"
          type="select"
          :form="form"
          :wasSubmit="wasSubmit"
          :defaultValue="groupement.delaiPaiement"
          fieldName="delaiPaiement"
          disableValue="Sélectionnez un délai de paiement"
          label="Délai de paiement"
          getFlow="name::groupements"
          getPanel="name::groupements"
          getField="type::delaiPaiement"
          :required="true"
        />
      </a-row>
      <mcma-sepa :groupement="groupement" :groupementEditing="this.form.getFieldsValue()" @fileUpload="onSepaUpload" />
      <a-row type="flex" :gutter="12">
        <mcma-field
          type="input"
          :form="form"
          :wasSubmit="wasSubmit"
          :defaultValue="groupement.codeIdentificationIndividuelle"
          getFlow="name::groupements"
          getPanel="name::groupements"
          getField="type::codeIdentification"
          fieldName="codeIdentificationIndividuelle"
        />
        <mcma-field
          type="input"
          :form="form"
          :wasSubmit="wasSubmit"
          :defaultValue="groupement.numConventionTripartite"
          getFlow="name::groupements"
          getPanel="name::groupements"
          getField="type::numConvention"
          fieldName="numConventionTripartite"
          label="N° de convention tripartite"
        />
      </a-row>

      <a-row type="flex" :gutter="12" class="switch-group">
        <mcma-field
          :colOffset="6"
          type="switch"
          :form="form"
          :wasSubmit="wasSubmit"
          :defaultValue="groupement.id ? groupement.papier : true"
          fieldName="papier"
          getFlow="name::groupements"
          getPanel="name::groupements"
          getField="type::papier"
        />
        <mcma-field
          type="switch"
          :form="form"
          :wasSubmit="wasSubmit"
          :defaultValue="groupement.optionExcel"
          fieldName="optionExcel"
          getFlow="name::groupements"
          getPanel="name::groupements"
          getField="type::optionExcel"
        />
        <mcma-field
          type="switch"
          :form="form"
          :wasSubmit="wasSubmit"
          :defaultValue="groupement.dematXml"
          fieldName="dematXml"
          getFlow="name::groupements"
          getPanel="name::groupements"
          getField="type::dematXml"
        />
      </a-row>
      <a-row type="flex" :gutter="12">
        <a-col :span="24">
          <div class="button-group-inline mt24">
            <mcma-button
              name="Réinitialiser"
              color="primary"
              leftIcon="undo-alt"
              :isFaIcon="true"
              @click="initialize(true)"
            />
            <mcma-button
              :name="getSaveButtonTitle"
              class="ml12"
              color="success"
              leftIcon="save"
              :isFaIcon="true"
              @click="submit"
            />
          </div>
        </a-col>
      </a-row>
    </a-form>
    <interlocuteur-create-modal
      :visible="showInterlocuteurCreateModal"
      from="groupement"
      @onCancel="setInterlocuteurCreateModal"
      @onOk="pushNewInterlocuteurFromModal"
    />
  </mcma-card>
</template>

<script>
import Axios from "@/util/Axios"
import _ from "lodash"
import { pick } from "@/util/Helpers"
import McmaCard from "@/components/mcma/common/McmaCard"
import McmaField from "@/components/mcma/common/McmaField"
import McmaButton from "@/components/mcma/common/McmaButton"
import McmaIcon from "@/components/mcma/common/McmaIcon"
import McmaSepa from "@/components/mcma/common/Fields/McmaSepa"
import InterlocuteurCreateModal from "@/components/mcma/steps/interlocuteurs/InterlocuteurCreateModal"
import { mapState } from "vuex"

export default {
  name: "GroupementsInterlocuteurForm",
  components: {
    McmaCard,
    McmaField,
    McmaButton,
    McmaIcon,
    McmaSepa,
    InterlocuteurCreateModal,
  },
  props: {
    groupement: Object,
  },
  data() {
    return {
      form: this.$form.createForm(this),
      wasSubmit: false,
      interlocuteurs: [],
      originalSelectedInterlocuteur: {},
      showInterlocuteurCreateModal: false,
    }
  },
  computed: {
    ...mapState(["membre", "operation"]),
    getCapitalize() {
      return (value) => {
        return this.$options.filters.capitalize(value)
      }
    },
    getSaveButtonTitle() {
      if (this.$route.params.action === "duplique" || this.groupement.id) {
        return `Valider les informations`
      }
      return "Créer le périmètre de facturation"
    },

    ibanIsRequired() {
      return (moyenPaiement) => {
        return moyenPaiement === "PRELEVEMENT" || moyenPaiement === "PRELEVEMENT_BANQUE_DE_FRANCE"
      }
    },
    codeIdentityVisuelIsRequired() {
      return (moyenPaiement) => {
        return moyenPaiement === "VIREMENT_SANS_MANDATEMENT" || moyenPaiement === "PRELEVEMENT_BANQUE_DE_FRANCE"
      }
    },
  },
  mounted() {
    this.initialize()
  },
  methods: {
    setInterlocuteurCreateModal(show) {
      this.showInterlocuteurCreateModal = !!show
    },
    pushNewInterlocuteurFromModal(interlocuteur) {
      this.pushInterlocuteur(interlocuteur)
      this.originalSelectedInterlocuteur = this.interlocuteurs.find((inter) => inter.id === interlocuteur.id)
      this.setInterlocuteurPayeurSelected(this.originalSelectedInterlocuteur)
      this.setInterlocuteurPayeurSelected({ selectedInterlocuteurId: this.originalSelectedInterlocuteur.id })
      this.setInterlocuteurCreateModal()
    },
    async initialize(reset) {
      this.form.getFieldDecorator("nom")
      this.form.getFieldDecorator("budget")
      this.form.getFieldDecorator("raisonSocialPayeur")
      this.form.getFieldDecorator("adresseFacturation")
      this.form.getFieldDecorator("codePostal")
      this.form.getFieldDecorator("commune")
      this.form.getFieldDecorator("interlocuteurPayeurNom")
      this.form.getFieldDecorator("interlocuteurPayeurId")
      this.form.getFieldDecorator("interlocuteurPayeurPrenom")
      this.form.getFieldDecorator("emailPayeur")
      this.form.getFieldDecorator("telephonePayeur")
      this.form.getFieldDecorator("mobilePayeur")
      this.form.getFieldDecorator("siret")
      this.form.getFieldDecorator("iban")
      this.form.getFieldDecorator("moyenPaiement")
      this.form.getFieldDecorator("delaiPaiement")
      this.form.getFieldDecorator("codeIdentificationIndividuelle")
      this.form.getFieldDecorator("numConventionTripartite")
      this.form.getFieldDecorator("engagementJuridique")
      this.form.getFieldDecorator("codeServiceExecutant")
      this.form.getFieldDecorator("rythmeFacturation")
      this.form.getFieldDecorator("optionExcel")
      this.form.getFieldDecorator("dematXml")
      this.form.getFieldDecorator("papier")
      this.form.getFieldDecorator("sepaCheck")
      this.form.getFieldDecorator("sepaFile")

      this.interlocuteurs = []
      this.originalSelectedInterlocuteur = {}

      try {
        await this.prepareInterlocuteurs()
        const siteInterlocuteur = await this.prepareInterlocuteurBySite()
        const membreInterlocuteur = await this.prepareInterlocuteurByMembre()
        this.originalSelectedInterlocuteur = siteInterlocuteur || membreInterlocuteur || {}
      } catch (e) {
        console.error(e)
      }

      if (this.originalSelectedInterlocuteur && this.originalSelectedInterlocuteur.id) {
        this.setInterlocuteurPayeurSelected(this.originalSelectedInterlocuteur)
        this.setInterlocuteurPayeurSelected({ selectedInterlocuteurId: this.originalSelectedInterlocuteur.id })
      } else {
        this.resetForm()
      }
      if (reset) {
        this.wasSubmit = false
        this.initializeFields()
        this.$emit("reset")
      }
    },
    initializeFields() {
      let setFields = pick(this.groupement, [
        "nom",
        "budget",
        "raisonSocialPayeur",
        "adresseFacturation",
        "codePostal",
        "commune",
        "interlocuteurPayeurNom",
        "interlocuteurPayeurPrenom",
        "emailPayeur",
        "telephonePayeur",
        "mobilePayeur",
        "siret",
        "iban",
        "moyenPaiement",
        "delaiPaiement",
        "codeIdentificationIndividuelle",
        "numConventionTripartite",
        "engagementJuridique",
        "codeServiceExecutant",
        "rythmeFacturation",
        "optionExcel",
        "dematXml",
        "papier",
        "sepaCheck",
      ])
      this.$nextTick(() => {
        const fields = this.form.getFieldsValue()
        fields.papier = this.groupement.id ? this.groupement.papier : true
        fields.sepaFile = null
        setFields = this.$mergeObject(fields, setFields)
        this.form.setFieldsValue(setFields)
      })
    },
    onSepaUpload(check, file) {
      this.form.setFieldsValue({
        sepaCheck: check,
        sepaFile: file,
      })
    },

    moyenPayementChange(value) {
      if (value === "PRELEVEMENT_BANQUE_DE_FRANCE" || value === "VIREMENT_SANS_MANDATEMENT") {
        this.form.setFieldsValue({
          codeIdentificationIndividuelle: this.form.getFieldValue("siret"),
        })
      }
    },
    prepareInterlocuteurBySite() {
      const interId = this.site && this.site.interlocuteurId ? this.site.interlocuteurId : null
      if (interId) {
        return Axios("get", "api/interlocuteurs/" + interId)
          .then((response) => {
            const siteInterlocuteur = _.find(this.interlocuteurs, ["id", response.data.id])
            if (siteInterlocuteur) {
              return Promise.resolve(siteInterlocuteur)
            } else {
              const interToPush = this.pushInterlocuteur(response.data)
              return Promise.resolve(interToPush)
            }
          })
          .catch(() => {
            return Promise.resolve(null)
          })
      }
      return Promise.resolve(null)
    },
    prepareInterlocuteurs() {
      Axios("get", "api/membres/" + this.membre.id + "/interlocuteurs")
        .then((response) => {
          const interlocuteurs = response.data._embedded.interlocuteurs || []
          interlocuteurs.forEach((inter) => {
            this.pushInterlocuteur(inter)
          })

          return this.interlocuteurs
        })
        .catch((error) => {
          console.log(
            `%c prepareInterlocuteurs error`,
            "background:#1e4d77 ; padding: 5px 7px 4px 0px; border-radius: 3px;  color: #FFFFFF",
            error
          )
          return null
        })
    },
    pushInterlocuteur(inter) {
      if (inter.role === "FACTURATION" || inter.role === "GESTIONNAIRE" || inter.role === "AUTRE") {
        const interToPush = pick(inter, ["id", "fonction", "role", "nom", "prenom", "telephone", "mobile", "mail"])
        interToPush.friendly = `${inter.nom.toUpperCase()} ${_.capitalize(inter.prenom)}`

        this.interlocuteurs.push(interToPush)
        this.interlocuteurs = _.uniqBy(this.interlocuteurs, "id")
        return interToPush
      }
      return null
    },
    prepareInterlocuteurByMembre() {
      const interF = this.membre.interlocuteurFacturation || {}
      let interToReturn = null
      let interToPush = null
      if (interF && interF.nom && interF.prenom && (interF.telephone || interF.mobile) && interF.id) {
        interToPush = this.pushInterlocuteur(interF)
        interToReturn = interToPush
      }
      return Axios("get", "api/membres/" + this.membre.id + "/interlocuteurs")
        .then((response) => {
          for (const interF of response.data._embedded.interlocuteurs) {
            if (
              interF.nom &&
              interF.prenom &&
              (interF.telephone || interF.mobile) &&
              interF.id &&
              !_.find(this.interlocuteurs, ["id", interF.id]) &&
              (interF.role === "GESTIONAIRE" || interF.role === "FACTURATION" || interF.role === "AUTRE")
            ) {
              this.pushInterlocuteur(interF)
              interToReturn = interToReturn && interToReturn.id ? interToReturn : interToPush
            }
          }
        })
        .catch(() => {
          return Promise.resolve(null)
        })
        .finally(() => {
          return Promise.resolve(interToReturn)
        })
    },
    selectedInterlocuteurIdChange(interlocuteurPayeurId) {
      this.originalSelectedInterlocuteur = this.interlocuteurs.find((inter) => inter.id === interlocuteurPayeurId)
      this.setInterlocuteurPayeurSelected(this.originalSelectedInterlocuteur)
    },
    setInterlocuteurPayeurSelected(object) {
      console.log("setInterlocuteurPayeurSelected")
      const result = {}
      if (object.id) result.interlocuteurPayeurId = object.id
      if (object.nom) result.interlocuteurPayeurNom = object.nom
      if (object.prenom) result.interlocuteurPayeurPrenom = object.prenom
      if (object.mail) result.emailPayeur = object.mail
      if (object.telephone) result.telephonePayeur = object.telephone
      if (object.mobile) result.mobilePayeur = object.mobile
      if (object.selectedInterlocuteurId) result.selectedInterlocuteurPayeurId = object.selectedInterlocuteurId
      this.$nextTick(() => {
        this.form.setFieldsValue(result)
      })
    },
    resetForm() {
      console.log("resetForm")
      this.$nextTick(() => {
        this.originalSelectedInterlocuteur = {}
        this.form.setFieldsValue({
          interlocuteurPayeurNom: this.groupement.interlocuteurPayeurNom,
          interlocuteurPayeurPrenom: this.groupement.interlocuteurPayeurPrenom,
          emailPayeur: this.groupement.emailPayeur,
          telephonePayeur: this.groupement.telephonePayeur,
          mobilePayeur: this.groupement.mobilePayeur,
          selectedInterlocuteurId: this.groupement.interlocuteurPayeurId,
        })
      })
    },
    submit() {
      const interlocuteurKeys = [
        "interlocuteurPayeurNom",
        "interlocuteurPayeurPrenom",
        "emailPayeur",
        "telephonePayeur",
        "mobilePayeur",
      ]
      this.form.validateFields(interlocuteurKeys, (errors, fieldsValue) => {
        this.wasSubmit = true
        if (errors) {
          this.$notification.error({
            message: "Vous avez des erreurs",
            description: `Veuillez remplir tous les champs comprenant des erreurs dans le formulaire de l'interlocuteur`,
          })
        } else {
          console.log("fieldsValue : %O", fieldsValue)
          console.log("originalSelectedInterlocuteur : %O", this.originalSelectedInterlocuteur)
          // if (!fieldsValue.selectedInterlocuteurId) {
          if (this.originalSelectedInterlocuteur === {}) {
            Axios("post", "api/interlocuteurs", {
              nom: fieldsValue.interlocuteurPayeurNom,
              prenom: fieldsValue.interlocuteurPayeurPrenom,
              telephone: fieldsValue.telephonePayeur,
              mobile: fieldsValue.mobilePayeur,
              mail: fieldsValue.emailPayeur,
              role: "FACTURATION",
              removed: false,
              membre: process.env.VUE_APP_API_URL + "/api/membres/" + this.membre.id,
              operationId: this.operation.id,
            })
              .then((response) => {
                this.$notification.success({
                  message: "Interlocuteur payeur ajouté",
                  description: `${fieldsValue.interlocuteurPayeurPrenom} ${fieldsValue.interlocuteurPayeurNom} à bien été ajouter à vos interlocuteurs payeurs.`,
                })
                const interToPush = this.pushInterlocuteur(response.data)
                this.setInterlocuteurPayeurSelected({ selectedInterlocuteurId: interToPush.id })
                this.saveForNextProcess(interToPush.id, fieldsValue)
              })
              .catch((error) => {
                console.error(error)
                this.$notification.error({
                  message: "Erreur d'ajout de l'interlocuteur payeur",
                  description: `${this.getCapitalize(fieldsValue.interlocuteurPayeurPrenom)} ${this.getCapitalize(
                    fieldsValue.interlocuteurPayeurNom
                  )} n'as pas pu étre ajouté à vos interlocuteurs payeurs.`,
                })
              })
          } else {
            console.log("interlocuteur exist")
            this.saveForNextProcess(fieldsValue.selectedInterlocuteurId, fieldsValue)
          }
        }
      })
    },
    saveForNextProcess(interlocuteurId, interlocuteurFieldsValue) {
      const nextProcessKeys = [
        "siret",
        "iban",
        "moyenPaiement",
        "delaiPaiement",
        "codeIdentificationIndividuelle",
        "numConventionTripartite",
        "engagementJuridique",
        "codeServiceExecutant",
        "state",
        "papier",
        "sepa",
        "rythmeFacturation",
        "optionExcel",
        "dematXml",
      ]
      this.form.validateFields(nextProcessKeys, (errors, fieldsValue) => {
        this.wasSubmit = true
        if (errors) {
          this.$notification.error({
            message: "Vous avez des erreurs",
            description: `Veuillez remplir tous les champs comprenant des erreurs dans le formulaire`,
          })
        } else {
          this.$emit("save", {
            interlocuteurId: interlocuteurId,
            interlocuteurFieldsValue: { ...interlocuteurFieldsValue, ...fieldsValue },
          })
        }
      })
    },
    getSelectedInterlocuteurId() {
      return this.form.getFieldValue("selectedInterlocuteurId")
    },
    goTo(routeName) {
      this.$router.push({
        name: routeName,
      })
    },
  },
}
</script>

<style lang="scss">
@import "./src/assets/scss/variables";
@import "./src/assets/scss/functions";

.switch-group {
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  .ant-col {
    .mcma-field.switch {
      .ant-form-item-label {
        text-align: left;
        flex-grow: 2;
      }
      .ant-form-item-control-wrapper {
        margin-left: calc(var(--padding, $padding) * 2);
      }
    }
  }
}
</style>
