<template>
  <div class="ninja"></div>
</template>

<script>
import { mapMutations, mapState } from "vuex"

export default {
  name: "McmaKonamiDebug",
  data() {
    return {
      keys: [68, 69, 66, 85, 71],
      isChecked: {
        0: false,
        1: false,
        2: false,
        3: false,
        4: false,
      },
      current: 0,
      oldColor: "#FF5722",
    }
  },
  computed: {
    ...mapState(["debug"]),
  },
  created() {
    window.addEventListener("keyup", this.listen)
  },
  beforeDestroy() {
    window.removeEventListener("keyup", this.listen)
  },
  methods: {
    ...mapMutations(["setState"]),
    async activatedDebugingMode() {
      const root = document.documentElement
      if (!this.debug) {
        this.oldColor = getComputedStyle(root).getPropertyValue(`--secondary-color`).trim()
      }
      let debugVar = {
        logTitle: `Debuging ${!this.debug ? "Activated" : "Desactivated"}`,
        color: !this.debug ? "#FF0000" : this.oldColor,
        notifType: !this.debug ? "warning" : "success",
        notifTitle: `Debuging mode ${!this.debug ? "activé" : "désactivé"}`,
        notifDescribe: !this.debug
          ? "Vous avez activé le mode 'debug'.\nSi vous ne savez pas ce que vous êtes en train de faire, rafraichissez la page."
          : "Vous avez désactivé le mode 'debug'.",
        value: !this.debug,
      }
      console.log(
        `%c ${debugVar.logTitle}`,
        `background:${debugVar.color} ; padding: 5px 7px 4px 0px; border-radius: 3px;  color: #FFFFFF`
      )
      this.$notification[debugVar.notifType]({
        message: debugVar.notifTitle,
        description: debugVar.notifDescribe,
      })
      this.setState({
        stateName: "debug",
        value: debugVar.value,
      })
      root.style.setProperty(`--secondary-color`, debugVar.color)
      root.style.setProperty(`--secondary-color-h`, await this.$hexToHSL(debugVar.color, "hue"))
      root.style.setProperty(`--secondary-color-s`, await this.$hexToHSL(debugVar.color, "saturation"))
      root.style.setProperty(`--secondary-color-l`, await this.$hexToHSL(debugVar.color, "lightness"))
    },
    listen(e) {
      const key = e.which || e.keyCode || e.detail
      if (this.keys.includes(key)) {
        if (key === this.keys[this.current]) {
          this.current += 1
          this.isChecked[this.current - 1] = true
          if (this.current === this.keys.length) {
            
            this.activatedDebugingMode()

            this.current = 0
            Object.keys(this.isChecked).forEach((k) => {
              this.isChecked[k] = false
            })
          }
        } else {
          Object.keys(this.isChecked).forEach((k) => {
            this.isChecked[k] = false
          })
          this.current = 0
        } 
      }else{
        Object.keys(this.isChecked).forEach((k) => {
            this.isChecked[k] = false
          })
          this.current = 0
      }
    },
  },
}
</script>
