<template>
  <section v-if="context.moyenPaiement === 'PRELEVEMENT' || context.moyenPaiement === 'prelevement'">
    <div class="card">
      <div class="card-content">
        <article class="card-title">
          SEPA
          <span class="badge">
            <i v-if="valid" class="material-icons light-green-text">check</i>
            <i v-else class="material-icons deep-orange-text">close</i>
          </span>
        </article>

        <blockquote>
          Dans le cadre du prélèvement comme moyen de paiement, votre fournisseur, une fois que dernier sera désigné,
          reviendra vers vous pour l'obtention d'un SEPA afin de mettre en place un prélèvement bancaire automatique
          pour le paiement de vos factures.
        </blockquote>

        <article>
          <input type="checkbox" id="check" v-model="check" ref="check" />
          <label for="check"> J'accepte </label>
        </article>
      </div>
      <!--
      <div class="card-action flex">

        <button type="button" class="btn waves-effect waves-light blue-grey" @click="download">
          <i class="material-icons left">file_download</i>
          Télécharger le SEPA
        </button>

        <article class="file-field input-field">
          <div class="btn waves-effect waves-light blue darken-2">
            <span>
              <i class="material-icons left">file_upload</i>
              Déposer le SEPA SIGNE
            </span>
            <input type="file" @change="processFile">
          </div>
        </article>

        <button type="button" class="btn waves-effect waves-light lime" @click="downloadSigned" v-if="context.sepa">
          <i class="material-icons left">remove_red_eye</i>
          Voir votre version signé
        </button>

      </div>
      -->
    </div>

    <a class="ninja" ref="downloadLink" />
  </section>
</template>

<script>
import Mixin from "./Mixin"
import Materialize from "materialize-css"
import Axios from "@/util/Axios"
import { mapState } from "vuex"

export default {
  name: "sepa",
  mixins: [Mixin],
  data() {
    return {
      check: false,
      file: null,
    }
  },
  computed: {
    ...mapState(["membre"]),
    valid() {
      return this.check && this.file
    },
    nonBloquant() {
      return this.context.sepa || (!this.check && !this.file) || this.valid
    },
  },
  methods: {
    initialize() {
      this.check = this.field.check
    },
    download() {
      Axios("get", "file/sepa/download", null, {
        responseType: "blob",
      })
        .then((response) => {
          const link = this.$refs.downloadLink
          link.href = window.URL.createObjectURL(new Blob([response.data]))
          link.setAttribute("download", "SEPA.docx")
          link.click()
        })
        .catch((error) => {
          console.error(error)
          Materialize.toast("Erreur lors du téléchargement", 4000, "amber white-text")
        })
    },
    processFile(event) {
      const sizeMax = process.env.VUE_APP_UPLOAD_SIZE * 1024 * 1024
      const file = event.target.files[0]

      if (file.size > sizeMax) {
        Materialize.toast(
          "Le fichier dépasse la taille autorisé qui est de " + process.env.VUE_APP_UPLOAD_SIZE + "Mo.",
          4000,
          "amber white-text"
        )
      } else {
        this.file = file
      }
    },
    downloadSigned() {
      Axios("get", "file/sepa/download?membreId=" + this.membre.id + "&groupementId=" + this.context.id, null, {
        responseType: "blob",
      })
        .then((response) => {
          const link = this.$refs.downloadLink
          link.href = window.URL.createObjectURL(new Blob([response.data]))
          link.setAttribute("download", this.context.nom + " - SEPA.docx")
          link.click()
        })
        .catch((error) => {
          console.error(error)
          Materialize.toast("Erreur lors du téléchargement", 4000, "amber white-text")
        })
    },
  },
  watch: {
    file() {
      this.field = {
        check: this.check,
        file: this.file,
      }
    },
    check() {
      this.field = {
        check: this.check,
        file: this.file,
      }
    },
    nonBloquant() {
      if (this.nonBloquant) {
        this.$refs.check.setCustomValidity("")
      } else {
        this.$refs.check.setCustomValidity("Vous devez accepter en même tant que d'envoyer votre version signé.")
      }
    },
  },
  created() {
    this.initialize()
  },
}
</script>

<style lang="scss" scoped>
.input-field {
  margin-top: 0px !important;
}
</style>
