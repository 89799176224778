<template>
  <section>
    <div class="date" v-if="modeCountDown">
      {{ countDownParse }}
    </div>
    <div class="date" v-else>
      {{ $d(dateF) }}
    </div>
  </section>
</template>

<script>
import { accorization, getTime } from "@/util/Helpers"
import { mapState } from "vuex"

export default {
  name: "count-down",
  props: ["modeCountDown", "dateFin"],
  data() {
    return {
      date: process.env.VUE_APP_DEADLINE,
      dateValues: {
        seconds: null,
        minutes: null,
        hours: null,
        days: null,
      },
      timer: null,
      isFinish: false,
    }
  },
  computed: {
    ...mapState(["operation"]),
    countDownParse() {
      return this.isFinish
        ? "Collecte terminée !"
        : `${accorization(this.dateValues.days, "jour", true)} et ${this.dateValues.hours}h${
            this.dateValues.minutes
          } restants`
    },
    dateF() {
      console.log("dateFin : %O", this.dateFin)
      console.log("this.operation.dateFin : %O", this.operation)
      if (this.dateFin) {
        return this.dateFin
      } else {
        return this.operation ? this.operation.dateFin : new Date().getTime()
      }
    },
  },
  mounted() {
    this.checkTimeIsOver()
    this.setDates()
    this.killInterval()
    this.timer = setInterval(() => {
      this.checkTimeIsOver()
      this.setDates()
    }, 60)
  },
  destroyed() {
    this.killInterval()
  },
  methods: {
    killInterval() {
      if (this.timer) {
        clearInterval(this.timer)
      }
    },
    setDates() {
      this.dateValues = {
        seconds: getTime(this.dateF, "seconds"),
        minutes: getTime(this.dateF, "minutes"),
        hours: getTime(this.dateF, "hours"),
        days: getTime(this.dateF, "days"),
      }
    },
    checkTimeIsOver() {
      if (!this.isFinish) {
        this.isFinish =
          getTime(this.dateF, "seconds", true) ||
          getTime(this.dateF, "minutes", true) ||
          getTime(this.dateF, "hours", true) ||
          getTime(this.dateF, "days", true)
        if (this.isFinish) {
          this.$emit("timeIsOver", this.isFinish)
        }
      }
    },
  },
}
</script>
