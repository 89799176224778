import find from "lodash/find"

/**
 * getDynamiqueFields(
 *  operation,
 *  property::value,
 *  property::value,
 *  property::value
 * )
 */

export function getDynamiqueFields(operation, flows, panels, fields, value, defaultList) {
  if (flows) {
    const names = ["flows", "panels", "fields"]
    let result = null
    let parent = operation
    for (let i = 0, len = names.length; i < len; i++) {
      const name = names[i]
      const argsStep = i + 1
      if (arguments[argsStep] && parent && parent[name]) {
        const deep = arguments[argsStep].split("::")
        if (deep.length === 2) {
          result = parent = find(parent[name], [deep[0], deep[1]])
        } else {
          console.log(`un probleme est survenu avec le deep ${name}`, arguments[argsStep])
        }
      } else {
        if (result && value) {
          result.value = value
        }
        return addDefaultListIfSet(result, defaultList)
      }
    }
    if (result && value) {
      result.value = value
    }
    return addDefaultListIfSet(result, defaultList)
  }
  return null
}

function addDefaultListIfSet(result, defaultList) {
  if (result && result.lockedValues && defaultList) {
    result.lockedValues = defaultList
  }
  return result
}
