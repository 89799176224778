<template>
  <vue-code-highlight v-if="debug">
    <slot />
  </vue-code-highlight>
</template>

<script>
import { mapState } from "vuex"
import { component as VueCodeHighlight } from "vue-code-highlight"
import "vue-code-highlight/themes/duotone-sea.css"
// import "vue-code-highlight/themes/window.css";

export default {
  name: "McmaCode",
  components: {
    VueCodeHighlight,
  },
  computed: {
    ...mapState(["debug"]),
  },
}
</script>

<style lang="scss">
pre {
  code {
    width: 100%;
  }
}
div pre[class*="language-"] {
  width: 100%;
}
</style>
