<template>
  <div>
    <a-spin
      :spinning="spinning"
      :tip="tip"
      :delay="delay"
      :wrapperClassName="
        'mcma-spin-box ' +
        wrapperClassName +
        (noTransparence ? ' no-transparence' : '') +
        (size ? ` size-${size}` : '') +
        (full ? ' full' : '')
      "
    >
      <a-icon
        slot="indicator"
        type="loading"
        :style="{ fontSize: size + 'px', height: size + 'px', width: size + 'px' }"
        spin
      />
      <slot></slot>
    </a-spin>
  </div>
</template>

<script>
export default {
  name: "McmaSpinBox",
  props: {
    spinning: Boolean,
    tip: String,
    delay: Number,
    size: {
      type: Number,
      default: 24,
    },
    noTransparence: Boolean,
    wrapperClassName: String,
    full: Boolean,
  },
}
</script>

<style lang="scss">
.mcma-spin-box {
  &.full {
    .ant-spin {
      width: 100%;
      min-height: calc(100vh - 130px);
      max-height: calc(100vh - 130px);
      i {
        top: -40px !important;
      }
      .ant-spin-text {
        top: calc(50% - 40px);
      }
    }
  }
  .ant-spin {
    display: flex !important;
    i {
      position: relative !important;
      top: inherit !important;
      left: inherit !important;
      margin: auto !important;
    }
  }
  @for $i from 0 through 100 {
    &.size-#{$i} {
      .ant-spin-text {
        font-size: 16px+ (1px * ($i * 0.1));
        margin-top: 40px+ (1px * ($i * 0.3));
      }
    }
  }
  &.no-transparence {
    .ant-spin-blur {
      opacity: 1;
    }
  }
}
</style>
