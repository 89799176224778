<template>
  <mcma-card title="Gestion des membres" icon="add_user" class="mcma-card-header" :inline="true">
    <template #describe>
      <span v-if="membresStats.length === 1"> Edition de votre <strong>unique membre</strong>. </span>
      <span v-else-if="membresStats.length > 1">
        Edition de vos <strong>{{ membresStats.length }} membres</strong>.
      </span>
      <span v-else> <strong>Aucun membre</strong> à éditer. </span>
    </template>
    <template #headerRightSide>
      <mcma-button
        name="Ajouter un nouveau membre"
        color="primary"
        leftIcon="user-plus"
        :isFaIcon="true"
        @click="goToCreateMemberForm()"
      />
    </template>
    <slot></slot>
  </mcma-card>
</template>

<script>
import McmaCard from "@/components/mcma/common/McmaCard"
import McmaButton from "@/components/mcma/common/McmaButton"

export default {
  name: "MembersHeader",
  props: {
    membresStats: Array,
  },
  components: {
    McmaCard,
    McmaButton,
  },
  methods: {
    goToCreateMemberForm() {
      this.$router.push({
        name: "members-detail",
        params: {
          action: "new",
        },
      })
    },
  },
}
</script>

<style lang="scss"></style>
