<template>
  <main>
    <section class="container">
      <form class="card" @submit.prevent="submit" @reset="initialize">
        <section class="card-content" id="addMemberForm">
          <span class="card-title">
            <template v-if="membre">
              Edition du membre : {{ membre.nom }}
              <span class="badge">
                <i class="material-icons pointer" @click="membre = null">close</i>
              </span>
            </template>
            <template v-else> Nouveau membre </template>
          </span>

          <div class="row">
            <article class="input-field col s12">
              <i class="material-icons prefix">account_circle</i>
              <input id="nom" type="text" class="validate" required v-model="nom" />
              <label for="nom" class="active">Nom</label>
            </article>
          </div>

          <div class="row" v-if="membre">
            <ul class="collection with-header">
              <li class="collection-header">
                <h5>Utilisateurs liés</h5>
              </li>
              <li class="collection-item" v-for="user in split(membre.userEmail)" :key="user">
                {{ user }}
              </li>
            </ul>
          </div>
        </section>
        <section class="card-action">
          <div class="row">
            <article class="col s6 center-align">
              <button class="waves-effect waves-light btn lime" type="submit" id="addMemberValid">
                <template v-if="membre">
                  <i class="material-icons left">edit</i>
                  Editer
                </template>
                <template v-else>
                  <i class="material-icons left">add</i>
                  Ajouter
                </template>
              </button>
            </article>

            <article class="col s6 center-align">
              <button class="waves-effect waves-light btn deep-orange" type="reset">
                <i class="material-icons left">refresh</i>
                Annuler
              </button>
            </article>
          </div>
        </section>
      </form>
    </section>

    <section class="row">
      <place-holder :notReady="!membres" class="col s12" id="addMemberGrid">
        <grid
          v-if="membres"
          name="Liste des membres"
          :rows="membres"
          :columns="['nom', 'userEmail', 'status', 'lastLogin', 'actions']"
          :columnHeader="columnHeader"
          :columnsDated="['lastLogin']"
          :search="true"
          :columnsNoSearch="['Actions']"
          :columnsListFilter="columnsListFilter"
        >
          <template slot="status" slot-scope="props">
            <span class="chip white-text blue darken-2" v-if="props.row.status === 'INITIALISED'"> Initialisé </span>
            <span class="chip white-text cyan" v-else-if="props.row.status === 'IN_PROGRESS'"> En&nbsp;cours </span>
            <span class="chip white-text light-green" v-else-if="props.row.status === 'EXPORTED'"> Exporté </span>
            <span class="chip white-text lime" v-else-if="props.row.status === 'APPROVED'"> Approuvé </span>
          </template>

          <template slot="userEmail" slot-scope="props">
            <div v-for="userMail in split(props.row.userEmail)" :key="userMail">
              {{ userMail }}
            </div>
          </template>

          <center slot="actions" slot-scope="props">
            <template v-if="props.row.status !== 'EXPORTED' && props.row.status !== 'APPROVED'">
              <router-link
                :to="{ name: 'site-link', params: { id: props.row.id } }"
                class="tutorialNoBtn editMemberLinksLink"
                v-tooltip.auto="'Associer des sites en masse'"
              >
                <i class="material-icons blue-grey-text">link</i>
              </router-link>

              <i
                class="material-icons pointer blue-text tutorialNoBtn addMemberEdit"
                v-tooltip.auto="'Editer le membre'"
                @click="edit(props.row)"
                >edit</i
              >
            </template>

            <i
              v-else
              class="material-icons pointer deep-orange-text"
              v-tooltip.auto="'Décertifier le membre'"
              @click="unCertify(props.row)"
              >undo</i
            >
          </center>
        </grid>
      </place-holder>
    </section>
  </main>
</template>

<script>
import Axios from "@/util/Axios"
import { mapMutations, mapState } from "vuex"
import Materialize from "materialize-css"
import Grid from "@/components/common/Grid"
import PlaceHolder from "@/components/common/PlaceHolder"

export default {
  name: "membres",
  components: {
    Grid,
    PlaceHolder,
  },
  data() {
    return {
      columnHeader: {
        userEmail: "Utilisateurs de ce membre",
        nom: "Nom du membre",
        status: "Statut",
        lastLogin: "Dernière connexion",
        actions: "Actions",
      },
      columnsListFilter: {
        status: {
          INITIALISED: "Initialisé",
          IN_PROGRESS: "En cours",
          EXPORTED: "Exporté",
          APPROVED: "Approuvé",
        },
      },

      nom: null,
      membre: null,
      membres: null,
    }
  },
  computed: {
    ...mapState(["user", "currentTutorial", "operation", "intro"]),
  },
  methods: {
    ...mapMutations(["setBreadcrumbAdd"]),
    initialize() {
      console.group("initialize@Membres")
      this.nom = null
      if (this.membre) {
        this.nom = this.membre.nom
      }

      this.membres = []
      console.log("Membres In : %O", this.membres)
      this.getPage(0).then((responseInit) => {
        this.computeResponse(responseInit)
        for (let page = 1; page < responseInit.data.page.totalPages; page++) {
          this.getPage(page).then((response) => {
            this.computeResponse(response)
          })
        }
      })
      console.log("Membres Out : %O", this.membres)
      console.groupEnd()
    },
    getPage(page) {
      console.group("getPage@componentMembres")
      console.groupEnd()
      return Axios(
        "get",
        "api/membreStats/search/findByOperationId?operationId=" + this.operation.id + "&size=50&page=" + page
      )
    },
    computeResponse(response) {
      this.membres.push(...response.data._embedded.membreStats)
    },
    submit() {
      console.group("submit@Membres")
      /*
      const membre = {
        // operation_id: this.operation.id,
        id: null,
        nom: this.nom,
        operation: this.operation,
        users: null,
        sites: null,
        groupements: null,
        approuvedInterlocuteurs: null,
        interlocuteurs: null,
        services: null,
        serviceCertified: false,
        lastLogin: null,
        approuved: 0,
        idPartern: '',
        certified: 0,
        remerciementMail: 0
      }
      */
      if (this.membre) {
        console.log("path membre : %O", this.membre)
        Axios(
          // 'patch',
          "post",
          "membre/save?membre=" + this.membre.id + "&name=" + this.nom + "&operation=" + this.operation.id
          /*
          'api/membres/' + this.membre.id, {
            nom: this.nom
          }
          */
        )
          .then((response) => {
            Materialize.toast("Membre édité", 4000, "lime white-text")
            this.membre = null
            // this.initialize()
          })
          .catch((error) => {
            console.error(error)
            Materialize.toast("Erreur lors de la modification", 4000, "deep-orange white-text")
          })
      } else {
        console.log(">> Add newMembre : %O", this.membre)
        console.log("operation : %O", process.env.VUE_APP_API_URL + "/api/operations/" + this.operation.id)
        Axios(
          "post",
          "membre/save?membre=" + 0 + "&name=" + this.nom + "&operation=" + this.operation.id
          // 'api/membres',
          // membre
        )
          .then((response) => {
            Materialize.toast("Membre ajouté", 4000, "lime white-text")
            /*
          console.log('> New membre response : %O', response)
          console.log('> this.operation._links.self.href : %O', this.operation._links.self.href)
          // ajout du lien vers l'opération
          Axios(
            'post',
            'operation/addLinkMembre?operation=' + this.operation.id + '&membre=' + response.data.id
          ).then(response => {
            Materialize.toast('Liaison membre / opérations sauvegardée', 4000, 'lime')
          }).catch(error => {
            console.error(error)
            Materialize.toast('Erreur lors de l\'ajout du lien entre le membre et son opération !', 4000, 'deep-orange white-text')
          })
          */
            /* TODO : recherche pourquoi la methode du href fonction pour user <=> membre et pas pour operation <=> membre
          Axios(
            'put',
            'api/membres/' + response.data.id + '/operations',
            this.operation._links.self.href + '\n',
            null, {
              'Content-Type': 'text/uri-list'
            }
          ).then(response => {
            Materialize.toast('Liaison membre / opérations sauvegardée', 4000, 'lime')
          }).catch(error => {
            console.error(error)
            Materialize.toast('Erreur lors de l\'ajout du lien entre le membre et son opération !', 4000, 'deep-orange white-text')
          })
          */
            this.initialize()
          })
          .catch((error) => {
            console.error(error)
            Materialize.toast("Erreur lors de l'ajout", 4000, "deep-orange white-text")
          })
        console.groupEnd()
      }
    },
    unCertify(membre) {
      Axios("patch", "api/membres/" + membre.id, {
        certified: false,
        approuved: false,
      })
        .then((response) => {
          Materialize.toast("Membre décertifié", 4000, "lime white-text")
          this.initialize()
        })
        .catch((error) => {
          console.error(error)
          Materialize.toast("Erreur lors de la décertification", 4000, "deep-orange white-text")
        })
      console.groupEnd()
    },
    split(value) {
      if (value) {
        return value.split(",")
      }
      return []
    },
    edit(row) {
      this.membre = row
      if (this.currentTutorial) {
        this.intro.nextStep()
      }
    },
  },
  watch: {
    membre() {
      this.initialize()
    },
  },
  created() {
    this.setBreadcrumbAdd({
      name: "Gestion des membres",
      action: () => {
        this.$router.push({
          name: "membres",
        })
      },
    })
    this.initialize()
  },
}
</script>

<style lang="scss" scoped></style>
