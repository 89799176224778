<template>
  <section class="text-editable-presentation">
    <ul class="collapsible" data-collapsible="expandable">
      <li>
        <div class="collapsible-header">Edition des textes libres de présentation</div>
        <div class="collapsible-body">
          <table>
            <tbody>
              <template>
                <tr>
                  <td>
                    <div>
                      <span>
                        <h5>Text libre de présentation de l'instruction de l'étape</h5>
                      </span>
                      <vue-editor v-model="step.instruction" />
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <div>
                      <span>
                        <h5 class="mt25">Text libre de présentation de la carte de l'étape</h5>
                      </span>
                      <vue-editor v-model="step.cardDescribe" />
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <div>
                      <span>
                        <h5 class="mt25">Text libre de présentation de l'étape adressé aux utilisateurs</h5>
                      </span>
                      <vue-editor v-model="step.describeInFlow" />
                    </div>
                  </td>
                </tr>
                <tr v-if="step.name === 'sites'">
                  <td>
                    <div>
                      <span>
                        <h5 class="mt25">
                          Text libre de présentation pour Segment, Formule tarifaire d'acheminement (FTA) et Puissances
                        </h5>
                      </span>
                      <vue-editor v-model="step.sftapDescribe" />
                    </div>
                  </td>
                </tr>
                <tr v-if="step.name === 'sites'">
                  <td>
                    <div>
                      <span>
                        <h5 class="mt25">
                          Aide au commentaire pour Segment, Formule tarifaire d'acheminement (FTA) et Puissances
                        </h5>
                      </span>
                      <vue-editor v-model="step.sftapComment" />
                    </div>
                  </td>
                </tr>
                <tr v-if="step.name === 'sites'">
                  <td>
                    <div>
                      <span>
                        <h5 class="mt25">Aide au commentaire pour ENR</h5>
                      </span>
                      <vue-editor v-model="step.enrDescribe" />
                    </div>
                  </td>
                </tr>
                <tr v-if="step.name === 'sites'">
                  <td>
                    <div>
                      <span>
                        <h5 class="mt25">Aide au commentaire pour ENR (Modal)</h5>
                      </span>
                      <vue-editor v-model="step.enrModalDescribe" />
                    </div>
                  </td>
                </tr>
              </template>
            </tbody>
          </table>
        </div>
      </li>
    </ul>
  </section>
</template>

<script>
import $ from "jquery"
import { VueEditor } from "vue2-editor"

export default {
  name: "TextEditorPresentation",
  props: ["step"],
  components: {
    VueEditor,
  },
  mounted() {
    $(".collapsible").collapsible()
  },
}
</script>

<style lang="scss" scoped>
.collapsible {
  box-shadow: none !important;
  border: 0 !important;
  margin: 0 !important;
  & li {
    & .collapsible-header {
      &.disabled {
        background: rgba(0, 0, 0, 0.1);
        color: rgba(0, 0, 0, 0.4);
        cursor: auto;
      }
    }
    & .collapsible-body {
      padding: 0 !important;
      & table {
      }
    }
    &:last-child {
      & .collapsible-header {
        border: 0 !important;
      }
      & .collapsible-body {
        border-top: 1px solid #ddd !important;
        border-bottom: 0 !important;
      }
    }
  }
}

tr {
  transition: 0.3s;
  transform-origin: top;
  & .chips {
    width: 50%;
    display: inline-block;
    margin: 0 !important;
  }
  &:hover {
    background: rgba(0, 0, 0, 0.05);
  }
  & td {
    min-width: 30px;
    padding: 5px !important;
    & .editBtn {
      cursor: pointer;
      display: inline-block;
      height: 30px;
      padding: 3px;
      border-radius: 15px;
      transition: 0.3s;
      &:hover {
        background: rgba(0, 0, 0, 0.1);
      }
    }
    &.edited {
      border-right: 60px solid transparent;
      & input {
        display: inline-block !important;
        min-width: 200px !important;
        margin: 0 !important;
      }
    }
    &:not(:last-child) {
      width: 1%;
      white-space: nowrap;
    }
    & .switch {
      display: inline-block;
    }
    & .lockBtn {
      display: inline-block;
      height: 30px;
      width: 30px;
      padding: 3px;
      cursor: pointer;
      border-radius: 15px;
      transition: 0.3s;
      background: #005bbb;
      color: white;
      &:hover {
        background: rgb(0, 102, 211);
      }
      &.locked {
        transform: scaleX(-1);
      }
    }
  }
  &.hidden td {
    padding: 0 !important;
    & > div {
      transition: 0.3s;
      height: 0 !important;
      overflow: hidden;
    }
  }
  &.expanded td > div {
    transition: 0.3s;
    overflow: hidden;
  }
}

.text-editable-presentation {
  .collapsible-header {
    margin: 0 5px;
    border-radius: 5px;
    &.active {
      border-bottom-right-radius: 0;
      border-bottom-left-radius: 0;
    }
  }
  .collapsible-body {
    margin: 0 5px;
    background: white;
    border-bottom-right-radius: 5px;
    border-bottom-left-radius: 5px;
  }
  table {
    margin: 10px 0 0 0 !important;
  }
}
</style>
