<template>
  <div
    class="mcma-multi-membre-fixed"
    v-if="membres && membres.length > 1"
    :class="[
      membres ? `push-right-${membres.length}` : '',
      {
        open: membre && showToggleMultiMembre,
      },
    ]"
  >
    <div class="toggle" @click="updateToggleMultiMembre(!showToggleMultiMembre)">
      <mcma-icon :class="{ 'rotate-180': showToggleMultiMembre }" type="angle-left" color="orange" :faIcon="true" />
    </div>
    <ul class="members-list">
      <li class="member-item" v-for="(mbre, index) in $orderBy(membres, 'id')" :key="mbre.id">
        <a-tooltip placement="bottomRight" overlayClassName="membersListTooltip">
          <template slot="title">
            <div class="no-wrap">
              <strong>
                Nom :
                <span class="primary-color">
                  {{ mbre.nom }}
                </span>
              </strong>
              <strong>
                Opération :
                <span class="orange-color">
                  {{ mbre.operationName }}
                </span>
              </strong>
            </div>
          </template>
          <div
            class="avatar-block"
            :class="{ active: membre && membre.id === mbre.id }"
            :style="{
              backgroundColor: membre && membre.id === mbre.id ? '#FFFFFF' : multiSiteColor[index],
              borderColor: multiSiteColor[index],
            }"
            @click="setMembreAndGoToStep(mbre)"
          >
            <div
              class="avatar"
              :style="{
                color: membre && membre.id === mbre.id ? multiSiteColor[index] : '#FFFFFF',
              }"
            >
              {{ getRealMembreName(mbre.nom)[0] }}
            </div>
          </div>
        </a-tooltip>
      </li>
    </ul>
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex"
import McmaIcon from "@/components/mcma/common/McmaIcon"

export default {
  name: "McmaMultiMembreFixed",
  components: {
    McmaIcon,
  },
  computed: {
    ...mapState(["membre", "membres", "showToggleMultiMembre", "operation", "multiSiteColor"]),
    getRealMembreName() {
      return (nom) => {
        if (nom.includes("]")) {
          const split = nom.split("]")
          return split[1].trim()
        }
        return nom
      }
    },
  },
  methods: {
    ...mapMutations(["setState"]),
    ...mapActions(["updateMembre", "requestOperationByMembre"]),
    updateToggleMultiMembre(show) {
      this.setState({
        stateName: "showToggleMultiMembre",
        value: show,
      })
    },
    async setMembreAndGoToStep(membre) {
      try {
        await this.updateMembre(membre)
        this.refreshWithHome()
        this.updateToggleMultiMembre(false)
      } catch (error) {
        console.log(
          `%c setMembreAndGoToStep error`,
          "background:red ; padding: 5px 7px 4px 0px; border-radius: 3px;  color: #FFFFFF",
          error
        )
      }
    },
    refreshWithHome() {
      this.$router.push({
        name: "home",
      })
    },
  },
}
</script>

<style lang="scss">
@import "./src/assets/scss/variables";
@import "./src/assets/scss/functions";

.mcma-multi-membre-fixed {
  position: absolute;
  z-index: 10;
  top: 150px;
  right: 0;
  background: $white;
  box-shadow: $box-shadow;
  width: auto;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  transition: transform 0.3s ease;
  @for $i from 0 through 10 {
    &.push-right-#{$i} {
      transform: translateX(50px * $i);
    }
  }

  &.open {
    transform: translateX(0);
    .toggle {
      margin-top: 1px;
    }
  }
  .toggle {
    height: 58px;
    width: 40px;
    margin-top: -2px;
    cursor: pointer;
    display: flex;
    .mcma-icon {
      margin: auto;
      transition: transform 0.3s ease;
      font-size: 20px;
      line-height: 20px;
      &.rotate-180 {
        transform: rotate(180deg);
      }
    }
  }
  .members-list {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: center;
    margin-bottom: 0;
    .member-item {
      width: 40px;
      height: 40px;
      margin: 10px 10px 10px 0;
      .avatar-block {
        height: 100%;
        width: 100%;
        border-radius: 40px;
        display: flex;
        cursor: pointer;
        border-width: 2px;
        border-style: solid;
        &.active {
          border-width: 0;
          box-shadow: $inset-box-shadow-strong;
        }
        &:hover {
          border-color: var(--primary-color, $primary-color);
        }
        .avatar {
          margin: auto;
          font-weight: 900;
          font-size: 15px;
        }
      }
    }
  }
}

.membersListTooltip {
  max-width: inherit;
  .ant-tooltip-inner {
    /*background: pink;*/
    .no-wrap {
      display: flex;
      flex-flow: column nowrap;
    }
  }
}
</style>
