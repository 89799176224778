<template>
  <div class="general-info-message-box">
    <div class="block-image-arrow">
      <img class="arrow" :src="require(`@/assets/images/arrow_up_of_zigzag.svg`)" alt="mcma arrow up of zigzag" />
      <img class="zigzag" :src="require(`@/assets/images/zigzag.svg`)" alt="mcma zigzag" />
    </div>
    <mcma-icon
      type="info-circle"
      color="blue"
      name="Informations générales"
      :coloriseText="true"
      textTransform="uppercase"
    />
    <p class="text-content">
      Pour commencer, merci de compléter tout d’abord les
      <span> informations générales </span>
      .
    </p>
  </div>
</template>

<script>
import McmaIcon from "@/components/mcma/common/McmaIcon"

export default {
  name: "GeneralInfoMessageBox",
  components: {
    McmaIcon,
  },
  data() {
    return {}
  },
  computed: {},
  methods: {},
}
</script>

<style lang="scss">
@import "./src/assets/scss/variables";
@import "./src/assets/scss/functions";

.general-info-message-box {
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: center;
  height: calc(150px + 30vw);
  width: 100%;
  border-radius: 4px;
  box-shadow: $box-shadow;
  background: $white;
  .block-image-arrow {
    position: relative;
    margin: 4vw auto 2vw auto;
    margin-right: 11vw;
    align-self: flex-end;
    img {
      &.arrow {
        position: absolute;
        top: -2vw;
        right: -1.2vw;
        width: 3vw;
      }
      &.zigzag {
        width: 37vw;
      }
    }
  }
  .mcma-icon {
    .icon-background {
      margin-right: 15px;
      .anticon {
        font-size: 25px;
      }
    }
    .name {
      font-size: 18px;
      font-weight: 700;
    }
  }
  .text-content {
    margin-top: 10px;
    max-width: 480px;
    text-align: center;
    font-size: 16px;
    span {
      color: var(--primary-color, $primary-color);
      font-weight: 600;
    }
  }
}
</style>
