<template>
  <section>
    <label class="active" for="delaiPaiement">
      <font-awesome-icon icon="asterisk" class="red-text" />
      Rythme de facturation
    </label>
    <select id="delaiPaiement" class="browser-default" required v-model="field" :disabled="disabled">
      <option :value="null" disabled selected>Sélectionnez un rythme de facturation</option>
      <option v-for="(friendly, key) in values" :value="key" :key="key">
        {{ friendly }}
      </option>
    </select>
  </section>
</template>

<script>
import Mixin from "./Mixin"
import _ from "lodash"

export default {
  name: "rythme-facturation",
  mixins: [Mixin],
  computed: {
    values() {
      return {
        MENSUEL: "Mensuel",
        BIMESTRIEL: "Bimestriel",
        TRIMESTRIEL: "Trimestriel",
        SEMESTRIEL: "Semestriel",
        ANNUEL: "Annuel",
      }
    },
    disabled() {
      return _.keys(this.values).length === 1
    },
  },
  created() {
    if (this.disabled) {
      this.field = _.keys(this.values)[0]
    }
  },
}
</script>

<style lang="scss" scoped></style>
