<template>
  <section class="input-field">
    <input id="email" type="email" v-model="field" required class="validate" ref="field" :disabled="disabled" />
    <label :class="{ active: field }" for="email">
      <font-awesome-icon icon="asterisk" class="red-text" />
      E-mail
    </label>
  </section>
</template>

<script>
import Mixin from "./Mixin"

export default {
  name: "email",
  mixins: [Mixin],
  props: ["disabled"],
  methods: {
    guardian() {
      if (
        this.field &&
        this.field.length &&
        !/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
          this.field
        )
      ) {
        this.$refs.field.setCustomValidity("Mauvais format d'adresse e-mail")
      } else {
        this.$refs.field.setCustomValidity("")
      }
    },
  },
  watch: {
    field() {
      this.guardian()
    },
  },
  mounted() {
    this.guardian()
  },
}
</script>

<style lang="scss" scoped></style>
