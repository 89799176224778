<template>
  <a-modal
    v-model="visible"
    :maskClosable="false"
    class="mcma-sites-interlocuteurs-masse-modal"
    :title="`Associer un interlocuteur`"
    width="1000px"
  >
    <div class="sentence">
      L’interlocuteur choisi sera associé uniquement aux sites dépourvus d’interlocuteur technique. Si vous souhaitez
      l’associer à tous les sites (y compris ceux qui ont déjà un interlocuteur) activez le bouton ci-dessous.
    </div>
    <br />
    <div class="switch">
      <label>
        <input type="checkbox" @click="onForce" />
        <span class="lever"></span>
        <font size="3">
          Associer l'interlocuteur sélectionné à tous les sites (même à ceux ayant déjà un interlocuteur)</font
        >
      </label>
    </div>

    <site-interlocuteur-form ref="siteInterlocuteurForm" @save="updateInterlocuteurMasse" validationButtonName="Valider"> </site-interlocuteur-form>

    <div class="align-row-end mtb12">
      <mcma-button name="Annuler" @click="close" />
    </div>
  </a-modal>
</template>

<script>
import McmaButton from "@/components/mcma/common/McmaButton"
import SiteInterlocuteurForm from "@/components/mcma/steps/sites/components/SiteInterlocuteurForm"
import Axios from "@/util/Axios"
import Materialize from "materialize-css"

export default {
  name: "McmaSitesInterlocuteursMasseModal",
  components: {
    McmaButton,
    SiteInterlocuteurForm,
  },
  props: {
    sites: Array,
    visible: Boolean,
  },
  data() {
    return {
      forceAllsite: false,
    }
  },
  methods: {
    onForce() {
      this.forceAllsite = !this.forceAllsite
    },
    updateInterlocuteurMasse(interlocuteurId) {
      const interlocuteurIdFinal = interlocuteurId || this.$refs.siteInterlocuteurForm.getSelectedInterlocuteurId()
      let contentDialog = ""
      if (this.sites && this.sites.length !== 0) {
        if (this.forceAllsite) {
          if (this.sites.length === 1) {
            contentDialog = `Attention, êtes-vous sûr de vouloir associer cet interlocuteur même au site "${this.sites[0].nom}" ayant déjà potentiellement un interlocuteur technique? `
          } else {
            contentDialog = `Attention, êtes-vous sûr de vouloir associer cet interlocuteur même au(x) ${
              Object.keys(this.sites).length
            } site(s) ayant déjà potentiellement un interlocuteur technique? `
          }
          var r = window.confirm(contentDialog)
          if (r) {
            for (const site of this.sites) {
              Axios("patch", "api/sites/" + site.id, {
                interlocuteur: process.env.VUE_APP_API_URL + "/api/interlocuteurs/" + interlocuteurIdFinal,
              }).then((site) => {
                this.$emit("save", site.data)
                this.$emit("cancel")
              })
            }
          } else {
            this.$emit("cancel")
          }
          Materialize.toast("Interlocuteur technique associé à tous les sites", 4000, "lime white-text")
        } else {
          for (const site of this.sites) {
            if (site.interlocuteurId === null) {
              Axios("patch", "api/sites/" + site.id, {
                interlocuteur: process.env.VUE_APP_API_URL + "/api/interlocuteurs/" + interlocuteurIdFinal,
              }).then((site) => {
                this.$emit("save", site.data)
                this.$emit("cancel")
              })
            }
          }
          this.$emit("cancel")
          Materialize.toast(
            "Interlocuteur technique associé à tous les sites SANS interlocuteur technique",
            4000,
            "lime white-text"
          )
        }
      } else {
        this.$emit("cancel")
        Materialize.toast("Aucun site n'est enregistré pour ce membre", 4000, "deep-orange white-text")
      }
    },
    close() {
      this.$emit("cancel")
    },
  },
}
</script>
<style lang="scss">
@import "./src/assets/scss/variables";
@import "./src/assets/scss/functions";

.mcma-sites-interlocuteurs-masse-modal {
  font-family: $font-family !important;
  width: 800px !important;
  .ant-modal-content {
    width: 1000px;
    .ant-modal-footer {
      display: none;
    }
  }
  .container-list {
    display: flex;
    flex-flow: column nowrap;
  }
}
</style>
