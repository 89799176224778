<template>
  <a-modal
    v-model="visible"
    v-if="operation"
    :maskClosable="false"
    class="mcma-import-operation-modal"
    :title="`Import depuis une autre opération`"
  >
    <div class="container-list">
      <div class="sentence">
        <strong>
          Veuillez selectionner l'opération source pour laquel vous souhaitez en tirer les configurations.
        </strong>
      </div>
      <a-form :form="form" class="content-box">
        <a-row type="flex" :gutter="12">
          <mcma-field
            :colSpan="24"
            type="select"
            :form="form"
            :wasSubmit="wasSubmit"
            fieldName="operationSourceId"
            label="Opération source"
            disableValue="Sélectionner l'operation source"
            :required="true"
          >
            <a-select-option
              v-for="(operation, index) in getOperationsWithoutCurrent"
              :key="index"
              :value="operation.id"
            >
              {{ operation.name }}
            </a-select-option>
          </mcma-field>
        </a-row>

        <mcma-card title="Copie Mails et Textes libres" icon="administration" class="mcma-card-in-tab" :inline="true">
          <a-row type="flex" :gutter="24" class="switch-group">
            <mcma-field
              :colSpan="24"
              type="switch"
              :form="form"
              :wasSubmit="wasSubmit"
              :defaultValue="false"
              label="Les mails"
              fieldName="duplicMail"
              flowIsAvailable="false"
              :defaultList="defaultListON"
            />
            <mcma-field
              :colSpan="24"
              type="switch"
              :form="form"
              :wasSubmit="wasSubmit"
              :defaultValue="false"
              label="Les textes libres"
              fieldName="duplicFreeText"
              flowIsAvailable="false"
              :defaultList="defaultListON"
            />
          </a-row>
        </mcma-card>

        <mcma-card title="Copie des Activations" icon="administration" class="mcma-card-in-tab" :inline="true">
          <a-row type="flex" :gutter="24" class="switch-group">
            <mcma-field
              :colSpan="24"
              type="switch"
              :form="form"
              :wasSubmit="wasSubmit"
              :defaultValue="false"
              label="FLOW : Copier les etats actifs et l'ordre"
              fieldName="duplicFlowOrder"
              flowIsAvailable="false"
              :defaultList="defaultListON"
            />
            <mcma-field
              :colSpan="24"
              type="switch"
              :form="form"
              :wasSubmit="wasSubmit"
              :defaultValue="false"
              label="PANEL : Copier les etats actifs"
              fieldName="duplicStatePanel"
              flowIsAvailable="false"
              :defaultList="defaultListON"
            />
          </a-row>
        </mcma-card>
        <mcma-card
          title="Copie des configurations des panels"
          icon="administration"
          class="mcma-card-in-tab"
          :inline="true"
        >
          <a-row type="flex" :gutter="24" class="switch-group">
            <mcma-field
              :colSpan="24"
              type="switch"
              :form="form"
              :wasSubmit="wasSubmit"
              :defaultValue="false"
              label="Sites - Données techniques (électricité)"
              fieldName="duplicSitesElec"
              flowIsAvailable="false"
              :defaultList="defaultListON"
            />
            <mcma-field
              :colSpan="24"
              type="switch"
              :form="form"
              :wasSubmit="wasSubmit"
              :defaultValue="false"
              label="Sites - Données techniques (gaz)"
              fieldName="duplicSitesGaz"
              flowIsAvailable="false"
              :defaultList="defaultListON"
            />
            <mcma-field
              :colSpan="24"
              type="switch"
              :form="form"
              :wasSubmit="wasSubmit"
              :defaultValue="false"
              label="Sites - Localisation"
              fieldName="duplicSitesLocalisation"
              flowIsAvailable="false"
              :defaultList="defaultListON"
            />
            <mcma-field
              :colSpan="24"
              type="switch"
              :form="form"
              :wasSubmit="wasSubmit"
              :defaultValue="false"
              label="Facturation"
              fieldName="duplicGroupements"
              flowIsAvailable="false"
              :defaultList="defaultListON"
            />
            <mcma-field
              :colSpan="24"
              type="switch"
              :form="form"
              :wasSubmit="wasSubmit"
              :defaultValue="false"
              label="Interlocuteurs"
              fieldName="duplicInterlocuteursService"
              flowIsAvailable="false"
              :defaultList="defaultListON"
            />
            <mcma-field
              :colSpan="24"
              type="switch"
              :form="form"
              :wasSubmit="wasSubmit"
              :defaultValue="false"
              label="Services"
              fieldName="duplicServicesService"
              flowIsAvailable="false"
              :defaultList="defaultListON"
            />
            <mcma-field
              :colSpan="24"
              type="switch"
              :form="form"
              :wasSubmit="wasSubmit"
              :defaultValue="false"
              label="Piece - documents upload"
              fieldName="duplicPieceDocumentUpload"
              flowIsAvailable="false"
              :defaultList="defaultListON"
            />
            <mcma-field
              :colSpan="24"
              type="switch"
              :form="form"
              :wasSubmit="wasSubmit"
              :defaultValue="false"
              label="Piece - documents download"
              fieldName="duplicPieceDocumentDownload"
              flowIsAvailable="false"
              :defaultList="defaultListON"
            />
            <mcma-field
              :colSpan="24"
              type="switch"
              :form="form"
              :wasSubmit="wasSubmit"
              :defaultValue="false"
              label="Documents"
              fieldName="duplicDocuments"
              flowIsAvailable="false"
              :defaultList="defaultListON"
            />
          </a-row>
        </mcma-card>
      </a-form>

      <div class="inline-buttons">
        <mcma-button name="Annuler" color="orange" class="mt24" @click="handleCancel" />
        <mcma-button name="Valider les informations" color="primary" class="mt24" @click="handleOk" />
      </div>
    </div>
  </a-modal>
</template>

<script>
import McmaButton from "@/components/mcma/common/McmaButton"
import McmaCard from "@/components/mcma/common/McmaCard"
import McmaField from "@/components/mcma/common/McmaField"
import Materialize from "materialize-css"
import { mapState } from "vuex"
import Axios from "@/util/Axios"

export default {
  name: "McmaImportOperationModal",
  props: {
    visible: Boolean,
    operation: Object,
  },
  components: {
    McmaCard,
    McmaButton,
    McmaField,
  },
  data() {
    return {
      form: this.$form.createForm(this),
      wasSubmit: false,
      defaultListON: ["OUI", "NON"],
    }
  },
  computed: {
    ...mapState(["operations"]),
    getOperationsWithoutCurrent() {
      if (this.operations) {
        return this.operations.filter((op) => op.id !== this.operation.id)
      }
    },
  },
  watch: {
    visible(value) {
      if (value) {
      }
    },
  },
  methods: {
    handleCancel() {
      this.$emit("cancel", this.operation)
    },
    handleOk() {
      this.form.validateFields(async (errors, fieldsValue) => {
        if (errors) {
          this.$notification.warning({
            message: "Selection invalide",
            description: `Veuillez selectionner ce que vous souhaitez importer.`,
          })
        } else {
          console.log("fieldsValue=%O", fieldsValue)
          console.log("Object.entries=%O", Object.entries(fieldsValue))
          const duplicateOperation = []
          for (const [key, value] of Object.entries(fieldsValue)) {
            console.log(`${key}: ${value}`)
            if (value === true && key.startsWith("duplic")) {
              duplicateOperation.push(key)
            }
          }

          const duplicateActions = Array.from(duplicateOperation)
          console.log("idOperationSource=%O", this.form.getFieldValue("operationSourceId"))
          console.log("idOperationTarget=%O", this.operation.id)
          console.log("duplicateOperation:%O", duplicateOperation)
          console.log("duplicateActions:%O", duplicateActions)

          Axios(
            "put",
            "/duplication/duplic/?idOperationSource=" +
              this.form.getFieldValue("operationSourceId") +
              "&idOperationTarget=" +
              this.operation.id,
            { duplicateOperation: duplicateOperation }
          )
            .then(() => {
              Materialize.toast("Données transférées", 4000, "lime white-text")
              this.$emit("save", this.operation)
            })
            .catch((err) => console.error(err))
        }
      })
    },
  },
}
</script>

<style lang="scss">
@import "./src/assets/scss/variables";
@import "./src/assets/scss/functions";

.mcma-import-operation-modal {
  font-family: $font-family !important;
  width: 800px !important;
  .ant-modal-content {
    .ant-modal-footer {
      display: none;
    }
  }
  .container-list {
    display: flex;
    flex-flow: column nowrap;
  }
}
</style>
