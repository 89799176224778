<template>
  <section>
    <div class="container">
      <a-row type="flex" :gutter="12" v-if="groupements">
        <a-col :span="24">
          <groupements-header :groupements="groupements">
            <mcma-spin-box :spinning="loading">
              <groupements-list-table
                :groupements="groupements"
                @initialize="initialize"
                @updateGroupement="updateGroupement"
                @selectedChanged="selectedChanged"
              />
            </mcma-spin-box>
          </groupements-header>
        </a-col>
      </a-row>
    </div>
  </section>
</template>

<script>
import Axios from "@/util/Axios"
import GuardiansMixin from "@/components/mcma/steps/guardians/GuardiansMixin"
import GroupementsHeader from "@/components/mcma/steps/groupements/components/GroupementsHeader"
import GroupementsListTable from "@/components/mcma/steps/groupements/components/GroupementsListTable"
import McmaSpinBox from "@/components/mcma/common/McmaSpinBox"
import { mapMutations, mapActions, mapState } from "vuex"
import _ from "lodash"

export default {
  name: "Groupements",
  mixins: [GuardiansMixin],
  components: {
    GroupementsHeader,
    GroupementsListTable,
    McmaSpinBox,
  },
  data() {
    return {
      flow: "FACTURATION",
      groupements: null,
      loading: true,
    }
  },
  computed: {
    ...mapState(["membre", "user", "operation"]),
  },
  methods: {
    ...mapMutations(["setBreadcrumbAdd", "setState"]),
    ...mapActions(["requestMembreByMembreId"]),
    initialize() {
      this.loading = true
      // this.refreshStore();
      // this.updateMembre(this.membre)
      this.requestMembreByMembreId(this.membre.id)
      Axios("get", "api/membres/" + this.membre.id + "/groupements").then((response) => {
        this.groupements = _.filter(response.data._embedded.groupements, {
          removed: false,
        })
        console.log(
          `%c this.groupements`,
          "background:green ; padding: 5px 7px 4px 0px; border-radius: 3px;  color: #FFFFFF",
          this.groupements
        )
        this.loading = false
      })
      // this.refreshStore();
    },
    updateGroupement(groupementId, dataToUpdate) {
      console.log(
        `%c updateGroupement`,
        "background:#1e4d77 ; padding: 5px 7px 4px 0px; border-radius: 3px;  color: #FFFFFF",
        groupementId,
        dataToUpdate
      )
    },
    selectedChanged(list) {
      console.log(
        `%c selectedChanged`,
        "background:#1e4d77 ; padding: 5px 7px 4px 0px; border-radius: 3px;  color: #FFFFFF",
        list
      )
    },
    resetSiteEditing() {
      this.setState({
        stateName: "groupementEditing",
        value: null,
      })
    },
  },
  created() {
    this.$store._mutations.resetState[0]("groupement")
    this.setBreadcrumbAdd({
      name: "Périmètres de facturation",
      action: () => {
        this.$router.push({
          name: "groupements",
          params: {
            operationId: this.operation.id,
            membreId: this.membre.id,
          },
        })
      },
    })
    this.initialize()
    this.resetSiteEditing()
    this.$nextTick(() => {
      this.initialize()
    })
  },
}
</script>

<style lang="scss">
.progression {
  cursor: pointer;
}
.progression-finish {
  color: red;
}
</style>
