import { mapGetters } from "vuex"

export default {
  computed: {
    ...mapGetters(["getFlow"]),
  },
  created() {
    console.group("GardiansMixin")
    console.log("> this.getFlow : %O", this.getFlow)
    console.log("> this.flow : %O", this.flow)
    if (!this.getFlow.includes(this.flow)) {
      this.$notification.warning({
        message: "Access refusé",
        description: "Vous n'avez pas accès à cette fonctionnalité.",
      })
      // this.$router.back();
    }
    console.groupEnd()
  },
}
