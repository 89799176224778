export const Segments = {
  C_0: {
    name: "C0",
    fta: [
      "HTA_CU_POINTE_FIXE",
      "HTA_LU_POINTE_FIXE",
      "HTA_CU_POINTE_MOBILE",
      "HTA_LU_POINTE_MOBILE",
      "BTINF_CU_BASE",
      "BTINF_CU_4",
      "BTINF_LU_BASE",
      "BTINF_MU_HP_HC",
      "BTSUP_CU",
      "BTSUP_LU",
      "BTINF_MU_4",
    ],
  },
  C_2: {
    name: "C2",
    fta: ["HTA_CU_POINTE_FIXE", "HTA_LU_POINTE_FIXE", "HTA_CU_POINTE_MOBILE", "HTA_LU_POINTE_MOBILE"],
  },
  C_3: {
    name: "C3",
    fta: ["HTA_CU_POINTE_FIXE", "HTA_LU_POINTE_FIXE", "HTA_CU_POINTE_MOBILE", "HTA_LU_POINTE_MOBILE"],
  },
  C_4: {
    name: "C4",
    fta: ["BTSUP_CU", "BTSUP_LU"],
  },
  C_5: {
    name: "C5",
    fta: ["BTINF_CU_BASE", "BTINF_CU_4", "BTINF_LU_BASE", "BTINF_MU_HP_HC", "BTINF_MU_4"],
  },
}
