<template>
  <div class="mcma-table">
    <div class="header">
      <div class="selected-sentence" :class="{ show: hasSelected }" v-if="dataSource.length > 0">
        <template>
          {{ selectedSentence }}
        </template>
      </div>
    </div>
    <a-table
      :rowSelection="rowSelection"
      :columns="getColumns"
      :dataSource="dataSource"
      :scroll="{ x: true }"
      :pagination="{
        defaultPageSize: 20,
        showSizeChanger: true,
        pageSizeOptions: ['5', '10', '20', '30', '50', '100'],
      }"
      @change="onChange"
      :locale="getLocale"
      bordered
    >
      <div
        slot="filterDropdown"
        slot-scope="{ setSelectedKeys, selectedKeys, confirm, clearFilters, column }"
        style="padding: 8px"
      >
        <a-input
          v-ant-ref="(c) => (searchInput[column.dataIndex] = c)"
          :placeholder="`Recherche par ${column.title.toLowerCase()}`"
          :value="selectedKeys[0]"
          @change="(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])"
          @pressEnter="() => handleSearch(column.dataIndex, selectedKeys, confirm)"
          style="width: 188px; margin-bottom: 8px; display: block"
        />
        <a-button
          type="primary"
          @click="() => handleSearch(column.dataIndex, selectedKeys, confirm)"
          icon="search"
          size="small"
          style="width: 90px; margin-right: 8px"
        >
        </a-button>
        <a-button
          @click="() => handleReset(column.dataIndex, clearFilters)"
          icon="sync"
          size="small"
          style="width: 90px"
        >
        </a-button>
      </div>
      <a-icon slot="filterIcon" slot-scope="filtered" type="search" :class="{ 'blue-color': filtered }" />
      <template slot="name" slot-scope="text">
        <mcma-search-table-filter :searchText="searchText" fieldName="name" :text="text" />
      </template>
      <template slot="type" slot-scope="text">
        <mcma-search-table-filter :searchText="searchText" fieldName="type" :text="text" />
      </template>
      <template slot="downloaded" slot-scope="text">
        <mcma-search-table-filter :searchText="searchText" fieldName="downloaded" :text="text" />
      </template>
      <template slot="uploaded" slot-scope="text">
        <mcma-search-table-filter :searchText="searchText" fieldName="uploaded" :text="text" />
      </template>
      <div slot="actions" slot-scope="props">
        <div class="cell-actions">
          <div class="actions-block">
            <div class="line">
              <div @click="downloadDocument(props)" v-if="isDownloadPage">
                <a-tooltip placement="top">
                  <template slot="title">
                    <span>Télécharger la pièce originale</span>
                  </template>
                  <div>
                    <mcma-icon type="download" color="purple" :faIcon="true" :full="true" />
                  </div>
                </a-tooltip>
              </div>
              <div v-if="!isDownloadPage" @click="triggerUpload(props)" class="ml5">
                <a-tooltip placement="top">
                  <template slot="title">
                    <span>Déposer la pièce</span>
                  </template>
                  <div>
                    <mcma-icon type="upload" color="success" :faIcon="true" :full="true" />
                  </div>
                </a-tooltip>
              </div>
              <div @click="downloadDocumentMembre(props)" v-if="isUploaded(props)" class="ml5">
                <a-tooltip placement="top">
                  <template slot="title">
                    <span>Voir votre version signé pour vérification</span>
                  </template>
                  <div>
                    <mcma-icon type="eye" color="primary" :faIcon="true" :full="true" />
                  </div>
                </a-tooltip>
              </div>
            </div>
          </div>
        </div>
      </div>
    </a-table>
    <input class="ninja" type="file" ref="documentUploaded" @change="(event) => onUploadChange(event)" />
    <a class="ninja" ref="downloadLink" />
  </div>
</template>

<script>
import Vue from "vue"
import McmaIcon from "@/components/mcma/common/McmaIcon"
import McmaButton from "@/components/mcma/common/McmaButton"
import McmaSearchTableFilter from "@/components/mcma/common/McmaSearchTableFilter"
import { mapState } from "vuex"
import Axios from "@/util/Axios"
import Constants from "@/util/Constants"
import { accorization } from "@/util/Helpers"

Vue.component("document-empty-without-filter", {
  template: `<a-empty image="https://gw.alipayobjects.com/mdn/miniapp_social/afts/img/A*pevERLJC9v0AAAAAAAAAAABjAQAAAQ/original">
                <span slot="description"> Aucun document </span>
              </a-empty>`,
})

Vue.component("document-empty-with-filter", {
  template: `<a-empty image="https://gw.alipayobjects.com/mdn/miniapp_social/afts/img/A*pevERLJC9v0AAAAAAAAAAABjAQAAAQ/original">
                <span slot="description"> Aucun document ne correspond au filtre renseigné </span>
              </a-empty>`,
})

export default {
  name: "DocumentsListTable",
  props: {
    documents: Array,
    documentsDownloaded: Array,
    documentsUploaded: Array,
    isDownloadPage: Boolean,
  },
  components: {
    McmaIcon,
    McmaButton,
    McmaSearchTableFilter,
  },
  data() {
    return {
      selectedRowKeys: [],
      oldSelectedRowKeys: [],
      dataSource: [],
      currentDoc: null,
      loading: false,
      filters: {},
      searchText: {
        name: "",
        type: "",
      },
      searchInput: {
        name: null,
        type: null,
      },
      columns: [
        {
          title: "Nom de la piece",
          dataIndex: "name",
          key: "name",
          fixed: "left",
          width: 200,
          scopedSlots: {
            filterDropdown: "filterDropdown",
            filterIcon: "filterIcon",
            customRender: "name",
          },
          onFilter: (value, record) =>
            record.name ? record.name.toString().toLowerCase().includes(value.toLowerCase()) : null,
          onFilterDropdownVisibleChange: (visible) => {
            if (visible) {
              setTimeout(() => {
                this.searchInput["name"].focus()
              }, 0)
            }
          },
        },
        {
          title: "Type de piece",
          dataIndex: "type",
          key: "type",
          scopedSlots: {
            filterDropdown: "filterDropdown",
            filterIcon: "filterIcon",
            customRender: "type",
          },
          onFilter: (value, record) =>
            record.type ? record.type.toString().toLowerCase().includes(value.toLowerCase()) : null,
          onFilterDropdownVisibleChange: (visible) => {
            if (visible) {
              setTimeout(() => {
                this.searchInput["type"].focus()
              }, 0)
            }
          },
        },
        {
          title: "Déjà téléchargée",
          dataIndex: "downloaded",
          key: "downloaded",
          filters: [
            {
              text: Constants.FILESTATUS.DOWNLOADED.text,
              value: Constants.FILESTATUS.DOWNLOADED.text,
            },
            {
              text: Constants.FILESTATUS.NOT_DOWNLOADED.text,
              value: Constants.FILESTATUS.NOT_DOWNLOADED.text,
            },
          ],
          onFilter: (value, record) => record.downloaded === value,
          // defaultFilteredValue: [Constants.FILESTATUS.NOT_DOWNLOADED.text],
          scopedSlots: { customRender: "downloaded" },
        },
        {
          title: "Déjà déposée",
          dataIndex: "uploaded",
          key: "uploaded",
          filters: [
            {
              text: Constants.FILESTATUS.UPLOADED.text,
              value: Constants.FILESTATUS.UPLOADED.text,
            },
            {
              text: Constants.FILESTATUS.NOT_UPLOADED.text,
              value: Constants.FILESTATUS.NOT_UPLOADED.text,
            },
          ],
          onFilter: (value, record) => record.uploaded === value,
          // defaultFilteredValue: [Constants.FILESTATUS.NOT_UPLOADED.text],
          scopedSlots: { customRender: "uploaded" },
        },
        {
          title: "Actions",
          fixed: "right",
          key: "actions",
          width: 100,
          scopedSlots: { customRender: "actions" },
        },
      ],
    }
  },
  computed: {
    ...mapState(["membre", "user", "currentTutorial", "operation"]),
    getLocale() {
      return {
        emptyText: this.dataSource.length === 0 ? <document-empty-without-filter /> : <document-empty-with-filter />,
      }
    },
    selectedSentence() {
      return accorization(this.oldSelectedRowKeys.length, "document sélèctionné", true, "aucun document sélèctionné")
    },
    getColumns() {
      return this.columns
    },
    hasSelected() {
      return this.selectedRowKeys.length > 0
    },
    rowSelection() {
      const { selectedRowKeys } = this
      return {
        selectedRowKeys,
        onSelectAll: this.onSelectAll,
        onChange: this.onSelectChange,
        hideDefaultSelections: true,
        onSelection: this.onSelection,
      }
    },
  },
  watch: {
    documents(newValue) {
      this.generateDataSource(newValue)
    },
    documentsDownloaded() {
      this.generateDataSource(this.documents)
    },
    documentsUploaded() {
      this.generateDataSource(this.documents)
    },
    selectedRowKeys(newValue, oldValue) {
      this.oldSelectedRowKeys = newValue.length === 0 ? [...oldValue] : [...newValue]
    },
  },
  mounted() {
    this.generateDataSource(this.documents)
  },
  methods: {
    updateSelectRowsOnFilterChange() {
      const dataSource = this.getDataSourceFiltered()
      this.selectedRowKeys = this.selectedRowKeys.filter((key) => {
        return dataSource.find((data) => {
          return key === data.key
        })
      })
    },
    onSelectChange(selectedRowKeys) {
      this.selectedRowKeys = selectedRowKeys
      const list = this.dataSource.filter((data) => selectedRowKeys.includes(data.key)).map((data) => data.key)
      this.$emit("selectedChanged", list)
    },
    onChange(pagination, filters, sorter) {
      this.filters = filters
      this.updateSelectRowsOnFilterChange()
    },
    generateDataSource(documents) {
      documents = this.setDocumentDownloadedUploaded(documents)
      this.dataSource = []
      documents.forEach((doc, index) => {
        this.dataSource.push({
          key: index,
          id: doc.id,
          name: doc.name,
          type: doc.type,
          downloaded: doc.downloaded,
          uploaded: doc.uploaded,
          extension: doc.extension,
        })
      })
      this.dataSource.sort((a, b) => b.id - a.id)
    },
    getDataSourceFiltered() {
      const toCheck = ["downloaded", "uploaded"]
      let dataSourceFiltered = [...this.dataSource].sort((a, b) => b.id - a.id)
      toCheck.forEach((key) => {
        if (this.filters[key] && this.filters[key].length > 0) {
          dataSourceFiltered = dataSourceFiltered.filter((data) => this.filters[key].includes(data[key]))
        }
      })
      for (let key in this.searchInput) {
        if (this.filters[key] && this.filters[key].length > 0) {
          dataSourceFiltered = dataSourceFiltered.filter((data) =>
            data[key] ? data[key].toString().toLowerCase().includes(this.searchText[key].toLowerCase()) : null
          )
        }
      }
      return dataSourceFiltered
    },
    onSelectAll(selected) {
      let dataSourceFiltered = []
      if (selected) {
        dataSourceFiltered = this.getDataSourceFiltered()
      }
      this.selectedRowKeys = dataSourceFiltered.map((data) => data.key)
      this.onSelectChange(this.selectedRowKeys)
    },
    handleSearch(fieldName, selectedKeys, confirm) {
      confirm()
      this.searchText[fieldName] = selectedKeys[0]
    },
    handleReset(fieldName, clearFilters) {
      clearFilters()
      this.searchText[fieldName] = ""
    },
    isUploaded(item) {
      return item.uploaded === Constants.FILESTATUS.UPLOADED.text
    },
    downloadDocument(doc) {
      Axios(
        "get",
        `file/piece/download?pieceId=${doc.id}&operationId=${this.operation.id}&membreId=${this.membre.id}`,
        null,
        {
          responseType: "blob",
        }
      )
        .then((response) => {
          console.log("oui oui on est là ")
          if (this.documentsDownloaded.indexOf(doc) === -1) {
            this.documentsDownloaded.push(doc)
            // doc.name = doc.name + ' (doc téléchargée)'
            doc.downloaded = Constants.FILESTATUS.DOWNLOADED.text
          }
          const link = this.$refs.downloadLink
          link.href = window.URL.createObjectURL(new Blob([response.data]))
          var fileName = doc.name + "." + doc.extension
          link.setAttribute("download", fileName)
          link.click()
          // this.$emit("initialize") ne fonctionne pas, va savoir pq ... (à check de plus près) => obligé de créer un autre event qui appelle initialize dans Documents.vue
          this.$emit("event")
          // this.goToNextStep();
        })
        .catch((error) => {
          console.error(error)
          this.$notification.error({
            message: "Erreur lors du téléchargement",
            description:
              "Une erreur est survenue lors du telechargement de la piece, veuillez contacter un administrateur.",
          })
        })
    },
    setDocumentDownloadedUploaded(documents) {
      console.group("setDocumentDownloadedUploaded")
      console.log("this.documentsUploaded : %O", this.documentsUploaded)
      if (documents) {
        documents.map((doc) => {
          const downloadFind = this.documentsDownloaded.find((d) => d.id === doc.id)
          const uploadFind = this.documentsUploaded.find((d) => {
            console.log("d:%O", d)
            console.log("doc.name:%O", doc.name)
            // voir sur le back dans quel cas on est
            // cas bof mise ne place à cause du SEHV.....
            // et de leur cas 1 et 2 dans la gestion des pièces
            if (d.name) {
              // cas bof lors de la vérif du tag upload en base
              return d.name.includes(doc.name)
            } else {
              // cas idéal lors de la vérif du fichier sur serveur
              return d.includes(doc.name)
            }
          })
          doc.downloaded = Constants.FILESTATUS[downloadFind ? "DOWNLOADED" : "NOT_DOWNLOADED"].text
          doc.uploaded = Constants.FILESTATUS[uploadFind ? "UPLOADED" : "NOT_UPLOADED"].text
        })
      }
      console.groupEnd()
      return documents
    },
    triggerUpload(doc) {
      this.currentDoc = doc
      const elem = this.$refs.documentUploaded
      elem.click()
    },
    onUploadChange(event) {
      if (event.target.files[0]) {
        this.uploadPieceMembre(event, this.currentDoc)
      }
    },
    uploadPieceMembre(event, doc) {
      console.group("uploadPieceMembre@FlowPieceUpload")
      // Attention bien penser à supprimer les attibuts downloaded et uploaded pour être conforme au back
      delete doc.downloaded
      delete doc.uploaded

      const sizeMax = process.env.VUE_APP_UPLOAD_SIZE * 1024 * 1024
      const file = event.target.files[0]

      if (file.size > sizeMax) {
        this.$message.warning(`Le fichier dépasse la taille autorisé qui est de ${process.env.VUE_APP_UPLOAD_SIZE}Mo.`)
      } else {
        const formData = new FormData()
        formData.append("piece", JSON.stringify(doc))
        formData.append("file", file)
        formData.append("membreId", this.membre.id)
        this.currentDoc = null
        Axios("post", "/file/piece/membre", formData, null, {
          "Content-Type": "multipart/form-data",
        })
          .then(() => {
            this.$emit("initialize")
            this.$notification.success({
              message: "Pièce signée !",
              description: `La pièce déposé à bien été signé.`,
            })
          })
          .catch((error) => {
            console.error(error)
            this.$notification.error({
              message: "Erreur de signature",
              description: `Une erreur c'est produite lors de la signature, veuillez contacter un administrateur.`,
            })
          })
      }
      console.groupEnd()
    },
    downloadDocumentMembre(doc) {
      console.group("downloadDocumentMembre@FlowPieceDownload")
      Axios("get", "file/pieceMembre/download?pieceId=" + doc.id + "&membreId=" + this.membre.id, null, {
        responseType: "blob",
      })
        .then((response) => {
          if (response.status === 200) {
            const link = this.$refs.downloadLink
            link.href = window.URL.createObjectURL(new Blob([response.data]))
            Axios("get", "file/pieceMembre/name?pieceId=" + doc.id + "&membreId=" + this.membre.id)
              .then((response) => {
                this.$emit("initialize")
                link.setAttribute("download", response.data)
                link.click()
              })
              .catch((error) => {
                console.error(error)
                this.$notification.error({
                  message: "Erreur lors du téléchargement",
                  description: "Désolé nous n'avons pas pu réccupérer le nom du fichier.",
                })
              })
          } else {
            this.$notification.error({
              message: "Erreur lors du téléchargement",
              description: "Désolé la pièce n'a pas été trouvée sur le serveur...",
            })
          }
        })
        .catch((error) => {
          console.error(error)
          this.$notification.error({
            message: "Erreur lors du téléchargement",
            description: "Désolé le téléchargement n'est pas disponible !",
          })
        })
      console.groupEnd()
    },
  },
}
</script>

<style lang="scss"></style>
