var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(!_vm.text)?_c('div',[_vm._v(" "+_vm._s(_vm.getText)+" ")]):_vm._e(),((typeof _vm.text === 'string' || typeof _vm.text === 'number') && _vm.tag)?_c('div',[(_vm.searchText[_vm.fieldName])?_c('span',{staticClass:"customTag"},[_c('div',{staticClass:"customTag-items"},_vm._l((_vm.text
            .toString()
            .split(new RegExp(("(?<=" + (_vm.searchText[_vm.fieldName]) + ")|(?=" + (_vm.searchText[_vm.fieldName]) + ")"), 'i'))),function(fragment,i){return _c('div',{key:'-' + i,staticClass:"part-of-split-tag",class:{
            'highlight-part': fragment.toLowerCase() === _vm.searchText[_vm.fieldName].toLowerCase(),
            split:
              _vm.text.toString().split(new RegExp(("(?<=" + (_vm.searchText[_vm.fieldName]) + ")|(?=" + (_vm.searchText[_vm.fieldName]) + ")"), 'i'))
                .length -
                1 >
              0,
            alone:
              i === 0 &&
              _vm.text.toString().split(new RegExp(("(?<=" + (_vm.searchText[_vm.fieldName]) + ")|(?=" + (_vm.searchText[_vm.fieldName]) + ")"), 'i'))
                .length -
                1 ===
                i,
            first:
              i === 0 &&
              !(
                _vm.text.toString().split(new RegExp(("(?<=" + (_vm.searchText[_vm.fieldName]) + ")|(?=" + (_vm.searchText[_vm.fieldName]) + ")"), 'i'))
                  .length -
                  1 ===
                i
              ),
            middle:
              i > 0 &&
              i <
                _vm.text.toString().split(new RegExp(("(?<=" + (_vm.searchText[_vm.fieldName]) + ")|(?=" + (_vm.searchText[_vm.fieldName]) + ")"), 'i'))
                  .length -
                  1 &&
              _vm.text.toString().split(new RegExp(("(?<=" + (_vm.searchText[_vm.fieldName]) + ")|(?=" + (_vm.searchText[_vm.fieldName]) + ")"), 'i'))
                .length -
                1 !==
                i,
            last:
              i > 0 &&
              _vm.text.toString().split(new RegExp(("(?<=" + (_vm.searchText[_vm.fieldName]) + ")|(?=" + (_vm.searchText[_vm.fieldName]) + ")"), 'i'))
                .length -
                1 ===
                i,
          }},[(fragment.toLowerCase() === _vm.searchText[_vm.fieldName].toLowerCase())?_c('a-tag',{staticClass:"highlight"},[_vm._v(" "+_vm._s(fragment)+" ")]):[_c('a-tag',{attrs:{"color":_vm.tagColor}},[_vm._v(" "+_vm._s(fragment)+" ")])]],2)}),0)]):[_c('div',{staticClass:"tag-list"},[_c('a-tag',{attrs:{"color":_vm.tagColor}},[_vm._v(" "+_vm._s(_vm.getText)+" ")])],1)]],2):(typeof _vm.text === 'string' || typeof _vm.text === 'number')?_c('div',[(_vm.searchText[_vm.fieldName])?_c('span',{staticClass:"mcma-search-table-filter"},[_vm._l((_vm.text
          .toString()
          .split(new RegExp(("(?<=" + (_vm.searchText[_vm.fieldName]) + ")|(?=" + (_vm.searchText[_vm.fieldName]) + ")"), 'i'))),function(fragment,i){return [(fragment.toLowerCase() === _vm.searchText[_vm.fieldName].toLowerCase())?_c('div',{key:i,staticClass:"highlight"},[_vm._v(" "+_vm._s(fragment)+" ")]):[_vm._v(_vm._s(fragment))]]})],2):[_vm._v(" "+_vm._s(_vm.getText)+" ")]],2):(typeof _vm.text === 'object')?_c('div',[(_vm.searchText[_vm.fieldName])?_c('span',{staticClass:"customTag"},_vm._l((_vm.text),function(tag,index){return _c('div',{key:index,staticClass:"customTag-items"},_vm._l((tag
            .toString()
            .split(new RegExp(("(?<=" + (_vm.searchText[_vm.fieldName]) + ")|(?=" + (_vm.searchText[_vm.fieldName]) + ")"), 'i'))),function(fragment,i){return _c('div',{key:index + '-' + i,staticClass:"part-of-split-tag",class:{
            'highlight-part': fragment.toLowerCase() === _vm.searchText[_vm.fieldName].toLowerCase(),
            split:
              tag.toString().split(new RegExp(("(?<=" + (_vm.searchText[_vm.fieldName]) + ")|(?=" + (_vm.searchText[_vm.fieldName]) + ")"), 'i'))
                .length -
                1 >
              0,
            alone:
              i === 0 &&
              tag.toString().split(new RegExp(("(?<=" + (_vm.searchText[_vm.fieldName]) + ")|(?=" + (_vm.searchText[_vm.fieldName]) + ")"), 'i'))
                .length -
                1 ===
                i,
            first:
              i === 0 &&
              !(
                tag.toString().split(new RegExp(("(?<=" + (_vm.searchText[_vm.fieldName]) + ")|(?=" + (_vm.searchText[_vm.fieldName]) + ")"), 'i'))
                  .length -
                  1 ===
                i
              ),
            middle:
              i > 0 &&
              i <
                tag.toString().split(new RegExp(("(?<=" + (_vm.searchText[_vm.fieldName]) + ")|(?=" + (_vm.searchText[_vm.fieldName]) + ")"), 'i'))
                  .length -
                  1 &&
              tag.toString().split(new RegExp(("(?<=" + (_vm.searchText[_vm.fieldName]) + ")|(?=" + (_vm.searchText[_vm.fieldName]) + ")"), 'i'))
                .length -
                1 !==
                i,
            last:
              i > 0 &&
              tag.toString().split(new RegExp(("(?<=" + (_vm.searchText[_vm.fieldName]) + ")|(?=" + (_vm.searchText[_vm.fieldName]) + ")"), 'i'))
                .length -
                1 ===
                i,
          }},[(fragment.toLowerCase() === _vm.searchText[_vm.fieldName].toLowerCase())?_c('a-tag',{staticClass:"highlight"},[_vm._v(" "+_vm._s(fragment)+" ")]):[_c('a-tag',{attrs:{"color":_vm.tagColor}},[_vm._v(" "+_vm._s(fragment)+" ")])]],2)}),0)}),0):[_c('div',{staticClass:"tag-list"},_vm._l((_vm.text),function(tag,index){return _c('a-tag',{key:index,attrs:{"color":_vm.tagColor}},[_vm._v(" "+_vm._s(tag)+" ")])}),1)]],2):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }