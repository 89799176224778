<template>
  <section>
    <div class="container">
      <a-row type="flex" :gutter="12">
        <a-col :span="24">
          <emails-tracker-header :emailsTracker="emailsTracker">
            <mcma-spin-box :spinning="loading">
              <emails-tracker-list-table
                :emailsTracker="emailsTracker"
                @initialize="initialize"
                @updateMails="updateMails"
                @selectedChanged="selectedChanged"
              />
            </mcma-spin-box>
          </emails-tracker-header>
        </a-col>
      </a-row>
    </div>
  </section>
</template>

<script>
import McmaSpinBox from "@/components/mcma/common/McmaSpinBox"
import EmailsTrackerHeader from "@/components/mcma/admin/emailsTracker/components/EmailsTrackerHeader"
import EmailsTrackerListTable from "@/components/mcma/admin/emailsTracker/components/EmailsTrackerListTable"
import { mapActions, mapMutations, mapState } from "vuex"
import Axios from "@/util/Axios"

export default {
  name: "emailsTracker",
  components: {
    EmailsTrackerHeader,
    EmailsTrackerListTable,
    McmaSpinBox,
  },
  data() {
    return {
      selected: [],
      loading: true,
    }
  },
  computed: {
    ...mapState(["user", "operation", "emailsTracker"]),
    ...mapActions(["refreshStore"]),
  },
  methods: {
    ...mapMutations(["setBreadcrumbAdd"]),
    ...mapActions(["requestEmailsTracker"]),
    initialize() {
      Axios("get", "/users/mailjet/retrieve?idOperation=" + this.operation.id).then(() => {
        this.requestEmailsTracker().then(() => {
          this.loading = false
        })
      })
    },
    updateMails(mailId, dataToUpdate) {
      console.log(
        `%c updateMails`,
        "background:#1e4d77 ; padding: 5px 7px 4px 0px; border-radius: 3px;  color: #FFFFFF",
        mailId,
        dataToUpdate
      )
    },
    selectedChanged(list) {
      console.log(
        `%c selectedChanged`,
        "background:#1e4d77 ; padding: 5px 7px 4px 0px; border-radius: 3px;  color: #FFFFFF",
        list
      )
    },
  },
  created() {
    this.setBreadcrumbAdd({
      name: "Suivi des emails",
      action: () => {
        this.$router.push({
          name: "emails-tracker",
        })
      },
    })
  },
  mounted() {
    this.initialize()
  },
}
</script>

<style lang="scss" scoped></style>
