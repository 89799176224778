<template>
  <section>
    <div
      class="tab"
      :class="activeTab === index ? 'active' : ''"
      v-for="(step, index) in steps"
      :key="index"
      @click="selectStep(step, index)"
    >
      <span v-if="isGeneralFlow(step)">
        {{ $t("super_admin.editor.steps." + step.name) }}
      </span>
      <span v-else> {{ index }} - {{ $t("super_admin.editor.steps." + step.name) }} </span>
    </div>
    <div class="stepContainer">
      <div v-for="(step, index) in steps" :key="index" v-if="activeTab === index">
        <general-flow v-if="isGeneralFlow(step)" :generalFlow="step" />
        <div class="switch" v-else>
          <div>
            <h6>
              Activer cette étape
              <label>
                <input type="checkbox" v-model="step.active" />
                <span class="lever"></span>
              </label>
            </h6>
          </div>
          <div>
            <span>
              <h6>Ordre d'apparition de cette étape dans le flow</h6>
            </span>
            <input v-model="step.ordre" />
          </div>
          <div>
            <text-editor-presentation :step="step" />
          </div>
        </div>
      </div>
      <component
        :is="selectedStep.name"
        v-if="!isGeneralFlow(selectedStep) && selectedStep.active && !!selectedStep.panels"
        class="stepEditor"
        v-model="selectedStep.panels"
      />
    </div>
  </section>
</template>

<script>
import TextEditorPresentation from "./stepEditor/TextEditorPresentation"
import GeneralFlow from "./GeneralFlow"
import { Steps } from "./stepEditor"
import { VueEditor } from "vue2-editor"

export default {
  name: "flow",
  props: ["value"],
  components: {
    ...Steps,
    GeneralFlow,
    TextEditorPresentation,
    VueEditor,
  },
  data() {
    return {
      steps: [],
      activeTab: 0,
      selectedStep: null,
    }
  },
  computed: {
    operation: {
      get() {
        console.log("operation@IndexFlow : %O", this.value)
        return this.value
      },
      set(value) {
        this.$emit("input", value)
      },
    },
    isGeneralFlow() {
      return (step) => {
        return step.name === "general"
      }
    },
  },
  created() {
    this.steps = this.getSteps()
    this.initSelectedStep()
  },
  methods: {
    initSelectedStep() {
      this.selectedStep = this.steps[0]
    },
    selectStep(step, index) {
      this.selectedStep = step
      this.activeTab = index
    },
    getSteps() {
      let steps = []
      console.groupCollapsed("Compute StepOrder")
      console.log(this.operation.flows)
      if (this.operation && this.operation.flows) {
        const general = this.operation.flows.find((item) => item.name === "general")
        if (general) {
          steps.push(general)
        }
        const documentsDownload = this.operation.flows.find(
          (item) => item.name === "documents-download" || item.name === "piecetelechargement"
        )
        if (documentsDownload) {
          steps.push(documentsDownload)
        }
        const sites = this.operation.flows.find((item) => item.name === "sites")
        if (sites) {
          steps.push(sites)
        }
        const groupements = this.operation.flows.find((item) => item.name === "groupements")
        if (groupements) {
          steps.push(groupements)
        }
        const services = this.operation.flows.find((item) => item.name === "services")
        if (services) {
          steps.push(services)
        }
        const documents = this.operation.flows.find((item) => item.name === "documents")
        if (documents) {
          steps.push(documents)
        }
        const documentsUpload = this.operation.flows.find(
          (item) => item.name === "documents-upload" || item.name === "piecedepot"
        )
        if (documentsUpload) {
          steps.push(documentsUpload)
        }
      }
      console.log("> steps : %O", steps)
      console.groupEnd()
      return steps
    },
  },
}
</script>

<style lang="scss" scoped>
.tab {
  display: inline-block;
  padding: 5px 10px;
  border-radius: 5px 5px 0 0;
  cursor: pointer;
  transition: 0.3s;
  &:not(.active) {
    background: #dbdbdb;
    border: 1px solid #cccccc;
    &:hover {
      background: #f5f5f5;
      border: 1px solid #f5f5f5;
    }
  }
  &.active {
    background: #f5f5f5;
    border: 1px solid #f5f5f5;
  }
}

.stepContainer {
  border-radius: 0 5px 5px 5px;
  background: #f5f5f5;
  padding: 5px;
  & .stepEditor {
    background: white;
    border-radius: 5px;
    padding: 0;
    margin: 5px;
    overflow: hidden;
  }
  & .editor-box {
    background: white;
    border-radius: 5px;
    margin: 5px;
  }
}
</style>
