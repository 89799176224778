<template>
  <section>
    <label class="active" for="delaiPaiement">
      <font-awesome-icon icon="asterisk" class="red-text" />
      Délai de paiement
    </label>
    <select id="delaiPaiement" class="browser-default" required v-model="field" :disabled="disabled">
      <option :value="null" disabled selected>Sélectionnez un délai de paiement</option>
      <option v-for="(friendly, key) in values" :value="key" :key="key">
        {{ friendly }}
      </option>
    </select>
  </section>
</template>

<script>
import Mixin from "./Mixin"
import _ from "lodash"

export default {
  name: "delai-paiement",
  mixins: [Mixin],
  computed: {
    values() {
      // console.log('values@DelaisDePaiement')
      // console.log('this.operation : %O', this.operation)
      if (["FDE80", "CARREFOUR", "ApprolysCentrachats"].includes(this.confOperation)) {
        return {
          JOURS_30: "30 jours",
        }
      }
      return {
        JOURS_15: "15 jours",
        JOURS_19: "19 jours",
        JOURS_30: "30 jours",
        JOURS_45: "45 jours",
        JOURS_50: "50 jours",
        JOURS_60: "60 jours",
      }
    },
    disabled() {
      return _.keys(this.values).length === 1
    },
  },
  created() {
    if (this.disabled) {
      this.field = _.keys(this.values)[0]
    }
  },
}
</script>

<style lang="scss" scoped></style>
