<template>
  <a-modal v-model="visible" :maskClosable="false" class="mcma-editor-modal" :title="getTitle">
    <div>
      <span class="align-end mr5" v-if="getFlow && getFlow.variables">
        <mcma-select-variables
          :variables="getFlow.variables"
          :disabled="!editor.selection"
          @addVariable="addVariableInText($event, 'editor')"
        />
      </span>
      <vue-editor
        id="editor"
        v-model="getFlowText"
        @focus="addQuill($event, 'editor')"
        @selection-change="getCursorPosition($event, 'editor')"
        @text-change="getCursorPosition($event, 'editor')"
      />
    </div>
    <template slot="footer">
      <div class="align-row-end mtb12">
        <mcma-button
          name="Fermer sans sauvegarder"
          color="orange"
          class="mr12"
          leftIcon="times"
          :isFaIcon="true"
          @click="handleCancel"
        />
        <mcma-button
          class="ml12"
          name="Valider les changements"
          color="success"
          leftIcon="check"
          :isFaIcon="true"
          :loading="loading"
          @click="handleOk"
        />
      </div>
    </template>
  </a-modal>
</template>

<script>
// <mcma-editor-modal :visible=""
//                    :flowName=""
//                    :flowProp=""
//                    @onCancel=""
//                    @onOk="" />

import McmaSelectVariables from "@/components/mcma/common/McmaSelectVariables"
import { VueEditor } from "vue2-editor"
import McmaButton from "@/components/mcma/common/McmaButton"
import { mapMutations, mapState } from "vuex"
import Axios from "@/util/Axios"

export default {
  name: "McmaEditorModal",
  props: {
    visible: Boolean,
    flowName: String,
    flowProp: String,
  },
  components: {
    McmaButton,
    McmaSelectVariables,
    VueEditor,
  },
  data() {
    return {
      getFlowText: "",
      loading: false,
      editor: {
        selection: null,
        quill: null,
      },
    }
  },
  computed: {
    ...mapState(["user", "operation"]),
    getTitle() {
      switch (this.flowProp) {
        case "collectDescribe":
          return "Text libre de présentation de la collecte"
        case "objectifDescribe":
          return "Text libre de présentation de l'objectif des étapes"
        case "generalInfoDescribe":
          return "Text libre de présentation de l'information générale"
        case "sftapDescribe":
          return "Text libre de présentation pour Segment, Formule tarifaire d'acheminement (FTA) et Puissances"
        case "sftapComment":
          return "Aide au commentaire pour Segment, Formule tarifaire d'acheminement (FTA) et Puissances"
        case "describeInFlow":
          return "Text libre de présentation de l'étape adressé aux utilisateurs"
        case "cardDescribe":
          return "Text libre de présentation de la carte de l'étape"
        case "instruction":
          return "Text libre de présentation de l'instruction de l'étape"
        default:
          return "Text libre de présentation"
      }
    },
    getFlow() {
      return this.operation.flows.find((flow) => flow.name === this.flowName)
    },
  },
  watch: {
    visible(value) {
      if (value) {
        this.initialize()
      }
    },
  },
  methods: {
    ...mapMutations(["setOperation"]),
    initialize() {
      const flow = { ...this.getFlow }
      if (flow) {
        this.getFlowText = flow[this.flowProp]
      }
    },
    handleCancel() {
      this.$emit("onCancel")
    },
    handleOk() {
      this.saveOperation()
    },
    getCursorPosition(range, editor) {
      if (range) {
        if (range.ops && this[editor].selection) {
          if (range.ops[1]) {
            this[editor].selection.index = range.ops[0].retain
            this[editor].selection.length = range.ops[1].retain
          } else {
            this[editor].selection.index = range.ops[0].retain + 1
            this[editor].selection.length = 0
          }
        } else if (!range.ops) {
          this[editor].selection = range
        }
      }
    },
    addQuill(quill, editor) {
      if (quill) {
        this[editor].quill = quill
      }
    },
    addVariableInText(variable, editor) {
      const position = this[editor].selection.index
      const lengthSelection = this[editor].selection.length

      this[editor].quill.deleteText(position, lengthSelection)
      this[editor].quill.insertText(position, variable)
    },
    saveOperation() {
      this.loading = true
      const updateOperation = { ...this.operation }
      let currentFlow = updateOperation.flows.find((flow) => flow.name === this.flowName)
      currentFlow[this.flowProp] = this.getFlowText
      this.$deleteKeyRecursive(updateOperation, "_links", true)
      if (updateOperation) {
        if (this.editor && this.editor.quill) {
          this.editor.quill.blur()
        }
        // supprime tous les champs value présent dans flieds
        // console.log('supprime tous les champs value présent dans flieds')
        updateOperation.flows.forEach((f) => {
          // console.log('f:%O', f)
          f.panels.forEach((p) => {
            // console.log('p:%O', p)
            p.fields.forEach((fl) => {
              // console.log('flieds:%O', fl)
              if (fl.value) {
                // console.log('==>> BAD !!!')
                delete fl.value
              }
            })
          })
        })
        return Axios("post", "operation/save", updateOperation)
          .then((response) => {
            this.loading = false
            this.setOperation(response.data)
            this.$emit("onSave")
            this.$notification.success({
              message: `Texte modifiée`,
              description: `Le text à bien été modifiée.`,
            })

            return this.initialize()
          })
          .catch((error) => {
            this.loading = false
            console.error("error", error)
            this.$notification.error({
              message: `Erreur de modification`,
              description: `Une erreur est survenue lors de la modification du texte de l'operation.`,
            })
            return error
          })
      }
    },
  },
}
</script>

<style lang="scss">
@import "./src/assets/scss/variables";
@import "./src/assets/scss/functions";

.mcma-editor-modal {
  font-family: $font-family !important;
  width: 900px !important;
  .mcma-button {
  }
  .quillWrapper {
    .ql-toolbar {
      border-top-left-radius: 5px;
      border-top-right-radius: 5px;
    }
    .ql-container {
      border-bottom-left-radius: 5px;
      border-bottom-right-radius: 5px;
    }
  }
}
</style>
