import TarifProfilCorresp from "./TarifProfilCorresp.json"

export const RV_RESULT = {
  OK: "OK",
  KO: "KO",
  DEROGABLE: "DEROGABLE",
  NOT_APPLICABLE: "NOT_APPLICABLE",
}

export const RV_GAZ = {
  RVG01(optionTarifaire, profil) {
    if (!TarifProfilCorresp[optionTarifaire].includes(profil)) {
      return {
        state: RV_RESULT.DEROGABLE,
        message: `L'option tarifaire renseignée est différente de celle calculée par correspondance.`,
      }
    }
    return {
      state: RV_RESULT.OK,
    }
  },
}
