<template>
  <section>
    <div class="container">
      <a-row type="flex" :gutter="12" v-if="documents">
        <a-col :span="24">
          <documents-header
            :documents="documents"
            :documentsDownloaded="documentsDownloaded"
            :documentsUploaded="documentsUploaded"
            :isDownloadPage="isDownloadPage"
          >
            <mcma-spin-box :spinning="loading">
              <documents-list-table
                :documents="documents"
                :documentsDownloaded="documentsDownloaded"
                :documentsUploaded="documentsUploaded"
                :isDownloadPage="isDownloadPage"
                @initialize="initialize"
                @selectedChanged="selectedChanged"
                @event="initialize"
              />
            </mcma-spin-box>
          </documents-header>
        </a-col>
      </a-row>
    </div>
    <a class="ninja" ref="downloadLink" />
  </section>
</template>

<script>
import DocumentsHeader from "@/components/mcma/steps/documents/components/DocumentsHeader"
import DocumentsListTable from "@/components/mcma/steps/documents/components/DocumentsListTable"
import McmaSpinBox from "@/components/mcma/common/McmaSpinBox"
import GuardiansMixin from "@/components/mcma/steps/guardians/GuardiansMixin"
import Axios from "@/util/Axios"
import { mapMutations, mapState } from "vuex"

export default {
  name: "Documents",
  mixins: [GuardiansMixin],
  components: {
    DocumentsHeader,
    DocumentsListTable,
    McmaSpinBox,
  },
  data() {
    return {
      flow: this.$route.params.action.toUpperCase(),
      documents: null,
      documentsDownloaded: [],
      documentsUploaded: [],
      loading: true,
    }
  },
  computed: {
    ...mapState(["membre", "operation"]),
    isDownloadPage() {
      return this.$route.params.action === "download"
    },
  },
  methods: {
    ...mapMutations(["setBreadcrumbAdd"]),
    async initialize() {
      this.documents = []
      this.documentsDownloaded = []
      this.loading = true

      try {
        this.documents = await this.requestDocuments()
        this.documentsDownloaded = await this.requestDocumentsMembreAlreadyDownloaded()
        this.documentsUploaded = await this.requestDocumentsMembreAlreadyUploaded()
      } catch (error) {
        console.log(
          `%c error`,
          "background:#1e4d77 ; padding: 5px 7px 4px 0px; border-radius: 3px;  color: #FFFFFF",
          error
        )
      }

      this.loading = false
    },
    requestDocuments() {
      return Axios("get", `api/pieces/search/findByOperationIdEquals?size=2000&operationId=${this.operation.id}`)
        .then((response) => {
          return response.data._embedded.pieces || []
        })
        .catch((error) => {
          console.log(
            `%c error`,
            "background:red ; padding: 5px 7px 4px 0px; border-radius: 3px;  color: #FFFFFF",
            error
          )
          return error
        })
    },
    requestDocumentsMembreAlreadyDownloaded() {
      return Axios("get", `file/pieceMembre/downloaded/?membreId=${this.membre.id}`)
        .then((response) => {
          return response.data || []
        })
        .catch((error) => {
          console.log(
            `%c error`,
            "background:red ; padding: 5px 7px 4px 0px; border-radius: 3px;  color: #FFFFFF",
            error
          )
          return error
        })
    },
    requestDocumentsMembreAlreadyUploaded() {
      return Axios("get", `file/pieceMembre/uploaded/?membreId=${this.membre.id}`)
        .then((response) => {
          console.log("requestDocumentsMembreAlreadyUploaded : %O", response.data)
          return response.data || []
        })
        .catch((error) => {
          console.log(
            `%c error`,
            "background:red ; padding: 5px 7px 4px 0px; border-radius: 3px;  color: #FFFFFF",
            error
          )
          return error
        })
    },
    download(piece, isMembre) {
      let membre = ""
      if (isMembre) {
        membre = "&membreId=" + this.membre.id
      }
      Axios("get", "file/piece/download?pieceId=" + piece.id + "&operationId=" + this.operation.id + membre, null, {
        responseType: "blob",
      })
        .then((response) => {
          const link = this.$refs.downloadLink
          link.href = window.URL.createObjectURL(new Blob([response.data]))
          link.setAttribute("download", piece.name + "." + piece.extension)
          link.click()
        })
        .catch((error) => {
          console.error(error)
          this.$notification.error({
            message: "Erreur lors du téléchargement",
            description:
              "Une erreur est survenue lors du telechargement de la piece, veuillez contacter un administrateur.",
          })
        })
    },
    processFile(event, piece) {
      const sizeMax = process.env.VUE_APP_UPLOAD_SIZE * 1024 * 1024
      const file = event.target.files[0]

      if (file.size > sizeMax) {
        this.$notification.warning({
          message: "Fichier trop volumineux",
          description: `Le fichier dépasse la taille autorisé qui est de ${process.env.VUE_APP_UPLOAD_SIZE}Mo.`,
        })
      } else {
        const formData = new FormData()
        formData.append("piece", JSON.stringify(piece))
        formData.append("file", file)
        formData.append("membreId", this.membre.id)
        Axios("post", "/file/piece/membre", formData, null, {
          "Content-Type": "multipart/form-data",
        })
          .then((reponse) => {
            this.initialize()
            this.$notification.success({
              message: "Piéce signé !",
              description: `La piéce à bien été signé.`,
            })
          })
          .catch((error) => {
            console.error(error)
            this.$notification.error({
              message: "Erreur de signature",
              description: `Une erreur c'est produite lors de la signature, veuillez contacter un administrateur.`,
            })
          })
      }
    },
    selectedChanged(list) {
      console.log(
        `%c selectedChanged`,
        "background:#1e4d77 ; padding: 5px 7px 4px 0px; border-radius: 3px;  color: #FFFFFF",
        list
      )
    },
  },
  created() {
    const name = this.$route.params.action === "download" ? "Télécharger les pièces" : "Déposer vos pièces"
    this.setBreadcrumbAdd({
      name: name,
      action: () => {
        this.$router.push({
          name: "documents",
          params: { action: this.$route.params.action },
        })
      },
    })
    this.initialize()
  },
}
</script>

<style lang="scss" scoped>
td .row,
td .input-field {
  margin: 0px;
}
</style>
