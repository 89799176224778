<template>
  <section>
    <div class="container">
      <a-row type="flex" :gutter="12" v-if="membresStats">
        <a-col :span="24">
          <mcma-spin-box :spinning="loading">
            <grid
              v-if="operationsGroupement"
              name="Opérations cross-groupement"
              :rows="operationsGroupement"
              :columns="columnsOperations"
              :search="true"
              :columnHeader="columnHeaderOperation"
              :multiSelect="true"
            >
            </grid>
          </mcma-spin-box>
          <mcma-spin-box :spinning="loading">
            <grid
              v-if="membresStatsGroupement"
              name="Membres cross-groupement"
              :rows="membresStatsGroupement"
              :columns="columnsMembres"
              :search="true"
              :columnHeader="columnHeaderMembre"
            >
            </grid>
          </mcma-spin-box>
          <mcma-spin-box :spinning="loading">
            <grid
              v-if="sitesGroupement"
              name="Sites cross-groupement"
              :rows="sitesGroupement"
              :columns="columnsSites"
              :search="true"
              :columnHeader="columnHeaderSite"
            >
            </grid>
          </mcma-spin-box>
        </a-col>
      </a-row>
    </div>
  </section>
</template>

<script>
import Grid from "@/components/common/Grid"
import McmaSpinBox from "@/components/mcma/common/McmaSpinBox"
import MembersHeader from "@/components/mcma/members/components/MembersHeader"
import MembersListTable from "@/components/mcma/members/components/MembersListTable"
import { mapActions, mapMutations, mapState } from "vuex"
import Axios from "@/util/Axios"
export default {
  name: "Observator",
  components: {
    Grid,
    McmaSpinBox,
    MembersHeader,
    MembersListTable,
  },
  data() {
    return {
      membresStatsGroupement: [],
      sitesGroupement: [],
      operationsGroupement: [],
      loading: true,
      columnHeaderMembre: {
        nom: "Nom",
        status: "Statut",
        operationId: "Opération",
      },
      columnHeaderOperation: {
        name: "Nom",
        id: "Identifiant",
      },
      columnHeaderSite: {
        nom: "Nom",
        status: "Statut",
        rae: "Rae",
        operationId: "Opération",
      },
    }
  },
  computed: {
    ...mapState(["user", "operation", "membresStats"]),
    columnsSites() {
      return ["nom", "status", "rae", "operationId"]
    },

    columnsMembres() {
      return ["nom", "status", "operationId"]
    },
    columnsOperations() {
      return ["name", "id"]
    },
  },
  methods: {
    ...mapMutations(["setBreadcrumbAdd"]),
    requestMembresStatsByGroupement(groupement) {
      return getPage(0).then((responseInit) => {
        this.membresStatsGroupement.push(...responseInit.data._embedded.membreStats)
        for (let page = 1; page < responseInit.data.page.totalPages; page++) {
          getPage(page).then((response) => {
            this.membresStatsGroupement.push(...response.data._embedded.membreStats)
          })
        }
        return Promise.resolve()
      })

      async function getPage(page) {
        return Axios(
          "get",
          `api/membreStats/search/findAllByOperationGroupement?groupement=${groupement}&size=50&page=${page}`
        )
      }
    },
    async requestSitesByGroupement(groupement) {
      var idsOperations = []
      await Axios("get", `api/operations/search/findByGroupementEquals?groupement=${groupement}`).then((resp) => {
        this.operationsGroupement.push(...resp.data._embedded.operations)
        resp.data._embedded.operations.forEach((o) => {
          idsOperations.push(o.id)
        })
      })
      
      return getPage(0, idsOperations).then((responseInit) => {
        if (responseInit.data._embedded.siteElecs != undefined) {
          this.sitesGroupement.push(...responseInit.data._embedded.siteElecs)
        }
        if (responseInit.data._embedded.siteGazes != undefined) {
          this.sitesGroupement.push(...responseInit.data._embedded.siteGazes)
        }
        //Les sites qui n'ont pas encore de Données techniques..
        if (responseInit.data._embedded.sites != undefined) {
          this.sitesGroupement.push(...responseInit.data._embedded.sites)
        }
        for (let page = 1; page < responseInit.data.page.totalPages; page++) {
          getPage(page, idsOperations).then((response) => {
            if (response.data._embedded.siteElecs != undefined) {
              this.sitesGroupement.push(...response.data._embedded.siteElecs)
            }
            if (response.data._embedded.siteGazes != undefined) {
              this.sitesGroupement.push(...response.data._embedded.siteGazes)
            }
            if (response.data._embedded.sites != undefined) {
          this.sitesGroupement.push(...response.data._embedded.sites)
        }
          })
        }
        return Promise.resolve()
      })

      function getPage(page, operationIds) {
        return Axios("get", `api/sites/search/findByOperationIdIn?operationIds=${operationIds}&size=1000&page=${page}`)
      }
    },
    initialize() {
      this.requestMembresStatsByGroupement(this.operation.groupement).then(() => {
        this.loading = false
      })
      this.requestSitesByGroupement(this.operation.groupement).then(() => {
        this.loading = false
      })
    },
    selectedChanged(list) {
      console.log(
        `%c selectedChanged`,
        "background:#1e4d77 ; padding: 5px 7px 4px 0px; border-radius: 3px;  color: #FFFFFF",
        list
      )
    },
  },
  created() {
    this.setBreadcrumbAdd({
      name: "Observateur",
      action: () => {
        this.$router.push({
          name: "observator",
        })
      },
    })
    this.initialize()
  },
}
</script>

<style lang="scss" scoped></style>
