<template>
  <!--  icon : sites-->
  <mcma-card title="Liste de vos interlocuteurs" icon="noIcon" class="mcma-card-header">
    <template #describe>
      <div
        v-if="getCurrentFlow && getCurrentFlow.describeInFlow"
        class="html-render-content"
        v-html="getCurrentFlow.describeInFlow"
        :class="{ 'can-edit': $isAdmin() }"
        @click="setEditorModal('services', 'describeInFlow')"
      />
    </template>

    <mcma-card class="mcma-card-header-body">
      <div class="sentence">
        <p
          class="principal-message with-bottom"
          v-if="membre.stateInterlocuteurs === Constants.STATUS.TO_COMPLETE.value"
        >
          Votre état actuel pour cette étape est
          <mcma-icon
            leftImage="edit_white"
            color="white"
            :coloriseText="true"
            text-transform="capitalize"
            :name="Constants.STATUS.TO_COMPLETE.text"
          />
        </p>
        <p
          class="principal-message with-bottom"
          v-else-if="membre.stateInterlocuteurs === Constants.STATUS.COMPLETED.value"
        >
          Votre état actuel pour cette étape est
          <mcma-icon
            leftImage="certified_white"
            color="white"
            :coloriseText="true"
            text-transform="capitalize"
            :name="Constants.STATUS.COMPLETED.text"
          />
        </p>
      </div>
      <div class="sentence" v-if="membre.stateInterlocuteurs === Constants.STATUS.COMPLETED.value">
        <p class="principal-message inline">
          Cette étape est terminée. <br />
          Pour savoir ce qu'il vous reste à faire :
        </p>
        <mcma-button name="Rendez-vous sur la page d'accueil" class="mt24" color="success" @click="goBack()" />
      </div>

      <mcma-divider
        class="mt20"
        v-if="
          membre.stateInterlocuteurs !== Constants.STATUS.CERTIFIED.value ||
          membre.stateInterlocuteurs === Constants.STATUS.COMPLETED.value ||
          (membre.approuvedInterlocuteurs && user.role !== 'CLIENT')
        "
      />

      <div class="button-group">
        <mcma-button
          v-if="membre.stateInterlocuteurs !== Constants.STATUS.CERTIFIED.value"
          name="Ajouter un nouvel interlocuteur"
          class="mt24 mr12"
          @click="goTo({ name: 'interlocuteurs-detail', params: { action: 'new' } })"
        />
      </div>
    </mcma-card>
    <slot></slot>

    <mcma-editor-modal
      v-if="$isAdmin()"
      :visible="showEditor"
      :flowName="flowName"
      :flowProp="flowProp"
      @onCancel="setEditorModal"
      @onSave="editorHasMakeChange"
    />

    <mcma-certify
      :visible="showCertifyModal"
      :canCertify="membreStats.statInterlocuteur.state === Constants.STATUS.COMPLETED.value"
      noCertifyMessage="Les interlocuteurs ne peuvent pas être certifiés."
      title="Validation des interlocuteurs"
      who="vos interlocuteurs"
      @onCancel="closeCertifyModal"
      @onCertify="certify"
    />
  </mcma-card>
</template>

<script>
import Axios from "@/util/Axios"
import McmaButton from "@/components/mcma/common/McmaButton"
import McmaCard from "@/components/mcma/common/McmaCard"
import McmaIcon from "@/components/mcma/common/McmaIcon"
import McmaDivider from "@/components/mcma/common/McmaDivider"
import McmaCertify from "@/components/mcma/common/McmaCertify"
import McmaEditorModal from "@/components/mcma/common/McmaEditorModal"
import { mapMutations, mapState } from "vuex"
import Constants from "@/util/Constants"

export default {
  name: "InterlocuteursHeader",
  props: {
    interlocuteurs: Array,
  },
  components: {
    McmaCard,
    McmaIcon,
    McmaButton,
    McmaDivider,
    McmaEditorModal,
    McmaCertify,
  },
  data() {
    return {
      showCertifyModal: false,
      showEditor: false,
      flowName: null,
      flowProp: null,
    }
  },
  computed: {
    ...mapState(["membre", "membreStats", "user", "operation"]),
    Constants() {
      return Constants
    },
    getCurrentFlow() {
      return this.operation.flows.find((item) => item.name === "services")
    },
  },
  methods: {
    ...mapMutations(["setState", "updateCurrentMembre"]),
    setEditorModal(flowName, flowProp) {
      this.showEditor = !!(flowName && flowProp)
      if (flowName && flowProp) {
        this.flowName = flowName
        this.flowProp = flowProp
      } else {
        setTimeout(() => {
          this.flowName = flowName
          this.flowProp = flowProp
        }, 300)
      }
    },
    editorHasMakeChange() {
      this.setEditorModal()
    },
    goTo(route) {
      this.setState({
        stateName: "interlocuteur",
        value: {},
      })
      if (typeof route === "string") {
        this.$router.push({ name: route })
      } else if (typeof route === "object") {
        this.$router.push(route)
      }
    },
    goBack() {
      // this.setBreadcrumb("goBack");
      this.$router.push({
        name: "steps",
      })
    },
    closeCertifyModal() {
      this.showCertifyModal = false
    },
    certify() {
      this.closeCertifyModal()
      Axios("patch", `api/membres/${this.membreStats.id}`, {
        approuvedInterlocuteurs: true,
      })
        .then(() => {
          this.updateCurrentMembre({
            approuvedInterlocuteurs: true,
            stateInterlocuteurs: Constants.STATUS.CERTIFIED.value,
          })
          this.$notification.success({
            message: `Interlocuteurs Certifié !`,
            description: `Les interlocuteurs ont bien été certifié !`,
          })
        })
        .catch((error) => {
          console.error("error", error)
          this.$notification.error({
            message: `Erreur de validation`,
            description: `Une erreur c'est produite lors de la certification des interlocuteurs, veuillez contacter un administrateur.`,
          })
        })
    },
    unCertify(membre) {
      Axios("patch", "api/membres/" + membre.id, {
        certified: false,
        approuved: false,
        approuvedInterlocuteurs: false,
      })
        .then(() => {
          this.updateCurrentMembre({
            certified: false,
            approuved: false,
            approuvedInterlocuteurs: false,
            stateInterlocuteurs: Constants.STATUS.COMPLETED.value,
          })
          this.$notification.success({
            message: "Interlocuteurs décertifiés",
            description: "Les interlocuteurs on bien été décertifiés.",
          })
        })
        .catch((error) => {
          console.error(error)
          this.$notification.error({
            message: "Erreur de décertification",
            description: "Erreur lors de la décertification. Veuillez contacter un Administrateur.",
          })
        })
    },
  },
}
</script>

<style lang="scss"></style>
