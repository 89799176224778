<template>
  <div class="mcma-table">
    <div class="header">
      <div class="selected-sentence" :class="{ show: hasSelected }" v-if="dataSource.length > 0">
        <template>
          {{ selectedMembersSentence }}
        </template>
      </div>
    </div>
    <a-table
      :rowSelection="rowSelection"
      :columns="getColumns"
      :dataSource="dataSource"
      :scroll="{ x: true }"
      :pagination="{
        defaultPageSize: 20,
        showSizeChanger: true,
        pageSizeOptions: ['5', '10', '20', '30', '50', '100'],
      }"
      @change="onChange"
      :locale="getLocale"
      bordered
    >
      <div
        slot="filterDropdown"
        slot-scope="{ setSelectedKeys, selectedKeys, confirm, clearFilters, column }"
        style="padding: 8px"
      >
        <a-input
          v-ant-ref="(c) => (searchInput[column.dataIndex] = c)"
          :placeholder="`Recherche par ${column.title.toLowerCase()}`"
          :value="selectedKeys[0]"
          @change="(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])"
          @pressEnter="() => handleSearch(column.dataIndex, selectedKeys, confirm)"
          style="width: 188px; margin-bottom: 8px; display: block"
        />
        <a-button
          type="primary"
          @click="() => handleSearch(column.dataIndex, selectedKeys, confirm)"
          icon="search"
          size="small"
          style="width: 90px; margin-right: 8px"
        >
        </a-button>
        <a-button
          @click="() => handleReset(column.dataIndex, clearFilters)"
          icon="sync"
          size="small"
          style="width: 90px"
        >
        </a-button>
      </div>
      <a-icon slot="filterIcon" slot-scope="filtered" type="search" :class="{ 'blue-color': filtered }" />
      <template slot="nom" slot-scope="text">
        <mcma-search-table-filter :searchText="searchText" fieldName="nom" :text="text" />
      </template>
      <template slot="status" slot-scope="props">
        <div class="cell-members-status">
          <img :src="props.statusIcon" :alt="props.status" />
          <span>{{ props.status }}</span>
        </div>
      </template>
      <template slot="nbDetail" slot-scope="text">
        <div class="cell-nbDetail">
          <span :class="{ low: text === 0, high: text > 0 }">
            {{ text }}
          </span>
        </div>
      </template>
      <template slot="progressDetail" slot-scope="text">
        <div class="cell-members-progress detail">
          <div class="progression">
            <a-progress :percent="Math.trunc(text * 100)" :strokeWidth="15" size="small" />
          </div>
        </div>
      </template>
      <template slot="actions" slot-scope="props">
        <div class="cell-actions" v-if="isMembersManager">
          <div class="actions-block">
            <div class="line">
              <div
                v-if="props.status !== 'EXPORTED' && props.status !== 'APPROVED'"
                @click.prevent="
                  goTo({
                    name: 'site-link',
                    params: { id: props.id },
                  })
                "
              >
                <a-tooltip placement="top">
                  <template slot="title">
                    <span>Associer des sites en masse</span>
                  </template>
                  <div>
                    <mcma-icon type="link" color="success" :full="true" :faIcon="true" />
                  </div>
                </a-tooltip>
              </div>
              <a-divider type="vertical" v-if="props.status !== 'EXPORTED' && props.status !== 'APPROVED'" />
              <div
                v-if="props.status !== 'EXPORTED' && props.status !== 'APPROVED'"
                @click.prevent="
                  goTo({
                    name: 'members-detail',
                    params: {
                      operationId: operation.id,
                      memberId: props.id,
                      action: 'edit',
                    },
                  })
                "
              >
                <a-tooltip placement="top">
                  <template slot="title">
                    <span>Editer le membre</span>
                  </template>
                  <div>
                    <mcma-icon leftImage="edit_white" color="primary" :full="true" />
                  </div>
                </a-tooltip>
              </div>
              <div
                v-if="!(props.status !== 'EXPORTED' && props.status !== 'APPROVED')"
                @click.prevent="unCertify(props)"
                class="ml5"
              >
                <a-tooltip placement="top">
                  <template slot="title">
                    <span>Décertifier le membre</span>
                  </template>
                  <div>
                    <mcma-icon type="undo-alt" color="orange" :full="true" :faIcon="true" />
                  </div>
                </a-tooltip>
              </div>
            </div>
          </div>
        </div>
        <div class="cell-actions" v-else>
          <div @click.prevent="goToSteps(props.member.id)">
            <a-tooltip placement="left">
              <template slot="title">
                <span>Connexion</span>
              </template>
              <img class="home" :src="getIcon('home')" alt="home" />
            </a-tooltip>
          </div>
          <a-divider
            type="vertical"
            v-if="
              user.role === 'COORDINATEUR_AVANCE' ||
              user.role === 'ADMIN' ||
              user.role === 'SUPER_ADMIN' ||
              superAdmin(props.member)
            "
          />
          <div
            class="actions-block"
            v-if="
              user.role === 'COORDINATEUR_AVANCE' ||
              user.role === 'ADMIN' ||
              user.role === 'SUPER_ADMIN' ||
              superAdmin(props.member)
            "
          >
            <div
              class="line"
              v-if="user.role === 'COORDINATEUR_AVANCE' || user.role === 'ADMIN' || user.role === 'SUPER_ADMIN'"
            >
              <div @click.prevent="mail('BDD_WELCOME', props.member)">
                <a-tooltip placement="top">
                  <template slot="title">
                    <span>Email de Bienvenue</span>
                  </template>
                  <img class="bienvenue" :src="getIcon('send_blue')" alt="bienvenue" />
                </a-tooltip>
              </div>
              <div @click.prevent="mail('BDD_RAISE', props.member)" class="ml5">
                <a-tooltip placement="top">
                  <template slot="title">
                    <span>Email de Relance</span>
                  </template>
                  <img class="relance" :src="getIcon('send_yellow')" alt="relance" />
                </a-tooltip>
              </div>
              <div
                v-if="user.role === 'ADMIN' || user.role === 'SUPER_ADMIN'"
                @click.prevent="action('exportAgain', false, props.member)"
                class="ml5"
              >
                <a-tooltip placement="top">
                  <template slot="title">
                    <span>Email de Remerciement</span>
                  </template>
                  <img class="remerciement" :src="getIcon('send_green')" alt="remerciement" />
                </a-tooltip>
              </div>
              <a-divider type="vertical" v-if="user.role === 'ADMIN' || user.role === 'SUPER_ADMIN'" />
              <div
                v-if="user.role === 'ADMIN' || user.role === 'SUPER_ADMIN'"
                @click.prevent="
                  action(props.member.status === 'NOT_PRESERVED' ? 'preserved' : 'notPreserved', false, props.member)
                "
                class="ml5"
              >
                <a-tooltip placement="topRight">
                  <template slot="title">
                    <span>{{
                      props.member.status === "NOT_PRESERVED"
                        ? "Retour dans le circuit"
                        : "Non concerné par l'opération"
                    }}</span>
                  </template>
                  <img
                    class="remerciement"
                    :src="getIcon(props.member.status === 'NOT_PRESERVED' ? 'preserved_box' : 'not_preserved_box')"
                    :alt="props.member.status === 'NOT_PRESERVED' ? 'preserved' : 'not preserved'"
                  />
                </a-tooltip>
              </div>
            </div>
            <div
              class="line"
              :class="{
                mt4: user.role === 'COORDINATEUR_AVANCE' || user.role === 'ADMIN' || user.role === 'SUPER_ADMIN',
              }"
              v-if="
                user.role === 'COORDINATEUR_AVANCE' ||
                user.role === 'ADMIN' ||
                user.role === 'SUPER_ADMIN' ||
                superAdmin(props.member)
              "
            >
              <div @click.prevent="action('exportAgain', true, props.member)">
                <a-tooltip
                  :placement="
                    user.role === 'COORDINATEUR_AVANCE' || user.role === 'ADMIN' || user.role === 'SUPER_ADMIN'
                      ? 'bottom'
                      : 'top'
                  "
                >
                  <template slot="title">
                    <span>Exporter de nouveau</span>
                  </template>
                  <div>
                    <mcma-icon class="file_download" type="file-export" color="purple" :full="true" :faIcon="true" />
                  </div>
                </a-tooltip>
              </div>
              <div @click.prevent="action('approuve', false, props.member)" class="ml5">
                <a-tooltip
                  :placement="
                    user.role === 'COORDINATEUR_AVANCE' || user.role === 'ADMIN' || user.role === 'SUPER_ADMIN'
                      ? 'bottom'
                      : 'top'
                  "
                >
                  <template slot="title">
                    <span>Approuver les exports</span>
                  </template>
                  <div>
                    <mcma-icon class="approved" type="check" color="green" :full="true" :faIcon="true" />
                  </div>
                </a-tooltip>
              </div>
            </div>
          </div>
        </div>
      </template>
    </a-table>
  </div>
</template>

<script>
import Vue from "vue"
import Axios from "@/util/Axios"
import McmaIcon from "@/components/mcma/common/McmaIcon"
import McmaSearchTableFilter from "@/components/mcma/common/McmaSearchTableFilter"
import { mapActions, mapGetters, mapMutations, mapState } from "vuex"
import Constants from "@/util/Constants"
import _ from "lodash"
import { accorization } from "@/util/Helpers"

Vue.component("membre-empty-without-filter", {
  template: `<a-empty image="https://gw.alipayobjects.com/mdn/miniapp_social/afts/img/A*pevERLJC9v0AAAAAAAAAAABjAQAAAQ/original">
                <span slot="description"> Aucun membre </span>
              </a-empty>`,
})

Vue.component("membre-empty-with-filter", {
  template: `<a-empty image="https://gw.alipayobjects.com/mdn/miniapp_social/afts/img/A*pevERLJC9v0AAAAAAAAAAABjAQAAAQ/original">
                <span slot="description"> Aucun membre ne correspond au filtre renseigné </span>
              </a-empty>`,
})

export default {
  name: "MembersListTableStats",
  props: {
    membresStats: Array,
    isMembersManager: Boolean,
  },
  components: {
    McmaIcon,
    McmaSearchTableFilter,
  },
  data() {
    return {
      selectedRowKeys: [],
      oldSelectedRowKeys: [],
      filters: {},
      searchText: {
        nom: "",
      },
      searchInput: {
        nom: null,
      },
      columns: [
        {
          title: "Nom",
          dataIndex: "nom",
          key: "nom",
          fixed: "left",
          width: 150,
          scopedSlots: {
            filterDropdown: "filterDropdown",
            filterIcon: "filterIcon",
            customRender: "nom",
          },
          onFilter: (value, record) =>
            record.nom ? record.nom.toString().toLowerCase().includes(value.toLowerCase()) : null,
          onFilterDropdownVisibleChange: (visible) => {
            if (visible) {
              setTimeout(() => {
                this.searchInput["nom"].focus()
              }, 0)
            }
          },
        },
        {
          title: "Statut",
          key: "status",
          filters: [
            {
              text: Constants.STATUS.INITIALISED.text,
              value: Constants.STATUS.INITIALISED.text,
            },
            {
              text: Constants.STATUS.IN_PROGRESS.text,
              value: Constants.STATUS.IN_PROGRESS.text,
            },
            {
              text: Constants.STATUS.EXPORTED.text,
              value: Constants.STATUS.EXPORTED.text,
            },
            {
              text: Constants.STATUS.APPROVED.text,
              value: Constants.STATUS.APPROVED.text,
            },
            {
              text: Constants.STATUS.NOT_PRESERVED.text,
              value: Constants.STATUS.NOT_PRESERVED.text,
            },
          ],
          onFilter: (value, record) => record.status === value,
          scopedSlots: { customRender: "status" },
        },
        {
          title: "Nombre de sites",
          dataIndex: "nbSites",
          key: "nbSites",
          sorter: (a, b) => a.nbSites - b.nbSites,
          scopedSlots: { customRender: "nbDetail" },
        },
        {
          title: "Progression des sites",
          dataIndex: "progressSites",
          key: "progressSites",
          sorter: (a, b) => a.progressSites - b.progressSites,
          scopedSlots: { customRender: "progressDetail" },
        },
        {
          title: "Nombre de périmètres de facturation",
          dataIndex: "nbGroupement",
          key: "nbGroupement",
          sorter: (a, b) => a.nbGroupement - b.nbGroupement,
          scopedSlots: { customRender: "nbDetail" },
        },
        {
          title: "Progression des périmètres de facturation",
          dataIndex: "progressGroupement",
          key: "progressGroupement",
          sorter: (a, b) => a.progressGroupement - b.progressGroupement,
          scopedSlots: { customRender: "progressDetail" },
        },
        {
          title: "Nombre de documents à traiter",
          dataIndex: "nbDocuments",
          key: "nbDocuments",
          sorter: (a, b) => a.nbDocuments - b.nbDocuments,
          scopedSlots: { customRender: "nbDetail" },
        },
        {
          title: "Nombre de documents téléchargé",
          dataIndex: "nbDocumentsDownloaded",
          key: "nbDocumentsDownloaded",
          sorter: (a, b) => a.nbDocumentsDownloaded - b.nbDocumentsDownloaded,
          scopedSlots: { customRender: "nbDetail" },
        },
        {
          title: "Nombre de documents déposé",
          dataIndex: "nbDocumentsUploaded",
          key: "nbDocumentsUploaded",
          sorter: (a, b) => a.nbDocumentsUploaded - b.nbDocumentsUploaded,
          scopedSlots: { customRender: "nbDetail" },
        },
        {
          title: "Nombre d'interlocuteurs",
          dataIndex: "nbInterlocuteur",
          key: "nbInterlocuteur",
          sorter: (a, b) => a.nbInterlocuteur - b.nbInterlocuteur,
          scopedSlots: { customRender: "nbDetail" },
        },
        {
          title: "Progression des interlocuteurs",
          dataIndex: "progressInterlocuteur",
          key: "progressInterlocuteur",
          sorter: (a, b) => a.progressInterlocuteur - b.progressInterlocuteur,
          scopedSlots: { customRender: "progressDetail" },
        },
        {
          title: "Actions",
          fixed: "right",
          key: "actions",
          width: 200,
          scopedSlots: { customRender: "actions" },
        },
      ],
      statusValues: {
        INITIALISED: {
          name: Constants.STATUS.INITIALISED.text,
          icon: require(`@/assets/images/initialised.svg`),
        },
        IN_PROGRESS: {
          name: Constants.STATUS.IN_PROGRESS.text,
          icon: require(`@/assets/images/in_progress.svg`),
        },
        EXPORTED: {
          name: Constants.STATUS.EXPORTED.text,
          icon: require(`@/assets/images/exported.svg`),
        },
        APPROVED: {
          name: Constants.STATUS.APPROVED.text,
          icon: require(`@/assets/images/approved.svg`),
        },
        NOT_PRESERVED: {
          name: Constants.STATUS.NOT_PRESERVED.text,
          icon: require(`@/assets/images/not_preserved.svg`),
        },
      },
      dataSource: [],
    }
  },
  computed: {
    ...mapState(["user", "currentTutorial", "operation"]),
    ...mapGetters(["getFlow"]),
    superAdmin() {
      return (member) => {
        return member.status === "EXPORTED" && (this.user.role === "ADMIN" || this.user.role === "SUPER_ADMIN")
      }
    },
    getLocale() {
      return {
        emptyText: this.dataSource.length === 0 ? <membre-empty-without-filter /> : <membre-empty-with-filter />,
      }
    },
    selectedMembersSentence() {
      return accorization(this.oldSelectedRowKeys.length, "membre sélèctionné", true, "aucun membre sélèctionné")
    },
    getProgress() {
      return (member) => {
        return member.certified ? 100 : Math.trunc(member.progress * 100)
      }
    },
    getIcon() {
      return (iconName) => {
        return iconName ? require(`@/assets/images/${iconName}.svg`) : null
      }
    },
    getColumns() {
      let newColumns = [...this.columns]
      if (this.isMembersManager) {
        newColumns.splice(-2, 1)
      }
      return newColumns
    },
    hasSelected() {
      return this.selectedRowKeys.length > 0
    },
    getProgressionByState() {
      return (member, state) => {
        let progression = 0
        if (this.getFlow.includes("PERIMETRE")) {
          progression += member.statSite[state] ? member.statSite[state] : 0
        }
        if (this.getFlow.includes("FACTURATION")) {
          progression += member.statGroupement[state] ? member.statGroupement[state] : 0
        }
        if (this.getFlow.includes("SERVICES")) {
          const upper = _.snakeCase(state).toUpperCase()
          progression += member.statService.state === upper ? 1 : 0
          progression += member.statInterlocuteur.state === upper ? 1 : 0
        }
        return progression.toString()
      }
    },
    rowSelection() {
      const { selectedRowKeys } = this
      return {
        selectedRowKeys,
        onSelectAll: this.onSelectAll,
        onChange: this.onSelectChange,
        hideDefaultSelections: true,
        selections: [
          {
            key: "all-not-finish-prog",
            text: "Sélectionner => progression < 100%",
            onSelect: () => {
              this.selectedAllNotFinishProg()
            },
          },
          {
            key: "status-initialise",
            text: "Sélectionner => status = initialisé",
            onSelect: () => {
              this.selectedMembers("INITIALISED")
            },
          },
          {
            key: "status-inProgress",
            text: "Sélectionner => status = in progress",
            onSelect: () => {
              this.selectedMembers("IN_PROGRESS")
            },
          },
          {
            key: "status-exported",
            text: "Sélectionner => status = exported",
            onSelect: () => {
              this.selectedMembers("EXPORTED")
            },
          },
          {
            key: "status-approved",
            text: "Sélectionner => status = approved",
            onSelect: () => {
              this.selectedMembers("APPROVED")
            },
          },
          {
            key: "status-notPreserved",
            text: "Sélectionner => status = not preserved",
            onSelect: () => {
              this.selectedMembers("NOT_PRESERVED")
            },
          },
        ],
        onSelection: this.onSelection,
      }
    },
  },
  watch: {
    membresStats: {
      deep: true,
      handler(membresStats) {
        this.generateDataSource(membresStats)
      },
    },
    selectedRowKeys(newValue, oldValue) {
      this.oldSelectedRowKeys = newValue.length === 0 ? [...oldValue] : [...newValue]
    },
  },
  mounted() {
    this.initialize()
  },
  methods: {
    ...mapActions(["refreshStore", "tutoGoesTo"]),
    ...mapMutations(["setState"]),
    initialize() {
      this.generateDataSource(this.membresStats)
      this.selectedRowKeys = []
      this.onSelectChange(this.selectedRowKeys)
    },
    goTo(route) {
      const membreStatsId = route && route.params ? route.params.memberId : null
      const membreStats = this.membresStats.find((g) => g.id === membreStatsId)
      this.setState({
        stateName: "membreStats",
        value: membreStats || {},
      })
      if (typeof route === "string") {
        this.$router.push({ name: route })
      } else if (typeof route === "object") {
        this.$router.push(route)
      }
    },
    unCertify(membre) {
      Axios("patch", "api/membres/" + membre.id, {
        certified: false,
        approuved: false,
      })
        .then((response) => {
          this.$notification.success({
            message: `Membre décertifié !`,
            description: `Le membre à été décertifié avec succés.`,
          })
          this.initialize()
        })
        .catch((error) => {
          console.error(error)
          this.$notification.error({
            message: `Erreur de décertification`,
            description: `Une erreur est survenue lors de la décertification du membre.`,
          })
        })
      console.groupEnd()
    },
    mail(type, member) {
      this.$emit("sendMail", { type: type, member: member })
    },
    action(type, all, member) {
      this.$emit(type, { all: all, member: member })
    },
    goToSteps(memberId) {
      if (this.currentTutorial && this.currentTutorial !== null) {
        this.tutoGoesTo(this.currentTutorial)
      }
      this.$router.push({
        name: "steps",
        params: {
          operationId: this.operation.id,
          membreId: memberId,
        },
      })
    },
    updateSelectRowsOnFilterChange() {
      const dataSource = this.getDataSourceFiltered()
      this.selectedRowKeys = this.selectedRowKeys.filter((key) => {
        return dataSource.find((data) => {
          return key === data.key
        })
      })
    },
    onSelectChange(selectedRowKeys, noEmit) {
      this.selectedRowKeys = selectedRowKeys
      const membresStatsList = this.dataSource
        .filter((data) => selectedRowKeys.includes(data.key))
        .map((data) => data.member)
      if (!(typeof noEmit === "boolean" && noEmit)) {
        this.$emit("selectedMembersChanged", { membresStatsList, selectedRowKeys })
      }
    },
    onChange(pagination, filters, sorter) {
      this.filters = filters
      this.updateSelectRowsOnFilterChange()
    },
    generateDataSource(members) {
      this.dataSource = []
      members.forEach((member, index) => {
        this.dataSource.push({
          key: index,
          id: member.id,
          nom: member.nom,
          status: member.status ? Constants["STATUS"][member.status].text : member.status,
          statusIcon: member.status ? this.getIcon(Constants["STATUS"][member.status].icon) : null,
          nbSites: member.statSite ? member.statSite.size : 0,
          progressSites: member.statSite ? member.statSite.progress : 0,
          nbGroupement: member.statGroupement ? member.statGroupement.size : 0,
          progressGroupement: member.statGroupement ? member.statGroupement.progress : 0,
          nbDocuments: member.statPiece ? member.statPiece.nbPiece : 0,
          nbDocumentsDownloaded: member.statPiece ? member.statPiece.nbPieceDownloaded : 0,
          nbDocumentsUploaded: member.statPiece ? member.statPiece.nbPieceUploaded : 0,
          nbInterlocuteur: member.statInterlocuteur ? member.statInterlocuteur.size : 0,
          progressInterlocuteur: member.statInterlocuteur ? member.statInterlocuteur.progress : 0,
          member: member,
        })
      })
      this.dataSource.sort((a, b) => b.id - a.id)
    },
    getDataSourceFiltered() {
      const toCheck = ["status"]
      let dataSourceFiltered = [...this.dataSource].sort((a, b) => b.id - a.id)
      toCheck.forEach((key) => {
        if (this.filters[key] && this.filters[key].length > 0) {
          dataSourceFiltered = dataSourceFiltered.filter((data) => this.filters[key].includes(data[key]))
        }
      })
      for (let key in this.searchInput) {
        if (this.filters[key] && this.filters[key].length > 0) {
          dataSourceFiltered = dataSourceFiltered.filter((data) =>
            data[key] ? data[key].toString().toLowerCase().includes(this.searchText[key].toLowerCase()) : null
          )
        }
      }
      return dataSourceFiltered
    },
    selectedAllNotFinishProg() {
      let dataSourceFiltered = this.getDataSourceFiltered()
      dataSourceFiltered = dataSourceFiltered.filter((data) => !data.member.certified).map((data) => data.key)
      this.selectedRowKeys = dataSourceFiltered
      this.onSelectChange(this.selectedRowKeys)
    },
    selectedMembers(status) {
      let dataSourceFiltered = this.getDataSourceFiltered()
      dataSourceFiltered = dataSourceFiltered.filter((data) => data.status === status).map((data) => data.key)
      this.selectedRowKeys = dataSourceFiltered
      this.onSelectChange(this.selectedRowKeys)
    },
    onSelectAll(selected) {
      let dataSourceFiltered = []
      if (selected) {
        dataSourceFiltered = this.getDataSourceFiltered()
      }
      this.selectedRowKeys = dataSourceFiltered.map((data) => data.key)
      this.onSelectChange(this.selectedRowKeys)
    },
    handleSearch(fieldName, selectedKeys, confirm) {
      confirm()
      this.searchText[fieldName] = selectedKeys[0]
    },
    handleReset(fieldName, clearFilters) {
      clearFilters()
      this.searchText[fieldName] = ""
    },
  },
}
</script>

<style lang="scss"></style>
