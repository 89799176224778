<template>
  <article class="input-field">
    <input id="siret" type="text" v-model="field" required class="validate" ref="field" />
    <label :class="{ active: field }" for="siret">
      <font-awesome-icon icon="asterisk" class="red-text" />
      Siret
    </label>
  </article>
</template>

<script>
import Mixin from "./Mixin"

export default {
  name: "siret",
  mixins: [Mixin],
  methods: {
    guardian() {
      if (this.field && this.field.length && !this.validateSiret(this.field)) {
        this.$refs.field.setCustomValidity("Mauvais format de Siret")
      } else {
        this.$refs.field.setCustomValidity("")
      }
    },
    validateSiret(siret) {
      var estValide
      if (!siret || siret.length !== 14 || isNaN(siret)) {
        estValide = false
      } else {
        // Le SIRET est un numérique à 14 chiffres
        // Les 9 premiers chiffres sont ceux du SIREN (ou RCS), les 4 suivants
        // correspondent au numéro d'établissement
        // et enfin le dernier chiffre est une clef de LUHN.
        var somme = 0
        var tmp
        for (var cpt = 0; cpt < siret.length; cpt++) {
          console.log("test" + cpt)
          if (cpt % 2 === 0) {
            // Les positions impaires : 1er, 3è, 5è, etc...
            tmp = siret.charAt(cpt) * 2 // On le multiplie par 2
            if (tmp > 9) {
              tmp -= 9 // Si le résultat est supérieur à 9, on lui soustrait 9
            }
          } else {
            tmp = siret.charAt(cpt)
          }
          somme += parseInt(tmp)
        }
        if (somme % 10 === 0) {
          estValide = true // Si la somme est un multiple de 10 alors le SIRET est valide
        } else {
          estValide = false
        }
      }
      return estValide
    },
  },
  watch: {
    field() {
      this.guardian()
    },
  },
  mounted() {
    this.guardian()
  },
}
</script>

<style lang="scss" scoped></style>
