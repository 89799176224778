import Etape1 from "@/components/mcma/steps/sites/Etapes/Etape_1/Etape_1"
import Etape2Elec from "@/components/mcma/steps/sites/Etapes/Etape_2_Elec/Etape_2_Elec"
import Etape2Gaz from "@/components/mcma/steps/sites/Etapes/Etape_2_Gaz/Etape_2_Gaz"
import Etape2Fuel from "@/components/mcma/steps/sites/Etapes/Etape_2_Fuel/Etape_2_Fuel"
import Etape2Eau from "@/components/mcma/steps/sites/Etapes/Etape_2_Eau/Etape_2_Eau"

export default function (confOperation, flow, user, site) {
  const etapes = [Etape1]
  console.log("site : %O", site)
  console.log("confOperation : %O", confOperation)
  console.log("user.role : %O", user.role)
  //need to search across confOperation to know if there is DT for fluide and add step2 or not
  const flows = confOperation.flows
  const flowSite = flows.find((f) => f.name === "sites")
  const panelElec = flowSite.panels.find((p) => p.name === "Données techniques (électricité)")
  const panelGaz = flowSite.panels.find((p) => p.name === "Données techniques (gaz)")
  //Search if there is at least one fields displayed, or return undefined if not found
  const anyFieldsElecActive = panelElec.fields.find((f) => f.displayed === true)
  const anyFieldsGazActive = panelGaz.fields.find((f) => f.displayed === true)
  if (site.id) {
    if (site.fluide === "ELEC") {
      //Display step2 if there is at least one field to display
      if(anyFieldsElecActive !== undefined){
        etapes.push(Etape2Elec)
      }
    } else if (site.fluide === "GAZ") {
      if(anyFieldsGazActive !== undefined){
        etapes.push(Etape2Gaz)
      }
    } else if (site.fluide === "FUEL") {
      etapes.push(Etape2Fuel)
    }/*else if(site.fluide === "EAU"){
      etapes.push(Etape2Eau)
    }*/
  }

  return etapes
}
