<template>
  <a-modal v-model="visible" :maskClosable="false" class="mcma-certify-modal" :title="title">
    <div v-if="canCertify">
      <p>Êtes-vous sur de vouloir certifier les informations de {{ who }} ?</p>
      <p class="error-color mt10">
        {{
          extraMessage ||
          "Vous ne pourrez plus modifier ceux-ci, ni en ajouter, via cette application après validation."
        }}
      </p>
      <a-checkbox class="mt20" @change="setCertifyWithoutAsk">
        Ne plus me demander cette confirmation jusqu'à ma prochaine connexion.
      </a-checkbox>
    </div>
    <div v-else>
      <mcma-empty :inCard="true" image="cant_certify" :center="true" :imageSize="300" :message="noCertifyMessage">
      </mcma-empty>
    </div>
    <template slot="footer">
      <div class="align-row-end mtb12" v-if="canCertify">
        <mcma-button name="Non, pas maintenant" color="orange" class="mr12" @click="handleCancel" />
        <mcma-button
          name="Oui, certifier"
          color="success"
          class="ok mr7"
          leftImage="certified_white"
          @click="handleOk"
        />
      </div>
      <div class="align-row-end mtb12" v-else>
        <mcma-button name="Fermer" color="primary" class="mr7" @click="handleCancel" />
      </div>
    </template>
  </a-modal>
</template>

<script>
// <mcma-certify :visible=""
//               @onCancel=""
//               @onCertify="" />

import McmaEmpty from "@/components/mcma/common/McmaEmpty"
import McmaButton from "@/components/mcma/common/McmaButton"

const CERTIFY_WITHOUT_ASK = "certify-without-ask"
export default {
  name: "McmaCertify",
  props: {
    visible: Boolean,
    canCertify: Boolean,
    title: String,
    who: String,
    noCertifyMessage: String,
    extraMessage: String,
  },
  components: {
    McmaButton,
    McmaEmpty,
  },
  data() {
    return {
      certifyWithoutAsk: null,
    }
  },
  watch: {
    visible(value) {
      if (value) {
        this.certifyWithoutAsk = window.localStorage.getItem(CERTIFY_WITHOUT_ASK)
        if (this.certifyWithoutAsk) {
          this.handleOk()
        }
      }
    },
  },
  methods: {
    handleCancel() {
      this.$emit("onCancel")
    },
    handleOk() {
      this.applyCertifyWithoutAsk()
      this.$emit("onCertify")
    },
    setCertifyWithoutAsk(e) {
      this.certifyWithoutAsk = e.target.checked
    },
    applyCertifyWithoutAsk() {
      if (this.certifyWithoutAsk) {
        window.localStorage.setItem(CERTIFY_WITHOUT_ASK, true)
      }
    },
  },
}
</script>

<style lang="scss">
@import "./src/assets/scss/variables";
@import "./src/assets/scss/functions";

.mcma-certify-modal {
  font-family: $font-family !important;
  width: 800px !important;
  .mcma-button {
    &.ok {
      button {
        padding: 3px 15px 3px 5px;
      }
    }
  }
  * {
    font-weight: 600;
  }
}
</style>
