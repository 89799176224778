<template>
  <main>
    <place-holder :notReady="!selected">
      <section class="container" v-if="selected">
        <div class="row">
          <div class="col s12">
            <div class="card">
              <article class="card-content">
                <span class="card-title"> Lien utilisateur / Membres </span>
                <blockquote>
                  Attribuez massivement vos membres à l'utilisateur "{{ selected.email }}" via le bouton
                  <i class="material-icons light-green-text">add</i>. Vous pouvez aussi les détacher via le bouton
                  <i class="material-icons deep-orange-text">remove</i>
                </blockquote>
              </article>
              <article class="card-action center-align">
                <button
                  type="button"
                  class="waves-effect waves-light btn light-green"
                  @click="submit"
                  id="editUserLinksValidSites"
                >
                  <i class="material-icons">save</i>
                  Valider
                </button>

                <button type="button" class="waves-effect waves-light btn blue darken-2" @click="initialize">
                  <i class="material-icons">remove</i>
                  Réinitialiser
                </button>
              </article>
            </div>
          </div>
        </div>
      </section>
    </place-holder>

    <section class="row">
      <article class="col s6" id="editUserLinksSitesToAdd">
        <place-holder :notReady="!membres">
          <grid
            v-if="membres"
            name="Membres disponibles"
            :rows="membres"
            :columns="['nom', 'operationName', 'Actions']"
            :search="true"
            :columnHeader="{ nom: 'Nom', operationName: 'Operation' }"
            :columnsNoSearch="['Actions']"
            :multiSelect="true"
            @select="(value) => (membresSelected = value)"
          >
            <template slot="header">
              <a
                href
                @click.prevent="add(membresSelected)"
                class="waves-effect waves-light btn light-green"
                id="editUserLinksSiteCheck"
              >
                <i class="material-icons">add</i>
                Ajouter les lignes sélectionnées.
              </a>
            </template>
            <center slot="Actions" slot-scope="props">
              <a href @click.prevent="add([props.row])">
                <i class="material-icons light-green-text editUserLinksSiteAdd">add</i>
              </a>
            </center>
          </grid>
        </place-holder>
      </article>
      <article class="col s6" id="editUserLinksSitesToRemove">
        <place-holder :notReady="!membresGrouped">
          <grid
            v-if="membresGrouped"
            name="Membres associés"
            :rows="membresGrouped"
            :columns="['nom', 'operationName', 'Actions']"
            :search="true"
            :columnHeader="{ nom: 'Nom', operationName: 'Operation' }"
            :columnsNoSearch="['Actions']"
            :multiSelect="true"
            @select="(value) => (membresGroupedSelected = value)"
          >
            <template slot="header">
              <a
                href
                @click.prevent="remove(membresGroupedSelected)"
                class="waves-effect waves-light btn deep-orange"
                id="editUserLinksSiteRemCheck"
              >
                <i class="material-icons">remove</i>
                Supprimer les lignes sélectionnées.
              </a>
            </template>
            <template slot="Actions" slot-scope="props">
              <center>
                <a href @click.prevent="remove([props.row])" class="editUserLinksSiteRem">
                  <i class="material-icons deep-orange-text">remove</i>
                </a>
              </center>
            </template>
          </grid>
        </place-holder>
      </article>
    </section>
  </main>
</template>

<script>
import Axios from "@/util/Axios"
import { mapState, mapMutations } from "vuex"
import _ from "lodash"
import Grid from "@/components/common/Grid"
import Materialize from "materialize-css"
import PlaceHolder from "@/components/common/PlaceHolder"

export default {
  name: "user-link-membre",
  components: {
    Grid,
    PlaceHolder,
  },
  data() {
    return {
      selected: null,

      membres: null,
      membresSelected: [],

      membresGrouped: null,
      membresGroupedSelected: [],
    }
  },
  computed: {
    ...mapState(["user", "operation"]),
  },
  methods: {
    ...mapMutations(["setBreadcrumbAdd"]),
    add(membres) {
      membres.forEach((site) => {
        site.selected = false
      })
      this.membres = [..._.pullAll(this.membres, membres)]
      this.membresGrouped.push(...membres)
      if (membres === this.membresSelected) {
        this.membresSelected = []
      } else {
        this.membresGroupedSelected = [..._.pullAll(this.membresSelected, membres)]
      }
    },
    remove(membres) {
      membres.forEach((site) => {
        site.selected = false
      })
      this.membresGrouped = [..._.pullAll(this.membresGrouped, membres)]
      this.membres.push(...membres)
      if (membres === this.membresGroupedSelected) {
        this.membresGroupedSelected = []
      } else {
        this.membresSelected = [..._.pullAll(this.membresGroupedSelected, membres)]
      }
    },
    initialize() {
      this.selected = null
      Axios("get", "api/users/" + this.$route.params.id).then((response) => {
        this.selected = response.data
        this.setBreadcrumbAdd({
          name: "Liens pour : " + this.selected.email,
          action: () => {},
        })
      })
      let operationName = this.operation.name
      console.log("operationName :%O", operationName)
      Axios(
        "get",
        "/api/operations/" + this.operation.id + "/membres" // ?projection=admin'
        // 'api/membres?size=' + size
      ).then((response) => {
        this.membres = response.data._embedded.membres
        this.membres.forEach(function (e) {
          e.operationName = operationName
        })
        // this.membres.operationName = operationName
        this.recadrage()
      })
      Axios("get", "api/users/" + this.$route.params.id + "/membres?projection=admin").then((response) => {
        this.membresGrouped = response.data._embedded.membres
        this.recadrage()
      })
    },
    recadrage() {
      if (this.membres && this.membresGrouped) {
        this.membres = _.differenceBy(this.membres, this.membresGrouped, "id")
      }
    },
    submit() {
      console.group(">> Ajout lien Utilisateur Membre")
      let membres = ""
      for (const membre of this.membresGrouped) {
        membres += membre._links.self.href + "\n"
      }
      console.log("User id : %O", this.$route.params.id)
      console.log("membres : %O", membres)
      Axios("put", "api/users/" + this.$route.params.id + "/membres", membres, null, {
        "Content-Type": "text/uri-list",
      }).then((response) => {
        Materialize.toast("Liaison user / membres sauvegardée", 4000, "lime")
        this.$router.push({
          name: "users-manager",
          params: {
            operationId: this.operation.id,
          },
        })
      })
      console.groupEnd()
    },
  },
  created() {
    this.initialize()
  },
}
</script>

<style lang="scss" scoped></style>
