<template>
  <mcma-card title="Statistiques" icon="statistics" class="mcma-card-statistics">
    <mcma-card-multi-progress :lines="membersLines" :loading="loading" />
    <mcma-spacer />
    <mcma-card-multi-progress :lines="othersLines" :loading="loading" :percentInColumn="true" />
  </mcma-card>
</template>

<script>
import McmaCard from "@/components/mcma/common/McmaCard"
import McmaCardMultiProgress from "@/components/mcma/common/McmaCardMultiProgress"
import McmaSpacer from "@/components/mcma/common/McmaSpacer"
import { mapGetters } from "vuex"
import _ from "lodash"
import Constants from "@/util/Constants"

export default {
  name: "Statistics",
  props: {
    membresStats: Array,
    loading: Boolean,
  },
  data() {
    return {
      membersLines: {},
      othersLines: {},
      status: [
        {
          name: Constants.STATUS.TO_COMPLETE.icon,
          color: Constants.STATUS.TO_COMPLETE.color,
        },
        {
          name: Constants.STATUS.COMPLETED.icon,
          color: Constants.STATUS.COMPLETED.color,
        },
        {
          name: Constants.STATUS.CERTIFIED.icon,
          color: Constants.STATUS.CERTIFIED.color,
        },
      ],
    }
  },
  components: {
    McmaCard,
    McmaCardMultiProgress,
    McmaSpacer,
  },
  computed: {
    ...mapGetters(["getFlow"]),
  },
  mounted() {
    this.updateTables()
  },
  watch: {
    membresStats(value) {
      this.updateTables()
    },
  },
  methods: {
    initTables() {
      this.membersLines = {
        heading: [
          {
            name: "offset",
          },
          {
            name: Constants.STATUS.INITIALISED.text,
            icon: Constants.STATUS.INITIALISED.icon,
            uppercase: false,
          },
          {
            name: Constants.STATUS.IN_PROGRESS.text,
            icon: Constants.STATUS.IN_PROGRESS.icon,
            uppercase: false,
          },
          {
            name: Constants.STATUS.EXPORTED.text,
            icon: Constants.STATUS.EXPORTED.icon,
            uppercase: false,
          },
          {
            name: Constants.STATUS.APPROVED.text,
            icon: Constants.STATUS.APPROVED.icon,
            uppercase: false,
          },
          {
            name: Constants.STATUS.NOT_PRESERVED.text,
            icon: Constants.STATUS.NOT_PRESERVED.icon,
            uppercase: false,
          },
        ],
        members: [
          {
            name: "membres",
            uppercase: true,
          },
          {
            color: Constants.STATUS.INITIALISED.color,
            value: this.getMembresStatsStateBy("INITIALISED"),
          },
          {
            color: Constants.STATUS.IN_PROGRESS.color,
            value: this.getMembresStatsStateBy("IN_PROGRESS"),
          },
          {
            color: Constants.STATUS.EXPORTED.color,
            value: this.getMembresStatsStateBy("EXPORTED"),
          },
          {
            color: Constants.STATUS.APPROVED.color,
            value: this.getMembresStatsStateBy("APPROVED"),
          },
          {
            color: Constants.STATUS.NOT_PRESERVED.color,
            value: this.getMembresStatsStateBy("NOT_PRESERVED"),
          },
        ],
      }
      this.othersLines = {
        heading: [
          {
            name: "offset",
          },
          {
            name: "interlocuteurs",
            singularWordInfosCount: "interlocuteur",
            uppercase: true,
          },
          {
            name: "sites",
            singularWordInfosCount: "site",
            uppercase: true,
          },
          {
            name: "facturations",
            singularWordInfosCount: "facture",
            uppercase: true,
          },
          {
            name: "pieces",
            singularWordInfosCount: "piece",
            uppercase: true,
          },
          {
            name: "services",
            singularWordInfosCount: "service",
            uppercase: true,
          },
        ],
        to_complete: [
          {
            name: Constants.STATUS.TO_COMPLETE.text,
            icon: Constants.STATUS.TO_COMPLETE.icon,
            uppercase: true,
          },
        ],
        completed: [
          {
            name: Constants.STATUS.COMPLETED.text,
            icon: Constants.STATUS.COMPLETED.icon,
            uppercase: true,
          },
        ],
        certified: [
          {
            name: Constants.STATUS.CERTIFIED.text,
            icon: Constants.STATUS.CERTIFIED.icon,
            uppercase: true,
          },
        ],
      }
    },
    updateTables() {
      this.initTables()
      console.log("this.getFlow : %O", this.getFlow)
      if (this.getFlow.includes("SERVICES")) {
        this.pushItem("Interlocuteurs")
      }
      if (this.getFlow.includes("PERIMETRE")) {
        this.pushItem("Site")
      }
      if (this.getFlow.includes("FACTURATION")) {
        this.pushItem("Groupement")
      }
      if (this.getFlow.includes("DOWNLOAD") || this.getFlow.includes("UPLOAD")) {
        this.pushItem("Pieces")
      }
      if (this.getFlow.includes("SERVICES")) {
        this.pushItem("Services")
      }
    },
    pushItem(methodKey) {
      let endStatus =
        methodKey === "Interlocuteurs" || methodKey === "Services" || methodKey === "Pieces"
          ? [Constants.STATUS.TO_COMPLETE.value, Constants.STATUS.COMPLETED.value, Constants.STATUS.CERTIFIED.value]
          : ["toComplete", Constants.STATUS.COMPLETED.icon, Constants.STATUS.CERTIFIED.icon]
      this.status.forEach((sttus, index) => {
        const methodName = `get${methodKey}StateBy`
        const methodValue = this[methodName](endStatus[index])
        this.othersLines[sttus.name].push({
          color: sttus.color,
          value: methodValue,
        })
      })
    },
    getMembresStatsStateBy(state) {
      if (this.membresStats.length > 0) {
        return _.countBy(this.membresStats, "status")[state] || 0
      }
      return 0
    },
    getSiteStateBy(state) {
      if (this.membresStats.length > 0) {
        return _.sumBy(this.membresStats, (membreStats) => {
          return membreStats.statSite[state]
        })
      }
      return 0
    },
    getGroupementStateBy(state) {
      if (this.membresStats.length > 0) {
        return _.sumBy(this.membresStats, (membreStats) => {
          return membreStats.statGroupement[state]
        })
      }
      return 0
    },
    getInterlocuteursStateBy(state) {
      if (this.membresStats.length > 0) {
        return (
          _.countBy(this.membresStats, (membreStats) => {
            return membreStats.statInterlocuteur.state
          })[state] || 0
        )
      }
      return 0
    },
    getServicesStateBy(state) {
      if (this.membresStats.length > 0) {
        return (
          _.countBy(this.membresStats, (membreStats) => {
            return membreStats.statService.state
          })[state] || 0
        )
      }
      return 0
    },
    getPiecesStateBy(state) {
      if (this.membresStats.length > 0) {
        return (
          _.countBy(this.membresStats, (membreStats) => {
            return membreStats.statPiece.state
          })[state] || 0
        )
      }
      return 0
    },
  },
}
</script>

<style lang="scss">
@import "./src/assets/scss/variables";
@import "./src/assets/scss/functions";

.mcma-card-statistics {
  min-width: 550px;
  .card-content {
    .list {
      .line {
        display: flex;
        flex-flow: row nowrap;
        justify-content: space-between;
        padding: 3px 0;
        min-width: 265px;
        width: 265px;
        color: var(--text-color, $text-color);
        .title {
          font-size: 16px;
          font-weight: 400;
        }
        .value {
          font-size: 16px;
          font-weight: 300;
        }
      }
    }
  }
  .progress-global-block {
    display: flex;
    height: 100%;
    .ant-progress {
      margin: auto 0 auto auto;
      .ant-progress-text {
        font-size: 1.5em;
        color: var(--heading-color, $heading-color) !important;
      }
    }
  }
}
</style>
