export default {
  getLost() {
    return [
      {
        intro:
          '<i class="material-icons">call_split</i> Oups ! Il semblerait que vous vous soyez égaré.<br/>La page sur laquelle vous êtes ne concerne pas le tutoriel que vous consultiez, celui-ci s\'est donc interrompu.',
      },
    ]
  },

  getAdminToMember() {
    return [
      {
        element: document.querySelector("#adminToMemberGrid"),
        intro:
          'Vous êtes sur le panneau d\'administration. Pour pouvoir continuer, vous devez vous connecter à un membre grâce au bouton <i class="material-icons">home</i>.',
      },
    ]
  },

  getRequiresMember() {
    return [
      {
        element: document.querySelector("#menuClientMultiMembres"),
        intro:
          "Vous êtes un utilisateur multimembre et vous n'avez pas sélectionné de membre. Avant de pouvoir continuer, il vous faut sélectionner un membre en cliquant sur le bouton 'SELECTIONNER UN MEMBRE'.",
      },
    ]
  },
  getRequiresMemberP2() {
    return [
      {
        element: document.querySelector("#select"),
        intro: "Sélectionnez l'un de ces membres.",
      },
    ]
  },

  getMenuClient() {
    return [
      {
        intro:
          "<p>Bienvenue dans le menu principal d'EnergieCollecte.</p><p>Ici, vous pouvez gérer toutes les informations nécessaires à la mise en place de votre fourniture d'énergie.</p>",
      },
      {
        element: document.querySelector("#menuClientExplanations"),
        intro: "Vous trouverez ici un résumé de ce que la page propose...",
      },
      {
        element: document.querySelector("#menuClientCountdown"),
        intro: "... ainsi que le temps qu'il reste avant la fin de l'opération.",
      },
    ]
  },
  getMenuClientP2() {
    let tutorial = [
      {
        element: document.querySelector("#menuClientProgression"),
        intro:
          'Voici la progression du membre sur lequel vous travaillez. Pour pouvoir certifier un membre, il faut que cette progression atteigne 100%. Afin d\'y parvenir, il est nécessaire de remplir différents objectifs.<br/>Une fois les 100% atteints, le membre peut être certifié.<br/><i class="material-icons">warning</i> Une fois un membre certifié, on ne peut plus modifier ses informations.<br/><i class="material-icons">info</i> Un administrateur peut décertifier un membre.',
      },
    ]
    if (document.querySelector("#menuClientSites")) {
      tutorial = [
        ...tutorial,
        {
          element: document.querySelector("#menuClientSites"),
          intro: "1 - Les sites<br/>Objectif :<br/>Tous les sites doivent être certifiés.",
        },
      ]
    }
    if (document.querySelector("#menuClientPerimetres")) {
      tutorial = [
        ...tutorial,
        {
          element: document.querySelector("#menuClientPerimetres"),
          intro:
            "2 - Les périmètres de facturation<br/>Objectifs :<br/>100% des sites doivent appartenir à un périmètre de facturation.<br/>Tous les périmètres de facturation doivent être certifiés.",
        },
      ]
    }
    if (document.querySelector("#menuClientInterlocutors")) {
      tutorial = [
        ...tutorial,
        {
          element: document.querySelector("#menuClientInterlocutors"),
          intro:
            "3 - Les interlocuteurs & les services<br/>Objectifs:<br/>Il doit y avoir au moins 1 interlocuteur certifié (dont au moins 1 gestionnaire).<br/>Il doit y avoir au moins un service certifié.",
        },
      ]
    }
    if (document.querySelector("#menuClientBills")) {
      tutorial = [
        ...tutorial,
        {
          element: document.querySelector("#menuClientBills"),
          intro:
            "Il est possible à tout moment avant la certification d'un membre d'ajouter une facture ou de signer une pièce.<br/><i class=\"material-icons\">warning</i> Les factures et les pièces ne seront plus accessibles une fois le membre certifié.",
        },
      ]
    }
    return tutorial
  },

  getMenuAdmin() {
    return [
      {
        intro:
          "Bienvenue dans le panneau d'administration de la plateforme EnergieCollecte.<br/>Ici, vous pouvez voir la progression globale de l'opération et agir sur chacun des membres.",
      },
      {
        element: document.querySelector("#menuAdminProgression"),
        intro: "Ce panneau vous indique la progression globale...",
      },
      {
        element: document.querySelector("#menuAdminTime"),
        intro: "... ainsi que le temps qu'il reste pour l'opération en cours.",
      },
      {
        element: document.querySelector("#menuAdminStats"),
        intro: "Ces lignes montrent le status de tous les membres ainsi que des données qui les composent.",
      },
      {
        element: document.querySelector("#menuAdminMembres"),
        intro: "Pour que la progression globale atteigne 100%, il faut que tous les membres soient au moins exportés.",
      },
      {
        element: document.querySelector("#menuAdminList"),
        intro: "À partir de ce tableau, vous pouvez agir sur chacun des membres.",
      },
      {
        element: document.querySelector("#menuAdminMail"),
        intro:
          "Grâce à ce menu, vous pouvez envoyer des mail de bienvenue, de relance et de remerciement à tous les membre sélectionnés.",
      },
      {
        element: document.querySelector("#menuAdminOperation"),
        intro: "Ce menu vous permet de gérer les utilisateurs, les membres ainsi que les pièces.",
      },
      {
        element: document.querySelector("#menuAdminExport"),
        intro: "Ce troisième menu vous permet d'exporter ou d'approuver tous les membres sélectionnés",
      },
      {
        element: document.getElementsByClassName("menuAdminActionMail")[0],
        intro:
          "Grâce à ces boutons, vous pouvez envoyer des mails de bienvenue, de relance ou de remerciement à ce membre en particulier plutôt que de faire un envoi de masse.",
      },
      {
        element: document.getElementsByClassName("menuAdminConnexion")[0],
        intro: "Enfin, vous pouvez vous connecter au membre grâce à ce bouton.",
      },
    ]
  },

  getValidSite() {
    return [
      {
        element: document.querySelector("#validSiteBtn"),
        intro: "Commencez par accéder à vos sites.",
        tooltipClass: "tutoExpects_home",
      },
    ]
  },
  getValidSiteP2() {
    return [
      {
        element: document.querySelector("#validSiteGrid"),
        intro: "Vous pouvez voir ici la liste de vos sites...",
        tooltipClass: "tutoExpects_sites",
      },
      {
        element: document.getElementsByClassName("validSiteAction")[0] || null,
        intro: document.getElementsByClassName("validSiteAction")[0]
          ? "... ainsi que leur état.<br/>Il existe trois états :<ul><li><b><u>À compléter</u></b> : il manque des informations ;</li><li><b><u>À certifier</u></b> : toutes les informations requises sont présentes, il ne reste plus qu'à certifier le site ;</li><li><b><u>Certifié</u></b> : le site est certifié.</li></ul><br/><i class=\"material-icons\">warning</i> Une fois un site certifié, il n'est plus possible de modifier ses informations. Seul un administrateur peut décertifier un site.<br/>Pour remplir les informations nécessaires à la validation d'un site, cliquez ici."
          : "Ce membre n'a pas de site ou ils ont tous été certifiés. On ne peut donc pas les modifier.",
      },
    ]
  },
  getValidSiteP3() {
    return [
      {
        element: document.querySelector("#validSiteForm"),
        intro: "Vous pouvez maintenant remplir ce formulaire...",
        tooltipClass: "tutoExpects_site",
      },
      {
        element: document.querySelector("#validSiteValid"),
        intro: "... puis le valider...",
      },
      {
        element: document.querySelector("#validSiteMenu"),
        intro: "... avant de passer à la prochaine étape grâce à ce menu.",
      },
      {
        element: document.querySelector("#validSiteFinal"),
        intro:
          "Une fois toutes les informations nécessaires renseignées et validées, un bouton vous permettant de certifier le site apparaîtra ici.",
      },
    ]
  },

  getValidGroup() {
    return [
      {
        element: document.querySelector("#validGroupBtn"),
        intro: "Commencez par accéder à vos périmètres de facturation.",
        tooltipClass: "tutoExpects_home",
      },
    ]
  },
  getValidGroupP2() {
    return [
      {
        element: document.querySelector("#validGroupGrid"),
        intro: "Vous pouvez voir ici la liste de vos périmètres de facturation...",
        tooltipClass: "tutoExpects_groupements",
      },
      {
        element: document.getElementsByClassName("validGroupAction")[0] || null,
        intro: document.getElementsByClassName("validGroupAction")[0]
          ? "... ainsi que leur état.<br/>Il existe trois états :<ul><li><b><u>À compléter</u></b> : il manque des informations ;</li><li><b><u>À certifier</u></b> : toutes les informations requises sont présentes, il ne reste plus qu'à certifier le périmètre ;</li><li><b><u>Certifié</u></b> : le périmètre est certifié.</li></ul><br/><i class=\"material-icons\">warning</i> Une fois un périmètre de facturation certifié, il n'est plus possible de modifier ses informations. Seul un administrateur peut décertifier un périmètre.<br/>Pour remplir les informations nécessaires à la validation d'un périmètre de facturation, cliquez ici."
          : "Ce membre n'a pas de périmètre de facturation ou ils ont tous été certifiés. On ne peut donc pas les modifier.",
      },
    ]
  },
  getValidGroupP3() {
    return [
      {
        element: document.querySelector("#validGroupFinal"),
        intro:
          "Une fois toutes les informations nécessaires renseignées et validées, un bouton vous permettant de certifier le périmètre apparaîtra ici.",
        tooltipClass: "tutoExpects_groupement",
      },
      {
        element: document.querySelector("#validGroupForm"),
        intro: "Vous pouvez renseigner ces informations via ce formulaire...",
      },
      {
        element: document.querySelector("#validGroupValid"),
        intro:
          '... puis le valider en cliquant sur ce bouton ou revenir à la liste des périmètres sans valider en cliquant sur le bouton "Retour".',
      },
    ]
  },
  getValidGroupP4() {
    return [
      {
        element: document.querySelector("#validGroupSites"),
        intro: "Voyons maintenant comment associer des sites à un périmètre. Pour ce faire, cliquez ici.",
        tooltipClass: "tutoExpects_groupements",
      },
    ]
  },
  getValidGroupP5() {
    return [
      {
        element: document.querySelector("#validGroupSitesToAdd"),
        intro: "Voici la liste des sites à associer.",
        tooltipClass: "tutoExpects_groupement-link",
      },
      {
        element: document.getElementsByClassName("validGroupSiteAdd")[0]
          ? document.querySelector("#validGroupSiteCheck")
          : document.querySelector("#validGroupSitesToAdd"),
        intro: document.getElementsByClassName("validGroupSiteAdd")[0]
          ? 'Ajoutez un site en le sélectionnant depuis la colonne "Sélection" puis en cliquant sur ce bouton. Cela vous permet d\'en ajouter plusieurs à la fois.'
          : "Aucun site n'est disponnible à l'ajout pour le moment.",
      },
      {
        element:
          document.getElementsByClassName("validGroupSiteAdd")[0] || document.querySelector("#validGroupSitesToAdd"),
        intro: document.getElementsByClassName("validGroupSiteAdd")[0]
          ? "Vous pouvez également ajouter les sites un par un en cliquant sur ce bouton."
          : 'S\'il y en avait eu, vous auriez pu en ajouter plusieurs à la fois (en les sélectionnant grâce à la colonne "Sélection" puis en cliquant sur "AJOUTER LES LIGNES SÉLECTIONNÉES") ou un par un (grâce à la colonne "Actions").',
      },
      {
        element: document.querySelector("#validGroupSitesToRemove"),
        intro:
          "Voici la liste des sites associés. Ce tableau fonctionne de la même manière que celui des sites disponnibles.",
      },
      {
        element: document.getElementsByClassName("validGroupSiteRem")[0]
          ? document.querySelector("#validGroupSiteRemCheck")
          : document.querySelector("#validGroupSitesToRemove"),
        intro: document.getElementsByClassName("validGroupSiteRem")[0]
          ? 'Supprimez un site en le sélectionnant depuis la colonne "Sélection" puis en cliquant sur ce bouton. Cela vous permet d\'en supprimer plusieurs à la fois.'
          : "Aucun site n'a été associé pour le moment.",
      },
      {
        element:
          document.getElementsByClassName("validGroupSiteRem")[0] || document.querySelector("#validGroupSitesToRemove"),
        intro: document.getElementsByClassName("validGroupSiteRem")[0]
          ? "Vous pouvez également supprimer les sites un par un en cliquant sur ce bouton."
          : 'S\'il y en avait eu, vous auriez pu en supprimer plusieurs à la fois (en les sélectionnant grâce à la colonne "Sélection" puis en cliquant sur "SUPPRIMER LES LIGNES SÉLECTIONNÉES") ou un par un (grâce au bouton <i class="material-icons light-green-text">add</i> dans la colonne "Actions").',
      },
      {
        element: document.querySelector("#validGroupValidSites"),
        intro:
          'Une fois les sites associés à votre convenance, il ne vous reste qu\'à valider.<br/><i class="material-icons">warning</i> Si un périmètre contient plusieurs sites, assurez-vous que tous les sites associés à celui-ci soient éligibles au multisite.<br/><i class="material-icons">info</i> N\'oubliez pas que tous vos sites doivent être associés à un périmètre de facturation.',
      },
    ]
  },

  getValidInterlocutor() {
    return [
      {
        element: document.querySelector("#validInterlocutorBtn"),
        intro: "Commencez par accéder à vos interlocuteurs.",
        tooltipClass: "tutoExpects_home",
      },
    ]
  },
  getValidInterlocutorP2() {
    return [
      {
        element: document.querySelector("#validInterlocutorGrid"),
        intro: "Voici la liste de vos interlocuteurs...",
        tooltipClass: "tutoExpects_interlocuteurs",
      },
      {
        element: document.getElementsByClassName("validInterlocutorState")[0],
        intro:
          "... ainsi que leur état.<br/>Il existe trois états :<ul><li><b><u>À compléter</u></b> : il manque des informations ;</li><li><b><u>À certifier</u></b> : toutes les informations requises sont présentes, il ne reste plus qu'à certifier les interlocuteurs ;</li><li><b><u>Certifié</u></b> : les interlocuteurs sont certifiés.</li></ul>",
      },
      {
        element: document.querySelector("#validInterlocutorAdd"),
        intro: "Cliquez ici pour ajouter un interlocuteur.",
      },
    ]
  },
  getValidInterlocutorP3() {
    return [
      {
        element: document.querySelector("#validInterlocutorForm"),
        intro: "Vous pouvez renseigner les informations nécessaires via ce formulaire.",
        tooltipClass: "tutoExpects_interlocuteur",
      },
      {
        element: document.querySelector("#validInterlocutorRole"),
        intro:
          "Définissez le rôle de l'interlocuteur grâce à cette liste déroulante.<br/><i class=\"material-icons\">info</i> N'oubliez pas qu'il faut au moins un interlocuteur gestionnaire pour pouvoir tous les certifier.",
      },
      {
        element: document.querySelector("#validInterlocutorValid"),
        intro:
          'Cliquez ici pour valider et revenir à la liste des interlocuteurs. (Ou cliquez sur le bouton "Retour" pour revenir à la liste des interlocuteurs sans valider)',
      },
    ]
  },
  getValidInterlocutorP4() {
    return [
      {
        element:
          document.getElementsByClassName("validInterlocutorEdit")[0] ||
          document.querySelector("#validInterlocutorGrid"),
        intro: document.getElementsByClassName("validInterlocutorEdit")[0]
          ? "Il est possible de modifier un interlocuteur en cliquant ici. La marche a suivre est alors la même que pour la création."
          : 'Vous n\'avez pas d\'interlocuteur pour l\'instant. Si vous en aviez, vous pourriez les éditer grâce au bouton <i class="material-icons">edit</i> dans la colonne "Actions".',
        tooltipClass: "tutoExpects_interlocuteurs",
      },
      {
        element: document.querySelector("#validInterlocutorFinal"),
        intro:
          "Une fois l'édition et la validation des interlocuteurs terminée, un bouton vous permettant de les certifier apparaîtra ici.<br/><i class=\"material-icons\">warning</i> Une fois certifiés, il n'est plus possible de modifier les interlocuteurs. Seul un administrateur peut les décertifier.",
      },
    ]
  },

  getValidService() {
    return [
      {
        element: document.querySelector("#validServiceBtn"),
        intro: "Commencez par accéder à vos services.",
        tooltipClass: "tutoExpects_home",
      },
    ]
  },
  getValidServiceP2() {
    return [
      {
        element: document.querySelector("#validServiceGrid"),
        intro: "Voici la liste de vos services...",
        tooltipClass: "tutoExpects_services",
      },
      {
        element: document.getElementsByClassName("validServiceState")[0],
        intro:
          "... ainsi que leur état.<br/>Il existe trois états :<ul><li><b><u>À compléter</u></b> : il manque des informations ;</li><li><b><u>À certifier</u></b> : toutes les informations requises sont présentes, il ne reste plus qu'à certifier les services ;</li><li><b><u>Certifié</u></b> : les services sont certifiés.</li></ul>",
      },
      {
        element: document.querySelector("#validServiceAdd"),
        intro: "Cliquez ici pour ajouter un service.",
      },
    ]
  },
  getValidServiceP3() {
    return [
      {
        element: document.querySelector("#validServiceForm"),
        intro: "Vous pouvez renseigner les informations nécessaires via ce formulaire...",
        tooltipClass: "tutoExpects_service",
      },
      {
        element: document.querySelector("#validServiceValid"),
        intro:
          '... puis les valider et revenir à la liste des services en cliquant sur ce bouton. (Cliquez sur le bouton "Retour" pour revenir à la liste des services sans valider)',
      },
    ]
  },
  getValidServiceP4() {
    return [
      {
        element: document.getElementsByClassName("validServiceEdit")[0] || document.querySelector("#validServiceGrid"),
        intro: document.getElementsByClassName("validServiceEdit")[0]
          ? "Il est possible de modifier un service en cliquant ici. La marche a suivre est alors la même que pour la création."
          : 'Vous n\'avez pas de service pour l\'instant. Si vous en aviez, vous pourriez les éditer grâce au bouton <i class="material-icons">edit</i> dans la colonne "Actions".',
        tooltipClass: "tutoExpects_services",
      },
      {
        element: document.querySelector("#validServiceFinal"),
        intro:
          "Une fois l'édition et la validation des services terminée, un bouton vous permettant de les certifier apparaîtra ici.<br/><i class=\"material-icons\">warning</i> Une fois certifiés, il n'est plus possible de modifier les services. Seul un administrateur peut les décertifier.",
      },
    ]
  },

  getUploadBill() {
    return [
      {
        element: document.querySelector("#uploadBillBtn"),
        intro: "Commencez par accéder à vos factures.",
        tooltipClass: "tutoExpects_home",
      },
    ]
  },
  getUploadBillP2() {
    return [
      {
        element: document.querySelector("#uploadBillGrid"),
        intro: "Voici la liste de vos factures.",
        tooltipClass: "tutoExpects_factures",
      },
      {
        element: document.getElementsByClassName("uploadBillDelete")[0] || document.querySelector("#uploadBillGrid"),
        intro: document.getElementsByClassName("uploadBillDelete")[0]
          ? "Vous pouvez supprimer une facture en cliquant ici..."
          : 'Vous n\'avez pas encore de facture (ou vous êtes dans un membre déjà certifié). Si vous en aviez, vous pourriez les supprimer grâce au bouton <i class="material-icons deep-orange-text">remove</i> dans la colonne "Actions"...',
      },
      {
        element: document.getElementsByClassName("uploadBillDownload")[0] || document.querySelector("#uploadBillGrid"),
        intro: document.getElementsByClassName("uploadBillDoawnload")[0]
          ? "... ou la télécharger en cliquant sur ce bouton."
          : '... ou les télécharger en cliquant sur le bouton <i class="material-icons blue-grey-text">file_download</i> de cette même colonne.',
      },
      {
        element: document.querySelector("#uploadBillAdd"),
        intro: "Enfin, vous pouvez ajouter une facture depuis votre ordinateur grâce à ce bouton.",
      },
    ]
  },

  getSignDocument() {
    return [
      {
        element: document.querySelector("#signDocumentBtn"),
        intro: "Commencez par accéder à vos pièces.",
        tooltipClass: "tutoExpects_home",
      },
    ]
  },
  getSignDocumentP2() {
    return [
      {
        element: document.querySelector("#signDocumentGrid"),
        intro: "Voici la liste de vos pièces.",
        tooltipClass: "tutoExpects_pieces-client",
      },
      {
        element:
          document.getElementsByClassName("signDocumentDownload")[0] || document.querySelector("#signDocumentGrid"),
        intro: document.getElementsByClassName("signDocumentDownload")[0]
          ? "Cliquez ici pour télécharger la pièce afin de la signer."
          : "Vous n'avez encore aucune pièce.",
      },
      {
        element:
          document.getElementsByClassName("signDocumentUpload")[0] || document.querySelector("#signDocumentGrid"),
        intro: document.getElementsByClassName("signDocumentUpload")[0]
          ? "Une fois signée, il ne vous reste plus qu'à déposer la pièce signée grâce à ce bouton."
          : 'Si vous en aviez, vous pourriez télécharger une pièce et la déposer une fois signée grâce aux boutons présents dans la colonne "Actions".',
      },
    ]
  },

  getAddUser() {
    return [
      {
        element: document.querySelector("#operationBtn"),
        intro: 'Commencez par accéder à vos utilisateurs en cliquant sur "Gestion des utilisateurs".',
        tooltipClass: "tutoExpects_home",
      },
    ]
  },
  getAddUserP2() {
    let tutorial = [
      {
        element: document.querySelector("#addUserGrid"),
        intro: "Voici la liste de vos utilisateurs.",
      },
      {
        element: document.querySelector("#addUserForm"),
        intro: "Pour en ajouter un nouveau, remplissez ce formulaire...",
      },
      {
        element: document.querySelector("#addUserValid"),
        intro: '... puis cliquez sur "AJOUTER".',
      },
      {
        element: document.getElementsByClassName("addUserEdit")[0] || document.querySelector("#addUserGrid"),
        intro: document.getElementsByClassName("addUserEdit")[0]
          ? "Pour éditer les informations d'un utilisateur, cliquez ici."
          : 'Vous n\'avez pas encore d\'utilisateur. Si vous en aviez, vous pourriez éditer leurs informations en cliquant sur le bouton <i class="material-icons">edit</i> dans la colonne "Actions".',
      },
    ]

    if (document.getElementsByClassName("addUserEdit")[0]) {
      tutorial = [
        ...tutorial,
        {
          element: document.querySelector("#addUserForm"),
          intro: "Il ne vous reste plus qu'à changer les informations nécessaires...",
        },
        {
          element: document.querySelector("#addUserValid"),
          intro:
            '... puis à cliquer sur "EDITER". Pour repasser de l\'édition à l\'ajout sans valider, vous pouvez cliquer "Terminer" dans le cas présent puis sur la croix en haut à droite de ce formulaire.',
        },
      ]
    }
    return tutorial
  },

  getEditUserLinks() {
    return [
      {
        element: document.querySelector("#operationBtn"),
        intro: 'Commencez par accéder à vos utilisateurs en cliquant sur "Gestion des utilisateurs".',
        tooltipClass: "tutoExpects_home",
      },
    ]
  },
  getEditUserLinksP2() {
    return [
      {
        element: document.querySelector("#addUserGrid"),
        intro: "Voici la liste de vos utilisateurs.",
        tooltipClass: "tutoExpects_users",
      },
      {
        element: document.getElementsByClassName("editUserLinksLink")[0] || document.querySelector("#addUserGrid"),
        intro: document.getElementsByClassName("editUserLinksLink")[0]
          ? "Pour associer des membres à un utilisateur, cliquez ici."
          : 'Vous pouvez associer des membres à un utilisateur en cliquant sur le bouton <i class="material-icons">link</i> dans la colonne "Actions".',
      },
    ]
  },
  getEditUserLinksP3() {
    return [
      {
        element: document.querySelector("#editUserLinksSitesToAdd"),
        intro: "Voici la liste des membres disponnible à l'association.",
      },
      {
        element: document.getElementsByClassName("editUserLinksSiteAdd")[0]
          ? document.querySelector("#editUserLinksSiteCheck")
          : document.querySelector("#editUserLinksSitesToAdd"),
        intro: document.getElementsByClassName("editUserLinksSiteAdd")[0]
          ? 'Ajoutez un membre en le sélectionnant depuis la colonne "Sélection" puis en cliquant sur ce bouton. Cela vous permet d\'en ajouter plusieurs à la fois.'
          : 'Vous pouvez associer plusieurs sites à la fois en les sélectionnant grâce à la colonne "Sélection" puis en cliquant sur "AJOUTER LES LIGNES SÉLECTIONNÉES"...',
      },
      {
        element:
          document.getElementsByClassName("editUserLinksSiteAdd")[0] ||
          document.querySelector("#editUserLinksSitesToAdd"),
        intro: document.getElementsByClassName("editUserLinksSiteAdd")[0]
          ? "Vous pouvez également ajouter les membres un par un en cliquant sur ce bouton."
          : '... ou associer des membres un par un grâce au bouton <i class="material-icons light-green-text">add</i> dans la colonne "Actions".',
      },
      {
        element: document.querySelector("#editUserLinksSitesToRemove"),
        intro:
          "Voici la liste des membres associés. Ce tableau fonctionne de la même manière que celui des membres disponnibles.",
      },
      {
        element: document.getElementsByClassName("editUserLinksSiteRem")[0]
          ? document.querySelector("#editUserLinksSiteRemCheck")
          : document.querySelector("#editUserLinksSitesToRemove"),
        intro: document.getElementsByClassName("editUserLinksSiteRem")[0]
          ? 'Dissociez un membre en le sélectionnant depuis la colonne "Sélection" puis en cliquant sur ce bouton. Cela vous permet d\'en dissocier plusieurs à la fois.'
          : 'Vous pouvez dissocier plusieurs membres à la fois en les sélectionnant grâce à la colonne "Sélection" puis en cliquant sur "SUPPRIMER LES LIGNES SÉLECTIONNÉES"...',
      },
      {
        element:
          document.getElementsByClassName("editUserLinksSiteRem")[0] ||
          document.querySelector("#editUserLinksSitesToRemove"),
        intro: document.getElementsByClassName("editUserLinksSiteRem")[0]
          ? "Vous pouvez également dissocier les membres un par un en cliquant sur ce bouton."
          : '... ou dissocier des membres un par un grâce au bouton <i class="material-icons deep-orange-text">remove</i> dans la colonne "Actions".',
      },
      {
        element: document.querySelector("#editUserLinksValidSites"),
        intro: "Une fois les membres associés à votre convenance, il ne vous reste qu'à valider.",
      },
    ]
  },

  getAddMember() {
    return [
      {
        element: document.querySelector("#operationBtn"),
        intro: 'Commencez par accéder à vos membres en cliquant sur "Gestion des membres".',
        tooltipClass: "tutoExpects_home",
      },
    ]
  },
  getAddMemberP2() {
    let tutorial = [
      {
        element: document.querySelector("#addMemberGrid"),
        intro: "Voici la liste de vos membres.",
      },
      {
        element: document.querySelector("#addMemberForm"),
        intro: "Pour en ajouter un nouveau, remplissez ce formulaire...",
      },
      {
        element: document.querySelector("#addMemberValid"),
        intro: '... puis cliquez sur "AJOUTER".',
      },
      {
        element: document.getElementsByClassName("addMemberEdit")[0] || document.querySelector("#addMemberGrid"),
        intro: document.getElementsByClassName("addMemberEdit")[0]
          ? "Pour éditer les informations d'un membre, cliquez ici."
          : 'Vous pouvez éditer les informations d\'un membre en cliquant sur le bouton <i class="material-icons">edit</i> dans la colonne "Actions".',
      },
    ]

    if (document.getElementsByClassName("addMemberEdit")[0]) {
      tutorial = [
        ...tutorial,
        {
          element: document.querySelector("#addMemberForm"),
          intro: "Il ne vous reste plus qu'à changer les informations nécessaires...",
        },
        {
          element: document.querySelector("#addMemberValid"),
          intro:
            '... puis à cliquer sur "EDITER". Pour repasser de l\'édition à l\'ajout sans valider, vous pouvez cliquer "Terminer" dans le cas présent puis sur la croix en haut à droite de ce formulaire.',
        },
      ]
    }
    return tutorial
  },

  getEditMemberLinks() {
    return [
      {
        element: document.querySelector("#operationBtn"),
        intro: 'Commencez par accéder à vos membres en cliquant sur "Gestion des membres".',
        tooltipClass: "tutoExpects_home",
      },
    ]
  },
  getEditMemberLinksP2() {
    return [
      {
        element: document.querySelector("#addMemberGrid"),
        intro: "Voici la liste de vos membres.",
      },
      {
        element: document.getElementsByClassName("editMemberLinksLink")[0] || document.querySelector("#addMemberGrid"),
        intro: document.getElementsByClassName("editMemberLinksLink")[0]
          ? "Pour associer des sites à un membre, cliquez ici."
          : 'Vous pouvez associer des sites à des membres en cliquant sur le bouton <i class="material-icons">link</i> dans la colonne "Actions".',
      },
    ]
  },
  getEditMemberLinksP3() {
    return [
      {
        element: document.querySelector("#editMemberLinksSitesToAdd"),
        intro: "Voici la liste des sites disponnible à l'association.",
      },
      {
        element: document.getElementsByClassName("editMemberLinksSiteAdd")[0]
          ? document.querySelector("#editMemberLinksSiteCheck")
          : document.querySelector("#editMemberLinksSitesToAdd"),
        intro: document.getElementsByClassName("editMemberLinksSiteAdd")[0]
          ? 'Ajoutez un site en le sélectionnant depuis la colonne "Sélection" puis en cliquant sur ce bouton. Cela vous permet d\'en ajouter plusieurs à la fois.'
          : 'Vous pouvez associer plusieurs sites à la fois en les sélectionnant grâce à la colonne "Sélection" puis en cliquant sur "AJOUTER LES LIGNES SÉLECTIONNÉES"...',
      },
      {
        element:
          document.getElementsByClassName("editMemberLinksSiteAdd")[0] ||
          document.querySelector("#editMemberLinksSitesToAdd"),
        intro: document.getElementsByClassName("editMemberLinksSiteAdd")[0]
          ? "Vous pouvez également associer les sites un par un en cliquant sur ce bouton."
          : '... ou ajouter des sites un par un grâce au bouton <i class="material-icons light-green-text">add</i> dans la colonne "Actions".',
      },
      {
        element: document.querySelector("#editMemberLinksSitesToRemove"),
        intro:
          "Voici la liste des sites associés. Ce tableau fonctionne de la même manière que celui des sites disponnibles.",
      },
      {
        element: document.getElementsByClassName("editMemberLinksSiteRem")[0]
          ? document.querySelector("#editMemberLinksSiteRemCheck")
          : document.querySelector("#editMemberLinksSitesToRemove"),
        intro: document.getElementsByClassName("editMemberLinksSiteRem")[0]
          ? 'Dissociez un site en le sélectionnant depuis la colonne "Sélection" puis en cliquant sur ce bouton. Cela vous permet d\'en dissocier plusieurs à la fois.'
          : 'Vous pouvez dissocier plusieurs sites à la fois en les sélectionnant grâce à la colonne "Sélection" puis en cliquant sur "SUPPRIMER LES LIGNES SÉLECTIONNÉES"...',
      },
      {
        element:
          document.getElementsByClassName("editMemberLinksSiteRem")[0] ||
          document.querySelector("#editMemberLinksSitesToRemove"),
        intro: document.getElementsByClassName("editMemberLinksSiteRem")[0]
          ? "Vous pouvez également dissocier les sites un par un en cliquant sur ce bouton."
          : '... ou dissocier des sites un par un grâce au bouton <i class="material-icons deep-orange-text">remove</i> dans la colonne "Actions".',
      },
      {
        element: document.querySelector("#editMemberLinksValidSites"),
        intro: "Une fois les sites associés à votre convenance, il ne vous reste qu'à valider.",
      },
    ]
  },

  getAddDocument() {
    return [
      {
        element: document.querySelector("#operationBtn"),
        intro: "Commencez par accéder à la gestion des pièces.",
        tooltipClass: "tutoExpects_home",
      },
    ]
  },
  getAddDocumentP2() {
    return [
      {
        element: document.querySelector("#addDocumentGrid"),
        intro: "Voici la liste de vos pièces.",
      },
      {
        element: document.querySelector("#addDocumentForm"),
        intro: "Pour en ajouter une nouvelle, remplissez ce formulaire...",
      },
      {
        element: document.querySelector("#addDocumentAdd"),
        intro: "... puis cliquez sur ajouter.",
      },
      {
        element: document.querySelector("#addDocumentReset"),
        intro: "Ce bouton vous permet de vider le formulaire.",
      },
      {
        element:
          document.getElementsByClassName("addDocumentDownload")[0] || document.querySelector("#addDocumentGrid"),
        intro: document.getElementsByClassName("addDocumentDownload")[0]
          ? "Enfin, vous pouvez télécharger une pièce déposée en cliquant ici."
          : 'Enfin, vous pouvez télécharger une pièce déposée en cliquant sur <i class="blue-grey-text material-icons">file_download</i> dans la colonne "Actions".',
      },
    ]
  },
}
