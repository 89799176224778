<template>
  <section>
    <article v-if="error" class="container">
      <center class="card-panel red darken-2 white-text">
        {{ $t("error.getData") }}
      </center>
    </article>

    <article v-else-if="progressBar" class="container">
      <center>
        <div class="chip">Chargement... {{ $n(progress, "percent") }}</div>
      </center>
      <div class="progress">
        <div class="determinate" :style="{ width: progress * 100 + '%' }" />
      </div>
    </article>

    <article v-else-if="notReady" class="container">
      <center>
        <div class="preloader-wrapper big active">
          <div class="spinner-layer spinner-blue-only">
            <div class="circle-clipper left">
              <div class="circle" />
            </div>
            <div class="gap-patch">
              <div class="circle" />
            </div>
            <div class="circle-clipper right">
              <div class="circle" />
            </div>
          </div>
        </div>
      </center>
    </article>

    <slot v-else-if="!noContent" :class="{ container: !noContent }">
      <div class="alert alert-danger">
        {{ $t("error.noContent") }}
      </div>
    </slot>
  </section>
</template>

<script>
export default {
  name: "place-holder",
  props: ["error", "notReady", "progress", "noContent"],
  computed: {
    progressBar() {
      return this.progress !== null ? this.progress >= 0 && this.progress < 1 : false
    },
  },
}
</script>

<style lang="scss" scoped></style>
