<template>
  <section v-if="membreStats && membreStats.statInterlocuteur">
    <div class="container pt17" v-if="membreStats.statInterlocuteur.state === Constants.STATUS.COMPLETED.value">
      <mcma-card :title="`Validation des interlocuteurs`" icon="certified" class="mcma-card-validate">
        <template #describe>
          Êtes-vous sur de vouloir certifier les informations de vos interlocuteurs ?
          <br />
          Vous ne pourrez plus modifier ceux-ci, ni en ajouter, via cette application après validation.
        </template>
        <div class="align-row-end mtb12">
          <mcma-button name="Non, je ne veux pas encore les certifier" color="orange" class="mr12" @click="goBack" />
          <mcma-button name="Oui, je les certifie" color="success" leftImage="certified_white" @click="validate" />
        </div>
      </mcma-card>
    </div>
    <div class="container pt17" v-else>
      <mcma-empty
        :inCard="true"
        image="cant_certify"
        :center="true"
        :imageSize="300"
        message="Les interlocuteurs ne peuvent pas être certifiés."
      >
        <mcma-button name="Revenir aux interlocuteurs" class="mt24" color="primary" @click="goBack" />
      </mcma-empty>
    </div>
  </section>
</template>

<script>
import GuardiansMixin from "@/components/mcma/steps/guardians/GuardiansMixin"
import McmaCard from "@/components/mcma/common/McmaCard"
import McmaButton from "@/components/mcma/common/McmaButton"
import McmaDivider from "@/components/mcma/common/McmaDivider"
import McmaEmpty from "@/components/mcma/common/McmaEmpty"
import { mapState, mapMutations } from "vuex"
import Axios from "@/util/Axios"
import Constants from "@/util/Constants"

export default {
  name: "validate-interlocuteurs",
  mixins: [GuardiansMixin],
  components: {
    McmaCard,
    McmaButton,
    McmaDivider,
    McmaEmpty,
  },
  data() {
    return {
      flow: "SERVICES",
    }
  },
  computed: {
    ...mapState(["membreStats", "user", "operation"]),
    Constants() {
      return Constants
    },
  },
  methods: {
    ...mapMutations(["setBreadcrumbAdd", "breadcrumbGoBack"]),
    goBack() {
      this.breadcrumbGoBack()
    },
    validate() {
      Axios("patch", `api/membres/${this.membreStats.id}`, {
        approuvedInterlocuteurs: true,
      })
        .then(() => {
          this.$notification.success({
            message: `Interlocuteurs Validé !`,
            description: `Les interlocuteurs ont bien été validé !`,
          })
          this.$router.push({
            name: "interlocuteurs",
          })
        })
        .catch((error) => {
          console.error("error", error)
          this.$notification.error({
            message: `Erreur de validation`,
            description: `Une erreur c'est produite lors de la validation des interlocuteurs, veuillez contacter un administrateur.`,
          })
        })
    },
  },
  created() {
    this.setBreadcrumbAdd({
      name: "Validation",
      action: () => {},
    })
  },
}
</script>

<style lang="scss" scoped></style>
