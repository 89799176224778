<template>
  <a-card class="mcma-card align-row">
    <div class="card-heading">
      <div class="card-avatar" v-if="icon && !hasSlot('cardAvatar')">
        <img class="avatar" :src="getIcon" :alt="title" />
      </div>
      <div class="card-avatar" v-if="!icon && hasSlot('cardAvatar')">
        <div class="cardAvatar">
          <slot name="cardAvatar"></slot>
        </div>
      </div>
      <div class="card-title" v-if="title && !inline">
        <div class="c-title">
          <h3>
            <p v-html="title"></p>
          </h3>
          <div v-if="hasSlot('headerRightSide')" class="headerRightSide align-row-end">
            <slot name="headerRightSide"></slot>
          </div>
        </div>
        <div v-if="hasSlot('describe')" class="describe">
          <p>
            <slot name="describe"></slot>
          </p>
        </div>
      </div>
      <div class="card-title card-row" v-if="title && inline">
        <div class="title-block">
          <div class="c-title" :class="{ 'title-alone': inline && !hasSlot('describe') }">
            <h3>
              {{ title }}
            </h3>
          </div>
          <div v-if="hasSlot('describe')" class="describe" :class="{ 'half-size': hasSlot('headerRightSide') }">
            <p>
              <slot name="describe"></slot>
            </p>
          </div>
        </div>
        <div v-if="hasSlot('headerRightSide')" class="headerRightSide">
          <slot name="headerRightSide"></slot>
        </div>
      </div>
    </div>
    <div v-if="hasSlot()" class="card-body">
      <slot></slot>
    </div>
  </a-card>
</template>

<script>
export default {
  name: "mcmaCard",
  props: {
    title: String,
    icon: String,
    inline: Boolean,
  },
  data() {
    return {
      getIcon:
        this.icon && this.icon !== "noIcon"
          ? require(`@/assets/images/${this.icon}.svg`)
          : this.icon === "noIcon"
          ? "data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iaXNvLTg4NTktMSI/Pgo8IS0tIEdlbmVyYXRvcjogQWRvYmUgSWxsdXN0cmF0b3IgMTkuMC4wLCBTVkcgRXhwb3J0IFBsdWctSW4gLiBTVkcgVmVyc2lvbjogNi4wMCBCdWlsZCAwKSAgLS0+CjxzdmcgdmVyc2lvbj0iMS4xIiBpZD0iTGF5ZXJfMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgeD0iMHB4IiB5PSIwcHgiCgkgdmlld0JveD0iMCAwIDUxMiA1MTIiIHN0eWxlPSJlbmFibGUtYmFja2dyb3VuZDpuZXcgMCAwIDUxMiA1MTI7IiB4bWw6c3BhY2U9InByZXNlcnZlIj4KPGc+Cgk8Zz4KCQk8cGF0aCBmaWxsPSIjMTg5MGZmIiBkPSJNMjU2LDBDMTE1LjAzLDAsMCwxMTUuMDUsMCwyNTZjMCwxNDAuOTcsMTE1LjA1LDI1NiwyNTYsMjU2YzE0MC45NywwLDI1Ni0xMTUuMDUsMjU2LTI1NkM1MTIsMTE1LjAzLDM5Ni45NSwwLDI1NiwwegoJCQkgTTI1Niw0ODJDMTMxLjM4Myw0ODIsMzAsMzgwLjYxNywzMCwyNTZTMTMxLjM4MywzMCwyNTYsMzBzMjI2LDEwMS4zODMsMjI2LDIyNlMzODAuNjE3LDQ4MiwyNTYsNDgyeiIvPgoJPC9nPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+Cjwvc3ZnPgo="
          : null,
    }
  },
  computed: {
    hasSlot() {
      return (name = "default") => {
        return !!this.$slots[name] || !!this.$scopedSlots[name]
      }
    },
  },
}
</script>

<style lang="scss">
@import "./src/assets/scss/variables";
@import "./src/assets/scss/functions";

.mcma-card {
  font-family: $font-family;
  box-shadow: $box-shadow;
  &.ant-card-bordered {
    border: none;
  }
  &.align-row {
    > .ant-card-body {
      display: flex;
      flex-flow: column nowrap;
      height: 100%;
      padding: 24px;
      .card-heading {
        display: flex;
        flex-flow: row nowrap;
        height: 100%;
        .card-avatar {
          min-width: 60px;
          width: 60px;
          height: 60px;
          border-radius: 5px;
          box-shadow: $box-shadow;
          display: flex;
          padding: 0;
          .avatar {
            height: 32px;
            width: auto;
            margin: auto;
          }
          .cardAvatar {
            display: flex;
            flex-flow: column nowrap;
            justify-content: center;
            align-items: center;
            width: 100%;
            height: 100%;
          }
        }
        .card-title {
          padding-left: 20px;
          flex-grow: 2;
          .c-title {
            display: flex;
            height: 60px;
            white-space: nowrap;
            h3 {
              color: var(--heading-color, $heading-color);
              font-size: 20px;
              font-weight: 500;
              margin: auto 0;
              &:first-letter {
                text-transform: capitalize;
              }
            }
          }
          .describe {
            display: flex;
            flex-flow: column nowrap;
            align-items: flex-start;
            justify-content: flex-start;
            &.half-size {
              max-width: 50vw;
            }
            p {
              color: var(--text-color, $text-color);
              margin-bottom: 0;
              font-size: 14px;
              font-weight: 400;
            }
          }
          &.card-row {
            display: flex;
            flex-flow: row nowrap;
            .title-block {
              display: flex;
              flex-flow: column nowrap;
              justify-content: flex-start;
              margin-right: 50px;
              > .c-title {
                height: inherit;
                margin-top: 5px;
                &.title-alone {
                  margin-top: 21px;
                }
              }
              > .describe {
                margin-top: 15px;
              }
            }
            .headerRightSide {
              display: flex;
              flex-flow: row nowrap;
              justify-content: flex-end;
              align-items: center;
              padding-top: 5px;
              flex-grow: 3;
            }
          }
        }
        .headerRightSide {
          display: flex;
          flex-grow: 1;
        }
      }
      .card-body {
        padding-top: calc(var(--padding, $padding) * 2);
      }
    }
  }
}

.mcma-card-header {
  .headerRightSide {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
  }
  .card-body {
    // position: relative;
    // margin-top: 24px;
    // &:before{
    // content: "";
    // position: absolute;
    // top: 0;
    // left: calc(var(--padding, $padding) * -1);
    // width: calc(100% + (var(--padding, $padding) * 2) * 2);
    // height: 1px;
    // background-image: linear-gradient(to right, $border-color-base 66%, rgba($border-color-base,0) 0%);
    // background-position: top;
    // background-size: 15px 1px;
    // background-repeat: repeat-x;
    // }
    .mcma-card-header-body {
      padding-top: 0;
      .card-body {
        padding-top: 0;
        display: flex;
        flex-flow: column nowrap;
        align-items: center;
      }
      .sentence {
        display: flex;
        flex-flow: column nowrap;
        text-align: center;
        align-items: center;
        .principal-message {
          display: flex;
          flex-flow: column nowrap;
          font-size: 16px;
          color: var(--text-color, $text-color);
          font-weight: 400;
          strong {
            color: var(--primary-color, $primary-color);
          }
          &.inline {
            display: inline;
          }
          &.with-bottom {
            margin-top: var(--padding, $padding);
            margin-bottom: calc(var(--padding, $padding) * 2);
          }
          .mcma-icon {
            align-self: center;
            background: var(--success-color, $success-color);
            border-radius: 4px;
            padding: 8px 15px 7px 15px;
            margin: 20px 0;
            font-weight: 900;
            img {
              height: 22px;
            }
          }
        }
        .secondary-message {
          font-size: 18px;
          font-weight: 900;
          background: var(--secondary-color, $orange);
          color: $white;
          border-radius: 4px;
          padding: 20px;
          margin-top: var(--padding, $padding);
          position: relative;
          &:before {
            content: "";
            position: absolute;
            top: calc(var(--padding, $padding) * -1);
            left: calc(var(--padding, $padding) * -1);
            width: calc(100% + (var(--padding, $padding) * 2) * 2);
            height: 1px;
            background-image: linear-gradient(to right, $border-color-base 66%, rgba($border-color-base, 0) 0%);
            background-position: top;
            background-size: 15px 1px;
            background-repeat: repeat-x;
          }
          &.done {
            background: var(--success-color, $success-color);
          }
          &.pending {
            background: var(--secondary-color, $orange);
          }
        }
      }
    }
  }
  .ant-progress {
    .ant-progress-outer {
      width: calc(100% - 60px);
    }
    .ant-progress-text {
      font-size: 22px;
      margin-left: 25px;
      line-height: 25px;
      text-align: right;
      font-weight: 300;
      color: var(--text-color, $text-color);
    }
    &.ant-progress-status-success {
      .ant-progress-text {
        i {
          color: var(--success-color, $success-color);
          svg {
            width: 30px;
            height: 30px;
          }
        }
      }
    }
  }
  .button-group {
    display: flex;
    flex-flow: row nowrap;
  }
}
</style>
