<template>
  <mcma-card :title="getTitle" icon="add_user" class="mcma-card-header">
    <!--    <template #describe>-->
    <!--      -->
    <!--    </template>-->
    <slot></slot>
  </mcma-card>
</template>

<script>
import McmaCard from "@/components/mcma/common/McmaCard"

export default {
  name: "MembersDetailCardHeader",
  props: {
    membreStats: Object,
  },
  components: {
    McmaCard,
  },
  data() {
    return {}
  },
  computed: {
    getTitle() {
      if (this.membreStats && this.membreStats.id) {
        return `Edition du membre ${this.membreStats.nom}`
      }
      return "Nouveau membre"
    },
  },
}
</script>

<style lang="scss"></style>
