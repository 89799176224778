<template>
  <section class="row">
    <div class="col s6" v-for="(step, index) in steps" :key="index">
      <h5>
        {{ step.fields[0].name }}
      </h5>
      <div class="switch">
        <label>
          <input type="checkbox" v-model="step.active" />
          <span class="lever"></span>
          Activer
        </label>
      </div>
      <label>Texte explicatif :</label>
      <vue-editor v-model="step.exlpain" class="explainEditor" />
    </div>
  </section>
</template>

<script>
import { VueEditor } from "vue2-editor"

export default {
  name: "documents",
  props: ["value"],
  components: {
    VueEditor,
  },
  computed: {
    steps: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit(value)
      },
    },
  },
}
</script>

<style lang="scss" scoped>
h5 {
  display: inline-block;
}

.switch {
  display: inline-block;
}

.row {
  padding: 10px !important;
}

.col {
  & > label {
    display: block;
  }
  &:not(:last-of-type) {
    border-right: 1px solid #ddd;
  }
}
</style>
