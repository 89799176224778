<template>
  <section>
    <div class="container">
      <a-row type="flex" :gutter="12" v-if="membresStats">
        <a-col :span="24">
          <members-header :membresStats="membresStats">
            <mcma-spin-box :spinning="loading">
              <members-list-table
                :membresStats="membresStats"
                :isMembersManager="true"
                @initialize="initialize"
                @selectedChanged="selectedChanged"
              />
            </mcma-spin-box>
          </members-header>
        </a-col>
      </a-row>
    </div>
  </section>
</template>

<script>
import McmaSpinBox from "@/components/mcma/common/McmaSpinBox"
import MembersHeader from "@/components/mcma/members/components/MembersHeader"
import MembersListTable from "@/components/mcma/members/components/MembersListTable"
import { mapActions, mapMutations, mapState } from "vuex"

export default {
  name: "MembresManager",
  components: {
    McmaSpinBox,
    MembersHeader,
    MembersListTable,
  },
  data() {
    return {
      loading: true,
    }
  },
  computed: {
    ...mapState(["user", "operation", "membresStats"]),
  },
  methods: {
    ...mapMutations(["setBreadcrumbAdd"]),
    ...mapActions(["requestMembresStats"]),
    initialize() {
      this.requestMembresStats().then(() => {
        this.loading = false
      })
    },
    selectedChanged(list) {
      console.log(
        `%c selectedChanged`,
        "background:#1e4d77 ; padding: 5px 7px 4px 0px; border-radius: 3px;  color: #FFFFFF",
        list
      )
    },
  },
  created() {
    this.setBreadcrumbAdd({
      name: "Gestion des membres",
      action: () => {
        this.$router.push({
          name: "members-manager",
          params: {
            operationId: this.operation.id,
          },
        })
      },
    })
    this.initialize()
  },
}
</script>

<style lang="scss" scoped></style>
