<template>
  <section class="input-field" v-if="exist">
    <input id="numConventionTripartite" type="text" v-model="field" />
    <label :class="{ active: field }" for="numConventionTripartite"> N° de convention tripartite </label>
  </section>
</template>

<script>
import Mixin from "./Mixin"

export default {
  name: "num-convention-tripartite",
  mixins: [Mixin],
  computed: {
    exist() {
      return this.confOperation !== "CARREFOUR"
    },
  },
}
</script>

<style lang="scss" scoped></style>
