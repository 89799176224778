<template>
  <a-modal
    v-model="visible"
    :maskClosable="false"
    class="mcma-modal-information"
    title="Quel approvisionnement enr choisir pour mes sites ?"
  >
    <div
      v-if="htmlRender"
      class="html-render-content"
      v-html="htmlRender"
      :class="{ 'can-edit': $isAdmin() }"
      @click="setEditorModal"
    />
    <template slot="footer">
      <div class="align-row-end mtb12">
        <mcma-button name="J'ai compris" color="success" class="ok mr7" @click="close" />
      </div>
    </template>
  </a-modal>
</template>

<script>
import McmaEmpty from "@/components/mcma/common/McmaEmpty"
import McmaButton from "@/components/mcma/common/McmaButton"

export default {
  name: "McmaEnrInfosModal",
  props: {
    visible: Boolean,
    htmlRender: String,
  },
  components: {
    McmaButton,
    McmaEmpty,
  },
  methods: {
    close() {
      this.$emit("close")
    },
    setEditorModal() {
      this.$emit("setEditorModal")
    },
  },
}
</script>

<style lang="scss">
@import "./src/assets/scss/variables";
@import "./src/assets/scss/functions";
</style>
