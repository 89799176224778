<template>
  <section>
    <div class="container">
      <a-row type="flex" :gutter="12" class="stretch">
        <a-col :span="10">
          <administration-informations :operation="operation" />
        </a-col>
        <a-col :span="14">
          <global-progress :membresStats="membresStats" :loading="loading" />
        </a-col>
      </a-row>
      <a-row>
        <a-col :span="24">
          <statistics :membresStats="membresStats" :loading="loading" />
        </a-col>
      </a-row>
      <a-row>
        <a-col :span="24">
          <members-list :membresStats="membresStats" :loading="loading" />
        </a-col>
      </a-row>
    </div>
  </section>
</template>

<script>
import { mapState, mapMutations, mapActions } from "vuex"
import AdministrationInformations from "@/components/mcma/operations/components/AdministrationInformations"
import GlobalProgress from "@/components/mcma/operations/components/GlobalProgress"
import Statistics from "@/components/mcma/operations/components/Statistics"
import MembersList from "@/components/mcma/members/MembersList"

export default {
  name: "operations-detail",
  components: {
    AdministrationInformations,
    GlobalProgress,
    Statistics,
    MembersList,
  },
  computed: {
    ...mapState(["user", "operation", "membresStats"]),
  },
  data() {
    return {
      loading: true,
    }
  },
  methods: {
    ...mapMutations(["setBreadcrumbAdd"]),
    ...mapActions(["refreshStore", "requestMembresStats", "updateMembre"]),
    initialize() {
      // this.refreshStore(null);
      this.updateMembre(null)
      this.requestMembresStats().then(() => {
        this.loading = false
      })
      this.updateAllColor(this.operation.colors)
    },
    updateAllColor(colors) {
      const root = document.documentElement
      Object.keys(colors).forEach(async (property) => {
        root.style.setProperty(`${property}`, colors[property])
        root.style.setProperty(`${property}-h`, await this.$hexToHSL(colors[property], "hue"))
        root.style.setProperty(`${property}-s`, await this.$hexToHSL(colors[property], "saturation"))
        root.style.setProperty(`${property}-l`, await this.$hexToHSL(colors[property], "lightness"))
      })
    },
  },
  created() {
    this.setBreadcrumbAdd({
      name: this.operation.name || "Panneau de controle",
      action: () => {
        this.$router.push({
          name: "operations-detail",
          params: {
            operationId: this.operation.id,
            membreId: null,
          },
        })
      },
    })
    this.initialize()
  },
}
</script>

<style lang="scss"></style>
