<template>
  <div class="ninja"></div>
</template>

<script>
import { mapState } from "vuex"

export default {
  name: "McmaKonamiUnlock",
  data() {
    return {
      keys: [77, 67, 77, 65],
      isChecked: {
        0: false,
        1: false,
        2: false,
        3: false,
      },
      current: 0,
    }
  },
  computed: {
    ...mapState(["user"]),
  },
  created() {
    window.addEventListener("keyup", this.listen)
  },
  beforeDestroy() {
    window.removeEventListener("keyup", this.listen)
  },
  methods: {
    listen(e) {
      const key = e.which || e.keyCode || e.detail
      if (this.keys.includes(key)) {
        if (key === this.keys[this.current]) {
          this.current += 1
          this.isChecked[this.current - 1] = true
          if (this.current === this.keys.length) {
            if (
              this.user.role === "SUPER_ADMIN" ||
              this.user.role === "ADMIN" ||
              this.user.role === "COORDINATEUR_AVANCE"
            ) {
              this.$emit("unlock")
              console.log(
                `%c Unlock success`,
                "background:#ff6e47 ; padding: 5px 7px 4px 0px; border-radius: 3px;  color: #FFFFFF"
              )
            }
            this.current = 0
            Object.keys(this.isChecked).forEach((k) => {
              this.isChecked[k] = false
            })
          }
        } else {
          Object.keys(this.isChecked).forEach((k) => {
            this.isChecked[k] = false
          })
          this.current = 0
        }
      }
    },
  },
}
</script>
