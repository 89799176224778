<template>
  <a-form :form="form">
    <a-row type="flex" :gutter="12" class="mt-0 mb-0">
      <mcma-field
        :colSpan="12"
        type="radio"
        :form="form"
        fieldName="typeSite"
        :wasSubmit="wasSubmit"
        getFlow="name::sites"
        getPanel="name::Données techniques (gaz)"
        getField="type::typeSite"
        :defaultValue="site.typeSite"
        extraLabel="Sélectionner le type de site qui correspond"
      />
    </a-row>
    <a-row type="flex" :gutter="12" class="mt-0 mb-0">
      <mcma-field
        :colSpan="24"
        type="switch"
        :form="form"
        :wasSubmit="wasSubmit"
        fieldName="bioGaz"
        getFlow="name::sites"
        getPanel="name::Données techniques (gaz)"
        getField="type::bioGaz"
        :defaultValue="site.bioGaz"
        :switchColors="['green', 'red']"
      >
      </mcma-field>
      <mcma-field
        :colSpan="24"
        type="select"
        :form="form"
        :wasSubmit="wasSubmit"
        fieldName="bioGazValue"
        getFlow="name::sites"
        getPanel="name::Données techniques (gaz)"
        getField="type::bioGazList"
        :disabled="activeENRUnique"
        :defaultValue="defaultValueEnr || 0"
      >
       <template #information class="flex-column">
          <div
            v-if="getSitesFlow && getSitesFlow.enrDescribe"
            class="html-render-content"
            v-html="getSitesFlow.enrDescribe"
            :class="{ 'can-edit': $isAdmin() }"
            @click="setEditorModal('sites', 'enrDescribe')"
          />
        </template>
      </mcma-field>
    </a-row>

    <mcma-card title="Option Tarifaire, Profil et CAR" class="mcma-card-in-tab" v-if="showCarcTech">
      <a-row type="flex" :gutter="12">
        <a-col :span="12">
          <div class="field-block-title">Carac. tech. actuelles</div>
          <a-row type="flex" :gutter="12">
            <a-col :span="24">
              <div class="field-list">
                <div class="field-item">
                  <span class="field-label">Option Tarifaire Initial</span>
                  <span class="field-value">{{ $getValueByKey(site.optionTarifaireInitial, "non renseigné") }}</span>
                </div>
                <div class="field-item">
                  <span class="field-label">Profil Initial</span>
                  <span class="field-value">{{ $getValueByKey(site.profilInitial, "non renseigné") }}</span>
                </div>
                <div class="field-item">
                  <span class="field-label">CAR (kWh) Initial</span>
                  <span class="field-value">{{ $getValueByKey(site.carInitial, "non renseigné") }}</span>
                </div>
              </div>
            </a-col>
          </a-row>
        </a-col>
        <a-col :span="12" v-if="showCarcTech">
          <div class="field-block-title">Carac. tech. qui seront mises en place</div>
          <a-row type="flex" :gutter="12" class="mtb4">
            <mcma-field
              :colSpan="24"
              type="select"
              :form="form"
              :wasSubmit="wasSubmit"
              fieldName="optionTarifaire"
              getFlow="name::sites"
              getPanel="name::Données techniques (gaz)"
              getField="type::optionTarifaire"
              :defaultValue="site.optionTarifaire"
            />
            <mcma-field
              :colSpan="24"
              type="select"
              :form="form"
              :wasSubmit="wasSubmit"
              fieldName="profil"
              getFlow="name::sites"
              getPanel="name::Données techniques (gaz)"
              getField="type::profil"
              :defaultValue="site.profil"
            />
          </a-row>
          <a-row type="flex" :gutter="12" class="mtb4">
            <mcma-field
              :colSpan="form.getFieldValue('optionTarifaire') === 'T_4' ? 12 : 24"
              type="input"
              :form="form"
              :wasSubmit="wasSubmit"
              fieldName="car"
              getFlow="name::sites"
              getPanel="name::Données techniques (gaz)"
              getField="type::car"
              :defaultValue="site.car ? site.car.toString() : null"
              numberOnly="decimal"
            />
            <mcma-field
              v-if="form.getFieldValue('optionTarifaire') === 'T_4'"
              :colSpan="12"
              type="input"
              :form="form"
              :wasSubmit="wasSubmit"
              fieldName="cja"
              :defaultValue="site.cja ? site.cja.toString() : null"
              label="CJA (MWh)"
              numberOnly="decimal"
            />
          </a-row>
        </a-col>
      </a-row>
      <a-row type="flex" :gutter="12" class="mt0">
        <mcma-field
          :colSpan="24"
          type="textarea"
          :form="form"
          :wasSubmit="wasSubmit"
          fieldName="commentaire"
          getFlow="name::sites"
          getPanel="name::Données techniques (gaz)"
          getField="type::comment"
          :height="120"
        />
      </a-row>
    </mcma-card>
    <a-row type="flex" :gutter="12">
      <a-col :span="24">
        <div class="button-group-inline">
          <mcma-button name="Réinitialiser" color="primary" leftIcon="undo-alt" :isFaIcon="true" @click="initialize" />
          <mcma-button
            name="Valider les informations"
            class="ml12"
            color="success"
            leftIcon="save"
            :isFaIcon="true"
            @click="submit"
          />
        </div>
      </a-col>
    </a-row>

    <mcma-editor-modal
      v-if="$isAdmin()"
      :visible="showEditor"
      :flowName="flowName"
      :flowProp="flowProp"
      @onCancel="setEditorModal"
      @onSave="editorHasMakeChange"
    />
    <mcma-enr-infos-modal
      :visible="openInfos"
      :htmlRender="getSitesFlow && getSitesFlow.enrModalDescribe ? getSitesFlow.enrModalDescribe : null"
      @setEditorModal="setEditorModal('sites', 'enrModalDescribe')"
      @close="closeInfos"
    />
  </a-form>
</template>

<script>
import Axios from "@/util/Axios"
import McmaButton from "@/components/mcma/common/McmaButton"
import McmaCard from "@/components/mcma/common/McmaCard"
import McmaField from "@/components/mcma/common/McmaField"
import { RV_RESULT, RV_GAZ } from "./RG_GAZ"
import { mapState } from "vuex"
import { getDynamiqueFields } from "@/util/Helpers/DynamiqueFields"
import McmaEditorModal from "@/components/mcma/common/McmaEditorModal"
import McmaEnrInfosModal from "@/components/mcma/common/McmaEnrInfosModal"

export default {
  name: "etape_2_gaz",
  props: ["site"],
  components: {
    McmaCard,
    McmaField,
    McmaButton,
    McmaEditorModal,
    McmaEnrInfosModal,
  },
  data() {
    return {
      form: this.$form.createForm(this),
      wasSubmit: false,
    }
  },
  computed: {
    ...mapState(["operation", "membre"]),
    activeENRUnique() {
      const enrUniqueSite = getDynamiqueFields(
        this.operation,
        "name::sites",
        "name::Localisation",
        "type::enrUniqueSite"
      )
      return enrUniqueSite.displayed
    },
    defaultValueEnr() {
      let enr = 0
      enr = this.activeENRUnique && this.membre.enrUniqueSite ? this.membre.enrUniqueSite : this.site.bioGazValue
      return enr
    },
    getSitesFlow() {
      return this.operation.flows.find((flow) => flow.name === "sites")
    },
    showCarcTech() {
      const typeOption = getDynamiqueFields(
        this.operation,
        "name::sites",
        "name::Données techniques (gaz)",
        "type::optionTarifaire"
      )
      const typeProfil = getDynamiqueFields(
        this.operation,
        "name::sites",
        "name::Données techniques (gaz)",
        "type::profil"
      )
      const typeCar = getDynamiqueFields(this.operation, "name::sites", "name::Données techniques (gaz)", "type::car")
      return typeOption && typeProfil && typeCar
        ? typeOption.displayed || typeProfil.displayed || typeCar.displayed
        : true
    },
  },
  methods: {
    initialize() {
      this.form.getFieldDecorator("bioGaz")
      this.form.getFieldDecorator("pce")
      this.form.getFieldDecorator("optionTarifaire")
      this.form.getFieldDecorator("profil")
      this.form.getFieldDecorator("car")
      this.form.getFieldDecorator("cja")
      this.form.getFieldDecorator("commentaire")
      this.form.setFieldsValue({
        bioGaz: this.site.bioGaz,
        bioGazValue: this.site.bioGazValue,
        pce: this.site.pce,
        optionTarifaire: this.site.optionTarifaire,
        profil: this.site.profil,
        car: this.site.car ? this.site.car.toString() : null,
        cja: this.site.cja ? this.site.cja.toString() : null,
        commentaire: this.site.commentaire,
      })
    },
    submit() {
      this.form.validateFields((errors, fieldsValue) => {
        if (errors) {
          this.$notification.warning({
            message: "Formulaire invalide",
            description: `Veuillez-vous assurez de remplir tous les champs requis.`,
          })
        } else {
          this.validate(fieldsValue)
        }
      })
    },
    validate(fieldsValue) {
      const errors = []
      const derogables = []
      let valid = true

      if (fieldsValue.optionTarifaire != null) {
        for (const rv in RV_GAZ) {
          const result = RV_GAZ[rv](fieldsValue.optionTarifaire, fieldsValue.profil)
          if (result.state === RV_RESULT.KO) {
            errors.push(result.message)
            valid = false
          } else if (result.state === RV_RESULT.DEROGABLE) {
            derogables.push(result.message)
          }
        }
      }

      if (valid && derogables.length) {
        let message = "Les paramètres de votre site ne respectent pas les règles suivantes :\n\n"
        for (const derogable of derogables) {
          message += "  - " + derogable + "\n"
        }
        message += "\nVoulez-vous vraiment déroger à ces règles?"

        this.confirmDerogation(message, fieldsValue)
      } else if (!valid && errors.length) {
        this.$notification.warning({
          message: "Formulaire invalide",
          description: `Veuillez-vous assurez de remplir tous les champs requis.`,
        })
      } else {
        this.process(fieldsValue)
      }
    },
    confirmDerogation(message, fieldsValue) {
      const that = this
      this.$confirm({
        title: `Déroger à la règle ?`,
        content: message,
        okText: "Oui",
        okType: "success",
        cancelText: "Non",
        onOk() {
          that.process(fieldsValue)
        },
        onCancel() {
          console.log(
            `%c Cancel dérogation rule`,
            "background:red ; padding: 5px 7px 4px 0px; border-radius: 3px;  color: #FFFFFF"
          )
          that.$notification.warning({
            message: "Correction des règles",
            description: `Veuillez corriger les règles non respecté pour finir l'operation correctement.`,
          })
        },
      })
    },
    process(fieldsValue) {
      Axios("patch", "api/sites/" + this.site.id, {
        ongletParametre: true,
        bioGaz: fieldsValue.bioGaz,
        bioGazValue: fieldsValue.bioGazValue,
        pce: fieldsValue.pce,
        optionTarifaire: fieldsValue.optionTarifaire,
        profil: fieldsValue.profil,
        car: fieldsValue.car,
        cja: fieldsValue.optionTarifaire === "T_4" ? fieldsValue.cja : null,
        commentaire: fieldsValue.commentaire,
        typeSite: fieldsValue.typeSite,
      })
        .then((response) => {
          this.$emit("save", response.data.id)
          this.$emit("update", response.data)
          this.$notification.success({
            message: "Paramètres sauvegardées !",
            description: `Les paramètres ont bien été sauvegardées`,
          })
        })
        .catch((error) => {
          console.error(error)
          this.$notification.error({
            message: "Erreur serveur",
            description: "Une erreur server est survenue, veuillez contacter un administrateur.",
          })
        })
    },
    closeInfos() {
      this.openInfos = false
    },
    openInfosModal() {
      this.openInfos = true
    },
  },
  watch: {
    site() {
      this.initialize()
    },
  },
  created() {
    this.initialize()
  },
}
</script>

<style lang="scss"></style>
