import Vue from "vue"
import Axios from "@/util/Axios"
import { getClientTechInfos } from "@/util/Helpers/Navigator"

Vue.config.errorHandler = (err, vm, info) => {
  console.error(err)

  const error = {
    vue: vm.$options._componentTag,
    lifeCycle: info,
    message: err.message,
    stack: err.stack,
    clientTechInfos: getClientTechInfos(),
    user: null,
    membre: null,
  }

  if (vm.$store.state.user) {
    error.user = {
      id: vm.$store.state.user.id,
      value: vm.$store.state.user.email,
    }
  }

  if (vm.$store.state.membre) {
    error.membre = {
      id: vm.$store.state.membre.id,
      value: vm.$store.state.membre.nom,
    }
  }

  Axios("post", "clientMonitor/error", error)
}
