<template>
  <section class="input-field">
    <input id="iban" type="text" v-model="field" :required="required" class="validate" ref="iban" />
    <label :class="{ active: field }" for="iban">
      <font-awesome-icon icon="asterisk" class="red-text" v-if="required" />
      Code IBAN
    </label>
  </section>
</template>

<script>
import Mixin from "./Mixin"
import IBAN from "iban"

export default {
  name: "iban",
  mixins: [Mixin],
  computed: {
    required() {
      return (
        this.context.moyenPaiement === "PRELEVEMENT_BANQUE_DE_FRANCE" || this.context.moyenPaiement === "PRELEVEMENT"
      )
    },
  },
  methods: {
    guardian() {
      if (this.field && this.field.length && !IBAN.isValid(this.field)) {
        this.$refs.iban.setCustomValidity("Mauvais format de code IBAN")
      } else {
        this.$refs.iban.setCustomValidity("")
      }
    },
  },
  watch: {
    field() {
      this.guardian()
    },
  },
  mounted() {
    this.guardian()
  },
}
</script>

<style lang="scss" scoped></style>
