<template>
  <main>
    <div class="container">
      <section class="row">
        <article class="card">
          <div class="card-content">
            <span class="card-title"> Liste de vos sites </span>
            <div class="progress">
              <div class="determinate" :style="{ width: progression * 100 + 1 + '%' }"></div>
            </div>
            <blockquote>
              <div v-if="progression > 0">
                Votre progression actuelle pour cette étape est de
                {{ $n(progression, "percent") }}.
              </div>
              <br />
              <span v-if="progression !== 1">
                Pour finir cette étape, vous devez compléter et certifier tous vos sites.
              </span>
              <span v-else>
                Cette étape est terminée. <br />
                Pour savoir ce qu'il vous reste à faire :
                <router-link :to="{ name: 'home' }" class="waves-effect waves-light btn blue darken-2">
                  rendez-vous sur la page d'accueil </router-link
                >.
              </span>
            </blockquote>
          </div>
          <center class="card-action" v-if="membre.status !== 'EXPORTED' && membre.status !== 'APPROVED'">
            <router-link
              :to="{ name: 'site', params: { id: 'new' } }"
              class="waves-effect waves-light btn blue darken-2"
            >
              Ajouter un nouveau site
            </router-link>
            <router-link
              :to="{ name: 'site', params: { id: 'masse' } }"
              class="waves-effect waves-light btn blue darken-2"
            >
              Modifier en masse vos sites
            </router-link>
          </center>
        </article>
      </section>
    </div>
    <section class="row">
      <article class="col s12" id="validSiteGrid">
        <place-holder :notReady="!sites">
          <grid
            v-if="sites"
            :rows="sites"
            :columns="columns"
            :search="true"
            :columnHeader="columnHeader"
            :columnsListFilter="columnsListFilter"
          >
            <!-- :columnsNoSearch="['multiSite']" -->
            <div slot="state" slot-scope="props" class="action">
              <router-link
                class="chip white-text deep-orange progression validSiteAction tutorialNoBtn"
                v-if="props.row.state === 'TO_COMPLETE'"
                :to="{ name: 'site', params: { id: props.row.id } }"
                tag="span"
              >
                <i class="material-icons">edit</i>
                À&nbsp;completer
              </router-link>

              <router-link
                class="chip white-text amber progression validSiteAction tutorialNoBtn"
                v-else-if="props.row.state === 'TO_CERTIFY'"
                :to="{ name: 'site', params: { id: props.row.id } }"
                tag="span"
              >
                <i class="material-icons">send</i>
                À&nbsp;certifier
              </router-link>

              <router-link
                class="chip white-text lime progression"
                v-else
                :to="{ name: 'site', params: { id: props.row.id } }"
                tag="span"
              >
                <i class="material-icons">check</i>
                Certifié
              </router-link>

              <i
                class="material-icons pointer deep-orange-text"
                v-tooltip.auto="'Décertifier le site'"
                v-if="props.row.state === 'CERTIFIED' && user.role !== 'CLIENT'"
                @click="unCertify(props.row)"
                >undo</i
              >
            </div>
          </grid>
        </place-holder>
      </article>
    </section>
  </main>
</template>

<script>
import Mixin from "../Mixin"
import Axios from "@/util/Axios"
import { mapState, mapMutations, mapActions } from "vuex"
import Grid from "@/components/common/Grid"
import _ from "lodash"
import SuperAxios from "axios"
import Materialize from "materialize-css"
import PlaceHolder from "@/components/common/PlaceHolder"

export default {
  name: "sites",
  mixins: [Mixin],
  components: {
    Grid,
    PlaceHolder,
  },
  data() {
    return {
      flow: "PERIMETRE",
      sites: null,
      columns: ["nom", "rae", "fluide", "status", "codeSite", "adresse", "commune", "state"],
      columnHeader: {
        nom: "Nom",
        status: "Statut",
        codeSite: "Code Site",
        codeGroupement: "Groupe",
        adresse: "Adresse",
        commune: "Commune",
        state: "Action",
        fluide: "Fluide",
        rae: "RAE",
      },
      columnsListFilter: {
        state: {
          TO_COMPLETE: "À completer",
          TO_CERTIFY: "À certifier",
          CERTIFIED: "Certifié",
        },
      },
    }
  },
  computed: {
    ...mapState(["membre", "user", "operation"]),
    progression() {
      if (this.sites) {
        let progress = 0
        progress += 1 * (this.membre.progressions.CERTIFIED || 0)
        progress += 0.5 * (this.membre.progressions.TO_CERTIFY || 0)
        return Math.trunc(progress / this.sites.length)
      }
      return 0
    },
  },
  methods: {
    ...mapMutations(["setBreadcrumbAdd"]),
    ...mapActions(["refreshStore"]),
    initialize() {
      this.sites = null
      this.setBreadcrumbAdd({
        name: "Sites",
        action: () => {
          this.$router.push({
            name: "sites",
          })
        },
      })
      Axios("get", "api/membres/" + this.membre.id + "/sites").then((response) => {
        this.sites = _.filter(
          _.flatMap(response.data._embedded, (value) => value),
          { removed: false }
        )
      })
      this.refreshStore()
    },
    unCertify(site) {
      const callMembre = Axios("patch", "api/membres/" + this.membre.id, {
        certified: false,
        approuved: false,
      })

      const callSite = Axios("patch", "api/sites/" + site.id, {
        certified: false,
      })

      SuperAxios.all([callMembre, callSite])
        .then((response) => {
          Materialize.toast("Site décertifiés", 4000, "lime white-text")
          this.initialize()
        })
        .catch((error) => {
          console.error(error)
          Materialize.toast("Site décertifiés", 4000, "lime white-text")
          this.initialize()
          // Materialize.toast('Erreur lors de la décertification', 4000, 'deep-orange white-text')
        })
    },
  },
  created() {
    this.initialize()
  },
}
</script>

<style lang="scss" scoped>
.progression {
  cursor: pointer;
}
.action {
  min-width: 150px;
}
</style>
