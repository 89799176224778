var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.membres && _vm.membres.length > 1)?_c('div',{staticClass:"mcma-multi-membre-fixed",class:[
    _vm.membres ? ("push-right-" + (_vm.membres.length)) : '',
    {
      open: _vm.membre && _vm.showToggleMultiMembre,
    } ]},[_c('div',{staticClass:"toggle",on:{"click":function($event){return _vm.updateToggleMultiMembre(!_vm.showToggleMultiMembre)}}},[_c('mcma-icon',{class:{ 'rotate-180': _vm.showToggleMultiMembre },attrs:{"type":"angle-left","color":"orange","faIcon":true}})],1),_c('ul',{staticClass:"members-list"},_vm._l((_vm.$orderBy(_vm.membres, 'id')),function(mbre,index){return _c('li',{key:mbre.id,staticClass:"member-item"},[_c('a-tooltip',{attrs:{"placement":"bottomRight","overlayClassName":"membersListTooltip"}},[_c('template',{slot:"title"},[_c('div',{staticClass:"no-wrap"},[_c('strong',[_vm._v(" Nom : "),_c('span',{staticClass:"primary-color"},[_vm._v(" "+_vm._s(mbre.nom)+" ")])]),_c('strong',[_vm._v(" Opération : "),_c('span',{staticClass:"orange-color"},[_vm._v(" "+_vm._s(mbre.operationName)+" ")])])])]),_c('div',{staticClass:"avatar-block",class:{ active: _vm.membre && _vm.membre.id === mbre.id },style:({
            backgroundColor: _vm.membre && _vm.membre.id === mbre.id ? '#FFFFFF' : _vm.multiSiteColor[index],
            borderColor: _vm.multiSiteColor[index],
          }),on:{"click":function($event){return _vm.setMembreAndGoToStep(mbre)}}},[_c('div',{staticClass:"avatar",style:({
              color: _vm.membre && _vm.membre.id === mbre.id ? _vm.multiSiteColor[index] : '#FFFFFF',
            })},[_vm._v(" "+_vm._s(_vm.getRealMembreName(mbre.nom)[0])+" ")])])],2)],1)}),0)]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }