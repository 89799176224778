import Vue from "vue"
import Router from "vue-router"
// import Home from "@/components/layout/Home";
import Contact from "@/components/layout/Contact"
import Groupements from "@/components/flow/Groupements"
import Groupement from "@/components/flow/Groupement"
import GroupementLink from "@/components/flow/GroupementLink"

import Sites from "@/components/flow/Sites"
import Site from "@/components/flow/Site"

import Interlocuteur from "@/components/flow/Interlocuteur"
import Interlocuteurs from "@/components/flow/Interlocuteurs"
import Service from "@/components/flow/Service"

import Services from "@/components/flow/Services"
import Factures from "@/components/flow/Factures"

import Validation from "@/components/flow/Validation"
import ValidationSite from "@/components/flow/ValidationSite"

import ValidationGroupement from "@/components/flow/ValidationGroupement"
import ValidationInterlocuteurs from "@/components/flow/ValidationInterlocuteurs"

import ValidationServices from "@/components/flow/ValidationServices"

import User from "@/components/layout/User"
// import Users from "@/components/admin/Users";
import Mails from "@/components/admin/Mails"
import Membres from "@/components/admin/Membres"
import SiteLink from "@/components/admin/SiteLink"

import UserLinkMembre from "@/components/admin/UserLinkMembre"
import PiecesAdmin from "@/components/flow/Pieces/Admin"
import PiecesClient from "@/components/flow/Pieces/Client"
import UserAdminLinkOperation from "@/components/superadmin/UserAdminLinkOperation"
import Tutorials from "@/components/layout/Tutorials"
// Afondevi

import OperationsDetail from "@/components/mcma/operations/OperationsDetail"

import Steps from "@/components/mcma/steps/Steps"
import SitesV2 from "@/components/mcma/steps/sites/Sites"
import SitesDetailV2 from "@/components/mcma/steps/sites/SitesDetail"
import ValidateSiteV2 from "@/components/mcma/steps/sites/ValidateSite"
import GroupementsV2 from "@/components/mcma/steps/groupements/Groupements"
import GroupementsDetailV2 from "@/components/mcma/steps/groupements/GroupementsDetail"
import ValidateGroupementV2 from "@/components/mcma/steps/groupements/ValidateGroupement"
import GroupementsLinkV2 from "@/components/mcma/steps/groupements/GroupementsLink"
import GroupementsLinkMass from "@/components/mcma/steps/groupements/GroupementsLinkMass"
import InterlocuteursV2 from "@/components/mcma/steps/interlocuteurs/Interlocuteurs"
import ValidateInterlocuteursV2 from "@/components/mcma/steps/interlocuteurs/ValidateInterlocuteurs"
import InterlocuteursDetailV2 from "@/components/mcma/steps/interlocuteurs/InterlocuteursDetail"
import DocumentsV2 from "@/components/mcma/steps/documents/Documents"
import EmailsTrackerV2 from "@/components/mcma/admin/emailsTracker/EmailsTracker"
import ContactUsV2 from "@/components/mcma/help/contactUs/ContactUs"
import HomeV2 from "@/components/mcma/redirection/Home"
import UsersManagerV2 from "@/components/mcma/users/UsersManager"
import UsersDetailV2 from "@/components/mcma/users/UsersDetail"
import MembersManagerV2 from "@/components/mcma/members/MembersManager"
import MembersDetailV2 from "@/components/mcma/members/MembersDetail"
import DocumentsManagerV2 from "@/components/mcma/admin/documentsManager/DocumentsManager"
import DocumentsManagerDetailV2 from "@/components/mcma/admin/documentsManager/DocumentsManagerDetail"
import Observator from "@/components/mcma/admin/observator/Observator"
// HOT FIX
import Board from "@/components/layout/Home/SuperAdmin/pages/Board/Board"
import Editor from "@/components/layout/Home/SuperAdmin/pages/Editor/Editor"
import AddAdmin from "@/components/layout/Home/SuperAdmin/pages/AddAdmin/AddAdmin"

// CD
import Piecetelechargement from "@/components/flow/Pieces/Download"
import Piecedepot from "@/components/flow/Pieces/Upload"
import SuperAdmin from "@/components/layout/Home/SuperAdmin/SuperAdminPages"

const originalPush = Router.prototype.push
Router.prototype.push = function push(location, onResolve, onReject) {
  if (onResolve || onReject)
    return originalPush.call(this, location, onResolve, onReject)
  return originalPush.call(this, location).catch((err) => {
    if (Router.isNavigationFailure(err)) {
      // resolve err
      return err
    }
    // rethrow error
    return Promise.reject(err)
  })
}

Vue.use(Router)

let router = new Router({
  scrollBehavior(to, from, savedPosition) {
    return { x: 0, y: 0 }
  },
  mode: "history",
  routes: [
    // {
    //   path: "/" + Home.name,
    //   name: Home.name,
    //   component: Home,
    //   alias: "/"
    // },
    {
      path: `/`,
      name: "home",
      component: HomeV2,
    },
    {
      path: `/operations/:operationId`,
      name: "operations-detail",
      component: OperationsDetail,
    },
    {
      path: `/operations/:operationId/observator`,
      name: "observator",
      component: Observator,
    },
    {
      path: "/operations/:operationId/membres/:membreId/steps",
      name: "steps",
      component: Steps,
    },
    {
      path: "/operations/:operationId/membres/:membreId/steps/sites",
      name: "sites",
      component: SitesV2,
    },
    {
      path: "/operations/:operationId/membres/:membreId/steps/sites/:siteId?/:action?",
      name: "sites-detail",
      component: SitesDetailV2,
    },
    {
      path: "/operations/:operationId/membres/:membreId/steps/sites/:siteId?/validation",
      name: "validate-site",
      component: ValidateSiteV2,
    },
    {
      path: "/operations/:operationId/membres/:membreId/piecetelechargement",
      name: "piecetelechargement",
      component: Piecetelechargement,
    },
    {
      path: "/operations/:operationId/membres/:membreId/piecedepot",
      name: "piecedepot",
      component: Piecedepot,
    },
    {
      path: "/superadmin",
      component: SuperAdmin,
      children: [
        {
          path: "/superadmin/board",
          name: "superadmin-board",
          component: Board,
        },
        {
          path: "/superadmin/:operationId?/edit",
          name: "superadmin-operation-editing",
          component: Editor,
        },
        {
          path: "/superadmin/add-admin",
          name: "superadmin-add-admin",
          component: AddAdmin,
        },
      ],
    },
    {
      path: "/operations/:operationId/membres/:membreId/steps/groupements",
      name: "groupements",
      component: GroupementsV2,
    },
    {
      path: "/operations/:operationId/membres/:membreId/steps/groupements/:groupementId?/:action?",
      name: "groupements-detail",
      component: GroupementsDetailV2,
    },
    {
      path: "/operations/:operationId/membres/:membreId/steps/groupements/:groupementId?/validation",
      name: "validate-groupement",
      component: ValidateGroupementV2,
    },
    {
      path: "/operations/:operationId/membres/:membreId/steps/groupements/:groupementId?/link",
      name: "groupements-link",
      component: GroupementsLinkV2,
    },
    {
      path: "/operations/:operationId/membres/:membreId/steps/groupements/:groupementId?/linkMass",
      name: "groupements-link-mass",
      component: GroupementsLinkMass,
    },
    {
      path: "/operations/:operationId/membres/:membreId/steps/interlocuteurs",
      name: "interlocuteurs",
      component: InterlocuteursV2,
    },
    {
      path: "/operations/:operationId/membres/:membreId/steps/interlocuteurs/validation",
      name: "validate-interlocuteurs",
      component: ValidateInterlocuteursV2,
    },
    {
      path: "/operations/:operationId/membres/:membreId/steps/interlocuteurs/:interlocuteurId?/:action?",
      name: "interlocuteurs-detail",
      component: InterlocuteursDetailV2,
    },
    {
      path: "/operations/:operationId/membres/:membreId/steps/documents/:action",
      name: "documents",
      component: DocumentsV2,
    },
    {
      path: "/operations/:operationId/emails-tracker",
      name: "emails-tracker",
      component: EmailsTrackerV2,
    },
    {
      path: "/operations/:operationId/users",
      name: "users-manager",
      component: UsersManagerV2,
    },
    {
      path: "/operations/:operationId/members",
      name: "members-manager",
      component: MembersManagerV2,
    },
    {
      path: "/operations/:operationId/users/:userId?/:action?",
      name: "users-detail",
      component: UsersDetailV2,
    },
    {
      path: "/operations/:operationId/members/:memberId?/:action?",
      name: "members-detail",
      component: MembersDetailV2,
    },
    {
      path: "/operations/:operationId/documents/:documentId?/:action?",
      name: "documents-detail",
      component: DocumentsManagerDetailV2,
    },
    {
      path: "/operations/:operationId/documents",
      name: "documents-manager",
      component: DocumentsManagerV2,
    },
    {
      path: "/help/contact-us",
      name: "contact-us",
      component: ContactUsV2,
    },
    {
      path: "/" + Contact.name,
      name: Contact.name,
      component: Contact,
    },

    // {
    //   path: "/" + Users.name,
    //   name: Users.name,
    //   component: Users
    // },
    {
      path: "/" + Membres.name,
      name: Membres.name,
      component: Membres,
    },
    {
      path: "/" + Mails.name,
      name: Mails.name,
      component: Mails,
    },
    {
      path: "/" + SiteLink.name + "/:name",
      name: SiteLink.name,
      component: SiteLink,
    },
    {
      path: "/" + UserLinkMembre.name + "/:id",
      name: UserLinkMembre.name,
      component: UserLinkMembre,
    },
    {
      path: "/superadmin/" + UserAdminLinkOperation.name + "/:id",
      name: UserAdminLinkOperation.name,
      component: UserAdminLinkOperation,
    },
    {
      path: "/" + Groupements.name,
      name: Groupements.name,
      component: Groupements,
    },
    {
      path: "/" + Groupement.name + "/:id",
      name: Groupement.name,
      component: Groupement,
    },
    {
      path: "/" + GroupementLink.name + "/:id",
      name: GroupementLink.name,
      component: GroupementLink,
    },

    {
      path: "/" + Sites.name,
      name: Sites.name,
      component: Sites,
    },

    {
      path: "/" + Site.name + "/:id",
      name: Site.name,
      component: Site,
    },
    {
      // OK
      path: "/" + ValidationSite.name + "/:id",
      name: ValidationSite.name,
      component: ValidationSite,
    },
    {
      // OK
      path: "/" + ValidationGroupement.name + "/:id",
      name: ValidationGroupement.name,
      component: ValidationGroupement,
    },
    {
      path: "/" + ValidationInterlocuteurs.name,
      name: ValidationInterlocuteurs.name,
      component: ValidationInterlocuteurs,
    },
    {
      path: "/" + Validation.name,
      name: Validation.name,
      component: Validation,
    },
    {
      path: "/" + ValidationServices.name,
      name: ValidationServices.name,
      component: ValidationServices,
    },

    {
      path: "/" + Interlocuteurs.name,
      name: Interlocuteurs.name,
      component: Interlocuteurs,
    },
    {
      path: "/" + Interlocuteur.name + "/:id",
      name: Interlocuteur.name,
      component: Interlocuteur,
    },

    {
      path: "/" + Services.name,
      name: Services.name,
      component: Services,
    },
    {
      path: "/" + Service.name + "/:id",
      name: Service.name,
      component: Service,
    },

    {
      path: "/" + Factures.name,
      name: Factures.name,
      component: Factures,
    },
    {
      path: "/" + User.name,
      name: User.name,
      component: User,
    },

    {
      path: "/" + PiecesAdmin.name,
      name: PiecesAdmin.name,
      component: PiecesAdmin,
    },
    {
      path: "/" + PiecesClient.name,
      name: PiecesClient.name,
      component: PiecesClient,
    },

    {
      path: "/" + Tutorials.name,
      name: Tutorials.name,
      component: Tutorials,
    },
  ],
})

router.beforeEach((to, from, next) => {
  if (from.name !== to.name) {
    console.log(
      `%c Navigate from [${from.name}] to [${to.name}]`,
      "background:green ; padding: 5px 7px 4px 0px; border-radius: 3px;  color: #FFFFFF"
    )
    next()
  }
})

export default router
