export const Ftas = {
  HTA_CU_POINTE_FIXE: {
    name: "HTA_CU_POINTE_FIXE",
    puissances: ["PTE/PM", "HPH", "HCH", "HPE", "HCE"],
  },
  HTA_LU_POINTE_FIXE: {
    name: "HTA_LU_POINTE_FIXE",
    puissances: ["PTE/PM", "HPH", "HCH", "HPE", "HCE"],
  },
  HTA_CU_POINTE_MOBILE: {
    name: "HTA_CU_POINTE_MOBILE",
    puissances: ["PTE/PM", "HPH", "HCH", "HPE", "HCE"],
  },
  HTA_LU_POINTE_MOBILE: {
    name: "HTA_LU_POINTE_MOBILE",
    puissances: ["PTE/PM", "HPH", "HCH", "HPE", "HCE"],
  },
  BTSUP_CU: {
    name: "BTSUP_CU",
    puissances: ["HPH", "HCH", "HPE", "HCE"],
  },
  BTSUP_LU: {
    name: "BTSUP_LU",
    puissances: ["PTE/PM", "HPH", "HCH", "HPE", "HCE"],
  },
  BTINF_CU_BASE: {
    name: "BTINF_CU_BASE",
    puissances: ["BASE"],
  },
  BTINF_LU_BASE: {
    name: "BTINF_LU_BASE",
    puissances: ["BASE"],
  },
  BTINF_MU_HP_HC: {
    name: "BTINF_MU_HP_HC",
    puissances: ["HP", "HC"],
  },
  BTINF_CU_4: {
    name: "BTINF_CU_4",
    puissances: ["HPH", "HCH", "HPE", "HCE"],
  },
  BTINF_MU_4: {
    name: "BTINF_MU_4",
    puissances: ["HPH", "HCH", "HPE", "HCE"],
  },
  A_PRECISER:{
    name: "",
    puissances: [],
  }
}

export const Segments = {
  C_0: {
    name: "C0",
    fta: [
      Ftas.HTA_CU_POINTE_FIXE,
      Ftas.HTA_LU_POINTE_FIXE,
      Ftas.HTA_CU_POINTE_MOBILE,
      Ftas.HTA_LU_POINTE_MOBILE,
      Ftas.BTINF_CU_BASE,
      Ftas.BTINF_CU_4,
      Ftas.BTINF_LU_BASE,
      Ftas.BTINF_MU_HP_HC,
      Ftas.BTSUP_CU,
      Ftas.BTSUP_LU,
      Ftas.BTINF_MU_4,
    ],
  },
  C_2: {
    name: "C2",
    fta: [Ftas.HTA_CU_POINTE_FIXE, Ftas.HTA_LU_POINTE_FIXE, Ftas.HTA_CU_POINTE_MOBILE, Ftas.HTA_LU_POINTE_MOBILE],
  },
  C_3: {
    name: "C3",
    fta: [Ftas.HTA_CU_POINTE_FIXE, Ftas.HTA_LU_POINTE_FIXE, Ftas.HTA_CU_POINTE_MOBILE, Ftas.HTA_LU_POINTE_MOBILE],
  },
  C_4: {
    name: "C4",
    fta: [Ftas.BTSUP_CU, Ftas.BTSUP_LU],
  },
  C_5: {
    name: "C5",
    fta: [Ftas.BTINF_CU_BASE, Ftas.BTINF_CU_4, Ftas.BTINF_LU_BASE, Ftas.BTINF_MU_HP_HC, Ftas.BTINF_MU_4],
  },
}

export const PS = ["BASE", "HP", "HC", "PTE/PM", "HPH", "HCH", "HPE", "HCE"]
