<template>
  <main class="container" v-if="site">
    <section v-if="site.state === 'TO_CERTIFY'" class="card">
      <article class="card-content">
        <div class="card-title">Validation du site "{{ site.nom }}"</div>
        <blockquote>
          Êtes-vous sur de vouloir certifier les informations de ce site ?
          <br />
          Vous ne pourrez plus modifier les caractéristiques de ce site via cette application après validation
        </blockquote>
      </article>
      <center class="card-action">
        <button type="button" class="waves-effect waves-light btn light-green" @click="validate">
          Oui, je certifie ce site
        </button>
        <router-link type="button" class="waves-effect waves-light btn blue darken-2" :to="{ name: 'sites' }">
          Non, je ne veux pas encore certifier ce site
        </router-link>
      </center>
    </section>
    <section v-else class="card-panel white-text deep-orange">Ce site ne peux pas être certifié.</section>
  </main>
</template>

<script>
import Mixin from "../Mixin"
import { mapState, mapMutations } from "vuex"
import Axios from "@/util/Axios"
import Materialize from "materialize-css"

export default {
  name: "validation-site",
  mixins: [Mixin],
  data() {
    return {
      flow: "PERIMETRE",
      site: null,
    }
  },
  computed: {
    ...mapState(["membre", "user", "operation"]),
  },
  methods: {
    validate() {
      Axios("patch", "api/sites/" + this.$route.params.id, {
        certified: true,
      }).then((response) => {
        Materialize.toast("Site Validé !", 4000, "white-text lime")
        this.$router.push({
          name: "sites",
        })
      })
    },
    ...mapMutations(["setBreadcrumbAdd"]),
  },
  created() {
    Axios("get", "api/sites/" + this.$route.params.id).then((response) => {
      this.site = response.data
      this.setBreadcrumbAdd({
        name: "Validation de : " + this.site.nom,
        action: () => {},
      })
    })
  },
}
</script>

<style lang="scss" scoped></style>
