<template>
  <main>
    <section class="row downloadDocumentGrid" id="downloadDocumentGrid">
      <mcma-card title="Liste des pièces à télécharger et <b> mettre à signature </b>" icon="administration">
        <template #describe>
          <p class="html-render-content" v-html="discribInflow"></p>
        </template>
        <blockquote>
          <div v-if="progression > 0">
            Votre progression actuelle pour cette étape est de
            {{ $n(progression, "percent") }}.
          </div>
          <span v-if="progression !== 1">
            Pour finir cette étape, vous devez télécharger toutes les pièces présentent ci-dessous.
          </span>
          <span v-else>
            Cette étape est termCette étape est terminée. <br />
            Pour savoir ce qu'il vous reste à faire :
            <router-link :to="{ name: 'steps' }" class="waves-effect waves-light btn blue darken-2">
              rendez-vous sur la page d'accueil
            </router-link>
          </span>
        </blockquote>
      </mcma-card>
      <a-table
        v-if="!loading"
        :columns="getColumns"
        :dataSource="dataSource"
        :scroll="{ x: true }"
        :pagination="{
          defaultPageSize: 20,
          showSizeChanger: true,
          pageSizeOptions: ['5', '10', '20', '30', '50', '100'],
        }"
        bordered
      >
        <div
          slot="filterDropdown"
          slot-scope="{ setSelectedKeys, selectedKeys, confirm, clearFilters, column }"
          style="padding: 8px"
        >
          <a-input
            v-ant-ref="(c) => (searchInput[column.dataIndex] = c)"
            :placeholder="`Recherche par ${column.title.toLowerCase()}`"
            :value="selectedKeys[0]"
            @change="(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])"
            @pressEnter="() => handleSearch(column.dataIndex, selectedKeys, confirm)"
            style="width: 188px; margin-bottom: 8px; display: block"
          />
          <a-button
            type="primary"
            @click="() => handleSearch(column.dataIndex, selectedKeys, confirm)"
            icon="search"
            size="small"
            style="width: 90px; margin-right: 8px"
          >
          </a-button>
          <a-button
            @click="() => handleReset(column.dataIndex, clearFilters)"
            icon="sync"
            size="small"
            style="width: 90px"
          >
          </a-button>
        </div>
        <a-icon slot="filterIcon" slot-scope="filtered" type="search" :class="{ 'blue-color': filtered }" />
        <template slot="customRender" slot-scope="data">
          <template v-if="typeof data === 'string'">
            {{ data }}
          </template>
        </template>
        <div slot="actions" slot-scope="props">
          <div class="cell-actions">
            <center>
              <button
                class="btn-floating waves-effect waves-light mcmaGrey signDocumentDownload"
                v-tooltip.auto="'Télécharger la pièce originale'"
                @click="downloadPiece(props)"
              >
                <i class="material-icons">file_download</i>
              </button>
              <button
                class="btn-floating waves-effect waves-light mcmaGrey signDocumentDownload"
                v-tooltip.auto="'Voir votre version signé pour vérification'"
                @click="downloadPieceMembre(props)"
                v-if="isUploaded(props)"
              >
                <i class="material-icons">remove_red_eye</i>
              </button>
            </center>
          </div>
        </div>
      </a-table>
    </section>
    <!-- <center class="switch col s12 checkBoxLine">
      <input type="checkbox" id="checkbox" v-model="membreContraintes.valid" @click="updateDownloadCertification()" :disabled="membreContraintes.valid">
      <label for="checkbox" class="checkBoxLabel">Je déclare avoir pris connaissance de la nécessité de faire signer les pièces téléchargées au plus tôt pour adhérer au groupement.</label>
    </center> -->
    <a class="ninja" ref="downloadLink" />
  </main>
</template>

<script>
import Mixin from "../../Mixin"
import Axios from "@/util/Axios"
import { mapState, mapMutations } from "vuex"
import Constants from "@/util/Constants"
import Materialize from "materialize-css"
import McmaCard from "@/components/mcma/common/McmaCard.vue"

export default {
  name: "pieces-download",
  components: {
    McmaCard,
  },
  mixins: [Mixin],
  data() {
    return {
      flow: "DOWNLOAD",
      pieces: [],
      piecesDownloaded: [],
      piecesUploaded: [],
      uploads: null,
      filters: {},
      pagination: {
        sortBy: "name",
      },
      columns: [
        {
          title: "Nom de la piece",
          dataIndex: "name",
          key: "name",
          // width: 1,
          // fixed: "left",
          scopedSlots: {
            filterDropdown: "filterDropdown",
            filterIcon: "filterIcon",
            customRender: "customRender",
          },
          onFilter: (value, record) =>
            record.name ? record.name.toString().toLowerCase().includes(value.toLowerCase()) : null,
          onFilterDropdownVisibleChange: (visible) => {
            if (visible) {
              setTimeout(() => {
                this.searchInput["name"].focus()
              }, 0)
            }
          },
        },
        {
          title: "Type de piece",
          dataIndex: "type",
          key: "type",
          // width: 100,
          // fixed: "left",
          scopedSlots: {
            filterDropdown: "filterDropdown",
            filterIcon: "filterIcon",
            customRender: "customRender",
          },
          onFilter: (value, record) =>
            record.name ? record.name.toString().toLowerCase().includes(value.toLowerCase()) : null,
          onFilterDropdownVisibleChange: (visible) => {
            if (visible) {
              setTimeout(() => {
                this.searchInput["name"].focus()
              }, 0)
            }
          },
        },
        {
          title: "Déjà téléchargée",
          dataIndex: "downloaded",
          key: "downloaded",
          scopedSlots: {
            filterDropdown: "filterDropdown",
            filterIcon: "filterIcon",
            customRender: "customRender",
          },
          width: 1,
          filters: [
            {
              text: Constants.FILESTATUS.DOWNLOADED.text,
              value: Constants.FILESTATUS.DOWNLOADED.text,
            },
            {
              text: Constants.FILESTATUS.NOT_DOWNLOADED.text,
              value: Constants.FILESTATUS.NOT_DOWNLOADED.text,
            },
          ],
          onFilter: (value, record) => record.status === value,
          // scopedSlots: {customRender: "status"}
        },
        {
          title: "Déjà déposée",
          dataIndex: "uploaded",
          filters: [
            {
              text: Constants.FILESTATUS.UPLOADED.text,
              value: Constants.FILESTATUS.UPLOADED.value,
            },
            {
              text: Constants.FILESTATUS.NOT_UPLOADED.text,
              value: Constants.FILESTATUS.NOT_UPLOADED.value,
            },
          ],
          onFilter: (value, record) => record.status === value,
          width: 1,
          scopedSlots: { customRender: "status" },
        },
        {
          title: "Actions",
          key: "actions",
          fixed: "right",
          width: 150,
          scopedSlots: { customRender: "actions" },
        },
      ],
      statusValues: {
        INITIALISED: {
          name: Constants.STATUS.INITIALISED.text,
          icon: require(`@/assets/images/initialised.svg`),
        },
        IN_PROGRESS: {
          name: Constants.STATUS.IN_PROGRESS.text,
          icon: require(`@/assets/images/in_progress.svg`),
        },
        EXPORTED: {
          name: Constants.STATUS.EXPORTED.text,
          icon: require(`@/assets/images/exported.svg`),
        },
        APPROVED: {
          name: Constants.STATUS.APPROVED.text,
          icon: require(`@/assets/images/approved.svg`),
        },
      },
      loading: true,
      search: "",
      membreContraintes: {},
      membreContraintesFactures: {},
      dataSource: [],
    }
  },
  computed: {
    ...mapState(["membre", "operation", "user"]),
    progression() {
      if (this.pieces && this.piecesDownloaded) {
        return Math.trunc(this.piecesDownloaded.length / this.pieces.length)
      }
      /*
      if (this.sites) {
        let progress = 0;
        progress += 1 * (this.membre.progressions.CERTIFIED || 0);
        progress += 0.5 * (this.membre.progressions.TO_CERTIFY || 0);
        return progress / this.sites.length;
      }
      */
      return 0
    },
    getColumns() {
      return this.columns
    },
    getLocale() {
      return {
        emptyText:
          this.dataSource.length === 0 ? <groupement-empty-without-filter /> : <groupement-empty-with-filter />,
      }
    },
    getIcon() {
      return (iconName) => {
        return iconName ? require(`@/assets/images/${iconName}.svg`) : null
      }
    },
    rowSelection() {
      const { selectedRowKeys } = this
      return {
        selectedRowKeys,
        onSelectAll: this.onSelectAll,
        onChange: this.onSelectChange,
        hideDefaultSelections: true,
        selections: [],
        onSelection: this.onSelection,
      }
    },
  },
  methods: {
    ...mapMutations(["setBreadcrumbAdd"]),
    isUploaded(item) {
      return item.uploaded === Constants.FILESTATUS.UPLOADED.text
    },
    updateSelectRowsOnFilterChange() {
      const dataSource = this.getDataSourceFiltered()
      this.selectedRowKeys = this.selectedRowKeys.filter((key) => {
        return dataSource.find((data) => {
          return key === data.key
        })
      })
    },
    onSelectChange(selectedRowKeys) {
      this.selectedRowKeys = selectedRowKeys
      const sitesList = this.dataSource.filter((data) => selectedRowKeys.includes(data.key)).map((data) => data.key)
      this.$emit("selectedSitesChanged", sitesList)
    },
    onChange(pagination, filters, sorter) {
      this.filters = filters
      this.updateSelectRowsOnFilterChange()
    },
    getDataSourceFiltered() {
      const toCheck = ["name", "type", "downloaded", "uploaded"]
      let dataSourceFiltered = [...this.dataSource].sort((a, b) => b.id - a.id)
      toCheck.forEach((key) => {
        if (this.filters[key] && this.filters[key].length > 0) {
          dataSourceFiltered = dataSourceFiltered.filter((data) => this.filters[key].includes(data[key]))
        }
      })
      for (let key in this.searchInput) {
        if (this.filters[key] && this.filters[key].length > 0) {
          dataSourceFiltered = dataSourceFiltered.filter((data) =>
            data[key] ? data[key].toString().toLowerCase().includes(this.searchText[key].toLowerCase()) : null
          )
        }
      }
      return dataSourceFiltered
    },
    checkPieceDownloadedUploaded() {
      console.group("> pieceName@FlowPieceDownload")
      console.log("this.piecesDownloaded : %O", this.piecesDownloaded)
      console.log("this.piecesUploaded : %O", this.piecesUploaded)
      console.log("this.pieces %O", this.pieces)
      if (this.pieces) {
        let piecesDownloaded = this.piecesDownloaded
        let piecesUploaded = this.piecesUploaded
        let pieces = this.pieces
        // console.log('piecesDownloaded : %O / pieces %O',  piecesDownloaded, pieces)
        pieces.forEach(function (piece, index) {
          // console.log('> piecesDanwloaded : %O',  piecesDownloaded)
          piece.downloaded = Constants.FILESTATUS.NOT_DOWNLOADED.text
          piece.uploaded = Constants.FILESTATUS.NOT_UPLOADED.text

          if (piecesDownloaded) {
            piecesDownloaded.forEach(function (pieceDownloaded, index) {
              // console.log('> piece %O',  piece)
              // console.log('pieceDanwloaded : %O / piece %O',  pieceDownloaded, piece)
              if (pieceDownloaded.id === piece.id) {
                // piece.name = piece.name + ' (piece téléchargée)'
                piece.downloaded = Constants.FILESTATUS.DOWNLOADED.text
              }
            })
          }

          if (piecesUploaded) {
            piecesUploaded.forEach(function (pieceUploaded, index) {
              // console.log('> piece %O',  piece)
              // console.log('pieceDanwloaded : %O / piece %O',  pieceDownloaded, piece)
              if (pieceUploaded.includes(piece.name)) {
                // piece.name = piece.name + ' (piece téléchargée)'
                piece.uploaded = Constants.FILESTATUS.UPLOADED.text
              }
            })
          }
        })
        this.dataSource = this.pieces
      }
      console.groupEnd()
    },
    initialize() {
      console.group("intialize@PiecesClient")
      const indexSites = this.operation.flows.map((e) => e.name).indexOf("piecetelechargement")
      console.log("indexOf Flow sites :  %O ", indexSites)
      console.log("this.operation.flows :  %O", this.operation.flows[indexSites])
      this.discribInflow = this.operation.flows[indexSites].describeInFlow
      this.pieces = []
      this.piecesDownloaded = []
      this.uploads = null
      this.loading = true

      const requests = []

      // Chargement de la liste pieces
      requests.push(
        Axios("get", "api/pieces/search/findByOperationIdEquals?size=2000&operationId=" + this.operation.id).then(
          (response) => {
            console.log("this.pieces : %O", response.data._embedded.pieces)
            this.pieces = response.data._embedded.pieces
          }
        )
      )

      // Chargement de la liste des piecesMembre déjà download
      requests.push(
        Axios("get", "file/pieceMembre/downloaded/?membreId=" + this.membre.id).then((response) => {
          console.log("pieceMembre/downloaded : %O", response.data)
          this.piecesDownloaded = response.data
          console.log("this.piecesDownloaded : %O", this.piecesDownloaded)
        })
      )

      // Chargement de la liste des piecesMembre déjà upload
      requests.push(
        Axios("get", "file/pieceMembre/uploaded/?membreId=" + this.membre.id).then((response) => {
          console.log("pieceMembre/downloaded : %O", response.data)
          this.piecesUploaded = response.data
          console.log("this.piecesDownloaded : %O", this.piecesUploaded)
        })
      )

      Promise.all(requests).then((responses) => {
        console.log("Chargement Ok")
        this.checkPieceDownloadedUploaded()
        this.loading = false
      })

      console.groupEnd()
    },
    updateDownloadAlreadyDown() {
      if (
        confirm(
          "Une fois cette option cochée vous ne pourrez plus la décocher. Vous pourrez toujours cependant télécharger les fichiers si besoin.\n Validez-vous cette option?"
        )
      ) {
        Axios("patch", "/api/membreContraintes/" + this.membreContraintesFactures.id, {
          valid: true,
        })
          .then((response) => {
            Axios("get", "/membre/setPiecesDownloaded?idMembre=" + this.membre.id)
              .then((response) => {
                Materialize.toast("L'option a correctement été sauvegardé", 4000, "mcmaDarkBlueGrey")
                this.goToNextStep()
              })
              .catch((error) => {
                console.log(error)
                Materialize.toast("Erreur serveur", 4000, "mcmaDarkOrange")
              })
          })
          .catch((error) => {
            console.log(error)
            Materialize.toast("Erreur serveur", 4000, "mcmaDarkOrange")
          })
      } else {
        this.membreContraintesFactures.valid = false
      }
    },
    updateDownloadCertification() {
      if (
        confirm(
          "Une fois cette option cochée vous ne pourrez plus la décocher. Vous pourrez toujours cependant télécharger les fichiers si besoin.\n Validez-vous cette option?"
        )
      ) {
        Axios("patch", "/api/membreContraintes/" + this.membreContraintes.id, {
          valid: true,
        })
          .then((response) => {
            Materialize.toast("L'option a correctement été sauvegardé", 4000, "mcmaDarkBlueGrey")
            this.goToNextStep()
          })
          .catch((error) => {
            console.log(error)
            Materialize.toast("Erreur serveur", 4000, "mcmaDarkOrange")
          })
      } else {
        this.membreContraintes.valid = false
      }
    },
    downloadPiece(piece) {
      console.log("piece : %O", piece)
      console.log("piece.extension : %O", piece.extension)
      console.log("this.operation : %O", this.operation)
      Axios(
        "get",
        "file/piece/download?pieceId=" + piece.id + "&operationId=" + this.operation.id + "&membreId=" + this.membre.id,
        null,
        {
          responseType: "blob",
        }
      )
        .then((response) => {
          console.log("> Index Pieces Download : %O", this.piecesDownloaded.indexOf(piece))
          if (this.piecesDownloaded.indexOf(piece) === -1) {
            this.piecesDownloaded.push(piece)
            // piece.name = piece.name + ' (piece téléchargée)'
            piece.downloaded = Constants.FILESTATUS.DOWNLOADED.text
          }
          const link = this.$refs.downloadLink
          link.href = window.URL.createObjectURL(new Blob([response.data]))
          var fileName = piece.name + "." + piece.extension
          console.log(fileName)
          link.setAttribute("download", fileName)
          link.click()
          // this.goToNextStep();
        })
        .catch((error) => {
          console.error(error)
          Materialize.toast("Erreur lors du téléchargement", 4000, "mcmaGrey white-text")
        })
    },
    downloadPieceMembre(piece) {
      console.group("downloadPieceMembre@FlowPieceDownload")
      console.log("piece : %O", piece)
      console.log("piece.extension : %O", piece.extension)
      console.log("this.operation : %O", this.operation)
      // let membre = "&membreId=" + this.membre.id;
      // console.log(piece);
      /*
      Axios("get", "file/piece/download?pieceId=" + piece.id + membre, null, {
        responseType: "blob"
      })
      */
      Axios("get", "file/pieceMembre/download?pieceId=" + piece.id + "&membreId=" + this.membre.id, null, {
        responseType: "blob",
      })
        .then((response) => {
          console.log(response)
          if (response.status === 200) {
            const link = this.$refs.downloadLink
            link.href = window.URL.createObjectURL(new Blob([response.data]))
            Axios("get", "file/pieceMembre/name?pieceId=" + piece.id + "&membreId=" + this.membre.id)
              .then((response) => {
                link.setAttribute("download", response.data)
                link.click()
              })
              .catch((error) => {
                console.error(error)
                Materialize.toast(
                  "Erreur lors du téléchargement : désolé nous n'avons pas pu réccupérer le nom du fichier",
                  4000,
                  "mcmaGrey white-text"
                )
              })
          } else {
            Materialize.toast(
              "Erreur lors du téléchargement : désolé la pièce n'a pas été trouvée sur le serveur...",
              4000,
              "mcmaGrey white-text"
            )
          }
        })
        .catch((error) => {
          console.error(error)
          Materialize.toast("Désolé le téléchargement n'est pas disponible !", 4000, "mcmaGrey white-text")
        })
      console.groupEnd()
    },
    changeSort(column) {
      if (this.pagination.sortBy === column) {
        this.pagination.descending = !this.pagination.descending
      } else {
        this.pagination.sortBy = column
        this.pagination.descending = false
      }
    },
    goToNextStep() {
      let nbPieceMembres = 0
      let nbPieces = 0
      Axios("get", "api/pieceMembres/search/findByMembreIdEquals?membreId=" + this.membre.id).then((response) => {
        if (response.data._embedded.pieceMembres) {
          nbPieceMembres = response.data._embedded.pieceMembres.length
        }
      })
      Axios("get", "api/pieces").then((response) => {
        if (response.data._embedded.pieces) {
          nbPieces = response.data._embedded.pieces.length
        }
      })
      setTimeout(function () {
        console.log("nbPiece ", nbPieces)
        console.log("nbPieceMembres ", nbPieceMembres)
        console.log("done")
        if (nbPieceMembres === nbPieces) {
          console.log("it's same value")
        } else {
          console.log("it's not same value")
        }
        if (nbPieceMembres === nbPieces) {
          if (
            confirm(
              'Vous avez validé toutes les étapes de téléchargement des fichiers. Voulez-vous être dirigé automatiquement vers l\'étape n°2 "Mes sites"?'
            )
          ) {
            this.$router.push({ name: "sites" })
          }
        }
      }, 2000)
    },
  },
  created() {
    this.setBreadcrumbAdd({
      name: "Téléchargement pièces",
      action: () => {
        this.$router.push({
          name: "piecetelechargement",
        })
      },
    })
    this.pagination.rowsPerPage = this.user.rowsInTableDefault
    this.initialize()
  },
}
</script>

<style lang="scss" scoped>
.checkBoxLine {
  margin-top: 50px;
  margin-bottom: 50px;
  background-color: white;
  margin-left: 20% !important;
  margin-right: 20% !important;
  width: 50% !important;
  border-style: solid;
  border-width: 5px;
  padding: 5px;
}

.checkBoxLabel {
  font-size: 18px;
}
</style>
