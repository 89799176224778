<template>
  <main class="container">
    <form class="card" @submit.prevent="submit" id="validServiceForm">
      <section class="card-content">
        <span class="card-title">
          <template v-if="service.id"> Modification </template>
          <template v-else> Ajout </template>
          d'un service
        </span>

        <article class="input-field">
          <input id="url" type="text" required v-model="url" />
          <label class="active" for="url">
            <font-awesome-icon icon="asterisk" class="red-text" />
            Url
          </label>
        </article>

        <article class="input-field">
          <input id="identifiant" type="text" required v-model="identifiant" />
          <label class="active" for="identifiant">
            <font-awesome-icon icon="asterisk" class="red-text" />
            Identifiant
          </label>
        </article>

        <article class="input-field">
          <input id="password" type="text" required v-model="password" />
          <label class="active" for="password">
            <font-awesome-icon icon="asterisk" class="red-text" />
            Mot de passe
          </label>
        </article>
      </section>
      <section class="card-action center-align">
        <button
          type="submit"
          class="waves-effect waves-light btn light-green tutoDoneRetourToRouteServices"
          id="validServiceValid"
        >
          <i class="material-icons">save</i>
          Valider
        </button>

        <button type="button" class="waves-effect waves-light btn blue darken-2" @click="initialize">
          <i class="material-icons">remove</i>
          Réinitialiser
        </button>
      </section>
    </form>
  </main>
</template>

<script>
import Mixin from "../Mixin"
import Axios from "@/util/Axios"
import { mapState, mapMutations } from "vuex"
import Materialize from "materialize-css"

export default {
  name: "service",
  mixins: [Mixin],
  data() {
    return {
      flow: "SERVICES",
      service: {},

      url: null,
      identifiant: null,
      password: null,
    }
  },
  computed: {
    ...mapState(["user", "membre", "operation"]),
  },
  methods: {
    ...mapMutations(["setBreadcrumbAdd"]),
    initialize() {
      /*
      const breadCrumb = [
        {
          name: this.membre.nom || this.user.email,
          action: () => {
            this.$router.push({
              name: "steps",
              params: {
                operationId: this.operation.id,
                membreId: this.membre.id,
              }
            })
          }
        }, {
          name: 'Services',
          action: () => {
            this.$router.push({
              name: 'services'
            })
          }
        }
      ]
      */

      if (this.$route.params.id === "new") {
        this.setBreadcrumbAdd({
          name: "Ajout d'un service",
          action: () => {},
        })
        this.service = {}
        this.url = null
        this.identifiant = null
        this.password = null
      } else {
        Axios("get", "api/services/" + this.$route.params.id).then((response) => {
          this.service = response.data
          this.setBreadcrumbAdd({
            name: "Edition d'un service",
            action: () => {},
          })
          this.url = this.service.url
          this.identifiant = this.service.identifiant
          this.password = this.service.password.trim()
        })
      }
    },
    submit() {
      const service = {
        membre: process.env.VUE_APP_API_URL + "/api/membres/" + this.membre.id,
        url: this.url,
        identifiant: this.identifiant,
        password: this.password.trim(),
      }
      if (this.service.id) {
        Axios("patch", "api/services/" + this.service.id, service)
          .then((response) => {
            Materialize.toast("Le service a bien été sauvegardées", 4000, "lime")
            this.$router.push({
              name: "services",
            })
          })
          .catch((error) => {
            console.error(error)
            Materialize.toast("Erreur serveur", 4000, "deep-orange")
          })
      } else {
        Axios("post", "api/services/", service)
          .then((response) => {
            Materialize.toast("Le nouveau service a bien été ajouté", 4000, "lime")
            this.$router.push({
              name: "services",
            })
          })
          .catch((error) => {
            console.error(error)
            Materialize.toast("Erreur serveur", 4000, "deep-orange")
          })
      }
    },
  },
  created() {
    this.initialize()
  },
}
</script>

<style lang="scss" scoped></style>
