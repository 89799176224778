<template>
  <main class="container">
    <user-form :user="user" :admin="false" :superAdmin="false" @submit="submit" />
  </main>
</template>

<script>
import UserForm from "@/components/common/UserForm"
import { mapMutations, mapState, mapActions } from "vuex"
import Materialize from "materialize-css"
import Axios from "@/util/Axios"

export default {
  name: "user",
  components: {
    UserForm,
  },
  computed: {
    ...mapState(["user"]),
  },
  methods: {
    ...mapMutations(["setBreadcrumbAdd"]),
    ...mapActions(["refreshStore"]),
    submit(user) {
      Axios("patch", "api/users/" + this.user.id, {
        ...user,
        welcomed: null,
      })
        .then((response) => {
          Materialize.toast("Utilisateur modifié", 4000, "lime white-text")
          this.refreshStore()
        })
        .catch((error) => {
          console.error(error)
          Materialize.toast("Erreur lors de la modification", 4000, "deep-orange white-text")
        })
    },
  },
}
</script>

<style lang="scss" scoped></style>
