<template>
  <a-form :form="form">
    <a-row type="flex" :gutter="12">
      <mcma-field
        :colSpan="12"
        type="input"
        :form="form"
        :wasSubmit="wasSubmit"
        fieldName="subject"
        label="Objet"
        placeholder="Objet"
        :required="true"
        :requiredMessage="`Merci de renseigner l'objet`"
      />
      <mcma-field
        :colSpan="12"
        type="input"
        :form="form"
        :wasSubmit="wasSubmit"
        fieldName="email"
        label="Adresse E-mail de réponse"
        placeholder="john@gmail.com"
        :defaultValue="currentUser.email"
        :required="true"
        :requiredMessage="`Merci de renseigner l'email`"
        rule="isEmail"
      />
      <mcma-field
        :colSpan="24"
        type="textarea"
        :form="form"
        :wasSubmit="wasSubmit"
        fieldName="message"
        label="Message"
        :height="180"
        :required="true"
      />
    </a-row>
    <a-row type="flex" :gutter="12">
      <a-col :span="24">
        <div class="button-group-inline mt24">
          <mcma-button
            name="Envoyer"
            class="ml12"
            color="success"
            leftIcon="paper-plane"
            :isFaIcon="true"
            @click="submit"
          />
        </div>
      </a-col>
    </a-row>
  </a-form>
</template>

<script>
import McmaButton from "@/components/mcma/common/McmaButton"
import McmaField from "@/components/mcma/common/McmaField"
import Axios from "@/util/Axios"
import { getClientTechInfos } from "@/util/Helpers/Navigator"

export default {
  name: "ContactUsForm",
  props: {
    currentUser: Object,
    membre: Object,
    operation: Object,
  },
  components: {
    McmaField,
    McmaButton,
  },
  data() {
    return {
      form: this.$form.createForm(this),
      wasSubmit: false,
    }
  },
  methods: {
    initialize() {
      this.form.getFieldDecorator("email")
      this.form.getFieldDecorator("subject")
      this.form.getFieldDecorator("message")
      this.form.setFieldsValue({
        email: this.currentUser && this.currentUser.email ? this.currentUser.email : null,
        subject: null,
        message: null,
      })
    },
    submit() {
      console.log("> Contact submit : %O", this)
      this.form.validateFields((errors, fieldsValue) => {
        this.wasSubmit = true
        if (errors) {
          this.$notification.warning({
            message: "Formulaire invalide",
            description: `Veuillez-vous assurez de remplir tous les champs requis.`,
          })
        } else {
          console.log("> Contact fieldsValue : %O", fieldsValue)

          Axios("post", "/mail/contact?idOperation=" + this.operation.id, {
            membreId: this.membre ? this.membre.id : null,
            mail: fieldsValue.email,
            subject: fieldsValue.subject,
            message: fieldsValue.message,
            clientTechInfos: getClientTechInfos(),
          })
            .then(() => {
              this.$notification.success({
                message: `Message envoyé !`,
                description: `Le message à bien été envoyé.\nUn exemplaire vous a été également communiqué.`,
              })
              this.initialize()
            })
            .catch((error) => {
              console.error(error)
              this.$notification.error({
                message: `Erreur d'envoie`,
                description: `Une erreur esr survenu lors de l'envoi du message.`,
              })
            })
        }
      })
    },
  },
  created() {
    this.initialize()
  },
}
</script>

<style lang="scss">
@import "./src/assets/scss/variables";
@import "./src/assets/scss/functions";

.change-password-block {
  display: flex;
  flex-flow: column nowrap;
  border-radius: 4px;
  background: transparent;
  padding: 0;
  max-height: 40px;
  overflow: hidden;
  transition: all 0.3s ease;
  &.show-change-password {
    background: hsla(var(--tertiary-color-h), var(--tertiary-color-s), var(--tertiary-color-l), 0.05);
    max-height: 300px;
    padding: calc(var(--padding, $padding) * 2);
  }
  .change-password-header {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;
  }
  .change-password-body {
    display: flex;
    flex-flow: row nowrap;
    margin-top: calc(var(--padding, $padding) * 2);
    .mcma-field {
      width: 50%;
      .ant-input {
        background-color: transparent;
      }
      &:first-child {
        margin-right: 10px;
      }
      &:last-child {
        margin-left: 10px;
      }
    }
  }
}

.change-password-enter-active {
  transition: all 0.5s ease;
}

.change-password-leave-active {
  transition: all 0s ease;
}

.change-password-leave-to,
.change-password-enter {
  color: pink;
}
</style>
