const pluralizeImport = require("pluralize-fr")

/**
 * accorization
 * * get number items + word (plurialize if needed)
 * ? Example :
 * * - 1 mail send
 * * - 2 mails sends
 */
export function accorization(items, singularWord, showNumber, messageOnEmpty) {
  let message = ""
  const isNumber = typeof items === "number"
  const number = (isNumber ? items : items && items.length) || 0
  if (showNumber) {
    message += number + " "
  }
  if (number === 0 && messageOnEmpty) {
    message = messageOnEmpty
  } else {
    message += number >= 2 ? pluralizeImport(singularWord) : singularWord
  }

  return message
}

export function plurialize(singularWord) {
  return pluralizeImport(singularWord)
}

export function getTime(date, timeString, getIsFinish) {
  const dateEntered = new Date(date)
  const now = new Date()
  const difference = dateEntered.getTime() - now.getTime()
  const seconds = Math.floor(difference / 1000) % 60
  const minutes = Math.floor(difference / 1000 / 60) % 60
  const hours = Math.floor(difference / 1000 / 60 / 60) % 24
  const days = Math.floor(difference / 1000 / 60 / 60 / 24)

  let number =
    timeString === "seconds"
      ? seconds
      : timeString === "minutes"
      ? minutes
      : timeString === "hours"
      ? hours
      : timeString === "days"
      ? days
      : 0

  let isFinish = seconds <= 0 && minutes <= 0 && hours <= 0 && days <= 0
  number = isFinish ? Math.abs(number) : number
  if (timeString !== "days") {
    number = number >= 0 && number < 10 ? "0" + number : number
  }

  if (getIsFinish) {
    return isFinish
  }
  return number
}

export function getNumberFromString(string) {
  if (string && typeof string === "string") {
    string = string.replace(/^\s+/, "")
    string = string.replace(/[^0-9\s]/, "")
    string = string.replace(/\s\s+/, " ")
    return string
  }
  return string
}
export function getDecimalFromString(string) {
  if (!string) {
    return string
  }
  let regex = /^-?(0|[1-9][0-9]*)([,.][0-9]*)?$/
  if (!regex.test(string)) {
    let part = ""
    for (let i = 0; i < string.length; i++) {
      part += string.charAt(i)
      part = regex.test(part) ? part : part.substring(0, part.length - 1)
    }
    return part
  }
  return string
}

export function updateObjectProperties(original, dataToUpdate) {
  function loop(original, dataToUpdate) {
    for (let key in dataToUpdate) {
      const value = dataToUpdate[key]
      if (typeof value === "object") {
        loop(original[key], value)
      } else {
        original[key] = value
      }
    }
  }
  loop(original, dataToUpdate)
}

/**
 * ? This function exctract some props from existing Object.
 *
 * * Example :
 * * const object = {id: 1, name: 'toto', age: 42};
 * * const object2 = pick(object, ['id', 'age']);
 * * console.log(object2); //output : {id: 1, age: 42}
 */
export function pick(obj, props) {
  if (!obj || !props) return
  const picked = {}
  props.forEach((prop) => {
    picked[prop] = obj[prop]
  })
  return { ...picked }
}
