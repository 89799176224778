<template>
  <a-form :form="form">
    <mcma-card>
      <a-row type="flex" :gutter="12" class="mt-0 mb-0">
        <mcma-field
          :colSpan="12"
          type="radio"
          :form="form"
          :wasSubmit="wasSubmit"
          fieldName="typeSite"
          getFlow="name::sites"
          getPanel="name::Données techniques (électricité)"
          getField="type::typeSite"
          :defaultValue="site.typeSite"
          extraLabel="Sélectionner le type de site qui correspond"
        />
      </a-row>
      <a-row v-if="form.getFieldValue('typeSite') === 'ECLAIRAGE_PUBLIC'" type="flex" :gutter="12" class="mt-0 mb-0">
        <mcma-field
          :colSpan="12"
          type="switch"
          :form="form"
          :wasSubmit="wasSubmit"
          fieldName="extinctionNuit"
          getFlow="name::sites"
          getPanel="name::Données techniques (électricité)"
          getField="type::extinctionNuit"
          :defaultValue="site.extinctionNuit"
        />
      </a-row>

      <mcma-card v-if="showTaxeTCFE" title="Type de taxe TCFE" class="mcma-card-in-tab transparent-bg">
        <a-row type="flex" :gutter="12" class="mt-0 mb-0">
          <mcma-field
            :colSpan="12"
            type="radio"
            :form="form"
            :wasSubmit="wasSubmit"
            fieldName="typeTcfe"
            getFlow="name::sites"
            getPanel="name::Données techniques (électricité)"
            getField="type::typeTcfe"
            :defaultValue="site.typeTcfe"
          />
        </a-row>
      </mcma-card>

      <mcma-card v-if="showExoCSPE" title="Exonération CSPE" class="mcma-card-in-tab transparent-bg">
        <a-row type="flex" :gutter="12" class="mt-0 mb-0">
          <mcma-field
            :colSpan="6"
            type="select"
            :form="form"
            :wasSubmit="wasSubmit"
            fieldName="exonerationCspe"
            getFlow="name::sites"
            getPanel="name::Données techniques (électricité)"
            getField="type::exonerationCspe"
            :defaultValue="site.exonerationCspe || 0"
          />
        </a-row>
      </mcma-card>

      <mcma-card v-if="showExoCEE" title="Exonération CEE" class="mcma-card-in-tab transparent-bg">
        <a-row type="flex" :gutter="12" class="mt-0 mb-0">
          <mcma-field
            :colSpan="24"
            type="switch"
            :form="form"
            :wasSubmit="wasSubmit"
            fieldName="exonerationCee"
            getFlow="name::sites"
            getPanel="name::Données techniques (électricité)"
            getField="type::exonerationCee"
          />
        </a-row>
      </mcma-card>

      <a-row type="flex" :gutter="12" class="mt-0 mb-0" v-if="this.operation.name == 'SDE35_ELEC_2023_2025'">
        <mcma-field
          :colSpan="12"
          type="input"
          :form="form"
          :wasSubmit="wasSubmit"
          fieldName="codeAPE"
          label="Code APE/NAF"
          :defaultValue="site.codeApeSite"
          extraLabel="Indiquer le code APE"
          rule="isAPE"
        >
          <template #information class="flex-column">
            <div
              v-if="getSitesFlow && getSitesFlow.sftapComment"
              class="html-render-content"
              v-html="getSitesFlow.sftapComment"
              :class="{ 'can-edit': $isAdmin() }"
              @click="setEditorModal('sites', 'sftapComment')"
            />
          </template>
        </mcma-field>
      </a-row>
    </mcma-card>
    <mcma-card v-if="showENR" title="Énergie renouvelable" class="mcma-card-in-tab transparent-bg" :inline="true">
      <a-row type="flex" :gutter="12" class="mt-0 mb-0">
        <mcma-field
          :colSpan="24"
          type="select"
          :form="form"
          :wasSubmit="wasSubmit"
          getFlow="name::sites"
          getPanel="name::Données techniques (électricité)"
          getField="type::enr"
          @change="
            resetHve
            setThve(form.getFieldValue('enr'))
          "
          :defaultValue="defaultValueEnr"
        >
          <template #information class="flex-column">
            <div
              v-if="getSitesFlow && getSitesFlow.enrDescribe"
              class="html-render-content"
              v-html="getSitesFlow.enrDescribe"
              :class="{ 'can-edit': $isAdmin() }"
              @click="setEditorModal('sites', 'enrDescribe')"
            />
            <span class="action" @click="openInfosModal">Cliquer ici</span>
            pour en savoir plus.
          </template>
        </mcma-field>
        <mcma-field
          type="switch"
          :form="form"
          :wasSubmit="wasSubmit"
          fieldName="hve"
          getFlow="name::sites"
          getPanel="name::Données techniques (électricité)"
          getField="type::hve"
          :defaultValue="site.hve"
        />

        <mcma-field
          type="switch"
          :form="form"
          :wasSubmit="wasSubmit"
          fieldName="thve"
          getFlow="name::sites"
          getPanel="name::Données techniques (électricité)"
          getField="type::thve"
          :defaultValue="site.thve"
        />
      </a-row>
    </mcma-card>
    <mcma-card
      v-if="showSegment"
      title="Segment, Formule tarifaire d'acheminement (FTA) et Puissances"
      class="mcma-card-in-tab transparent-bg"
    >
      <template #describe>
        <span v-if="form.getFieldValue('segment')">
          <div
            v-if="getSitesFlow && getSitesFlow.sftapDescribe"
            class="html-render-content"
            v-html="getSitesFlow.sftapDescribe"
            :class="{ 'can-edit': $isAdmin() }"
            @click="setEditorModal('sites', 'sftapDescribe')"
          />
        </span>
        <!--<span v-else>Vous devez dans un premier temps selectionner un segment</span>-->
      </template>
      <a-row type="flex" :gutter="12" class="mt25">
        <a-col :span="12" v-if="!site.added">
          <div class="field-block-title">Caractéristiques techniques initiales</div>
          <a-row type="flex" :gutter="12">
            <a-col :span="24">
              <div class="field-list">
                <div class="field-item">
                  <span class="field-label">Segment Initial</span>
                  <span class="field-value">
                    {{ $getValueByKey(site.segmentInitial, "non renseigné") }}
                  </span>
                </div>
                <div class="field-item">
                  <span class="field-label">FTA Initial</span>
                  <span class="field-value">
                    {{ $getValueByKey(site.ftaInitial, "non renseigné") }}
                  </span>
                </div>
                <div class="field-item" v-for="puissance in puissancesInitial" :key="puissance">
                  <span class="field-label">{{ puissance }}</span>
                  <span class="field-value" v-if="psInitial[puissance]">
                    {{ psInitial[puissance] }}
                    <span v-if="site.segmentInitial === 'C_2' || site.segmentInitial === 'C_3'">(kW)</span>
                    <span v-else>(kVA)</span>
                  </span>
                  <span class="field-value" v-else>Indefinie</span>
                </div>
              </div>
            </a-col>
          </a-row>
        </a-col>
        <a-col :span="site.added ? 24 : 12">
          <div class="field-block-title">
            <span v-if="this.showENR || this.showFTA || this.showPS || this.showSegment"
              >Caractéristiques techniques souhaitées</span
            >
          </div>
          <a-row type="flex" :gutter="12" class="mtb4">
            <mcma-field
              :colSpan="24"
              type="select"
              :form="form"
              :wasSubmit="wasSubmit"
              :class="{ mb12: !form.getFieldValue('segment') }"
              @change="onUpdateSegment($event)"
              disableValue="Sélectionnez un segment"
              getFlow="name::sites"
              getPanel="name::Données techniques (électricité)"
              getField="type::segment"
              :defaultValue="site.segment"
              :disabledOptions="!site.added && site.segment && (site.segment === 'C_2' || site.segment === 'C_3')"
              :disabled="
                !site.added &&
                site.segment &&
                site.segment !== 'C_2' &&
                site.segment !== 'C_3' &&
                site.segment !== 'C_0'
              "
            />
          </a-row>
          <a-row type="flex" :gutter="12" class="mtb4" v-if="showFTA && form.getFieldValue('segment') && ftas">
            <mcma-field
              :colSpan="24"
              v-if="ftas.length > 0"
              type="select"
              :form="form"
              :wasSubmit="wasSubmit"
              fieldName="fta"
              @change="onUpdateFta($event)"
              getFlow="name::sites"
              getPanel="name::Données techniques (électricité)"
              getField="type::fta"
              :disableValue="`Sélectionnez une FTA pour le segment ${
                form.getFieldValue('segment')
                  ? $getValueByKey(form.getFieldValue('segment'))
                  : form.getFieldValue('segment')
              }`"
              :defaultValue="site.fta"
            >
              <a-select-option
                v-for="(fta, index) in ftas"
                :key="index"
                :value="fta"
                v-if="!(form.getFieldValue('typeSite') === 'ECLAIRAGE_PUBLIC' && fta === 'BTINF_MU_HP_HC')"
                >{{ $getValueByKey(fta) }}</a-select-option
              >
            </mcma-field>

            <div class="error-content" v-else>
              <p class="error">
                Aucune FTA n'est configuré pour le segment
                {{
                  form.getFieldValue("segment")
                    ? $getValueByKey(form.getFieldValue("segment"))
                    : form.getFieldValue("segment")
                }}
              </p>
            </div>
          </a-row>
          <a-row
            type="flex"
            :gutter="12"
            class="mtb4"
            v-if="showPS && showFTA && form.getFieldValue('segment') && puissances && puissances.length > 0"
          >
            <a-col :span="24">
              <ul class="red-text" v-if="invalidPs">
                Des règles ne sont pas respectés :
                <li v-for="error in invalidPs" :key="error">{{ error }}</li>
              </ul>
            </a-col>
          </a-row>
          <a-row
            type="flex"
            :gutter="12"
            class="mtb4"
            v-if="showPS && showFTA && form.getFieldValue('segment') && puissances && puissances.length > 0"
          >
            <div class="flex-column">
              <label class="mcma-label">Puissance(s)</label>
              <div>
                <mcma-field
                  v-for="puissance in puissances"
                  :key="puissance"
                  :colSpan="8"
                  type="input"
                  :form="form"
                  :wasSubmit="wasSubmit"
                  @change="inputPs($event, puissance)"
                  :fieldName="`ps-${puissance}`"
                  :defaultValue="defaultValuePS(puissance)"
                  :label="`${puissance} ${
                    form.getFieldValue('segment') === 'C_2' || form.getFieldValue('segment') === 'C_3'
                      ? '(kW)'
                      : '(kVA)'
                  }`"
                  :required="true"
                  numberOnly="decimal"
                />
              </div>
            </div>
          </a-row>
        </a-col>
      </a-row>
    </mcma-card>
     <a-row type="flex" :gutter="12" class="mt0">
        <mcma-field
          :colSpan="24"
          type="textarea"
          :form="form"
          :wasSubmit="wasSubmit"
          fieldName="commentaire"
          getFlow="name::sites"
          getPanel="name::Données techniques (électricité)"
          getField="type::codeNafSite"
          :height="120"
        />
      </a-row>
    <a-row type="flex" :gutter="12">
      <a-col :span="24">
        <div class="button-group-inline">
          <mcma-button name="Réinitialiser" color="primary" leftIcon="undo-alt" :isFaIcon="true" @click="initialize" />
          <mcma-button
            name="Valider les informations"
            class="ml12"
            color="success"
            leftIcon="save"
            :isFaIcon="true"
            @click="submit"
          />
        </div>
      </a-col>
    </a-row>

    <mcma-editor-modal
      v-if="$isAdmin()"
      :visible="showEditor"
      :flowName="flowName"
      :flowProp="flowProp"
      @onCancel="setEditorModal"
      @onSave="editorHasMakeChange"
    />

    <mcma-enr-infos-modal
      :visible="openInfos"
      :htmlRender="getSitesFlow && getSitesFlow.enrModalDescribe ? getSitesFlow.enrModalDescribe : null"
      @setEditorModal="setEditorModal('sites', 'enrModalDescribe')"
      @close="closeInfos"
    />
  </a-form>
</template>

<script>
import Axios from "@/util/Axios"
import McmaButton from "@/components/mcma/common/McmaButton"
import McmaCard from "@/components/mcma/common/McmaCard"
import McmaField from "@/components/mcma/common/McmaField"
import McmaEditorModal from "@/components/mcma/common/McmaEditorModal"
import McmaEnrInfosModal from "@/components/mcma/common/McmaEnrInfosModal"
import { getDynamiqueFields } from "@/util/Helpers/DynamiqueFields"
import { pick } from "@/util/Helpers"
import { mapState } from "vuex"
import { Ftas, Segments } from "./Puissances"
import { RA_RESULT, RA_TURPE, RV_RESULT, RV_TURPE } from "./RG_TURPE"

export default {
  name: "etape_2_elec",
  props: ["site"],
  components: {
    McmaCard,
    McmaField,
    McmaButton,
    McmaEditorModal,
    McmaEnrInfosModal,
  },
  data() {
    return {
      form: this.$form.createForm(this),
      wasSubmit: false,
      openInfos: false,
      ftas: [],
      puissances: [],
      invalidPs: false,
      showEditor: false,
      flowName: null,
      flowProp: null,
    }
  },
  computed: {
    ...mapState(["operation", "membre"]),
    showTaxeTCFE() {
      const typeTcfe = getDynamiqueFields(
        this.operation,
        "name::sites",
        "name::Données techniques (électricité)",
        "type::typeTcfe"
      )
      return typeTcfe ? typeTcfe.displayed : false
    },
    showExoCSPE() {
      const exoCSPE = getDynamiqueFields(
        this.operation,
        "name::sites",
        "name::Données techniques (électricité)",
        "type::exonerationCspe"
      )
      return exoCSPE ? exoCSPE.displayed : false
    },
    showExoCEE() {
      const exoCee = getDynamiqueFields(
        this.operation,
        "name::sites",
        "name::Données techniques (électricité)",
        "type::exonerationCee"
      )
      return exoCee ? exoCee.displayed : false
    },
    showENR() {
      const enr = getDynamiqueFields(
        this.operation,
        "name::sites",
        "name::Données techniques (électricité)",
        "type::enr"
      )
      return enr ? enr.displayed : false
    },
    activeENRUnique() {
      const enrUniqueSite = getDynamiqueFields(
        this.operation,
        "name::sites",
        "name::Localisation",
        "type::enrUniqueSite"
      )
      return enrUniqueSite.displayed
    },
    showFTA() {
      const fta = getDynamiqueFields(
        this.operation,
        "name::sites",
        "name::Données techniques (électricité)",
        "type::fta"
      )
      return fta ? fta.displayed : false
    },
    showPS() {
      const power = getDynamiqueFields(
        this.operation,
        "name::sites",
        "name::Données techniques (électricité)",
        "type::power"
      )
      return power ? power.displayed : false
    },
    showSegment() {
      const segment = getDynamiqueFields(
        this.operation,
        "name::sites",
        "name::Données techniques (électricité)",
        "type::segment"
      )
      return segment ? segment.displayed : false
    },
    puissancesInitial() {
      if (this.site.ftaInitial) {
        return Ftas[this.site.ftaInitial].puissances
      } else {
        return []
      }
    },
    defaultValueEnr() {
      return this.activeENRUnique && this.membre.enrUniqueSite ? this.membre.enrUniqueSite : this.site.enr
    },
    getSitesFlow() {
      return this.operation.flows.find((flow) => flow.name === "sites")
    },
    psInitial() {
      return this.site.psInitial ? JSON.parse(this.site.psInitial) : {}
    },
    canUpdate() {
      return (fieldsValue) => {
        const ps = this.getAllPsAvailable(fieldsValue, true)
        for (let key in ps) {
          if (!ps[key] || ps[key] === "0") {
            return false
          }
        }
        const site = this.site || {}
        const enr = fieldsValue.enr ? fieldsValue.enr.toString() : null
        const psSiteStringComma = site.ps ? this.$replaceAll(site.ps, ".", ",") : null
        const psStringComma = ps ? this.$replaceAll(JSON.stringify(ps), ".", ",") : null
        return !!(
          enr &&
          fieldsValue.segment &&
          fieldsValue.fta &&
          this.ftas.length > 0 &&
          ((site.enr && enr !== site.enr.toString()) ||
            fieldsValue.typeSite !== site.typeSite ||
            fieldsValue.hve !== site.hve ||
            fieldsValue.segment !== site.segment ||
            fieldsValue.fta !== site.fta ||
            fieldsValue.commentaire !== site.commentaire ||
            psSiteStringComma !== psStringComma)
        )
      }
    },
    defaultValuePS() {
      return (puissance) => {
        if (puissance === "PTE/PM") {
          return this.site.fta === "BTSUP_LU" && !this.psInitial["PTE/PM"]
            ? this.psInitial["HPH"]
            : this.psInitial["PTE/PM"]
        }
        const sitePs = this.site.ps ? JSON.parse(this.site.ps) : {}
        return sitePs[puissance] || this.psInitial[puissance] || "0"
      }
    },
  },
  methods: {
    initialize() {
      this.puissances = []
      let editSiteElec = pick(this.site, [
        "enr",
        "typeSite",
        "extinctionNuit",
        "hve",
        "thve",
        "typeTcfe",
        "exonerationCspe",
        "exonerationCee",
        "segment",
        "fta",
        "commentaire",
      ])
      this.$nextTick(() => {
        editSiteElec.enr = this.integerToString(editSiteElec.enr)
        editSiteElec.exonerationCspe = this.integerToString(editSiteElec.exonerationCspe)
        editSiteElec = this.$mergeObject(this.form.getFieldsValue(), editSiteElec)
        this.form.setFieldsValue(editSiteElec)
        this.initSegment()
        editSiteElec.thve = this.setThve(editSiteElec.enr)
      })
    },
    setThve(enr) {
      if (enr === "100") {
        this.form.setFieldsValue({
          thve: true,
        })
      } else {
        this.form.setFieldsValue({
          thve: false,
        })
      }
    },
    integerToString(currentField) {
      currentField = currentField ? currentField.toString() : "0"
    },
    resetHve() {
      this.$nextTick(() => {
        this.form.setFieldsValue({
          hve: false,
        })
      })
    },
    setEditorModal(flowName, flowProp) {
      console.group("setEditorModal@Etape2_Elec")
      console.log("flowName : %O", flowName)
      console.log("flowProp : %O", flowProp)
      this.showEditor = !!(flowName && flowProp)
      if (flowName && flowProp) {
        this.flowName = flowName
        this.flowProp = flowProp
      } else {
        setTimeout(() => {
          this.flowName = flowName
          this.flowProp = flowProp
        }, 300)
      }
      console.groupEnd()
    },
    editorHasMakeChange() {
      this.setEditorModal()
    },
    submit() {
      let valid = true
      this.form.validateFields((errors, fieldsValue) => {
        if (errors) {
          // || !this.canUpdate(fieldsValue)
          this.$notification.warning({
            message: "Formulaire invalide",
            description: `Veuillez-vous assurez de remplir tous les champs requis.`,
          })
        } else {
          const currentFta = Ftas[fieldsValue.fta] || {}
          fieldsValue.ps = this.getAllPsAvailable(fieldsValue, true)
          if (currentFta.puissances && currentFta.puissances.length > 0) {
            for (let key in fieldsValue.ps) {
              if (!currentFta.puissances.includes(key)) {
                delete fieldsValue.ps[key]
              }
            }
          }

          valid = this.validatePs(fieldsValue) ? valid : false

          if (valid) {
            Axios("patch", "api/sites/" + this.site.id, {
              ongletParametre: true,
              enr: fieldsValue.enr,
              typeSite: fieldsValue.typeSite,
              extinctionNuit: fieldsValue.typeSite === 'ECLAIRAGE_PUBLIC' ? fieldsValue.extinctionNuit : 0,
              typeTcfe: fieldsValue.typeTcfe,
              exonerationCspe: fieldsValue.exonerationCspe,
              exonerationCee: fieldsValue.exonerationCee,
              codeApeSite: fieldsValue.codeAPE,
              hve: fieldsValue.hve,
              thve: fieldsValue.thve,
              segment: fieldsValue.segment,
              fta: fieldsValue.fta,
              ps: fieldsValue.ps ? JSON.stringify(fieldsValue.ps) : fieldsValue.ps,
              commentaire: fieldsValue.commentaire,
            })
              .then((response) => {
                this.$emit("update", response.data)
                this.$notification.success({
                  message: "Paramètres sauvegardées !",
                  description: `Les paramètres ont bien été sauvegardées`,
                })
              })
              .catch((error) => {
                console.error(error)
                this.$notification.error({
                  message: "Erreur serveur",
                  description: "Une erreur server est survenue, veuillez contacter un administrateur.",
                })
              })
          } else {
            this.$notification.warning({
              message: "Formulaire invalide",
              description: `Veuillez-vous assurez de remplir tous les champs requis.`,
            })
          }
        }
      })
    },
    validatePs(fieldsValue) {
      this.invalidPs = false

      const errors = []
      const derogables = []
      let valid = true

      for (const rv in RV_TURPE) {
        const result = RV_TURPE[rv](
          fieldsValue.typeSite === "ECLAIRAGE_PUBLIC",
          fieldsValue.segment,
          fieldsValue.fta,
          fieldsValue.ps
        )
        if (result.state === RV_RESULT.KO) {
          errors.push(result.message)
          valid = false
        } else if (result.state === RV_RESULT.DEROGABLE) {
          derogables.push(result.message)
        }
      }

      if (valid && derogables.length) {
        let message = "Les puissances indiquées pour votre site ne respectent pas les règles suivantes :\n"
        for (const derogable of derogables) {
          message += "  - " + derogable + "\n"
        }
        message += "Voulez-vous vraiment déroger à ces règles?"

        if (!confirm(message)) {
          return false
        }
      } else if (!valid && errors.length) {
        this.invalidPs = errors
        return false
      }
      return true
    },
    inputPs(value, item) {
      let adjust = value
      for (const ra in RA_TURPE) {
        const result = RA_TURPE[ra](
          this.form.getFieldValue("typeSite") === "ECLAIRAGE_PUBLIC",
          this.form.getFieldValue("segment"),
          this.form.getFieldValue("fta"),
          adjust
        )
        if (result.state === RA_RESULT.ADJUSTED) {
          adjust = result.value
        }
      }
      if (adjust !== value) {
        this.form.setFieldsValue({
          [item]: adjust,
        })
      }
    },
    initSegment() {
      const segment = this.form.getFieldValue("segment")
      const dynSegment = getDynamiqueFields(
        this.operation,
        "name::sites",
        "name::Données techniques (électricité)",
        "type::segment",
        segment
      )
      if (segment && dynSegment && dynSegment.lockedValues.includes(segment || dynSegment.value)) {
        this.loadFtas(segment)
        const fta = this.form.getFieldValue("fta") || this.site.fta
        this.puissances = fta ? Ftas[this.site.fta].puissances : undefined
      } else {
        const toReset = {
          segment: undefined,
          fta: undefined,
        }
        const ps = this.getAllPsAvailable()
        ps.forEach((key) => {
          toReset[key] = undefined
        })
        this.form.setFieldsValue(toReset)
      }
    },
    getAllPsAvailable(fieldsValues, getHasObject) {
      if (!fieldsValues) {
        fieldsValues = this.form.getFieldsValue()
      }
      const fieldsValuesKeys = Object.keys(fieldsValues) || []
      const psKeys = fieldsValuesKeys.filter((item) => item.startsWith("ps-"))
      if (getHasObject) {
        const psObject = {}
        psKeys.forEach((key) => {
          const keyParse = key.replace("ps-", "")
          psObject[keyParse] = fieldsValues[key]
        })
        return psObject
      }
      return psKeys
    },
    loadFtas(segment) {
      const segmentObject = Segments[segment.toUpperCase()] || {}
      const ftasBySegment = segmentObject.fta || []
      const dynFta = getDynamiqueFields(
        this.operation,
        "name::sites",
        "name::Données techniques (électricité)",
        "type::fta",
        this.form.getFieldValue("fta")
      )
      const lockedValues = dynFta.lockedValues || []
      this.ftas = ftasBySegment.filter(function (item) {
        return lockedValues.includes(item.name)
      })
      this.ftas = this.ftas.map((fta) => fta.name)
      if (this.ftas.length > 0) {
        if (!this.ftas.includes(this.site.fta)) {
          this.emptyForm()
        }
      } else {
        this.emptyForm()
      }
    },
    emptyForm() {
      this.puissances = []
      const toReset = { fta: undefined }
      const ps = this.getAllPsAvailable()
      ps.forEach((key) => {
        toReset[key] = undefined
      })
      this.form.setFieldsValue(toReset)
    },
    onUpdateSegment(segment) {
      this.loadFtas(segment)
    },
    onUpdateFta(fta) {
      this.puissances = Ftas[fta].puissances
    },
    closeInfos() {
      this.openInfos = false
    },
    openInfosModal() {
      this.openInfos = true
    },
  },
  watch: {
    site() {
      this.initialize()
    },
  },
  created() {
    this.initialize()
  },
}
</script>

<style lang="scss">
@import "./src/assets/scss/variables";
@import "./src/assets/scss/functions";

.error-content {
  display: flex;
  width: 100%;
  height: 50px;
  background: #f15921;
  margin: 10px 6px;
  border-radius: 4px;
  .error {
    margin: auto;
    color: white;
    font-weight: 900;
  }
}
</style>
