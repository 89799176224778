<template>
  <center v-if="page !== null && total">
    <ul class="pagination">
      <li :class="{ disabled: rangeIndex === 0, 'waves-effect': rangeIndex !== 0 }" @click="ranging(rangeIndex - 1)">
        <a>
          <font-awesome-icon icon="angle-double-left" class="red-text" />
        </a>
      </li>
      <li :class="{ disabled: page === 0, 'waves-effect': page !== 0 }" @click="paging(page - 1)">
        <a>
          <i class="material-icons">chevron_left</i>
        </a>
      </li>
      <li v-for="n in range" :key="n" class="waves-effect" :class="{ active: n === page + 1 }" @click="paging(n - 1)">
        <a>
          {{ n }}
        </a>
      </li>
      <li :class="{ disabled: page === total - 1, 'waves-effect': page !== total - 1 }" @click="paging(page + 1)">
        <a>
          <i class="material-icons">chevron_right</i>
        </a>
      </li>
      <li
        :class="{ disabled: rangeIndex === maxRangeIndex, 'waves-effect': rangeIndex !== maxRangeIndex }"
        @click="ranging(rangeIndex + 1)"
      >
        <a>
          <font-awesome-icon icon="angle-double-right" class="red-text" />
        </a>
      </li>
    </ul>
  </center>
</template>

<script>
import _ from "lodash"

export default {
  name: "pagination",
  props: ["page", "total"],
  data() {
    return {
      rangeIndex: 0,
    }
  },
  computed: {
    range() {
      const start = 5 * this.rangeIndex + 1
      let end = start + 5
      if (this.rangeIndex === this.maxRangeIndex && this.total % 5 !== 0) {
        end = start + (this.total % 5)
      }
      return _.range(start, end)
    },
    maxRangeIndex() {
      let quotient = Math.floor(this.total / 5)
      if (this.total % 5 === 0) {
        quotient--
      }
      return quotient
    },
  },
  methods: {
    paging(n) {
      if (n >= 0 && n < this.total) {
        if (n < this.range[0] - 1) {
          this.rangeIndex--
        }
        if (n > this.range[this.range.length - 1] - 1) {
          this.rangeIndex++
        }
        this.$emit("paging", n)
      }
    },
    ranging(n) {
      if (n >= 0 && n <= this.maxRangeIndex) {
        this.rangeIndex = n
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.pagination li.active {
  background-color: #6c6d70;
}
</style>
