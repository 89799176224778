/* eslint-disable standard/computed-property-even-spacing */
<template>
  <form class="card" @submit.prevent="submit">
    <section class="card-content">
      <span class="card-title"> Grille des sites </span>
      <blockquote v-if="!site.id">
        Tant que les informations d'identification du site que vous voulez créer ne sont pas complétées, vous ne pouvez
        pas accéder aux autres onglets.
      </blockquote>
      <!-- <span>{{membre}}</span>
      <span>{{sites}}</span>
      <span>{{Segments}}</span>
      -->

      <ag-grid-vue
        style="height: 500px"
        class="ag-theme-material"
        :grid-options="gridOptions"
        :modules="modules"
        :columnDefs="columnDefs"
        :rowData="rowData"
        :enableRangeSelection="true"
        :fillOperation="fillOperation"
        :enableFillHandle="true"
        :row-selection="'multiple'"
        :row-multi-select-with-click="true"
        :default-col-def="defaultColDef"
        :enableCellChangeFlash="false"
        :components="components"
        @firstDataRendered="onFirstDataRendered"
        @cellValueChanged="onCellValueChanged"
        @cellDoubleClicked="onCellDoubleClicked"
      >
      </ag-grid-vue>
      <!-- @cellValueChanged="onCellValueChanged" -->
      <!-- for debug -->
      <!--
      this.confNom : {{ this.confNom }} <br />
      this.confCodeSite : {{ this.confCodeSite }} <br />
      this.confRae : {{ this.confRae }} <br />
      this.confFluide : {{ this.confFluide }} <br />
      this.confStatus : {{ this.confStatus }} <br />
      this.confAdresse : {{ this.confAdresse }} <br />
      this.confCodePostal : {{ this.confCodePostal }} <br />
      this.confCommune : {{ this.confCommune }} <br />
      this.confCodeImputation : {{ this.confCodeImputation }} <br />
      this.confDateActivation : {{ this.confDateActivation }} <br />
      this.confDateFinContrat : {{ this.confDateFinContrat }} <br />
     -->
    </section>
    <center class="card-action">
      <button type="submit" class="waves-effect waves-light btn light-green" id="validSiteValid">
        <i class="material-icons">save</i>
        Valider
      </button>

      <button type="button" class="waves-effect waves-light btn blue darken-2" @click="initialize">
        <i class="material-icons">remove</i>
        Réinitialiser
      </button>
    </center>
  </form>
</template>

<script>
/*
import {
  Rae,
  CodeImputation,
  CodePostal,
  CodeInsee,
  DateActivation,
  SiteStatus,
  DateFinContrat
} from "@/components/common/Fields";
*/
import moment from "moment"
import GenericField from "@/components/common/Fields/GenericField"
import Axios from "@/util/Axios"
import MixinValidator from "@/util/Mixin/Validator"
import { AllModules } from "@ag-grid-enterprise/all-modules"
import { AgGridVue } from "ag-grid-vue"
import $ from "jquery"
import _ from "lodash"
import Materialize from "materialize-css"
import { getDynamiqueFields } from "@/util/Helpers/DynamiqueFields"
import { mapState } from "vuex"
import { Segments } from "./Fta"

import "jquery-ui-bundle"

const DATE_FORMAT = "DD/MM/YYYY"

/*
  function numberParser(params) {
    return Number(params.newValue);
  }
  */
/*
  function compareValues(params) {
      return {color: 'green', backgroundColor: 'black'};
  }
  */
/*
  function isForceRefreshSelected() {
    return document.querySelector("#forceRefresh").checked;
  }
  */

function dateStringToTimeStamp(value) {
  if (value && moment(value, DATE_FORMAT).format("x") !== "Invalid date") {
    return Math.round(moment(value, DATE_FORMAT).format("x"))
  }
  return value
}

function timeStampToDateString(params) {
  if (Array.isArray(params.value) && params.value.length > 0) {
    if (typeof params.value[0] === "number") {
      return moment(params.value[0], "x").format(DATE_FORMAT)
    } else {
      return params.value
    }
  } else if (params.value) {
    if (typeof params.value === "number") {
      return moment(params.value, "x").format(DATE_FORMAT)
    } else {
      return params.value
    }
  } else {
    return null
  }
}

function getDatePicker() {
  function Datepicker() {}
  Datepicker.prototype.init = function (params) {
    this.eInput = document.createElement("input")
    this.eInput.value = moment(params.value, "x").format(DATE_FORMAT)
    $(this.eInput).datepicker({ dateFormat: "dd/mm/yy" })
  }
  Datepicker.prototype.getGui = function () {
    return this.eInput
  }
  Datepicker.prototype.afterGuiAttached = function () {
    this.eInput.focus()
    this.eInput.select()
  }
  Datepicker.prototype.getValue = function () {
    console.log("this.eInput.value", this.eInput.value)
    return this.eInput.value
  }
  Datepicker.prototype.destroy = function () {}
  Datepicker.prototype.isPopup = function () {
    return false
  }
  return Datepicker
}

export default {
  name: "masse",
  mixins: [MixinValidator],
  props: ["site"],
  components: {
    AgGridVue,
    GenericField,
  },
  data() {
    return {
      components: null,
      modules: AllModules,
      nom: null,
      codeSite: null,
      rae: null,
      fluide: null,
      status: null,
      refTDG: null,
      refSite: null,
      adresse: null,
      codePostal: null,
      commune: null,
      codeImputation: null,
      // commentaire: null,
      added: null,
      dateActivation: null,
      dateResiliation: null,
      codeInsee: null,
      interlocuteurTech: null,
      interlocuteurs: [],
      currentInterlocuteur: null,
      sites: null,
      columnDefs: null,
      rowData: null,
      confCodeImputation: null,
      confENR: null,
      confFTA: null,
      defaultColDef: null,
      gridOptions: {},
    }
  },
  async beforeMount() {
    this.defaultColDef = {
      sortable: true,
      filter: "agSetColumnFilter",
      cellClass: function (params) {
        return `${params.colDef.field}_${params.node.id}`
      },
    }
    this.columnDefs = [
      {
        headerName: "Fluide",
        field: "fluide",
      },
      {
        headerName: "RAE/PCE",
        field: "rae",
        editable: false,
        sort: {
          direction: "asc",
          priority: 0,
        },
      },
      {
        headerName: "Nom",
        field: "nom",
        editable: true,
      },
      {
        headerName: "CodeSite",
        field: "codeSite",
        editable: true,
      },
      {
        headerName: "Groupe",
        field: "codeGroupement",
        editable: true,
      },
      {
        headerName: "Adresse",
        field: "adresse",
        editable: true,
        cellEditor: "agLargeTextCellEditor",
      },
      {
        headerName: "Commune",
        field: "commune",
        editable: true,
      },
      {
        headerName: "Date d'Entrée",
        field: "dateActivation",
        editable: true,
        cellEditor: "datePicker",
        valueFormatter: timeStampToDateString,
      },
      {
        headerName: "Date de sortie",
        field: "dateResiliation",
        editable: true,
        cellEditor: "datePicker",
        valueFormatter: timeStampToDateString,
      },
      {
        headerName: "Segment",
        field: "segment",
        editable: false,
        cellRenderer: (data) => {
          return data.value ? data.value : "C0"
        },
      },
      {
        headerName: "FTA",
        field: "fta",
        editable: true,
        cellEditor: "agRichSelectCellEditor",
        cellEditorParams: (params) => {
          let selectedSegement = params.data.segment
          selectedSegement = selectedSegement || "C_0"
          return {
            cellHeight: 40,
            values: this.getFtas(selectedSegement),
          }
        },
      },
      {
        headerName: "Imputation",
        field: "codeImputation",
        editable: true,
        cellEditor: "agRichSelectCellEditor",
        cellEditorParams: {
          cellHeight: 50,
          values: this.confCodeImputation.lockedValues,
        },
      },
      {
        headerName: "ENR",
        field: "enr",
        editable: true,
        cellRenderer: (data) => {
          return this.$getValueByKey(data.value)
        },
        cellEditorParams: {
          cellHeight: 50,
          values: this.confENR.lockedValues,
        },
        cellEditor: "agRichSelectCellEditor",
      },
      {
        headerName: "Type de site",
        field: "typeSite",
        minWidth: 200,
        editable: true,
        cellRenderer: (data) => {
          return this.$getValueByKey(data.value)
        },
        cellEditorParams: {
          cellHeight: 50,
          values: this.confTypeSite.lockedValues,
        },
        cellEditor: "agRichSelectCellEditor",
      },
    ]

    this.components = { datePicker: getDatePicker() }

    // this.rowData = this.sites;
    /*
      this.rowData = [
        { make: "Toyota", model: "Celica", price: 35000 },
        { make: "Ford", model: "Mondeo", price: 32000 },
        { make: "Porsche", model: "Boxter", price: 72000 }
      ];
      */
  },
  computed: {
    ...mapState(["membre", "operation"]),
    getFtas() {
      return (segment) => {
        const allFtasForSegment = Segments[segment] ? Segments[segment].fta : []
        return (
          this.confFTA.lockedValues.filter(function (item) {
            return !!allFtasForSegment.includes(item)
          }) || []
        )
      }
    },
    Segments() {
      return Segments
    },
    fields() {
      if (this.operation && this.operation.flows) {
        const flow = _.find(this.operation.flows, ["name", "sites"])
        if (flow && flow.panels) {
          const panel = _.find(flow.panels, ["name", "Localisation"])
          if (panel && panel.fields) {
            panel.fields.forEach((element) => {
              switch (element.type) {
                case "siteName":
                  element.value = this.nom
                  break
                case "adress":
                  element.value = this.adresse
                  break
                case "postalCode":
                  element.value = this.codePostal
                  break
                case "siteCode":
                  element.value = this.codeSite
                  break
                /* case "comment":
                    element.value = this.commentaire;
                    break; */
                case "town":
                  element.value = this.commune
                  break
                case "codeImputation":
                  element.value = this.codeImputation
                  break
                case "dateFinContrat":
                  element.value = this.Resiliation
                  break
                default:
                  element.value = this[element.type]
              }
              if (!element.value) {
                console.log("element.type non trouvé : %O", element.type)
              } else {
                console.log("%O : %O", element.type, element.value)
              }
            })
            return panel.fields
          }
        }
      }
      return []
    },
    fluidField: {
      get() {
        if (this.operation && this.operation.flows) {
          const flow = _.find(this.operation.flows, ["name", "sites"])
          if (flow && flow.panels) {
            const panel = _.find(flow.panels, ["name", "Localisation"])
            if (panel && panel.fields) {
              let field = _.find(panel.fields, ["type", "fluid"])
              if (!this.fluide) {
                field.value = field.lockedValues[0]
              } else {
                field.value = this.fluide
              }
              return field
            }
          }
        }
        return null
      },
      set(value) {
        this.fluide = value
      },
    },
    fluidFieldElec: {
      get() {
        if (this.fluidField) {
          if (this.fluidField.lockedValues) {
            return this.fluidField.lockedValues.indexOf("Électricité") > -1
          }
        }
        return false
      },
    },
    fluidFieldGaz: {
      get() {
        if (this.fluidField) {
          if (this.fluidField.lockedValues) {
            return this.fluidField.lockedValues.indexOf("Gaz") > -1
          }
        }
        return false
      },
    },
    customInterlocuteur() {
      if (this.membre.interlocuteurTechnique && this.membre.interlocuteurTechnique.id && this.interlocuteurTech) {
        return (
          this.membre.interlocuteurTechnique.fonction !== this.interlocuteurTech.fonction ||
          this.membre.interlocuteurTechnique.prenom !== this.interlocuteurTech.prenom ||
          this.membre.interlocuteurTechnique.nom !== this.interlocuteurTech.nom ||
          this.membre.interlocuteurTechnique.mail !== this.interlocuteurTech.mail ||
          this.membre.interlocuteurTechnique.telephone !== this.interlocuteurTech.telephone ||
          this.membre.interlocuteurTechnique.mobile !== this.interlocuteurTech.mobile
        )
      } else {
        return false
      }
    },
  },
  methods: {
    initialize() {
      console.group("intialize@Masse")
      this.confCodeImputation = getDynamiqueFields(
        this.operation,
        "name::sites",
        "name::Localisation",
        "type::codeImputation"
      )
      this.confENR = getDynamiqueFields(
        this.operation,
        "name::sites",
        "name::Données techniques (électricité)",
        "type::enr"
      )
      this.confENR.lockedValues = this.confENR.lockedValues.map((item) => item.match(/(\d+)/)[0])
      this.confFTA = getDynamiqueFields(
        this.operation,
        "name::sites",
        "name::Données techniques (électricité)",
        "type::fta"
      )
      this.confTypeSite = getDynamiqueFields(
        this.operation,
        "name::sites",
        "name::Données techniques (électricité)",
        "type::typeSite"
      )
      this.sites = null
      this.sitesNoCertified = []
      Axios("get", "api/membres/" + this.membre.id + "/sites").then((response) => {
        this.sites = _.filter(
          _.flatMap(response.data._embedded, (value) => value),
          { removed: false }
        )
        this.sites.forEach((site) => {
          if (!site.certified) {
            this.sitesNoCertified.push(site)
          }
        })
        this.rowData = this.sitesNoCertified
        console.log("rowData", this.rowData)
      })
      console.log("this.site : %O", this.site)
      console.groupEnd()
    },
    submit() {
      console.group("Site>Gestion en masse>Submit")
      console.log("this.rowData : %O", this.rowData)
      const requests = []
      this.rowData.forEach((site) => {
        if (Array.isArray(site.changed) && site.changed.length > 0) {
          let allChange = {}
          site.changed.forEach((change) => {
            console.log("modification : %O - %O - %O <=  %O", site.id, site.rae, change, site[change])
            let valueToUpdate = null
            if (Array.isArray(site[change]) && site[change].length > 0) {
              // cas du changement par glisser dans le tableau
              valueToUpdate = site[change][0]
            } else {
              // cas du changement unique
              valueToUpdate = site[change]
            }

            const isDate = moment(valueToUpdate, DATE_FORMAT, true).isValid()
            if (isDate) {
              allChange[change] = dateStringToTimeStamp(valueToUpdate)
            } else if (change === "enr" && typeof valueToUpdate === "string") {
              allChange[change] = valueToUpdate.replace("%", "")
            } else {
              allChange[change] = valueToUpdate
            }
          })
          console.log("modification allChange : %O - %O - %O <=  %O", site.id, site.rae, allChange)
          console.log("Object : %O", Object.entries(allChange).length !== 0)
          console.log("Object : %O", allChange.constructor === Object)
          if (!site.segment) {
            allChange.segment = "C_0"
          }
          // Blindage : test si allCange est bon avant d'envoyer sur le back
          if (Object.entries(allChange).length !== 0 && allChange.constructor === Object) {
            requests.push(
              Axios("patch", "api/sites/" + site.id, allChange)
                .then((response) => {
                  site.changed = []
                  this.initialize()
                  /*
                Materialize.toast(
                  "Les modifications du site " + site.nom + "[" + site.rae + "] ont bien été sauvegardées",
                  4000,
                  "lime"
                );
                */
                })
                .catch((error) => {
                  console.error(error)
                  Materialize.toast("Erreur serveur", 4000, "deep-orange")
                  Materialize.toast(
                    "Erreur serveur lors des modification du site : " + site.nom + "[" + site.rae + "]",
                    4000,
                    "deep-orange"
                  )
                })
            )
          } else {
            Materialize.toast(
              "Erreur à la construction des modification pour le site : " + site.nom + "[" + site.rae + "]",
              4000,
              "deep-orange"
            )
          }
        }
      })
      Promise.all(requests).then((responses) => {
        Materialize.toast("Les modifications ont bien été sauvegardées.", 4000, "lime")
      })
      console.groupEnd()
    },
    proceed(interlocuteurId) {},
    onFirstDataRendered() {
      this.gridOptions.api.sizeColumnsToFit()
    },
    onCellValueChanged(params) {
      // console.log('Value change');
      // console.log('params', params);
      // console.log('params.colDef.field', params.colDef.field);
      let newValue = {
        value: params.newValue,
        isArray: false,
      }
      newValue.isArray = Array.isArray(params.newValue) && params.newValue.length > 0

      if (newValue.isArray) {
        newValue.value.forEach((value) => {
          this.addColorIfChange(params, value)
        })
      } else {
        this.addColorIfChange(params, newValue.value)
      }
    },
    addColorIfChange(params, value) {
      const isDate = moment(value, DATE_FORMAT, true).isValid()
      let valueTocheck = isDate ? dateStringToTimeStamp(value) : value
      if (params.oldValue !== valueTocheck) {
        console.log("Value change : %O + %O", params.oldValue, " => ", valueTocheck)
        if (params.data.changed === undefined) {
          console.log("init params.data.changed")
          params.data.changed = []
        }
        if (params.data.changed.indexOf(params.colDef.field) === -1) {
          params.data.changed.push(params.colDef.field)
        }
        const element = document.querySelector(`.${params.colDef.field}_${params.node.id}`)
        element.classList.add("cell-data-has-changed")
      }
    },
    fillOperation(params) {
      const ranges = this.gridApi.getCellRanges()
      if (params.column.colId !== "fta") {
        return params.initialValues
      } else {
        // permet de n'appliquer les changements de Fta qu'au ligne dont le segement est identique à la ligne initiale !!!
        // console.log("startRow rowIndex", ranges[0].startRow.rowIndex)
        const direction = params.direction === "up" ? -1 : 1
        const dataInit = this.gridApi.getDisplayedRowAtIndex(ranges[0].startRow.rowIndex).data
        const segmentInit = dataInit.segment || "C_0"
        // console.log('dataCurrent', dataInit)
        console.log("segmentInit", segmentInit)
        // console.log("cible rowIndex", ranges[0].startRow.rowIndex + params.currentIndex + 1)
        const dataCurrent = this.gridApi.getDisplayedRowAtIndex(
          ranges[0].startRow.rowIndex + (params.currentIndex + 1) * direction
        ).data
        const segmentCurrent = dataCurrent.segment || "C_0"
        // console.log('dataCurrent', dataCurrent)
        console.log("segmentCurrent", segmentCurrent)
        if (segmentInit === segmentCurrent) {
          return params.initialValues
        } else {
          return dataCurrent.fta
        }
      }
      /*
        console.log("params", params)
        console.log("params.column.colId", params.column.colId)
        console.log("this.gridApi", this.gridApi)
        const ranges = this.gridApi.getCellRanges()
        console.log("ranges", ranges)
        console.log("ranges.startRow", ranges[0].startRow.rowIndex)
        console.log("params.currentIndex", params.currentIndex)
        console.log("ranges", ranges[0].startRow.rowIndex + params.currentIndex)
        console.log("segement init", this.rowData[ranges[0].startRow.rowIndex].segment)
        console.log("segement current", this.rowData[ranges[0].startRow.rowIndex + params.currentIndex + 1].segment)
        return  params.initialValues
        */
    },
    onCellDoubleClicked(params) {
      const parent = document.querySelector(`.ag-virtual-list-container`)
      const elements = document.querySelectorAll(`.ag-virtual-list-item`)
      if (elements.length > 0) {
        const className = "cell-number-selected-is-"
        elements.forEach((element, index) => {
          if (params.value.toString() === element.textContent) {
            parent.classList.add(className + index)
          }
        })
      }
    },
  },
  watch: {
    site() {
      // this.initialize();
    },
  },
  created() {
    this.initialize()
  },
  mounted() {
    this.gridApi = this.gridOptions.api
  },
}
</script>

<style lang="scss" scoped>
.col {
  margin-bottom: 10px;
}

.interlocuteur-wrapper {
  display: inline-block;
  width: 100%;
  border: 1px solid #ddd;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12), 0 3px 1px -2px rgba(0, 0, 0, 0.2);
  & ul {
    padding: 20px;
  }
  & div {
    padding: 20px;
    border-bottom: 1px solid #ddd;
  }
}
</style>
