<template>
  <main>
    <place-holder :notReady="!selected">
      <section class="container" v-if="selected">
        <div class="row">
          <div class="col s12">
            <div class="card">
              <article class="card-content">
                <span class="card-title"> Lien Administrateur / Opérations </span>
                <blockquote>
                  Attribuez massivement vos opérations à l'Adminstrateur "{{ selected.email }}" via le bouton
                  <i class="material-icons light-green-text">add</i>. Vous pouvez aussi les détacher via le bouton
                  <i class="material-icons deep-orange-text">remove</i>
                </blockquote>
              </article>
              <article class="card-action center-align">
                <button
                  type="button"
                  class="waves-effect waves-light btn light-green"
                  @click="submit"
                  id="editUserLinksValidSites"
                >
                  <i class="material-icons">save</i>
                  Valider
                </button>

                <button type="button" class="waves-effect waves-light btn blue darken-2" @click="initialize">
                  <i class="material-icons">remove</i>
                  Réinitialiser
                </button>
              </article>
            </div>
          </div>
        </div>
      </section>
    </place-holder>

    <section class="row">
      <article class="col s6" id="editUserLinksSitesToAdd">
        <place-holder :notReady="!operations">
          <grid
            v-if="operations"
            name="Opérations disponibles"
            :rows="operations"
            :columns="['name', 'groupement', 'Actions']"
            :search="true"
            :columnHeader="{ name: 'Nom', groupement: 'Groupement' }"
            :columnsNoSearch="['Actions']"
            :multiSelect="true"
            @select="(value) => (operationsSelected = value)"
          >
            <template slot="header">
              <a
                href
                @click.prevent="add(operationsSelected)"
                class="waves-effect waves-light btn light-green"
                id="editUserLinksSiteCheck"
              >
                <i class="material-icons">add</i>
                Ajouter les lignes sélectionnées.
              </a>
            </template>
            <center slot="Actions" slot-scope="props">
              <a href @click.prevent="add([props.row])">
                <i class="material-icons light-green-text editUserLinksSiteAdd">add</i>
              </a>
            </center>
          </grid>
        </place-holder>
      </article>
      <article class="col s6" id="editUserLinksSitesToRemove">
        <place-holder :notReady="!operationsGrouped">
          <grid
            v-if="operationsGrouped"
            name="Operations associés"
            :rows="operationsGrouped"
            :columns="['name', 'groupement', 'Actions']"
            :search="true"
            :columnHeader="{ name: 'Nom', groupement: 'Operation' }"
            :columnsNoSearch="['Actions']"
            :multiSelect="true"
            @select="(value) => (operationsGroupedSelected = value)"
          >
            <template slot="header">
              <a
                href
                @click.prevent="remove(operationsGroupedSelected)"
                class="waves-effect waves-light btn deep-orange"
                id="editUserLinksSiteRemCheck"
              >
                <i class="material-icons">remove</i>
                Supprimer les lignes sélectionnées.
              </a>
            </template>
            <template slot="Actions" slot-scope="props">
              <center>
                <a href @click.prevent="remove([props.row])" class="editUserLinksSiteRem">
                  <i class="material-icons deep-orange-text">remove</i>
                </a>
              </center>
            </template>
          </grid>
        </place-holder>
      </article>
    </section>
  </main>
</template>

<script>
import Axios from "@/util/Axios"
import { mapState, mapMutations } from "vuex"
import _ from "lodash"
import Grid from "@/components/common/Grid"
import Materialize from "materialize-css"
import PlaceHolder from "@/components/common/PlaceHolder"

export default {
  name: "user-admin-link-operation",
  components: {
    Grid,
    PlaceHolder,
  },
  data() {
    return {
      selected: null,

      operations: null,
      operationsSelected: [],

      operationsGrouped: null,
      operationsGroupedSelected: [],
    }
  },
  computed: {
    ...mapState(["user", "operation"]),
  },
  methods: {
    ...mapMutations(["setBreadcrumbAdd"]),
    add(operations) {
      operations.forEach((site) => {
        site.selected = false
      })
      this.operations = [..._.pullAll(this.operations, operations)]
      this.operationsGrouped.push(...operations)
      if (operations === this.operationsSelected) {
        this.operationsSelected = []
      } else {
        this.operationsGroupedSelected = [..._.pullAll(this.operationsSelected, operations)]
      }
    },
    remove(operations) {
      operations.forEach((site) => {
        site.selected = false
      })
      this.operationsGrouped = [..._.pullAll(this.operationsGrouped, operations)]
      this.operations.push(...operations)
      if (operations === this.operationsGroupedSelected) {
        this.operationsGroupedSelected = []
      } else {
        this.operationsSelected = [..._.pullAll(this.operationsGroupedSelected, operations)]
      }
    },
    initialize() {
      console.group("initialize@UserAdminLinkOperation")
      this.selected = null
      Axios("get", "api/users/" + this.$route.params.id).then((response) => {
        this.selected = response.data
        this.setBreadcrumbAdd({
          name: "Liens pour : " + this.selected.email,
          action: () => {},
        })
      })
      let operationName = "" // this.operation.name;
      console.log("operationName :%O", operationName)
      Axios("get", "/api/operations/?page=0&size=150").then((response) => {
        this.operations = response.data._embedded.operations
        /* this.operations.forEach(function(e) {
          e.operationName = operationName;
        }); */
        // this.operations.operationName = operationName
        this.recadrage()
      })
      console.log("Bloucle 0 this.operations  : %O", this.operations)
      Axios("get", "api/operations/search/findByOperationsEquals/?user=" + this.$route.params.id).then((response) => {
        console.log("Bloucle 1 response : %O", response)
        console.log("Bloucle 1 response : %O", response.data._embedded.operations)
        this.operationsGrouped = response.data._embedded.operations
        this.recadrage()
        /*
        let myoperationsGrouped = [];
        response.data._embedded.operationUsers.forEach(function(e) {
          Axios("get", "/api/operations/" + e.operationId).then(response => {
            console.log("Bloucle 2 response : %O", response.data);
            // myoperationsGrouped.push(response.data);
            this.operationsGrouped.push(response.data);
            console.log(
              "Bloucle 3 myoperationsGrouped : %O",
              myoperationsGrouped
            );
            // this.recadrage(myoperationsGrouped);
          });
        });
        // console.log("Bloucle 3 myoperationsGrouped : %O", myoperationsGrouped);
        // this.operationsGrouped = myoperationsGrouped;
        // this.operationsGrouped = response.data._embedded.userOperations;
        */
      })
      console.groupEnd()
    },
    recadrage() {
      console.group("recadrage@UserAdminLinkOperation")
      console.log("this.operations : %O", this.operations)
      // this.operationsGrouped = myoperationsGrouped;
      console.log("this.operationsGrouped : %O", this.operationsGrouped)
      if (this.operations && this.operationsGrouped) {
        this.operations = _.differenceBy(this.operations, this.operationsGrouped, "id")
      }
      console.groupEnd()
    },
    submit() {
      console.group(">> Ajout lien Utilisateur Opération")
      // let operations = "";
      // operations += operation._links.self.href + "\n";
      let userId = this.$route.params.id
      let email = this.selected.email
      Axios("post", "operation/deleteLinkUser?user=" + userId)
        .then((response) => {
          console.log("Delete response : %O", response)
          this.operationsGrouped.forEach(function (operation) {
            Axios("post", "operation/addLinkUser?operation=" + operation.id + "&user=" + userId)
              .then((response) => {
                Materialize.toast(
                  "Liaison utilisateur-opération sauvegardée : " + email + " => " + operation.name,
                  6000,
                  "lime"
                )
              })
              .catch((error) => {
                console.error(error)
                Materialize.toast(
                  "Erreur lors de l'ajout des liens entre l'utilisateur et l'opération ajoutées" +
                    operation.name +
                    " !",
                  4000,
                  "deep-orange white-text"
                )
                // this.initialize();
              })
          })
          /*
          setTimeout(function() {
          console.log("user-admin-link-operation submit@ReInit");
          this.initialize();
            }, 5000);
          */
        })
        .catch((error) => {
          console.error(error)
          Materialize.toast(
            "Erreur lors de la suppression des liens entre l'utilisateur et les opérations écartées !",
            4000,
            "deep-orange white-text"
          )
        })
      // for (const operation of this.operationsGrouped) {
      // console.log("operations : %O", operations);
      /*
      Axios(
        "put",
        "api/users/" + this.$route.params.id + "/operations",
        operations,
        null,
        {
          "Content-Type": "text/uri-list"
        }
      ).then(response => {
        Materialize.toast(
          "Liaison user / operations sauvegardée",
          4000,
          "lime"
        );
        this.$router.push({
          name: "users"
        });
      });
      */
      console.groupEnd()
    },
  },
  created() {
    this.initialize()
  },
  watch: {
    /* 
    operationsGrouped() {
      this.recadrage();
    },
    operations() {
      this.recadrage();
    } */
  },
}
</script>

<style lang="scss" scoped></style>
