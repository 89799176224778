<template>
  <form class="card" @submit.prevent="submit" @reset="initialize">
    <section class="card-content">
      <span class="card-title">
        <template v-if="user">
          Edition de l'utilisateur : {{ user.email }}
          <span class="badge" v-if="admin">
            <i class="material-icons pointer" @click="$emit('new')">close</i>
          </span>
        </template>
        <template v-else> Nouvel utilisateur </template>
      </span>
      <div class="row">
        <article class="input-field col s6">
          <i class="material-icons prefix">account_circle</i>
          <input id="nom" type="text" class="validate" required v-model="nom" />
          <label for="nom" class="active">
            <font-awesome-icon icon="asterisk" class="red-text" />
            Nom
          </label>
        </article>

        <article class="input-field col s6">
          <input id="prenom" type="text" class="validate" required v-model="prenom" />
          <label for="prenom" class="active">
            <font-awesome-icon icon="asterisk" class="red-text" />
            Prenom
          </label>
        </article>
      </div>
      <div class="row">
        <article class="input-field col s12">
          <i class="material-icons prefix">mail</i>
          <input id="email" type="email" class="validate" required v-model="email" />
          <label for="email" class="active">
            <font-awesome-icon icon="asterisk" class="red-text" />
            E-mail
          </label>
        </article>
      </div>
      <div class="row">
        <article class="col s4 center-align" v-if="user">
          <input type="checkbox" id="changePassword" v-model="changePassword" :disabled="emailChanged" />
          <label for="changePassword">Changer le mot de passe</label>
        </article>

        <template v-if="changePassword || !user">
          <article class="input-field col" :class="user ? 's4' : 's6'">
            <i class="material-icons prefix">lock</i>
            <input id="password" type="password" class="validate" required v-model="password" />
            <label for="password" class="active">
              <font-awesome-icon icon="asterisk" class="red-text" />
              Mot de passe
            </label>
          </article>

          <article class="input-field col" :class="user ? 's4' : 's6'">
            <input
              id="passwordConfirmation"
              type="password"
              :class="{ invalid: invalidPassword }"
              required
              v-model="passwordConfirmation"
            />
            <label
              for="passwordConfirmation"
              class="active"
              data-error="Le mot de passe et sa confirmation ne correspondent pas."
              style="width: 100%"
            >
              <font-awesome-icon icon="asterisk" class="red-text" />
              Confirmation
            </label>
          </article>
        </template>
      </div>

      <article class="row valign-wrapper" v-if="admin">
        <div class="col s2">
          <i class="material-icons small prefix">local_offer</i>
          <label>
            <font-awesome-icon icon="asterisk" class="red-text" />
            Rôle
          </label>
        </div>
        <div class="col s10">
          <select class="browser-default" v-model="role" required>
            <option v-if="!superAdmin" value="CLIENT">Client</option>
            <option value="ADMIN">Admin</option>
            <option value="COORDINATEUR">Coordinateur</option>
            <option value="COORDINATEUR_AVANCE">Coordinateur Avancé</option>
          </select>
        </div>
      </article>
    </section>
    <section class="card-action">
      <div class="row">
        <article class="col s6 center-align">
          <button class="waves-effect waves-light btn lime" type="submit" id="addUserValid">
            <template v-if="user">
              <i class="material-icons left">edit</i>
              Editer
            </template>
            <template v-else>
              <i class="material-icons left">add</i>
              Ajouter
            </template>
          </button>
        </article>

        <article class="col s6 center-align">
          <button class="waves-effect waves-light btn deep-orange" type="reset">
            <i class="material-icons left">refresh</i>
            Annuler
          </button>
        </article>
      </div>
    </section>
  </form>
</template>

<script>
import Sha1 from "sha1"

export default {
  name: "user-form",
  props: ["user", "admin", "superAdmin"],
  data() {
    return {
      nom: null,
      prenom: null,
      email: null,
      role: null,
      changePassword: false,
      password: null,
      passwordConfirmation: null,
    }
  },
  computed: {
    invalidPassword() {
      if (this.password && this.passwordConfirmation) {
        return this.password.trim() !== this.passwordConfirmation.trim() || !this.passwordConfirmation
      }
      return true
    },
    emailChanged() {
      return this.user && this.user.email !== this.email
    },
  },
  methods: {
    initialize() {
      this.nom = null
      this.prenom = null
      this.email = null
      this.role = "CLIENT"
      this.password = null
      this.passwordConfirmation = null
      if (this.user) {
        this.nom = this.user.nom
        this.prenom = this.user.prenom
        this.email = this.user.email
        this.role = this.user.role
      }
    },
    submit() {
      const user = {
        nom: this.nom,
        prenom: this.prenom,
        email: this.email,
        role: this.role,
      }
      let valid = true

      if ((this.user && this.changePassword) || !this.user) {
        if (this.invalidPassword) {
          valid = false
        } else {
          user.password = Sha1(user.email + ":" + this.password.trim()).toUpperCase()
        }
      }
      if (!this.user && user.password) {
        user.oldPassword = this.password.trim()
      }

      if (valid) {
        this.$emit("submit", user)
      }
    },
  },
  watch: {
    user() {
      this.initialize()
    },
    emailChanged() {
      if (this.emailChanged) {
        this.changePassword = true
      }
    },
  },
  created() {
    this.initialize()
  },
}
</script>

<style lang="scss" scoped></style>
