<template>
  <div class="mcma-tree-select">
    <a-tree-select
      :dropdownStyle="{ maxHeight: '400px', overflow: 'auto' }"
      placeholder="Ajouter une variable"
      dropdownClassName="mcma-tree-select-dropdown"
      @select="selectChange"
      v-model="treeSelectValue"
      :disabled="disabled"
    >
      <a-tree-select-node v-for="variable in variables" :key="variable.key" :title="variable.title" :selectable="false">
        <a-tree-select-node v-for="child in variable.children" :key="child.key" :value="`${variable.key}.${child.key}`">
          <div slot="title" class="value-block">
            <span class="value">
              {{ child.title }}
            </span>
            <span class="example"> ex: {{ getExample(variable.key, child.key) }} </span>
          </div>
        </a-tree-select-node>
      </a-tree-select-node>
    </a-tree-select>
  </div>
</template>

<script>
import { mapState } from "vuex"

export default {
  name: "McmaSelectVariables",
  props: {
    variables: Array,
    disabled: Boolean,
  },
  data() {
    return {
      treeSelectValue: undefined,
    }
  },
  computed: {
    ...mapState(["user", "membre"]),
    getExample() {
      return (parent, child) => {
        if (this[parent] && this[parent][child]) {
          return this.$getValueByKey(this[parent][child])
        }
        return "aucun exemple"
      }
    },
  },
  watch: {
    treeSelectValue() {
      this.treeSelectValue = undefined
    },
  },
  methods: {
    selectChange(value) {
      if (value) {
        this.$emit("addVariable", `{{${value}}}`)
      }
    },
  },
}
</script>

<style lang="scss">
@import "./src/assets/scss/variables";
@import "./src/assets/scss/functions";

.mcma-tree-select {
  .ant-select {
    min-width: 400px;
  }
}

.mcma-tree-select-dropdown {
  .value-block {
    .value {
      color: var(--primary-color, $primary-color);
    }
    .example {
      font-size: 12px;
      color: $disabled-color;
      font-style: italic;
    }
  }
}
</style>
