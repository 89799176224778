<template>
    <a-modal v-model="visible" :maskClosable="false" :width="1000">
        <mcma-card title="PREREQUIS A L'IMPORT EN MASSE" class="prerequis_import">
            <font size="3">
                <h6>Comment Importer mes données?</h6>
                * Télécharger le fichier template<br />
                <mcma-button name="Cliquez ici pour télécharger le template" color="blue" leftIcon="download"
                    :isFaIcon="true" @click="downloadTemplate" />
                * Compléter les données<br />
                * Importer le fichier qui a été exporté<br /><br />
            </font>
        </mcma-card>
        <template slot="footer">
            <mcma-spin-box :spinning="loading">
                <mcma-card>
                    <a-row type="flex" style=""> </a-row>
                    <a-row type="flex">
                        <a-col :span="12">
                            <input class="file_import" type="file" ref="fileInput" @change="processFile" />
                        </a-col>
                        <a-col :span="12">
                            <mcma-button name="Importer le fichier séléctionné" color="blue" leftIcon="upload"
                                :isFaIcon="true" @click="importUsers" />
                        </a-col>
                    </a-row>
                </mcma-card>

                <div class="align-row-end mtb12">
                    <mcma-button name="Fermer" color="blue" class="ok mr7" @click="$emit('close')" />
                </div>
            </mcma-spin-box>
        </template>
        <a class="ninja" ref="downloadLink" />
    </a-modal>
</template>
<script>
import McmaButton from "@/components/mcma/common/McmaButton"
import Materialize from "materialize-css"
import Axios from "@/util/Axios"
import McmaCard from "@/components/mcma/common/McmaCard"
import McmaSpinBox from "@/components/mcma/common/McmaSpinBox"
import { mapState } from "vuex"
import _ from "lodash"
export default {
    name: "ImportUsersMasseModal",
    props: {
        visible: Boolean,
    },
    components: {
        McmaButton,
        McmaCard,
        McmaSpinBox,
    },
    data() {
        return {
            loading: false,
            file: null,
        }
    },
    computed: {
        ...mapState(["membre", "operation"]),
    },
    methods: {
        downloadTemplate() {
            console.log("> downloadFileUsersImport@usersManager")
            var date = new Date()
            var month = "" + (date.getMonth() + 1)
            var day = "" + date.getDate()
            if (month.length < 2) month = "0" + month
            if (day.length < 2) day = "0" + day
            var fileName =
                "importUsers_" +
                this.operation.name +
                "_" +
                date.getFullYear() +
                month +
                day +
                "_" +
                date.getHours() +
                date.getMinutes() +
                date.getSeconds() +
                ".xlsx"
            fileName = fileName.replace(" ", "_")
            Axios("get", "users/importPatron?operation=" + this.operation.id + "&fileName=" + fileName, null, {
                responseType: "blob",
            })
                .then((response) => {
                    console.log("> response :  %O", response)
                    const link = this.$refs.downloadLink
                    link.href = window.URL.createObjectURL(new Blob([response.data]))
                    link.setAttribute("download", fileName)
                    link.click()
                })
                .catch((error) => {
                    console.error(error)
                    Materialize.toast("Erreur lors du téléchargement", 4000, "amber white-text")
                })
        },
        importUsers() {
            //TODO=========> Post to users/importUsers file mediapart
            if (this.checkFileFormat) {
                const formData = new FormData()
                Materialize.toast("Chargement du fichier", 3000, "lime")
                formData.append("operation", this.operation.id)
                formData.append("file", this.file)
                this.loading = true
                Axios("post", "/users/importUsers", formData, null, {
                    "Content-Type": "multipart/form-data",
                })
                    .then((reponse) => {
                        Materialize.toast(
                            "Import des données terminées !",
                            2000,
                            "light-green darken-2"
                        )

                        this.close()
                        this.$emit("initialize")
                    })
                    .catch((reponse) => {
                        Materialize.toast("Erreur lors du dépot du fichier", 4000, "amber white-text")
                        this.loading = false
                    })
            }
            this.loading = false
        },

        close() {
            this.$emit("close")
        },
        checkFileFormat() {
            const sizeMax = process.env.VUE_APP_UPLOAD_SIZE * 1024 * 1024
            const file = this.file
            let format = true
            if (file.size > sizeMax) {
                Materialize.toast(
                    "Le fichier dépasse la taille autorisé qui est de " + process.env.VUE_APP_UPLOAD_SIZE + "Mo.",
                    4000,
                    "amber white-text"
                )
                format = false
            }
            return format
        },
        processFile(event) {
            console.group("importFile@Membre")
            this.file = event.target.files[0]
            console.log("Fichier ajouté : ", this.file.name)
            console.groupEnd()
        },
    },
}
</script>
<style lang="scss">
.prerequis_import {
    background-color: #f0f0f0;
}
</style>