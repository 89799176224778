<template>
  <a-form :form="form">
    <mcma-card title="Entitée facturée" icon="administration" class="mcma-card-in-tab" :inline="true">
      <a-row type="flex" :gutter="12">
        <mcma-field
          type="input"
          :form="form"
          :wasSubmit="wasSubmit"
          fieldName="nom"
          getFlow="name::groupements"
          getPanel="name::groupements"
          getField="type::groupementName"
          placeholder="Mon périmètre n°5"
          :defaultValue="groupement.nom"
          :required="true"
          @change="updateGroupementEditing(form.getFieldsValue())"
        />
        <mcma-field
          type="input"
          :form="form"
          :wasSubmit="wasSubmit"
          fieldName="budget"
          getFlow="name::groupements"
          getPanel="name::groupements"
          getField="type::groupementBudget"
          placeholder="Mon budget n°2"
          :defaultValue="groupement.budget"
          @change="updateGroupementEditing(form.getFieldsValue())"
        />
        <mcma-field
          type="input"
          :form="form"
          :wasSubmit="wasSubmit"
          fieldName="raisonSocialPayeur"
          :defaultValue="groupement.raisonSocialPayeur"
          getFlow="name::groupements"
          getPanel="name::groupements"
          getField="type::raisonSocialePayeur"
          placeholder="Ma raison sociale Payeur"
          :required="true"
          :requiredMessage="`Merci de renseigner la raison sociale du gestionnaire de la facture`"
          @change="updateGroupementEditing(form.getFieldsValue())"
        />
        <mcma-field
          type="input"
          :form="form"
          :wasSubmit="wasSubmit"
          fieldName="voieNumber"
          :defaultValue="groupement.numeroVoie"
          getFlow="name::groupements"
          getPanel="name::groupements"
          getField="type::voieNumber"
          placeholder="Numéro voie"
          :required="true"
          :requiredMessage="`Merci de renseigner le numéro de la voie`"
          @change="updateGroupementEditing(form.getFieldsValue())"
        />
        <mcma-field
          type="input"
          :form="form"
          :wasSubmit="wasSubmit"
          fieldName="voieName"
          :defaultValue="groupement.nomVoie"
          getFlow="name::groupements"
          getPanel="name::groupements"
          getField="type::voieName"
          placeholder="Nom voie"
          :required="true"
          :requiredMessage="`Merci de renseigner le nom de la voie`"
          @change="updateGroupementEditing(form.getFieldsValue())"
        />
        <mcma-field
          type="input"
          :form="form"
          :wasSubmit="wasSubmit"
          fieldName="codePostal"
          :defaultValue="groupement.codePostal"
          getFlow="name::groupements"
          getPanel="name::groupements"
          getField="type::codePostal"
          placeholder="92000"
          :required="true"
          rule="isZipCode"
          @change="updateGroupementEditing(form.getFieldsValue())"
        />
        <mcma-field
          type="input"
          :form="form"
          :wasSubmit="wasSubmit"
          fieldName="commune"
          :defaultValue="groupement.commune"
          getFlow="name::groupements"
          getPanel="name::groupements"
          getField="type::town"
          placeholder="PARIS"
          :required="true"
          :requiredMessage="`Merci de renseigner la commune`"
          :uppercase="true"
          @change="updateGroupementEditing(form.getFieldsValue())"
        />
      </a-row>
    </mcma-card>
  </a-form>
</template>

<script>
import { mapState } from "vuex"
import moment from "moment"
import Axios from "@/util/Axios"
import { pick } from "@/util/Helpers"
import McmaCard from "@/components/mcma/common/McmaCard"
import McmaField from "@/components/mcma/common/McmaField"
import McmaButton from "@/components/mcma/common/McmaButton"
import McmaSpinBox from "@/components/mcma/common/McmaSpinBox"
import Constants from "@/util/Constants"

export default {
  name: "GroupementsEdit",
  data() {
    return {
      form: this.$form.createForm(this),
      wasSubmit: false,
    }
  },
  components: {
    McmaCard,
    McmaField,
    McmaButton,
    McmaSpinBox,
  },
  props: {
    groupement: Object,
  },
  computed: {
    ...mapState(["membre", "operation"]),
  },
  mounted() {
    this.initialize()
  },
  methods: {
    moment,
    updateGroupementEditing(groupement) {
      this.$root.$emit("ROOT_updateGroupementEditing", groupement)
    },
    initialize() {
      let editGroupement = pick(this.groupement, [
        "nom",
        "budget",
        "raisonSocialPayeur",
        "adresseFacturation",
        "codePostal",
        "commune",
      ])
      editGroupement = this.$mergeObject(this.form.getFieldsValue(), editGroupement)
      this.$nextTick(() => {
        this.form.setFieldsValue(editGroupement)
      })
    },
    save() {
      this.$emit("save")
    },
    submit(interlocuteurId, interlocuteurFieldsValue) {
      console.log(
        `%c prepare saving Groupement`,
        "background:purple ; padding: 5px 7px 4px 0px; border-radius: 3px;  color: #FFFFFF"
      )
      this.form.validateFields((errors, fieldsValue) => {
        console.log(
          `%c fieldsValue`,
          "background:#1e4d77 ; padding: 5px 7px 4px 0px; border-radius: 3px;  color: #FFFFFF",
          fieldsValue
        )
        this.wasSubmit = true
        if (errors) {
          this.$notification.error({
            message: "Vous avez des erreurs",
            description: `Veuillez remplir tous les champs comprenant des erreurs`,
          })
          this.$scrollToTop()
        } else {
          if (!interlocuteurId) {
            this.$notification.error({
              message: "Aucun interlocuteur n'existe",
              description: `Vous devez préalablement selectionner ou créer un interlocuteur payeur pour ce périmètre de facturation.`,
            })
          } else {
            console.log("have interlocuteur selected")
            let tmpState = Constants.STATUS.TO_COMPLETE.value
            if (this.$route.params.action !== "duplique") {
              tmpState = Constants.STATUS.TO_CERTIFY.value
            }
            console.log("interlocuteurFieldsValue : %O", interlocuteurFieldsValue)
            const groupement = {
              membre: process.env.VUE_APP_API_URL + "/api/membres/" + this.membre.id,
              nom: fieldsValue.nom,
              budget: fieldsValue.budget,
              raisonSocialPayeur: fieldsValue.raisonSocialPayeur,
              adresseFacturation: fieldsValue.adresseFacturation,
              codePostal: fieldsValue.codePostal,
              commune: fieldsValue.commune,
              nomVoie: fieldsValue.voieName,
              numeroVoie: fieldsValue.voieNumber,
              interlocuteurPayeurId: interlocuteurId,
              interlocuteurPayeurNom: interlocuteurFieldsValue.interlocuteurPayeurNom,
              interlocuteurPayeurPrenom: interlocuteurFieldsValue.interlocuteurPayeurPrenom,
              telephonePayeur: interlocuteurFieldsValue.telephonePayeur,
              mobilePayeur: interlocuteurFieldsValue.mobilePayeur,
              emailPayeur: interlocuteurFieldsValue.emailPayeur,
              siret: interlocuteurFieldsValue.siret,
              iban: interlocuteurFieldsValue.iban,
              moyenPaiement: interlocuteurFieldsValue.moyenPaiement ? interlocuteurFieldsValue.moyenPaiement : "A_PRECISER" ,
              delaiPaiement: interlocuteurFieldsValue.delaiPaiement,
              codeIdentificationIndividuelle: interlocuteurFieldsValue.codeIdentificationIndividuelle,
              numConventionTripartite: interlocuteurFieldsValue.numConventionTripartite,
              engagementJuridique: interlocuteurFieldsValue.engagementJuridique,
              codeServiceExecutant: interlocuteurFieldsValue.codeServiceExecutant,
              state: tmpState, // interlocuteurFieldsValue.state,
              papier: interlocuteurFieldsValue.papier,
              sepa: interlocuteurFieldsValue.sepaCheck,
              operationId: this.operation.id,
              rythmeFacturation: interlocuteurFieldsValue.rythmeFacturation,
              optionExcel: interlocuteurFieldsValue.optionExcel,
              dematXml: interlocuteurFieldsValue.dematXml,
            }
            console.log(">> Modif/Add groupement : %O", groupement)

            if (!groupement.nom) {
              this.$notification.error({
                message: "Erreur lors de l'enregistement du Groupement",
                description: "Désolé nous n'avons pas pu réccurpérer les valeurs saisies dans le formulaire...",
              })
            } else {
              this.$emit("loading", true)
              let request = null
              if (this.$route.params.action !== "duplique" && this.groupement.id) {
                // groupement.state = Constants.STATUS.TO_CERTIFY.value
                request = Axios("patch", "api/groupements/" + this.groupement.id, groupement)
                console.log(">> Patch groupement : %O", groupement)
              } else {
                // groupement.state = Constants.STATUS.TO_COMPLETE.value
                request = Axios("post", "api/groupements", groupement)
                console.log(">> Post groupement : %O", groupement)
              }
              request
                .then(() => {
                  if (this.$route.params.action !== "duplique") {
                    this.$notification.success({
                      message: "Périmètre sauvegardé",
                      description: "Le périmètre de facturation a bien été sauvegardé.",
                    })
                  } else {
                    this.$notification.success({
                      message: "Périmètre dupliqué",
                      description: "Le périmètre de facturation a bien été dupliqué.",
                    })
                  }

                  if (this.sepaCheck && this.sepaFile) {
                    const formData = new FormData()
                    formData.append("file", this.sepaFile)
                    formData.append("membreId", this.membre.id)
                    formData.append("groupement", groupement.nom)
                    Axios("post", "/file/sepa/membre", formData, null, {
                      "Content-Type": "multipart/form-data",
                    })
                      .then((reponse) => {
                        this.$notification.success({
                          message: "SEPA signé !",
                          description: "Le SEPA à bien été signé.",
                        })
                      })
                      .catch((error) => {
                        console.error(error)
                        this.$notification.error({
                          message: "Erreur SEPA",
                          description: "Erreur lors de la signature du SEPA.",
                        })
                      })
                  } else if (!this.sepaCheck && this.groupement.sepa) {
                    Axios("delete", "file/sepa?membreId=" + this.membre.id + "&groupement=" + this.groupement.nom)
                      .then((reponse) => {
                        this.$notification.success({
                          message: "SEPA supprimé !",
                          description: "Le SEPA à bien été supprimé.",
                        })
                      })
                      .catch((error) => {
                        console.error(error)
                        this.$notification.error({
                          message: "Erreur SEPA !",
                          description: "Erreur lors de la suppression du SEPA.",
                        })
                      })
                  }

                  // this.$emit("save", response.data.id);
                  this.$emit("loading", false)
                  this.$router.push({
                    name: "groupements",
                  })
                })
                .catch((error) => {
                  console.error(error)
                  this.$notification.error({
                    message: "Erreur serveur",
                    description: "Une erreur server est survenue, veuillez contacter un administrateur.",
                  })
                })
            }
          }
        }
      })
    },
  },
}
</script>

<style lang="scss"></style>
