<template>
  <a-form :form="form">
    <a-row type="flex" :gutter="12" class="mt-0 mb-0">
      <mcma-field
        :colSpan="12"
        type="radio"
        :form="form"
        fieldName="typeSite"
        :wasSubmit="wasSubmit"
        getFlow="name::sites"
        getPanel="name::Données techniques (eau)"
        getField="type::typeSite"
        :defaultValue="site.typeSite"
        extraLabel="Sélectionner le type de site qui correspond"
      />
    </a-row>

    <a-row type="flex" :gutter="12" class="mtb4">
      <mcma-field
        :colSpan="8"
        type="input"
        :form="form"
        :wasSubmit="wasSubmit"
        :defaultValue="site.matriculeCompteur"
        fieldName="matriculeCompteur"
        getFlow="name::sites"
        getPanel="name::Données techniques (eau)"
        getField="type::matriculeCompteurEau"
        extraLabel="Référence compteur (matricule)"
      />
    </a-row>

    <a-row type="flex" :gutter="12" class="mt-0 mb-0">
      <a-col :span="24">
        <mcma-button
          name="Valider les informations"
          class="ml12"
          color="success"
          leftIcon="save"
          :isFaIcon="true"
          @click="submit"
        />
      </a-col>
    </a-row>
  </a-form>
</template>

<script>
import Axios from "@/util/Axios"
import McmaButton from "@/components/mcma/common/McmaButton"
import McmaField from "@/components/mcma/common/McmaField"

export default {
  name: "Etape_2_Eau",
  props: ["site"],
  components: {
    McmaField,
    McmaButton,
  },
  data() {
    return {
      form: this.$form.createForm(this),
      wasSubmit: false,
    }
  },
  computed: {},
  methods: {
    submit() {
      this.form.validateFields((errors, fieldsValue) => {
        if (errors) {
          this.$notification.warning({
            message: "Formulaire invalide",
            description: `Veuillez-vous assurez de remplir tous les champs requis.`,
          })
        } else {
          this.process(fieldsValue)
        }
      })
    },
    process(fieldsValue) {
      Axios("patch", "api/sites/" + this.site.id, {
        matriculeCompteur: fieldsValue.matriculeCompteur,
        typeSite: fieldsValue.typeSite,
        ongletParametre: true,
      })
        .then((response) => {
          this.$emit("save", response.data.id)
          this.$emit("update", response.data)
          this.$notification.success({
            message: "Paramètres sauvegardées !",
            description: `Les paramètres ont bien été sauvegardées`,
          })
        })
        .catch((error) => {
          console.error(error)
          this.$notification.error({
            message: "Erreur serveur",
            description: "Une erreur server est survenue, veuillez contacter un administrateur.",
          })
        })
    },
  },
}
</script>
