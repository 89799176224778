<template>
  <div class="mcma-spacer"></div>
</template>

<script>
export default {
  name: "mcma-spacer",
}
</script>

<style lang="scss">
@import "./src/assets/scss/variables";
@import "./src/assets/scss/functions";

.mcma-spacer {
  padding-top: var(--padding, $padding);
  width: 100%;
}
</style>
