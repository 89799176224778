<template>
  <div class="mcma-button" :class="{ 'has-value': getValue }">
    <a-dropdown v-if="hasSlot()" :trigger="onClick ? ['click'] : ['hover']">
      <a-menu slot="overlay">
        <slot></slot>
      </a-menu>
      <a-button
        :type="color"
        :size="size"
        :shape="shape"
        :loading="loading"
        :disabled="disabled"
        @click.prevent="action"
        :class="'button-bb button-bb-' + color"
        class="mcma-button-content"
      >
        <a-icon v-if="leftIcon && !isFaIcon && !loading" class="leftIcon" :type="leftIcon" />
        <font-awesome-icon
          v-else-if="leftIcon && isFaIcon && !loading"
          :icon="[regularIcon ? 'far' : 'fas', leftIcon]"
          :class="getFaIconColor"
        />
        <img v-else-if="leftImage && !loading" class="leftImage" :src="getLeftImage" :alt="leftImage" />
        <div v-if="leftImage" class="triangle-separator"></div>
        <span class="name" :class="{ uppercase: uppercase }">
          {{ name }}
        </span>
        <a-icon v-if="isDropdown" class="rightIcon" type="down" />
        <a-icon v-else-if="rightIcon && !isFaIcon" class="rightIcon" :type="rightIcon" />
        <font-awesome-icon
          v-else-if="rightIcon && isFaIcon"
          :icon="[regularIcon ? 'far' : 'fas', rightIcon]"
          :class="getFaIconColor"
        />
        <img v-else-if="rightImage" class="rightImage" :src="getRightImage" :alt="rightImage" />
      </a-button>
    </a-dropdown>
    <a-button
      v-else
      :type="color"
      :size="size"
      :shape="shape"
      :loading="loading"
      :disabled="disabled"
      @click.prevent="action"
      :class="[
        {
          'without-icon': !leftIcon && !leftImage && !rightIcon && !rightImage,
          'has-leftImage': leftImage,
          'leftIcon-with-name': leftIcon && name,
          'has-faIcon-alone': leftIcon && isFaIcon && !name,
          'button-bb': bbButton && !getValue,
          fullwidth: fullwidth,
        },
        `button-bb-${color}`,
      ]"
      class="mcma-button-content simple-button"
    >
      <a-icon v-if="leftIcon && !isFaIcon && !loading" class="leftIcon" :type="leftIcon" />
      <font-awesome-icon
        v-else-if="leftIcon && isFaIcon && !loading"
        :icon="[regularIcon ? 'far' : 'fas', leftIcon]"
        :class="getFaIconColor"
      />
      <img v-else-if="leftImage" class="leftImage" :src="getLeftImage" :alt="leftImage" />
      <span class="name" :class="{ uppercase: uppercase }">
        {{ name }}
      </span>
      <a-icon v-if="rightIcon && !isFaIcon" class="rightIcon" :type="rightIcon" />
      <font-awesome-icon
        v-else-if="rightIcon && isFaIcon"
        :icon="[regularIcon ? 'far' : 'fas', rightIcon]"
        :class="getFaIconColor"
      />
      <img v-else-if="rightImage" class="rightImage" :src="getRightImage" :alt="rightImage" />
    </a-button>
    <div v-if="getValue" class="value-block" ref="valueBlock" :class="color + '-color'">
      <span class="value">{{ getValue }}</span>
    </div>
  </div>
</template>

<script>
export default {
  name: "McmaButton",
  props: {
    color: {
      type: String,
      default: "primary",
    },
    shape: String,
    size: String,
    name: String,
    leftImage: String,
    rightImage: String,
    leftIcon: String,
    rightIcon: String,
    isDropdown: Boolean,
    uppercase: Boolean,
    regularIcon: Boolean,
    disabled: Boolean,
    loading: Boolean,
    isFaIcon: Boolean,
    fullwidth: Boolean,
    debounce: {
      type: Number,
      default: 500,
    },
    onClick: Boolean,
    value: [String, Number, Boolean],
    bbButton: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      debounceDisable: false,
      valueBlockWidth: 0,
    }
  },
  computed: {
    hasSlot() {
      return (name = "default") => {
        return !!this.$slots[name] || !!this.$scopedSlots[name]
      }
    },
    getValue() {
      return this.value !== false ? this.value.toString() : null
    },
    getLeftImage() {
      return this.leftImage ? require(`@/assets/images/${this.leftImage}.svg`) : null
    },
    getRightImage() {
      return this.rightImage ? require(`@/assets/images/${this.rightImage}.svg`) : null
    },
    getFaIconColor() {
      // if(this.disabled){
      //   return "disable-icon-color";
      // }
      return "white-color"
    },
  },
  mounted() {
    if (this.getValue) {
      this.valueBlockWidth = this.$refs.valueBlock.clientWidth + 7
    }
  },
  methods: {
    action() {
      if (this.debounceDisable) return
      this.debounceDisable = true
      this.$emit("click")

      setTimeout(() => {
        this.debounceDisable = false
      }, this.debounce)
    },
  },
}
</script>

<style lang="scss">
@import "./src/assets/scss/variables";
@import "./src/assets/scss/functions";

.mcma-button {
  /*overflow: hidden;*/
  // box-shadow: $strong-box-shadow;
  border-radius: 4px;
  &.has-value {
    position: relative;
    display: flex;
    flex-flow: row nowrap;
    .mcma-button-content {
      position: relative;
      z-index: 1;
    }
  }
  .mcma-button-content {
    overflow: hidden;
    box-sizing: content-box;
    padding: 0 10px 0 3px;
    border-top: 0;
    border-left: 0;
    border-right: 0;
    .name {
      font-weight: 900;
    }
    &.simple-button {
      padding: 3px 20px 0 20px;
      display: flex;
      flex-flow: row nowrap;
      justify-content: center;
      align-items: center;
      &.fullwidth {
        width: calc(100% - 40px);
      }
      &.has-leftImage {
        padding: 0 15px 2px 5px;
        .leftImage {
          margin-right: 0;
          position: relative;
          top: 2px;
          height: 36px;
        }
      }
      &.has-faIcon-alone {
        padding: 3px 13px;
        .svg-inline--fa {
          font-size: 15px;
          width: auto;
          position: relative;
          top: 1px;
        }
      }
      &.leftIcon-with-name {
        .name {
          top: 0;
          margin-left: 10px;
        }
      }
      &.without-icon {
        padding: 3px 20px;
      }
    }
    &.ant-btn {
      /*padding: 0 8px;*/
    }
    .triangle-separator {
      width: 30px;
      height: 100%;
      position: absolute;
      transform: rotate(45deg);
      top: 1px;
      left: 13px;
      border-right: 1px solid rgba($white, 0.2);
      border-top: 1px solid rgba($white, 0.2);
    }
    .name {
      line-height: 33px;
      position: relative;
      top: 2px;
    }
    .leftImage {
      margin-right: 30px;
      margin-bottom: 2px;
      height: 100%;
      transform: scale(0.5, 0.5);
    }
    .rightImage {
    }
    .ant-dropdown-trigger {
      > .leftIcon {
      }
    }
    .leftIcon {
    }
    .rightIcon {
      &.anticon-down {
        position: relative;
        top: 1px;
      }
    }
  }
  .value-block {
    height: 36px;
    top: 0;
    right: 3px;
    z-index: 0;
    background: $grey-light;
    display: flex;
    position: relative;
    padding: 0 15px 0 18px;
    font-weight: 900;
    box-sizing: content-box;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    .value {
      margin: auto;
    }
  }
}

.inline-buttons {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;
  .mcma-button {
    margin-right: 20px;
    &:last-child {
      margin-right: 0;
    }
  }
}
</style>
