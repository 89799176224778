<template>
  <section>
    <div class="container">
      <mcma-spin-box :spinning="loading">
        <a-row type="flex" :gutter="12">
          <a-col :span="24">
            <explain />
          </a-col>
        </a-row>
        <a-row v-if="membre && operation && !loading" type="flex" :gutter="12">
          <a-col :span="18">
            <actual-progress />
          </a-col>
          <a-col :span="6">
            <general-info @completed="displayGeneralInfo" />
          </a-col>
        </a-row>
        <a-row type="flex" :gutter="12">
          <a-col :span="24">
            <mcma-divider />
          </a-col>
        </a-row>
        <a-row v-if="membre && operation && !loading" type="flex" :gutter="12">
          <a-col :span="24" v-if="!superUnlock && generalInfoNeeded">
            <general-info-message-box />
          </a-col>
          <a-col :span="24" v-else>
            <mcma-flows v-if="membre" />
          </a-col>
        </a-row>
      </mcma-spin-box>
    </div>
    <mcma-konami-unlock @unlock="setSuperUnlock" />
  </section>
</template>

<script>
import Explain from "@/components/mcma/steps/components/Explain"
import ActualProgress from "@/components/mcma/steps/components/ActualProgress"
import GeneralInfo from "@/components/mcma/steps/components/GeneralInfo"
import GeneralInfoMessageBox from "@/components/mcma/steps/components/GeneralInfoMessageBox"
import McmaDivider from "@/components/mcma/common/McmaDivider"
import McmaFlows from "@/components/mcma/steps/components/Flows"
import McmaSpinBox from "@/components/mcma/common/McmaSpinBox"
import McmaKonamiUnlock from "@/components/mcma/common/McmaKonamiUnlock"
import { mapState, mapMutations, mapActions } from "vuex"
import Axios from "@/util/Axios"

export default {
  name: "steps",
  components: {
    Explain,
    ActualProgress,
    GeneralInfo,
    GeneralInfoMessageBox,
    McmaSpinBox,
    McmaDivider,
    McmaFlows,
    McmaKonamiUnlock,
  },
  data() {
    return {
      groupements: null,
      generalInfoNeeded: true,
      loading: true,
      superUnlock: false,
    }
  },
  computed: {
    ...mapState(["user", "membre", "operation", "confOperation"]),
  },
  methods: {
    ...mapMutations(["setBreadcrumbAdd", "setOperation", "setState"]),
    ...mapActions(["requestMembresStats", "requestMembreStats", "requestMembreByMembreId"]),
    setSuperUnlock() {
      if (this.user.role === "SUPER_ADMIN" || this.user.role === "ADMIN" || this.user.role === "COORDINATEUR_AVANCE") {
        this.superUnlock = true
        this.$scrollToBottom()
        this.$notification.success({
          message: "Étapes débloquées",
          description: `Les étapes ont été débloqués !`,
        })
      }
    },
    displayGeneralInfo(needed) {
      console.log("> displayGeneralInfo : %O", needed)
      this.generalInfoNeeded = needed
    },
    setNewOperation() {
      console.group("setNewOperation")
      if (this.membre && this.membre.id) {
        Axios("get", "api/membres/" + this.membre.id + "/operation").then((response) => {
          // TODO gérer le cas ou un membre est dans plusieurs oprération
          console.log("> response.data : %O", response.data._embedded.operations[0])
          this.setOperation(response.data._embedded.operations[0])
        })
      }
      console.groupEnd()
    },
    async initialise() {
      console.group("initialise@Step")
      console.log("membre : %O", this.membre)
      console.log("operation : %O", this.operation)
      console.log("this.$router : %O", this.$router)
      console.log("this.$router.history.current.params.membreId : %O", this.$router.history.current.params.membreId)
      this.loading = true
      if (!this.membre) {
        try {
          await this.requestMembreByMembreId(this.$router.history.current.params.membreId)
        } catch (error) {
          console.log(error)
        }
      } else {
        // dans utilisateur CLIENT qui arrive directement sur le STEP
        try {
          await this.requestMembreStats(this.$router.history.current.params.membreId)
        } catch (error) {
          console.log(error)
        }
      }
      this.setBreadcrumbAdd({
        name: this.membre.nom || this.user.email,
        action: () => {
          this.$router.push({
            name: "steps",
            params: {
              operationId: this.operation.id,
              membreId: this.membre.id,
            },
          })
        },
      })
      if (!this.membre || this.membre.interlocuteurFacture === null || !this.membre.interlocuteurFacture) {
        let final = { ...this.membre }
        final.interlocuteurFacture = {
          fonction: null,
          civility: null,
          prenom: null,
          nom: null,
          mail: null,
          telephone: null,
          mobile: null,
        }
        this.setState({
          stateName: "membre",
          value: final,
        })
      }
      if (!this.membre || this.membre.interlocuteurTechnique === null || !this.membre.interlocuteurTechnique) {
        let final = { ...this.membre }
        final.interlocuteurTechnique = {
          fonction: null,
          civility: null,
          prenom: null,
          nom: null,
          mail: null,
          telephone: null,
          mobile: null,
        }
        this.setState({
          stateName: "membre",
          value: final,
        })
      }
      this.updateAllColor(this.operation.colors)
      this.loading = false
      console.groupEnd()
    },
    updateAllColor(colors) {
      const root = document.documentElement
      Object.keys(colors).forEach(async (property) => {
        root.style.setProperty(`${property}`, colors[property])
        root.style.setProperty(`${property}-h`, await this.$hexToHSL(colors[property], "hue"))
        root.style.setProperty(`${property}-s`, await this.$hexToHSL(colors[property], "saturation"))
        root.style.setProperty(`${property}-l`, await this.$hexToHSL(colors[property], "lightness"))
      })
    },
  },
  watch: {
    membre: {
      handler() {},
      deep: true,
    },
  },
  created() {
    this.initialise()
  },
}
</script>
