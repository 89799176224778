import _ from "lodash"
import { PS, Ftas } from "./Puissances"

export const RV_RESULT = {
  OK: "OK",
  KO: "KO",
  DEROGABLE: "DEROGABLE",
  NOT_APPLICABLE: "NOT_APPLICABLE",
}

export const RV_TURPE = {
  RVT01(eclairagePublique, segment, fta, ps) {
    if (!["C2", "C3", "C4"].includes(segment.name)) {
      return {
        state: RV_RESULT.NOT_APPLICABLE,
      }
    }
    var before = null
    for (const key of PS) {
      const value = ps[key]
      if (value !== null) {
        if (before !== null && _.toNumber(value) < _.toNumber(before)) {
          return {
            state: RV_RESULT.KO,
            message: "Les puissances ne peuvent pas être décroissantes d'un poste sur le suivant",
          }
        }
        before = value
      }
    }
    return {
      state: RV_RESULT.OK,
    }
  },
  RVT02(eclairagePublique, segment, fta, ps) {
    if (!["C4"].includes(segment.name)) {
      return {
        state: RV_RESULT.NOT_APPLICABLE,
      }
    }
    if (!ps.HCE || _.toNumber(ps.HCE) <= 36) {
      return {
        state: RV_RESULT.DEROGABLE,
        message: "La PS du poste HCE ne doit pas être inférieure ou égale à 36 KVA",
      }
    }
    return {
      state: RV_RESULT.OK,
    }
  },
  RVT03(eclairagePublique, segment, fta, ps) {
    for (const key in ps) {
      if (_.toNumber(ps[key]) === 0 && (!["C2", "C3", "C4"].includes(segment.name) || key === "HCE")) {
        return {
          state: RV_RESULT.DEROGABLE,
          message: "Les PS ne doivent pas être égales à 0",
        }
      }
    }
    return {
      state: RV_RESULT.OK,
    }
  },
  RVT04(eclairagePublique, segment, fta, ps) {
    if (!["C5"].includes(segment.name)) {
      return {
        state: RV_RESULT.NOT_APPLICABLE,
      }
    }
    for (const key in ps) {
      if (_.toNumber(ps[key]) > 36) {
        return {
          state: RV_RESULT.DEROGABLE,
          message: "La PS ne peut pas être supérieur à 36 KVA",
        }
      }
    }
    return {
      state: RV_RESULT.OK,
    }
  },
  RVT05(eclairagePublique, segment, fta, ps) {
    if (!["C4"].includes(segment.name)) {
      return {
        state: RV_RESULT.NOT_APPLICABLE,
      }
    }
    for (const key in ps) {
      if (_.toNumber(ps[key]) > 250) {
        return {
          state: RV_RESULT.DEROGABLE,
          message: "La PS ne peut pas être supérieure à 250 KVA",
        }
      }
    }
    return {
      state: RV_RESULT.OK,
    }
  },
  RVT06(eclairagePublique, segment, fta, ps) {
    if (!["C5"].includes(segment.name) || eclairagePublique) {
      return {
        state: RV_RESULT.NOT_APPLICABLE,
      }
    }
    for (const key in ps) {
      if (_.toNumber(ps[key]) < 3) {
        return {
          state: RV_RESULT.DEROGABLE,
          message: "La PS ne peut pas être inférieure à 3 KVA",
        }
      }
    }
    return {
      state: RV_RESULT.OK,
    }
  },
  RVT07(eclairagePublique, segment, fta, ps) {
    if (!["C5"].includes(segment.name) || !eclairagePublique) {
      return {
        state: RV_RESULT.NOT_APPLICABLE,
      }
    }
    for (const key in ps) {
      if (_.toNumber(ps[key]) < 0.1) {
        return {
          state: RV_RESULT.DEROGABLE,
          message: "La PS ne peut pas être inférieure à 0,1 KVA",
        }
      }
    }
    return {
      state: RV_RESULT.OK,
    }
  },
}

export const RA_RESULT = {
  NOT_APPLICABLE: "NOT_APPLICABLE",
  ADJUSTED: "ADJUSTED",
  RAS: "RAS",
}

export const RA_TURPE = {
  RAT01(eclairagePublique, segment, fta, ps) {
    if (!["C5"].includes(segment.name) || eclairagePublique) {
      return {
        state: RA_RESULT.NOT_APPLICABLE,
      }
    }
    const value = _.toNumber(ps)
    const pas = 6
    if (value > 18 && value < 36 && value % pas !== 0) {
      const rest = pas - (value % pas)
      return {
        state: RA_RESULT.ADJUSTED,
        value: _.toString(parseInt(value + rest)),
      }
    }
    return {
      state: RA_RESULT.RAS,
    }
  },
  RAT02(eclairagePublique, segment, fta, ps) {
    if (!["C5"].includes(segment.name) || eclairagePublique) {
      return {
        state: RA_RESULT.NOT_APPLICABLE,
      }
    }
    const value = _.toNumber(ps)
    const pas = 3
    if (value < 18 && value % pas !== 0) {
      const rest = pas - (value % pas)
      return {
        state: RA_RESULT.ADJUSTED,
        value: _.toString(parseInt(value + rest)),
      }
    }
    return {
      state: RA_RESULT.RAS,
    }
  },
  RAT03(eclairagePublique, segment, fta, ps) {
    if (!["C4", "C3", "C2"].includes(segment.name)) {
      return {
        state: RA_RESULT.NOT_APPLICABLE,
      }
    }
    const value = _.toNumber(ps)
    return {
      state: RA_RESULT.ADJUSTED,
      value: _.toString(parseInt(value)),
    }
  },
}

export const RG_TURPE = {
  RG_FTA(eclairagePublique, segment) {
    switch (segment) {
      case "C_2" || "C_3":
        return [
          Ftas.HTA_CU_POINTE_FIXE.name,
          Ftas.HTA_LU_POINTE_FIXE.name,
          Ftas.HTA_CU_POINTE_MOBILE.name,
          Ftas.HTA_LU_POINTE_MOBILE.name,
        ]
      case "C_4":
        return [Ftas.BTSUP_CU.name, Ftas.BTSUP_LU.name]
      case "C_5":
        if (eclairagePublique.eclairagePublique) {
          return [Ftas.BTINF_CU_BASE.name, Ftas.BTINF_CU_4.name, Ftas.BTINF_LU_BASE.name, Ftas.BTINF_MU_4.name]
        }
        return [
          Ftas.BTINF_CU_BASE.name,
          Ftas.BTINF_CU_4.name,
          Ftas.BTINF_LU_BASE.name,
          Ftas.BTINF_MU_HP_HC.name,
          Ftas.BTINF_MU_4.name,
        ]
    }
    return []
  },
}
