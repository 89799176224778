<template>
  <section class="input-field col s6">
    <input id="codeInsee" type="text" v-model="field" class="validate" required ref="field" />
    <label :class="{ active: field }" for="codeInsee">
      <font-awesome-icon icon="asterisk" class="red-text" />
      Code Insee
    </label>
  </section>
</template>

<script>
import Mixin from "./Mixin"

export default {
  name: "code-insee",
  mixins: [Mixin],
  methods: {
    guardian() {
      if (this.field && this.field.length && !/^[0-9]{5}$/.test(this.field)) {
        this.$refs.field.setCustomValidity("Mauvais format de code Insee")
      } else {
        this.$refs.field.setCustomValidity("")
      }
    },
  },
  watch: {
    field() {
      this.guardian()
    },
  },
  mounted() {
    this.guardian()
  },
}
</script>

<style lang="scss" scoped></style>
