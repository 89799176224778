<template>
  <mcma-card title="Progression Actuelle" icon="actual_progress" class="mcma-card-actual-progress" :inline="true">
    <!--    <template #describe>-->
    <!--      Votre progression actuelle est de-->
    <!--    </template>-->
    <template #headerRightSide>
      <a-progress strokeColor="#1890ff" :percent="progression" :format="() => progression + '%'" :strokeWidth="22" />
    </template>
    <mcma-card
      v-if="
        (membre && membre.status === Constants.STATUS.IN_PROGRESS.value && progression === 100) ||
        membre.status === Constants.STATUS.EXPORTED.value ||
        membre.status === Constants.STATUS.APPROVED.value ||
        canCertify
      "
      class="mcma-card-actual-progress-body"
    >
      <div v-if="membre.status === Constants.STATUS.IN_PROGRESS.value && canCertify" class="sentence">
        <p class="principal-message inline">
          Vous avez rempli toutes les <strong>informations necessaires</strong>, Merci ! <br />
          <strong> Pensez à bien cliquer sur le bouton certifier ci dessous</strong> <br />
          <strong> pour en informer votre coordonnateur qui procèdera à la validation de vos données </strong> <br />
          et ainsi recevoir l'export de vos données par mail.
        </p>
      </div>

      <div
        v-if="
          (membre && membre.status === Constants.STATUS.EXPORTED.value) ||
          membre.status === Constants.STATUS.APPROVED.value || membre.certified
        "
        class="sentence"
      >
        <p class="principal-message with-bottom">
          Nous vous remercions d'avoir entièrement validé vos données. Elles sont maintenant
          <mcma-icon
            leftImage="certified_white"
            color="white"
            :coloriseText="true"
            text-transform="capitalize"
            name="certifiées"
          />
          et transmises à l'équipe en charge de votre groupement.
        </p>
        <p class="secondary-message done">Vous n'avez plus d'action à réaliser.</p>
      </div>
      <mcma-button
        v-if="canCertify"
        name="Certifier le membre / exporter les données"
        color="success"
        class="mt24"
        @click="showCertifyModal = true"
      />
    </mcma-card>

    <mcma-certify
      v-if="canCertify"
      :visible="showCertifyModal"
      :canCertify="canCertify"
      noCertifyMessage="Ce membre ne peut pas être certifié."
      :title="`Validation du membre : ${membre.nom}`"
      who="ce membre"
      extraMessage="Vous ne pourrez plus modifier ni ajouter des sites, périmètres de facturation et interlocuteurs, via cette application après validation."
      @onCancel="closeCertifyModal"
      @onCertify="certify"
    />
  </mcma-card>
</template>

<script>
import McmaButton from "@/components/mcma/common/McmaButton"
import McmaCard from "@/components/mcma/common/McmaCard"
import McmaIcon from "@/components/mcma/common/McmaIcon"
import McmaCertify from "@/components/mcma/common/McmaCertify"
import { mapState } from "vuex"
import Axios from "@/util/Axios"
import Constants from "@/util/Constants"

export default {
  name: "ActualProgress",
  components: {
    McmaCard,
    McmaIcon,
    McmaButton,
    McmaCertify,
  },
  data() {
    return {
      showCertifyModal: false,
    }
  },
  computed: {
    ...mapState(["membre", "membreStats", "user", "operation", "confOperation"]),
    Constants() {
      return Constants
    },
    progression() {
      // console.log('progression@ActualProgress')
      if (this.membreStats) {
        // console.log('this :  %O', (this.membreStats.progress || 0))
        var value = Math.trunc((this.membreStats.progress || 0) * 100)
        if(value > 100){
          value = 100
        }
        return value
      }
      return 0
    },
    canCertify() {
      if (this.membre && this.progression === 100) {
        if (this.membre.status === "IN_PROGRESS" && !this.membre.certified) {
          return true
        } else if (this.user.role !== "CLIENT" && this.membre.status === "INITIALISED") {
          return true
        }
      }
      return false
    },
  },
  methods: {
    goTo(routeName) {
      this.$router.push({
        name: routeName,
      })
    },
    closeCertifyModal() {
      this.showCertifyModal = false
    },
    certify() {
      this.closeCertifyModal()
      Axios("patch", "api/membres/" + this.membre.id, {
        certified: true,
      })
        .then(() => {
          this.$notification.success({
            message: `Membre Certifié !`,
            description: `Le membre à bien été certifié !`,
          })
          this.$nextTick(() => {
            this.membre.status = Constants.STATUS.EXPORTED.value
          })
        })
        .catch((error) => {
          console.error("error", error)
          this.$notification.error({
            message: `Erreur de validation`,
            description: `Une erreur s'est produite lors de la certification du membre, veuillez contacter un administrateur.`,
          })
        })
    },
  },
}
</script>

<style lang="scss">
@import "./src/assets/scss/variables";
@import "./src/assets/scss/functions";

.mcma-card-actual-progress {
  .headerRightSide {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
  }
  .card-body {
    .mcma-card-actual-progress-body {
      padding-top: 0;
      .card-body {
        padding-top: 0;
        display: flex;
        flex-flow: column nowrap;
        align-items: center;
      }
      .sentence {
        display: flex;
        flex-flow: column nowrap;
        align-items: center;
        text-align: center;
        .principal-message {
          display: flex;
          flex-flow: column nowrap;
          font-size: 16px;
          color: var(--text-color, $text-color);
          font-weight: 400;
          strong {
            color: var(--primary-color, $primary-color);
          }
          &.inline {
            display: inline;
          }
          &.with-bottom {
            margin-top: var(--padding, $padding);
            margin-bottom: calc(var(--padding, $padding) * 2);
          }
          .mcma-icon {
            align-self: center;
            background: var(--success-color, $success-color);
            border-radius: 4px;
            padding: 8px 15px 7px 15px;
            margin: 20px 0;
            font-weight: 900;
            img {
              height: 22px;
            }
          }
        }
        .secondary-message {
          font-size: 18px;
          font-weight: 900;
          background: var(--secondary-color, $orange);
          color: $white;
          border-radius: 4px;
          padding: 20px;
          margin-top: var(--padding, $padding);
          position: relative;
          &:before {
            content: "";
            position: absolute;
            top: calc(var(--padding, $padding) * -1);
            left: calc(var(--padding, $padding) * -1);
            width: calc(100% + (var(--padding, $padding) * 2) * 2);
            height: 1px;
            background-image: linear-gradient(to right, $border-color-base 66%, rgba($border-color-base, 0) 0%);
            background-position: top;
            background-size: 15px 1px;
            background-repeat: repeat-x;
          }
          &.done {
            background: var(--success-color, $success-color);
          }
          &.pending {
            background: var(--secondary-color, $orange);
          }
        }
      }
    }
  }
  .ant-progress {
    .ant-progress-outer {
      width: calc(100% - 60px);
    }
    .ant-progress-text {
      font-size: 22px;
      margin-left: 25px;
      line-height: 25px;
      text-align: right;
      font-weight: 300;
      color: var(--text-color, $text-color);
    }
    &.ant-progress-status-success {
      .ant-progress-text {
        i {
          color: var(--success-color, $success-color);
          svg {
            width: 30px;
            height: 30px;
          }
        }
      }
    }
  }
}
</style>
